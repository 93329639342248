export const APP_SLICE = 'app';

export const APP_INITIAL_STATE: AppState = {
  selectedTab: 0,
  selectedRadioButton: 'SB ACM',
  selectedCheckerTab: 0,
  selectedReasonTab: 0,
};
export const RADIO_OPTIONS = [
  {
    label: 'SB ACM', value: 'SB ACM',
  },
  {
    label: 'SB MOD ACM', value: 'SB MOD ACM',
  },
];

export const USER_GUIDE_ERROR = 'Failed to load the user guide';
export const USER_GUIDE_FILE_NAME = 'userguide.pdf';
export const USER_GUIDE = 'User guide';
