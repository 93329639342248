/*
 * File: locale.ts
 * Airbus, 2024
 * Author: CMIS FE
 * Description: CMIS APP Locale
 * Copyright (C) 2023-2024 CMIS APP
 * This file is part of CMIS APP.
 *
 * CMIS APP is Aviation product:
 * Used in the latest version
 *
 * CMIS APP FE Code
 *
 * This belong to with CMIS APP.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  LOAD_POST_API_METHOD, POST_DISCLAIMER_API_PATH,
} from '../../utils/config/apiConfig';
import {
  DISCLAIMER_DETAILS_SLICE, POST_DISCLAIMER_STATUS,
} from './constants';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';

// Thunk to update the disclaimer status
export const postDisclaimerStatus: any = (payload: DisclaimerStatusAPIPayloadType) => {
  const customAction = {
    sliceName: DISCLAIMER_DETAILS_SLICE,
    actionType: POST_DISCLAIMER_STATUS,
  };
  const apiRequestObj = {
    path: POST_DISCLAIMER_API_PATH,
    method: LOAD_POST_API_METHOD,
    body: payload,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
