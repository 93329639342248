/* eslint-disable @typescript-eslint/no-explicit-any */
import { restructureResponseForCheckerTable } from '../../utils/commonMethods/generalMethods';
import { getPaginatedChunkData, getReasonStatusMapping } from '../../utils/commonUtil';
import { CHECKER_DETAILS_INITIAL_STATE } from './constants';

export const setCheckerDetailsTableDataFulfilled = (state: CheckerDetailsTableStateType, action: CheckerDetailsTableAction) => {
  const calculatedPageNum = (action.payload.records_offset + state.paginationData.rowPerPage) / state.paginationData.rowPerPage;
  const reasonStatusMapping = getReasonStatusMapping();
  const restructuredData = restructureResponseForCheckerTable(action.payload.result, reasonStatusMapping);
  const prevPage = state.paginationData.page;
  const chunkData = getPaginatedChunkData(
    calculatedPageNum,
    restructuredData.tableData,
    state.paginationData.rowPerPage,
    prevPage,
    action.payload.records_offset,
    state.total_records,
  );
  return {
    ...state,
    isCheckerDetailsDataLoading: false,
    checkerDetailsData: restructuredData,
    total_records: action.payload.result.total_result,
    // // update the pagination config as per response count
    paginationData: {
      start: action.payload.records_offset,
      end: action.payload.records_offset + action.payload.result.ACR_MIS_data.length,
      rowPerPage: state.paginationData.rowPerPage,
      page: calculatedPageNum, // currently selected
      selectedChunkData: chunkData,
    },
  };
};

export const setCheckerDetailsTableDataPending = (state: CheckerDetailsTableStateType) => ({
  ...state,
  isCheckerDetailsDataLoading: true,
});

export const setCheckerDetailsTableDataRejected = (state: CheckerDetailsTableStateType, action?:FilterAPIRejectedAction) => {
  // if request comes from column level filter and no record found for the combination we are showing the previous state
  if (action?.meta.arg === 'columnLevelFilter') {
    return { ...state, isCheckerDetailsDataLoading: false };
  }
  return {
    ...state,
    isCheckerDetailsDataLoading: false,
    errorMessage: 'Table Data Failed',
    checkerDetailsData: CHECKER_DETAILS_INITIAL_STATE.checkerDetailsData,
    paginationData: CHECKER_DETAILS_INITIAL_STATE.paginationData,
    total_records: CHECKER_DETAILS_INITIAL_STATE.total_records,
    records_offset: CHECKER_DETAILS_INITIAL_STATE.records_offset,
  };
};

// load fleet fin and ata together
export const loadCheckerFleetFinAndAtaDetailsPending = (state: CheckerDetailsTableStateType) => ({
  ...state,
  isFilterDetailsFinLoading: true,
});

export const loadCheckerFleetFinAndAtaDetailsRejected = (state: CheckerDetailsTableStateType, action: CheckerDetailsTableAction) => {
  return {
    ...state,
    isFilterDetailsFinLoading: false,
    errorMessage: action.payload?.message,
    fleetDetailFilterOptions: {
      ...state.fleetDetailFilterOptions,
    },
  };
};

export const loadCheckerFleetFinAndAtaDetailsFulfilled = (state: CheckerDetailsTableStateType, action: CheckerDetailsTableAction) => {
  const FleetFinResp = action.payload?.result?.ACR_MIS_data.map((item) => item.fleet_data.fleet_fin)
    .filter((item:any, index: number, array: any) => array.indexOf(item) === index);
  const FleetAtaResp = action.payload?.result?.ACR_MIS_data.map((item) => item.fleet_data.ata)
    .filter((item: any, index: number, array: any) => array.indexOf(item) === index);
  if (action.meta?.arg === 'CheckerDetailsOperatorChanged') {
    return {
      ...state,
      isFilterDetailsFinLoading: false,
      checkerDetailsFilterOptions: {
        ...state.checkerDetailsFilterOptions,
        fin: FleetFinResp,
        ata: FleetAtaResp?.filter((ata) => !!ata),
      },
    };
  }
  return {
    ...state,
    isFilterDetailsFinLoading: false,
    fleetDetailFilterOptions: {
      ...state.fleetDetailFilterOptions,
      fin: FleetFinResp,
      ata: FleetAtaResp?.filter((ata) => !!ata),
    },
  };
};
