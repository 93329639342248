/* eslint-disable @typescript-eslint/no-explicit-any */
import { popDetailsCellWrapper } from '../../../utils/TableUtils/ColumnUtils';

export const reasonDetailColumns = [
  {
    accessor: 'source',
    id: 'source',
    align: 'left',
    Header: 'Source',
    width: 1,
    disableSortBy: true,
  },
  {
    accessor: 'pnr',
    id: 'pnr',
    align: 'left',
    Header: 'Part Number',
    width: 80,
    Cell: (row: any) => popDetailsCellWrapper(row),
    disableSortBy: true,
  },
  {
    accessor: 'as_allowed_pnr',
    id: 'as_allowed_pnr',
    align: 'left',
    Header: 'As allowed part number',
    width: 80,
    Cell: (row: any) => popDetailsCellWrapper(row),
    disableSortBy: true,
  },
  {
    accessor: 'as_allowed_cond',
    id: 'as_allowed_cond',
    align: 'left',
    Header: 'As allowed conditions',
    width: 600,
    Cell: (row: any) => popDetailsCellWrapper(row),
    disableSortBy: true,
  },
];
