/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { rootReducer } from '../root_reducer/appReducer';
import { isRequiredForEnv } from '../utils/commonUtil';

const logger = createLogger({
  // to show the difference between what changed in state
  diff: true,

  // to log time
  duration: true,
  timestamp: true,
});
// The console logs will be shown in development Environment only
export const loggerEnv:any = isRequiredForEnv(['dev']) ? logger : [];
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(loggerEnv),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
