/* eslint-disable @typescript-eslint/no-explicit-any */
// Extra Reducers to handle promises of Async Thunk
import { storeReasonStatusMapping } from '../../utils/commonUtil';

export const loadReasonStatusMapPending = (state: ReasonStatusMappingState) => ({
  ...state,
});

export const loadReasonStatusMapRejected = (state: ReasonStatusMappingState, action: any) => {
  return {
    ...state,
    loadReasonStatusMapping: true,
    errorMessage: action.payload?.message,
    status: action.payload?.ResponseMetadata?.HTTPStatusCode,
  };
};

export const loadReasonStatusMapFullfilled = (state: ReasonStatusMappingState, action: ReasonStatusMappingAction) => {
  // Inverting the mapping
  const ReasonKeyMaper = action.payload?.data?.reason_key_mapper ? Object.fromEntries(Object.entries(action.payload?.data?.reason_key_mapper).map(([key, value]) => [value, key])) : {};
  const StatusKeyMaper = action.payload?.data?.status_key_mapper ? Object.fromEntries(Object.entries(action.payload?.data?.status_key_mapper).map(([key, value]) => [value, key])) : {};
  storeReasonStatusMapping(ReasonKeyMaper, StatusKeyMaper);
  return {
    ...state,
    loadReasonStatusMapping: false,
    data: {
      reason_key_mapper: ReasonKeyMaper,
      status_key_mapper: StatusKeyMaper,
    },
  };
};
