import React from 'react';
import type { ChartData, ChartOptions } from 'chart.js';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';

// registering plugins globally
ChartJS.register(ArcElement, Tooltip, Legend);

type PieChartProps = {
  data: ChartData<'pie'>;
  options: ChartOptions<'pie'>;
};

export function PieChart(props: PieChartProps) {
  const { data, options } = props;
  return <Pie data={data} options={options} />;
}
