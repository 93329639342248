import {
  Button, Col, ColorModeProvider, Popover, Row, Stack,
} from '@airbus/components-react';
import {
  ArrowDropDown, ArrowDropUp, Settings, Input,
} from '@airbus/icons/react';
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LOGOUT_BUTTON, SETTINGS, USER_SCOPE_NSWC } from '../../utils/constants';
import UseOutsideClick from '../Shared/OutsideClick/UseOutsideClick';
import './ProfileMenu.scss';
import { getUserName } from '../../utils/commonMethods/generalMethods';
import { useAppSelector } from '../../store/hooksTypes';

const ProfileMenu: React.FC = function ProfileMenu() {
  const navigate = useNavigate();
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [hidden] = useState(false);
  const featureToggleConfig = useAppSelector((state) => state.featureToggleDetails?.data);
  const { userScope } = useAppSelector((state) => state.authEntrySlice);
  const handleArrowToggle = () => {
    setOpen(!open);
  };
  UseOutsideClick(buttonRef, () => {
    setOpen(false);
  });

  return (
    <>
      <Stack spacing="2-x">
        <Button
          ref={buttonRef}
          variant="primary"
          onClick={handleArrowToggle}
          aria-haspopup="dialog"
          className="user-button"
          data-testid="profile-button"
        >
          {getUserName()}
          {open === false ? <ArrowDropDown /> : <ArrowDropUp />}
        </Button>
      </Stack>
      {open && (
        <ColorModeProvider mode="light">
          <Popover
            placement="top"
            className="usermenu-popup"
            open={open}
            referenceNode={buttonRef.current}
            arrow={hidden}
            data-testid="profile-popover"
          >
            <Row className="profile-row">
              {featureToggleConfig?.email === 'true' && userScope !== USER_SCOPE_NSWC
              && (
              <Col xxs={7}>
                <Button
                  className="logout-button"
                  aria-label="settings-button"
                  data-testid="settings-button"
                  onClick={() => navigate('/settings')}
                  icon={<Settings className="profile-icon" />}
                >
                  {SETTINGS}
                </Button>
              </Col>
              )}
              <Col xxs={7}>
                <Button
                  className="logout-button"
                  aria-label="logout-button"
                  data-testid="logout-button"
                  onClick={() => navigate('/SignOut')}
                  icon={<Input className="profile-icon" />}
                >
                  {LOGOUT_BUTTON}
                </Button>
              </Col>
            </Row>
          </Popover>
        </ColorModeProvider>
      )}
    </>
  );
};
export default ProfileMenu;
