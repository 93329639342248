/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Buffer } from 'buffer';
import React, { useEffect, useState } from 'react';
import {
  Col, ComboBox, Row, Spinner, Typography,
} from '@airbus/components-react';
import { useSearchParams } from 'react-router-dom';
import {
  COMBOBOX_PLACEHOLDER, DEFAULT_COL_FILTER_LABEL, DEFAULT_COL_FILTER_VALUE, FIN_STATUS_MAPPER, columnlevelCheckerKeys, NEW_HYBRID_BOX_COLUMN_FILTER,
} from '../../../../utils/constants';
import { rowsVisible } from '../../../../utils/commonMethods/modalPopupMethods';
import {
  getComboboxOpions, getSmallerTextValue, handleFilterSelection, initialSelectedComboValues,
} from './ColumnLevelhandleUtils';
import { chipConstants } from '../../ChipLabel/constants';
import { HybridComboBox } from '../../HybridComboBox/HybridComboBox';
import { getStatusReasonMapper } from '../../../../utils/commonUtil';
import { resetComboBox, setBoxValues } from '../../../../utils/commonMethods/generalMethods';

interface SelectedComboFilters {
  'label': string,
  'value': string,
  'column': string,
  'ext': string
}

const getSelectedDropdownValue: any = (selectedDropdownValue: any[]) => {
  let selectedDropdownValueUpdated: any = {};
  selectedDropdownValueUpdated = selectedDropdownValue.map((item: SelectedComboFilters) => {
    const selectedDropdownItem = { ...item };
    if (selectedDropdownItem.label === DEFAULT_COL_FILTER_VALUE) {
      selectedDropdownItem.label = DEFAULT_COL_FILTER_LABEL;
    }
    if (selectedDropdownItem.label in FIN_STATUS_MAPPER) {
      selectedDropdownItem.label = chipConstants[FIN_STATUS_MAPPER[selectedDropdownItem.label]].chipText;
    }
    if (columnlevelCheckerKeys.includes(selectedDropdownItem.column) && (selectedDropdownItem.label.includes('#'))) {
      // eslint-disable-next-line prefer-destructuring
      selectedDropdownItem.label = selectedDropdownItem.label.split('#')[1];
      selectedDropdownItem.label = getSmallerTextValue(selectedDropdownItem.label, 60);
    }
    return selectedDropdownItem;
  });
  return selectedDropdownValueUpdated;
};

const getQryParamsSet: any = (qryParamsSet: any) => {
  return qryParamsSet && qryParamsSet.length ? JSON.parse(Buffer.from(qryParamsSet[0], 'base64').toString('ascii')) : '';
};

const ComboboxUtils: any = (props: any) => {
  const {
    tableHeaders, stateChange, resetForm, tableState, dispatcher, filterthunk, filterPayloadFn, filterStates, setDataConfigFn, reasonStatusMappingState,
  } = props;
  const columnLevelFilterState = tableState;
  /* Initial state for filter and combo box based on the column headers */
  const intialStateFilter: any = {};
  const intialSpinnerData: any = [];
  tableHeaders.map((col: any) => {
    intialStateFilter[col.id] = [];
    intialSpinnerData[col.id] = false;
    return intialStateFilter;
  });

  /* Hooks : Column Level Filters */
  const columnFilter = Object.values(columnLevelFilterState.FilterState);
  const [filterValues, setFilterValues] = useState(columnFilter.length === 0 ? intialStateFilter : columnLevelFilterState.FilterState);
  const [comboBoxValues, setcomboBoxValues] = useState(columnLevelFilterState.FilterState);
  const [spinner, setSpinner] = useState(intialSpinnerData);
  const [selectedCombovalue, setSelectedComboValues] = useState(initialSelectedComboValues(columnLevelFilterState.FilterState));

  const [qryParams] = useSearchParams();
  const qryParamsSetDecrypted = getQryParamsSet(qryParams.getAll('msn_sb'));
  useEffect(() => {
    setBoxValues(
      columnLevelFilterState,
      getStatusReasonMapper,
      reasonStatusMappingState,
      setcomboBoxValues,
      comboBoxValues,
      setSpinner,
      intialSpinnerData,
    );
  }, [columnLevelFilterState.FilterData]);

  useEffect(() => {
    resetComboBox(resetForm, setSelectedComboValues, setFilterValues, intialStateFilter);
  }, [resetForm]);

  useEffect(() => {
    const payLoad = filterPayloadFn('id_aircraft_registration', filterStates, filterValues, dispatcher, filterthunk, qryParamsSetDecrypted, tableState);
    const payloadArray = stateChange(payLoad, filterValues);
    setDataConfigFn(payloadArray, filterValues);
  }, [filterValues]);

  /* onchange : fetch filter values api call */
  const fetchFilterValues = (col: any) => {
    setSpinner({ ...spinner, [col]: true });
    const bodyPayload = filterPayloadFn(col, filterStates, filterValues, dispatcher, filterthunk, qryParamsSetDecrypted, tableState);
    const payloadArray = stateChange(bodyPayload, filterValues);
    setDataConfigFn(payloadArray, filterValues);
  };

  return (
    tableHeaders.map((headerItem: any) => {
      const { id, Header, filterMatch } = headerItem;
      const selectedDropdownValue = selectedCombovalue[id] ? selectedCombovalue[id] : [];
      /* When we are updating the selected values to combo box the both label and value should be same
      but for the default value of column level filter both are different so that we are updating here */
      const selectedDropdownValueUpdated = getSelectedDropdownValue(selectedDropdownValue);
      const ariaLabel = `${id}`;
      const isChecker = 'isCheckerDetailsDataLoading' in columnLevelFilterState;
      const optionValues = getComboboxOpions(id, comboBoxValues, isChecker);
      return (
        <Row className="data-filter-row" key={id}>
          <Col xxs={3} key={id} className="data-filter-label">
            <Typography variant="small" className="filter-column-color">
              {Header}
            </Typography>
          </Col>
          <Col xxs={1}>
            {spinner[id] === true && <Spinner className="spinner-align" />}
          </Col>
          <Col xxs={8} className="dynamic-header-outside">
            {NEW_HYBRID_BOX_COLUMN_FILTER.includes(Header) ? (
              <HybridComboBox
                state={tableState}
                dispatcher={dispatcher}
                disabled={false}
                selectedOptions={selectedDropdownValueUpdated}
                isSearchDisabled={false}
                hybridComboClassName="hybrid-column-level"
                placement="bottom"
                id={`combo-box-id-${id}`}
                data-testid="filter-selector"
                handleApply={(value: any) => {
                  handleFilterSelection(
                    value,
                    setFilterValues,
                    filterValues,
                    setSelectedComboValues,
                    id,
                    selectedCombovalue,
                  );
                }}
                isLazyloading
                showApply={false}
                options={optionValues}
                mode="columnLevel"
                applyFocus
                handleFocus={() => fetchFilterValues(id)}
              />
            ) : (
              <ComboBox
                multiple
                filterMatch={filterMatch}
                size="small"
                id="combo-box-id"
                allowVirtualization
                virtualizationOptions={{
                  overscanCount: 10,
                  rowsVisible: rowsVisible(optionValues, 4),
                  rowHeight: 50,
                }}
                getChipLabel={(option) => option.ext}
                aria-label={ariaLabel}
                value={selectedDropdownValueUpdated}
                data-testid="filter-selector"
                placeholder={
                  selectedDropdownValue.length ? '' : COMBOBOX_PLACEHOLDER
                }
                limitChips={3}
                options={optionValues}
                onChange={(value: any) => {
                  handleFilterSelection(
                    value,
                    setFilterValues,
                    filterValues,
                    setSelectedComboValues,
                    id,
                    selectedCombovalue,
                  );
                }}
                onFocus={() => fetchFilterValues(id)}
              />
            )}
          </Col>
        </Row>
      );
    })
  );
};

export default ComboboxUtils;
