import { getPaginatedChunkData } from '../../utils/commonUtil';
import { DATATABLE_INITIAL_STATE, PART_DATA_ERROR } from './constants';

export const partDataTableReducers = (
  state: PartDataTableState,
  action: PartDataTableAction,
) => {
  const calculatedPageNum = action.payload.paginationData.page;
  const prevPage = state.paginationData.page;
  const chunkData = getPaginatedChunkData(
    calculatedPageNum,
    action.payload.PartBrowserData,
    action.payload.paginationData.rowPerPage,
    prevPage,
    state.paginationData.start,
    state.total_records,
  );
  return ({
    ...state,
    paginationData: {
      start: action.payload.paginationData.start,
      end: action.payload.paginationData.end,
      rowPerPage: action.payload.paginationData.rowPerPage,
      page: action.payload.paginationData.page,
      selectedChunkData: chunkData,
    },
  });
};
export const setDataTableColumns = (state: PartDataTableState, action: PartDataTableAction) => {
  return ({
    ...state,
    columns: action.payload.columns,
  });
};
export const ColumnFilterStateUpdate = (state: PartDataTableState, action: PartDataTableAction) => {
  return {
    ...state,
    isColumnDataLoading: false,
    FilterState: action.payload.records,
  };
};

export const ColumnFilterPayloadUpdate = (state: PartDataTableState, action: PartDataTableAction) => {
  return {
    ...state,
    isColumnDataLoading: false,
    FilterPayload: action.payload.records,
  };
};
export const ColumnLevelPending = (
  state: PartDataTableState,
) => ({
  ...state,
  isColumnDataLoading: true,
});

export const ColumnLevelRejected = (state: PartDataTableState) => {
  return {
    ...state,
    isColumnDataLoading: false,
    errorMessage: PART_DATA_ERROR,
    FilterData: DATATABLE_INITIAL_STATE.FilterData,
  };
};

export const ColumnLevelFullfilled = (state: PartDataTableState, action: PartDataTableAction) => {
  return {
    ...state,
    isColumnDataLoading: false,
    FilterData: action.payload.data,
  };
};
