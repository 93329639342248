/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/button-has-type */
import './DownloadInfoDrawer.scss';
import React, { useEffect, useRef } from 'react';
import {
  Container, Row, Col, Typography, Drawer,
} from '@airbus/components-react';
import { Close } from '@airbus/icons/react';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import { clearNotificationCount, updateDrawerState, clearHighlight } from '../../../models/downloadStatusModel/downloadStatusSlice';
import UseOutsideClick from '../OutsideClick/UseOutsideClick';
import { DOWNLOADS } from '../../../utils/constants';
import DownloadInfoList from './DownloadInfoList/DownloadInfoList';

const DownloadInfoDrawer: React.FC = () => {
  const drawerRef = useRef(null);
  const drawerDispatch: any = useAppDispatch();
  const { isDownloadDrawerClose, downloadStatus } = useAppSelector((state) => state.downloadStatus);

  UseOutsideClick(drawerRef, () => {
    drawerDispatch(updateDrawerState(false));
    drawerDispatch(clearHighlight());
  });

  useEffect(() => {
    if (isDownloadDrawerClose) {
      drawerDispatch(clearNotificationCount());
    }
  }, [isDownloadDrawerClose]);

  const onDrawerClose: () => void = () => {
    drawerDispatch(updateDrawerState(!isDownloadDrawerClose));
    drawerDispatch(clearHighlight());
  };

  return (
    <Container>
      <Drawer anchor="right" open={isDownloadDrawerClose} className="custom-download-drawer" data-testid="drawer-open" disableBackdrop>
        <div ref={drawerRef} className="download-drawer-container">
          <Row className="download-drawer-header">
            <Col xxs={6}>
              <Typography variant="large" className="download-text">{DOWNLOADS}</Typography>
            </Col>
            <Col xxs={6} className="download-align close-button-align">
              <Close onClick={onDrawerClose} className="close-drawer" data-testid="drawer-close" />
            </Col>
          </Row>
          <DownloadInfoList downloadStatus={downloadStatus} currentDate={new Date()} />
        </div>
      </Drawer>
    </Container>
  );
};

export default DownloadInfoDrawer;
