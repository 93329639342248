/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Card, Textarea, Typography,
} from '@airbus/components-react';
import { useEffect, useState } from 'react';
import './CheckerFinEditComponent.scss';
import CheckerFinStatusComponent from './CheckerFinStatusComponent';
import CheckerFinEditHistoryComponent from './CheckerFinEditHistoryComponent';
import {
  COMMENTS,
} from '../../utils/constants';
import { handleOnchangeComment } from './CheckerFinEditModelUtils';

const CheckerFinInlineModelComponent = ({
  selectedFin,
  finStatusHistory,
  checkerDataTableState,
  CheckerFinEditModalPopupDispatcher,
}: any) => {
  const [commentText, setCommentText] = useState<Array<CheckerSelectedFinDataType | any>>(selectedFin);
  const handleChangeCmdArgs = {
    commentText,
    setCommentText,
    checkerDataTableState,
    CheckerFinEditModalPopupDispatcher,
  };
  useEffect(() => {
    const newFinArr = commentText.map((item: CheckerSelectedFinDataType) => {
      const changedFinObj = checkerDataTableState.finChangesState.find((finValue: CheckerSelectedFinDataType) => (
        finValue.fleet_fin === item.fleet_fin
        && finValue.id_aircraft_registration === item.id_aircraft_registration));
      const setCommentObj = changedFinObj || item;
      return { ...setCommentObj };
    });
    setCommentText(newFinArr);
  }, [checkerDataTableState.finChangesState]);

  return (
    <div className="modal-container-outer">
      {
        selectedFin.map((selectedFinValues: CheckerSelectedFinDataType, index: number) => (
          <Card className="modal-container-card" key={selectedFinValues.fleet_fin}>
            <div className="modal-content" key={selectedFinValues.fleet_fin}>
              <div className="status-section">
                <CheckerFinStatusComponent statusProps={selectedFinValues} checkerDataTableState={checkerDataTableState} CheckerFinEditModalPopupDispatcher={CheckerFinEditModalPopupDispatcher} />
              </div>
              <div className="commends-section">
                <Typography className="comments-header-label">
                  {COMMENTS}
                  {' '}
                  <span className="required-comments-fields"> *</span>
                </Typography>
                <Textarea
                  rows={1}
                  data-testid="commend-id"
                  placeholder=""
                  maxLength={1000}
                  value={commentText[index].comment}
                  defaultValue={commentText[index].comment}
                  onChange={(event) => handleOnchangeComment(event.target.value, index, selectedFinValues, handleChangeCmdArgs)}
                />
              </div>
              <div className="history-section">
                <CheckerFinEditHistoryComponent finStatusHistory={finStatusHistory && finStatusHistory.data[`${selectedFinValues.id_aircraft_registration}__SEP__${selectedFinValues.fleet_fin}`] && finStatusHistory.data[`${selectedFinValues.id_aircraft_registration}__SEP__${selectedFinValues.fleet_fin}`]} finIndex={index} checkerDataTableState={checkerDataTableState} />
              </div>
            </div>
          </Card>
        ))
      }
    </div>
  );
};
export default CheckerFinInlineModelComponent;
