/*
 * File: locale.ts
 * Airbus, 2024
 * Author: CMIS FE
 * Description: CMIS APP Locale
 * Copyright (C) 2023-2024 CMIS APP
 * This file is part of CMIS APP.
 *
 * CMIS APP is Aviation product:
 * Used in the latest version
 *
 * CMIS APP FE Code
 *
 * This belong to with CMIS APP.
 */
import { createSlice } from '@reduxjs/toolkit';
import {
  FEATURE_TOGGLE_SLICE,
  FEATURE_TOGGLE_INITIAL_STATE,
} from './constants';
import {
  postFeatureToggleFullfilled,
  postFeatureTogglePending,
  postFeatureToggleRejected,
} from './featureToggleReducer';
import { postFeatureToggleStatus } from './featureToggleThunk';

export const featureToggleDetailsSlice = createSlice({
  name: FEATURE_TOGGLE_SLICE,
  initialState: FEATURE_TOGGLE_INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postFeatureToggleStatus().pending, postFeatureTogglePending)
      .addCase(postFeatureToggleStatus().rejected, postFeatureToggleRejected)
      .addCase(postFeatureToggleStatus().fulfilled, postFeatureToggleFullfilled);
  },
});
export default featureToggleDetailsSlice;
