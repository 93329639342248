/* eslint-disable @typescript-eslint/no-explicit-any */
import { tableInCellRows } from '../../../utils/TableUtils/ColumnUtils';

export const deviationTableColumns = [
  {
    accessor: 'source',
    id: 'source',
    align: 'left',
    Header: 'Source',
    width: 1,
    disableSortBy: true,
  },
  {
    accessor: 'fin_origin',
    id: 'fin_origin',
    align: 'left',
    Header: 'FIN origin',
    width: 80,
    disableSortBy: true,
  },
  {
    accessor: 'pn_origin',
    id: 'pn_origin',
    align: 'left',
    Header: 'PN origin',
    width: 80,
    Cell: (row: any) => tableInCellRows(row),
    disableSortBy: true,
  },
  {
    accessor: 'deviation_description',
    id: 'deviation_description',
    align: 'left',
    Header: 'Description',
    width: 400,
    Cell: (row: any) => tableInCellRows(row),
    disableSortBy: true,
  },
];
