import { createSlice } from '@reduxjs/toolkit';
import { APP_INITIAL_STATE, APP_SLICE } from './constants';
import {
  getAppState,
  setBrowserState,
  setAcmSbState,
  setCheckState,
  userGuidePending,
  userGuideRejected,
  userGuideFullfilled,
  setReasonTabState,
} from './appConfigReducer';
import { userGuide } from './appConfigThunk';

export const appSlice = createSlice({
  name: APP_SLICE,
  initialState: APP_INITIAL_STATE,
  reducers: {
    getState: getAppState,
    setBrowseState: setBrowserState,
    setAcmSb: setAcmSbState,
    setCheckerState: setCheckState,
    setReasonTab: setReasonTabState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(userGuide().pending, userGuidePending)
      .addCase(userGuide().rejected, userGuideRejected)
      .addCase(userGuide().fulfilled, userGuideFullfilled);
  },
});
export const {
  getState, setBrowseState, setAcmSb, setCheckerState, setReasonTab,
} = appSlice.actions;
export default appSlice;
