/*
 * File: locale.ts
 * Airbus, 2024
 * Author: CMIS FE
 * Description: CMIS APP Locale
 * Copyright (C) 2023-2024 CMIS APP
 * This file is part of CMIS APP.
 *
 * CMIS APP is Aviation product:
 * Used in the latest version
 *
 * CMIS APP FE Code
 *
 * This belong to with CMIS APP.
 */
export const DISCLAIMER_INITIAL_STATE: disclaimerStateType = {
  isDisclaimerStatusLoading: false,
  errorMessage: '',
};

export const DISCLAIMER_DETAILS_SLICE = 'disclaimerDetails';
export const POST_DISCLAIMER_STATUS = 'postDisclaimerStatus';
export const POST_DISCLAIMER_STATUS_ERROR = 'Failed to load the disclaimer status';
