/* eslint-disable no-nested-ternary */
/*
general and aws configs
*/
export default () => {
  const {
    REACT_APP_DOMAIN,
    REACT_APP_REGION,
    REACT_APP_USERPOOL_ID,
    REACT_APP_USERPOOL_WEBCLIENT_ID,
    REACT_APP_ENDPOINT,
    REACT_APP_ACCOUNTALIASES,
    REACT_APP_ASPIRE,
    REACT_APP_CLOUDFRONT,
  } = process.env;

  const { host } = window.location; // getting the current url to identify the correct redirection url
  const apiEndPoint = host.indexOf('.airbus-v.com') > -1 || host.indexOf('.services.skywise.com') > -1
    ? `https://${REACT_APP_DOMAIN}/`
    : REACT_APP_ENDPOINT;
  const redirectURL = REACT_APP_DOMAIN && REACT_APP_DOMAIN.indexOf('localhost:') > -1
    ? `http://${REACT_APP_DOMAIN}`
    : host.indexOf('.airbus-v.com') > -1 || host.indexOf('.services.skywise.com') > -1
      ? `https://${REACT_APP_DOMAIN}`
      : `https://${REACT_APP_CLOUDFRONT}`;

  const userPoolDomain = `https://${REACT_APP_ACCOUNTALIASES}-${REACT_APP_ASPIRE}.auth.${REACT_APP_REGION}.amazoncognito.com`;

  return {
    Auth: {
      region: REACT_APP_REGION,
      userPoolId: REACT_APP_USERPOOL_ID,
      userPoolWebClientId: REACT_APP_USERPOOL_WEBCLIENT_ID,
      mandatorySignIn: true,
      oauth: {
        domain: `${REACT_APP_ACCOUNTALIASES}-${REACT_APP_ASPIRE}.auth.${REACT_APP_REGION}.amazoncognito.com`,
        scope: [
          'email',
          'openid',
          'profile',
          'phone',
          'aws.cognito.signin.user.admin',
        ],
        authenticationUrl: `${userPoolDomain}/oauth2/authorize?redirect_uri=${redirectURL}&response_type=code&client_id=${REACT_APP_USERPOOL_WEBCLIENT_ID}`,
        logoutUrl: `${userPoolDomain}/logout?redirect_uri=${redirectURL}&response_type=code&client_id=${REACT_APP_USERPOOL_WEBCLIENT_ID}`,
        redirectSignIn: redirectURL,
        redirectSignOut: redirectURL,
        responseType: 'code',
      },
    },
    API: {
      endpoints: [
        {
          name: 'API_CMIS',
          endpoint: apiEndPoint,
          region: REACT_APP_REGION,
        },
        {
          name: 'API_USERPOOL',
          endpoint: userPoolDomain,
          region: REACT_APP_REGION,
        },
      ],
    },
  };
};
