/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  LOAD_CHECKER_DETAILS_API_PATH, LOAD_POST_API_METHOD, LOAD_CHECKER_GET_FIN_HISTORY_API_PATH, LOAD_CHECKER_UPDATE_FIN_API_PATH,
} from '../../utils/config/apiConfig';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { CustomThunkAction } from '../../utils/thunkUtils/thunkTypes';
import {
  CHECKER_DETAILS_SLICE, LOAD_CHECKER_FIN_HISTORY, LOAD_FILTER_DATA, UPDATE_CHECKER_FIN_STATUS, lOAD_CHECKER_DETAILS, lOAD_CHECKER_FLEET_FIN_ATA_DETAILS,
} from './constants';

const createCustomThunkCall = (action: CustomThunkAction, body: CheckerDetailsAPIPayloadType) => {
  const apiRequestObj = {
    path: LOAD_CHECKER_DETAILS_API_PATH,
    method: LOAD_POST_API_METHOD,
    body,
  };
  return createCustomAsyncThunk(action, apiRequestObj);
};

export const loadCheckerDetails: any = (payload: CheckerDetailsAPIPayloadType) => {
  const customAction = {
    sliceName: CHECKER_DETAILS_SLICE,
    actionType: lOAD_CHECKER_DETAILS,
  };
  return createCustomThunkCall(customAction, payload);
};
export const loadCheckerDetailsFilterData: any = (payload: CheckerDetailsAPIPayloadType) => {
  const Action = {
    sliceName: CHECKER_DETAILS_SLICE,
    actionType: LOAD_FILTER_DATA,
  };
  return createCustomThunkCall(Action, payload);
};

export const loadCheckerFleetFinAndAtaDetails: any = (payload: CheckerDetailsAPIPayloadType) => {
  const customAction = {
    sliceName: CHECKER_DETAILS_SLICE,
    actionType: lOAD_CHECKER_FLEET_FIN_ATA_DETAILS,
  };
  return createCustomThunkCall(customAction, payload);
};
// Thunk to get the history details for the fin
export const loadCheckerFinHistoryDetails: any = (payload: CheckerDetailsAPIPayloadType) => {
  const customAction = {
    sliceName: CHECKER_DETAILS_SLICE,
    actionType: LOAD_CHECKER_FIN_HISTORY,
  };
  const apiRequestObj = {
    path: LOAD_CHECKER_GET_FIN_HISTORY_API_PATH,
    method: LOAD_POST_API_METHOD,
    body: payload,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

// Thunk to update the fin status
export const updateCheckerFinStatusDetails: any = (payload: CheckerFinstatusUpdateAPIPayloadType) => {
  const customAction = {
    sliceName: CHECKER_DETAILS_SLICE,
    actionType: UPDATE_CHECKER_FIN_STATUS,
  };
  const apiRequestObj = {
    path: LOAD_CHECKER_UPDATE_FIN_API_PATH,
    method: LOAD_POST_API_METHOD,
    body: payload,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
