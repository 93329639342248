import { getPaginatedChunkData } from '../../utils/commonUtil';

export const sbDataPaginationReducer = (
  state: SbDataTableState,
  action: DataTablePaginationAction,
) => {
  const calculatedPageNum = action.payload.pageNumber;
  const previousPage = state.paginationData.page;
  const chunkData = getPaginatedChunkData(calculatedPageNum, state.SbBrowserData, action.payload.rowPerPage, previousPage, state.paginationData.start, state.total_records);
  return ({
    ...state,
    paginationData: {
      ...state.paginationData,
      page: action.payload.pageNumber,
      rowPerPage: action.payload.rowPerPage,
      selectedChunkData: chunkData,
    },
  });
};
