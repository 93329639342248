import { getDisplayColumns } from '../../utils/commonUtil';
import { tableColumns, partColumns } from '../../components/Shared/Tabs/BrowserTab/BrowserTableStructure';
// Slice
export const DATATABLE_SLICE = 'displayPartDataTable';

export const DATATABLE_INITIAL_STATE: PartDataTableState = {
  isBrowserDataLoading: false,
  errorMessage: '',
  PartBrowserData: [],
  total_records: 0,
  records_offset: 0,
  paginationData: {
    rowPerPage: 100,
    page: 1,
    start: 0,
    end: 0,
    selectedChunkData: [],
  },
  columns: getDisplayColumns([...tableColumns, ...partColumns]),
  FilterData: [],
  FilterState: [],
  FilterPayload: [],
  isColumnDataLoading: false,
};
export const LOAD_PART_DATA = 'loadPartBrowseData';
export const LOAD_FILTER_DATA = 'loadPartFilterData';
export const PART_DATA_ERROR = 'Unable to get PART Browser Data';
