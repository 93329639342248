/* eslint-disable react-hooks/exhaustive-deps */
import './App.scss';
import { Container, Stack } from '@airbus/components-react';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  Navigate, Route, Routes,
} from 'react-router-dom';
import { RoutesConfig, createRouteMapping } from './RouteMapping';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import SignIn from '../../components/SignIn/SignIn';
import SignOut from '../../components/SignOut/SignOut';
import HelpModal from '../../components/Shared/HelpModal/HelpModal';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import ToastComponent from '../../components/Shared/ToastComponent/ToastComponent';
import { toastLocationMap } from '../../models/toastModel/constants';
import DownloadInfoDrawer from '../../components/Shared/DownloadInfoDrawer/DownloadInfoDrawer';
import DisclaimerModalPopup from '../../components/DisclaimerModal/DisclaimerModalPopup';
import { registerPostHogProperties, shouldOpenDisclaimer } from '../../utils/commonUtil';
import { postFeatureToggleStatus } from '../../models/featureToggleModal/featureToggleThunk';
import NotificationSettings from '../../components/NotificationSettings/NotificationSettings';
import { USER_SCOPE_NSWC } from '../../utils/constants';
import UploadFliHistory from '../../components/UploadFliHistory/UploadFliHistory';

const App: React.FC = function App() {
  const dispatcher = useAppDispatch();
  const isHelpModalOpen = useAppSelector((state) => state.helpModal.helpModalState);
  const toastStack = useAppSelector((state) => state.toast);
  const userPermissions = useAppSelector((state) => state.authEntrySlice);
  const disclaimerState = useAppSelector((state) => state.disclaimerDetails);
  const featureToggleConfig = useAppSelector((state) => state.featureToggleDetails?.data);
  const [routerList, setRouterList] = useState<Array<RoutesConfig>>([]);
  const [disclaimerIsOpen, setDisclaimerIsOpen] = useState(false);
  const setRoutesFromPermissions = (moduleList: Array<string>) => {
    const mappedRouters = createRouteMapping(moduleList);
    setRouterList(mappedRouters);
  };
  useEffect(() => {
    if (userPermissions.moduleList.length > 0) {
      setRoutesFromPermissions(userPermissions.moduleList);
      dispatcher(postFeatureToggleStatus(userPermissions.userIcao)());
    }
  }, [userPermissions.moduleList]);

  // disclaimer only visible to val/pro external user
  useEffect(() => {
    if (shouldOpenDisclaimer(userPermissions.userScope)) {
      setDisclaimerIsOpen(true);
    }
    registerPostHogProperties({
      USER_SCOPE: userPermissions.userScope,
    });
  }, [disclaimerState.disclaimerStatus, userPermissions.userScope]);

  const routerMap = (): JSX.Element[] => {
    return routerList.map((routeItem, index) => {
      return <Route key={`i${index + 1}`} path={routeItem.path} element={routeItem.element} />;
    });
  };

  return (
    <div className="app-container" data-testid="appName" aria-label="cmi">
      <Container className="AppBody">
        <DisclaimerModalPopup isOpen={disclaimerIsOpen} setDisclaimerIsOpen={setDisclaimerIsOpen} userIcao={userPermissions.userIcao} dispatcher={dispatcher} />
        <Routes>
          <Route element={<PrivateRoute />}>
            {!disclaimerIsOpen && routerMap()}
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
          <Route path="/signIn" element={<SignIn />} />
          <Route path="/signout" element={<SignOut />} />
          {featureToggleConfig?.email === 'true' && userPermissions.userScope !== USER_SCOPE_NSWC
            && <Route path="/settings" element={<NotificationSettings />} />}
          {featureToggleConfig?.nswc === 'true' && userPermissions.userScope === USER_SCOPE_NSWC && <Route path="/uploadHistory" element={<UploadFliHistory />} />}
        </Routes>
      </Container>
      {isHelpModalOpen && <HelpModal />}
      <DownloadInfoDrawer />
      {!isEmpty(toastStack.toasts)
        && (
          <>
            <Stack className="bottomToast" spacing="2-x" data-testid="bottom-toast">
              <ToastComponent location={toastLocationMap.SB_BROWSER} />
            </Stack>
            <Stack className="bottomToast" spacing="2-x" data-testid="bottom-toast">
              <ToastComponent location={toastLocationMap.POPUP_FILTER} />
            </Stack>
            <Stack className="bottomToast" spacing="2-x" data-testid="bottom-toast">
              <ToastComponent location={toastLocationMap.GLOBAL} />
            </Stack>
            <Stack className="bottomToast" spacing="2-x" data-testid="bottom-toast">
              <ToastComponent location={toastLocationMap.CHECKER} />
            </Stack>

          </>
        )}
    </div>
  );
};

export default App;
