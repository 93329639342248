/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  GET_FILE_STATUS_ERROR,
} from './constants';

export const fileStatusPending = (
  state: FileStausStateType,
) => ({
  ...state,
  isFileStatusLoading: true,
});

export const fileStatusRejected = (state: FileStausStateType) => {
  return {
    ...state,
    isFileStatusLoading: false,
    errorMessage: GET_FILE_STATUS_ERROR,
  };
};

export const fileStatusFullfilled = (state: FileStausStateType, action: FileStausActionType) => {
  const fileStatusData = action.payload.data.map((item: any) => {
    const status = item.cmi_status ? `${item.fli_status}/${item.cmi_status}` : item.fli_status;
    return {
      ...item,
      status,
    };
  });
  return {
    ...state,
    isFileStatusLoading: false,
    data: fileStatusData,
  };
};
