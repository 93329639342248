import { getDisplayColumns } from '../../utils/commonUtil';
import { tableColumns, sbColumns, actionColumns } from '../../components/Shared/Tabs/BrowserTab/BrowserTableStructure';
// Slice
export const DATATABLE_SLICE = 'displaySbDataTable';
export const DATATABLE_INITIAL_STATE: SbDataTableState = {
  isBrowserDataLoading: false,
  errorMessage: '',
  SbBrowserData: [],
  total_records: 0,
  records_offset: 0,
  paginationData: {
    rowPerPage: 100,
    page: 1,
    start: 0,
    end: 0,
    selectedChunkData: [],
  },
  columns: getDisplayColumns([...actionColumns, ...tableColumns, ...sbColumns]),
  FilterData: [],
  FilterState: [],
  FilterPayload: [],
  isColumnDataLoading: false,
  concurrentToggle: false,
  sbStatusViewToggle: false,
};
export const LOAD_SB_DATA = 'loadSbBrowseData';
export const LOAD_FILTER_DATA = 'loadSbFilterData';
export const SB_DATA_ERROR = 'Unable to get SB Browser Data';
