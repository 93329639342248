import { createSlice } from '@reduxjs/toolkit';
import {
  CHECKER_FLEET_INITIAL_STATE, CHECKER_FLEET_SLICE,
} from './constants';
import {
  loadCheckerOperatorsPending,
  loadCheckerOperatorsRejected,
  loadCheckerOperatorsFullfilled,
  loadFleetDataPending,
  loadFleetDataRejected,
  loadFleetDataFullfilled,
} from './checkerFleetExtraReducers';
import {
  setFleetViewState,
  getCheckerFleetState,
  setFleetViewFilters,
  setCheckerFleetInitialLoad,
  setFleetViewData,
  setFleetViewFilteredData,
  setFleetViewInnerData,
  setOperatorList,
} from './checkerFleetReducer';
import {
  loadCheckerOperators, loadCheckerFleetData,
} from './checkerFleetThunk';

export const checketFleetSlice = createSlice({
  name: CHECKER_FLEET_SLICE,
  initialState: CHECKER_FLEET_INITIAL_STATE,
  reducers: {
    getCheckerFleet: getCheckerFleetState,
    setFleetState: setFleetViewState,
    setFleetViewFilter: setFleetViewFilters,
    setCheckerInitialLoad: setCheckerFleetInitialLoad,
    getFleetViewFilteredData: setFleetViewFilteredData,
    getFleetViewData: setFleetViewData,
    updateFleetViewInnerData: setFleetViewInnerData,
    setOperatorFilters: setOperatorList,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCheckerOperators().pending, loadCheckerOperatorsPending)
      .addCase(loadCheckerOperators().rejected, loadCheckerOperatorsRejected)
      .addCase(loadCheckerOperators().fulfilled, loadCheckerOperatorsFullfilled)
      .addCase(loadCheckerFleetData().pending, loadFleetDataPending)
      .addCase(loadCheckerFleetData().rejected, loadFleetDataRejected)
      .addCase(loadCheckerFleetData().fulfilled, loadFleetDataFullfilled);
  },
});
export const {
  getCheckerFleet,
  setFleetState,
  setFleetViewFilter,
  getFleetViewData,
  getFleetViewFilteredData,
  setCheckerInitialLoad,
  updateFleetViewInnerData,
  setOperatorFilters,
} = checketFleetSlice.actions;
export default checketFleetSlice;
