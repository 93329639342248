/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable dot-notation */
import {
  Col, Container, Row, Tooltip, Typography,
} from '@airbus/components-react';
import { Dispatch } from 'redux';
import { chipConstants } from '../Shared/ChipLabel/constants';
import {
  ATA,
  CURRENT_STATUS,
  FIN,
  FIN_STATUS_MAPPER,
  SELECT_STATUS,
  PART_NUMBER,
  MSN_AIRCRAFT_REGISTRATION,
} from '../../utils/constants';
import CheckerFinEditStatusComponent from './CheckerFinEditStatusComponent';

interface FinStatusInterface {
  statusProps: CheckerSelectedFinDataType;
  checkerDataTableState: any
  CheckerFinEditModalPopupDispatcher: Dispatch<any>;
}
const CheckerFinStatusComponent = ({ statusProps, checkerDataTableState, CheckerFinEditModalPopupDispatcher }: FinStatusInterface) => {
  const finStatus: string = chipConstants[FIN_STATUS_MAPPER[statusProps.fin_status]].chipText;
  const partNumber = statusProps.part_number.join(',').split(',');
  let partNo;
  if (partNumber.length > 1) {
    partNo = `${partNumber[0]}....`;
  } else {
    partNo = partNumber.join(',');
  }
  const pnrTooltip = partNumber.join(',');
  return (
    <Container className="fin-status-container">
      <Row>
        <Col xs={2}>
          <Typography variant="small" className="fin-status-header-label">{FIN}</Typography>
          <Typography variant="small" className="fin-status-header-value">{statusProps.fleet_fin}</Typography>
        </Col>
        <Col xs={3}>
          <Typography variant="small" className="fin-status-header-label">{CURRENT_STATUS}</Typography>
          <Typography variant="small" className="fin-status-header-value">{finStatus}</Typography>
        </Col>
        <Col xs={3}>
          <Typography variant="small" className="fin-status-header-label">{MSN_AIRCRAFT_REGISTRATION}</Typography>
          <Typography variant="small" className="fin-status-header-value">{statusProps.id_aircraft_registration}</Typography>
        </Col>
        <Col xs={2}>
          <Typography variant="small" className="fin-status-header-label">{ATA}</Typography>
          <Typography variant="small" className="fin-status-header-value">{statusProps.ata}</Typography>
        </Col>
        <Col xs={2}>
          <Typography variant="small" className="fin-status-header-label">{PART_NUMBER}</Typography>
          <Tooltip placement="right" label={pnrTooltip}><Typography variant="small" className="fin-status-header-value">{partNo}</Typography></Tooltip>
        </Col>
      </Row>
      <Row className="changes-fin">
        <Col xl={2}>
          <Typography variant="small" className="select-status-header-label">{SELECT_STATUS}</Typography>
        </Col>
      </Row>
      <CheckerFinEditStatusComponent statusProps={statusProps} checkerDataTableState={checkerDataTableState} CheckerFinEditModalPopupDispatcher={CheckerFinEditModalPopupDispatcher} />
    </Container>
  );
};
export default CheckerFinStatusComponent;
