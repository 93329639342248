/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Col,
  Container, IconButton, Inline, Row, Tab, Tabs, Typography,
} from '@airbus/components-react';
import { Close, LiveHelp } from '@airbus/icons/react';
import React from 'react';
import './DetailPopup.scss';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import { toggleReasonDetail } from '../../../models/checkerDetailsModel/checkerDetailsSlice';
import { reasonDetailColumns } from './PopupTableStructure';
import Table from '../../Shared/Table/Table';
import {
  DEVIATION_DETAILS, MORE_INFO, REASON_DETAILS_TITLE,
  USER_SCOPE_NSWC,
} from '../../../utils/constants';
import { setHelpModelToggle } from '../../../models/helpModel/helpModelSlice';
import DeviationTable from '../DeviationTable/DeviationTable';
import { AirNavxCell } from '../../Airnavx/AirNavxCell';
import { setReasonTab } from '../../../models/appConfigModel/appConfigSlice';

interface ContainerProps {
  data: ReasonDetail;
}
const DetailsContainer = ({ data }: ContainerProps) => {
  const modalDispatch = useAppDispatch();
  const dispatch = useAppDispatch();
  const selctedTab = useAppSelector((state) => state.app.selectedReasonTab);
  const featureToggleConfig = useAppSelector((state) => state.featureToggleDetails?.data);
  const userPermissions = useAppSelector((state) => state.authEntrySlice);
  const {
    PopupTableData, DeviationDetails, ...headerData
  } = data;

  const detailsPopupFilter = (key: any, value: any) => {
    if (key === 'finValue' || key === 'operator_code_icao' || key === 'PNR') return;
    if (key === 'FIN') {
      const finStatusElement = data?.FIN?.props?.children?.slice(-1);
      return (
        <Col key={key}>
          <div className="reason-header-text">{key}</div>
          <Col className="reason-fin-text">
            {AirNavxCell({
              row: {
                values: {
                  fin: data.finValue, id_aircraft_registration: data.MSN,
                },
                original: { operator_code_icao: data.operator_code_icao },
                tableName: 'reason-details-table',
              },
              value: data.finValue,
              column: { id: 'fin' },
            })}
          </Col>
          <Col className="reason-fin-status">{finStatusElement}</Col>
        </Col>
      );
    }
    return (
      <Col key={key}>
        <div className="reason-header-text">{key}</div>
        <div className="reason-header-value">{value}</div>
      </Col>
    );
  };
  return (
    <Container className="detail-popup-container">
      <div className="modal-popup-container">
        <Inline spacing="1-x" className="reason-detail-header">
          <Typography variant="medium" className="header-label">
            {MORE_INFO}
            <LiveHelp className="reason-help-icon" data-testid="reason-details-help-icon" onClick={() => { dispatch(setHelpModelToggle({ helpModalState: true, sourceOfHelpClick: 'checkerReasonDetailsIcon' })); }} />
          </Typography>
          <IconButton
            variant="ghost"
            data-testid="Reason-popover-close"
            onClick={() => {
              modalDispatch(toggleReasonDetail(false));
            }}
          >
            <Close />
          </IconButton>
        </Inline>
      </div>
      <Container className="selected-reason-header">
        <Row spacing="quarter-x">
          {Object.entries(headerData).map(([key, value]) => detailsPopupFilter(key, value))}
        </Row>
      </Container>
      <Inline spacing="5-x" className="reason-tabs">
        <Tabs
          aria-label="Tabs purpose label"
          size="medium"
          value={useAppSelector((state) => state.app.selectedReasonTab)}
          data-testid="checker-click"
          id="checker-reason-tab-id"
          onChange={(event, value) => { dispatch(setReasonTab({ selectedReasonTab: value })); }}
        >
          <Tab data-testid="reason-details">
            {REASON_DETAILS_TITLE}
          </Tab>
          <Tab
            data-testid="deviation-details"
            disabled={DeviationDetails.length === 0 || (featureToggleConfig?.nswc === 'true' && userPermissions.userScope === USER_SCOPE_NSWC)}
          >
            {DEVIATION_DETAILS}
          </Tab>
        </Tabs>
      </Inline>
      <Container className="reason-popup-container checker-details-container" id="reasonContainer">
        {selctedTab === 0 && !isEmpty(PopupTableData) ? (
          <Table
            data-testid="reason-details-table"
            tableData={PopupTableData}
            headerColumns={reasonDetailColumns}
            showChangeViewButton={false}
            showExportButton={false}
            showFilterButton={false}
            tableClassName="checker-data-table"
            loadingupdate={false}
            showPagination={false}
          />
        ) : (
          <div>
            {DeviationDetails.length > 0
              && <DeviationTable data={DeviationDetails} />}
          </div>
        )}
      </Container>
    </Container>
  );
};
export default DetailsContainer;
