/* eslint-disable no-nested-ternary */
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AppHeader from '../../Shared/AppHeader/AppHeader';
import Loader from '../../Shared/Loader/Loader';
import { LOCALES } from '../../../assets/locale';

interface Loadingprops {
    loading: boolean|null;
    isAuthenticated: boolean|null|undefined;
}
const loadingAuthenticate = (isAuthenticated: boolean|null|undefined) => {
  if (isAuthenticated) {
    return (
      <div>
        <AppHeader appName={LOCALES} />
        <Outlet />
      </div>
    );
  }
  return <Navigate to="/signIn" />;
};

const LoadingWrapper :React.FC<Loadingprops> = function LoadingWrapper(props: Loadingprops) {
  const { loading, isAuthenticated } = props;
  return (
    <div>
      {loading ? (
        <Loader isBlocking={loading} />
      ) : (loadingAuthenticate(isAuthenticated)
      )}
    </div>
  );
};
export default LoadingWrapper;
