/* eslint-disable @typescript-eslint/no-explicit-any */
import { getPartAPIForDataSource } from '../../utils/commonUtil';
import { LOAD_POST_API_METHOD } from '../../utils/config/apiConfig';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import {
  LOAD_PART_DATA, DATATABLE_SLICE, LOAD_FILTER_DATA,
} from './constants';

export const loadPartBrowseData: any = (payloadBody: BodyPayLoadPartData, dataSource: string) => {
  const customAction = {
    sliceName: DATATABLE_SLICE,
    actionType: LOAD_PART_DATA,
  };
  const apiRequestObj = {
    path: getPartAPIForDataSource(dataSource),
    method: LOAD_POST_API_METHOD,
    body: payloadBody,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
export const loadPartFilterData: any = (payloadBody: BodyPayLoadPartData, dataSource: string) => {
  const customAction = {
    sliceName: DATATABLE_SLICE,
    actionType: LOAD_FILTER_DATA,
  };
  const apiRequestObj = {
    path: getPartAPIForDataSource(dataSource),
    method: LOAD_POST_API_METHOD,
    body: payloadBody,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
