/* CMI-S UI */
import {
  SB_REF, MSN_AC_REG, CONFIGURATION, STATUS,
} from './concurrentFilterConstants';

interface ConcurrentFilter {
    sbReference: string;
    msn: string,
    conf: string,
    status: string
  }
export const concurrentFilterArray = (msnData: ConcurrentFilter) => {
  return ([{
    label: SB_REF, value: msnData.sbReference,
  },
  {
    label: MSN_AC_REG, value: msnData.msn,
  },
  {
    label: CONFIGURATION, value: msnData.conf,
  },
  {
    label: STATUS, value: msnData.status,
  },
  ]);
};
