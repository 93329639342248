/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash';
import {
  MATCH_FLAG, NO_MATCH_FLAG, SORT_BY_STATUS, STATUS_EXCEPT_MATCH, STATUS_WITH_MATCH, UNMATCH_FONT,
} from '../constants';

export const sortPnrArray : (dataValue: any) => any = (dataValue) => {
  const updatedDetaValue = dataValue?.replace(/NODATA/g, ' ');
  const detailArray = updatedDetaValue?.split('_NEXTELEMENT_').map((value: any) => {
    return value.trim();
  });
  const asAllowedPnrArray = detailArray.map((str: string) => str.split('_SEP_'));
  const finalPnrArray = asAllowedPnrArray.map((x: any[]) => [x[0]]);
  /* sort reason_detail based on best status */
  asAllowedPnrArray.sort((a: any, b: any) => {
    return SORT_BY_STATUS.indexOf(a[2]) - SORT_BY_STATUS.indexOf(b[2]);
  });
  return { asAllowedPnrArray, finalPnrArray };
};

// Handle for as allowed pnrs in matched scenario.
export const findMatchedCond: (asAllowedPnrArr: string[][], itemPnr: string) => any = (asAllowedPnrArr, itemPnr) => {
  const matchedCond = new Map<string, string>();
  // check if the pnr in as allowed pnr list is a exact match or status is S2(match with condition scenario)
  const pnrMatched = asAllowedPnrArr.find((el) => (el?.includes(itemPnr) && STATUS_WITH_MATCH.some((stat) => el?.includes(stat))));
  // check if the pnr in as allowed pnr list is a exact match or status is not S1/S2
  const pnrMatchedWithoutMatchStatus = asAllowedPnrArr.find((el) => (el?.includes(itemPnr) && STATUS_EXCEPT_MATCH.some((stat) => el?.includes(stat))));
  const pnrMatchWithoutStatus = !!pnrMatchedWithoutMatchStatus;
  let matchCondition = '';
  let matchPnr = '';
  // get the matched condition
  if (pnrMatched) {
    // _CL_ is the seperator for match case to show bold pnr and condition
    matchCondition = pnrMatched[3] && `${pnrMatched[3]}_CL_${UNMATCH_FONT}`;
    matchPnr = pnrMatched[0] && `${pnrMatched[0]}_CL_${MATCH_FLAG}`;
  } else {
    matchCondition = pnrMatchWithoutStatus ? pnrMatchedWithoutMatchStatus[3] && `${pnrMatchedWithoutMatchStatus && pnrMatchedWithoutMatchStatus[3]}_CL_${UNMATCH_FONT}` : '';
    matchPnr = pnrMatchWithoutStatus ? pnrMatchedWithoutMatchStatus[0] && `${pnrMatchedWithoutMatchStatus && pnrMatchedWithoutMatchStatus[0]}_CL_${MATCH_FLAG}` : '';
  }
  // get matched pnrs
  matchedCond.set(matchCondition, matchPnr);
  return matchedCond;
};

// Handle for as allowed pnrs that are not matching and dont have any conditions
export const findUnMatchWithoutCond: (asAllowedPnrArr: string[][], itemPnr: string) => any = (asAllowedPnrArr, itemPnr) => {
  const unMatchWithoutCondMap = new Map<string, string>();
  const pnrNotMatched = asAllowedPnrArr.filter((el) => (!el?.includes(itemPnr) && isEmpty(el[3])));
  // remove duplicate as allowed pnrs for which condition is blank but reason and status are different.
  const unMatchEmptyPnr = Array.from(new Set(pnrNotMatched.map((el) => el[0]))).join(', ');
  const unMatchEmptyCond = pnrNotMatched.map((el) => el[3]).join(', ');
  unMatchWithoutCondMap.set(unMatchEmptyCond, unMatchEmptyPnr);
  return unMatchWithoutCondMap;
};

// Club as allowed pnrs and condition in case of not match with pnr but having same condition.
export const findUnMatchWithCond: (asAllowedPnrArr: string[][], itemPnr: string) => any = (asAllowedPnrArr, itemPnr) => {
  const pnrNotMatched = asAllowedPnrArr.filter((el) => (!el?.includes(itemPnr) && !isEmpty(el[3])));
  // hash map for pnrs with same conditions
  const conditionHashMap = new Map<string, string>();
  pnrNotMatched.forEach((ele) => {
    if (!conditionHashMap.has(ele[3])) {
      conditionHashMap.set(ele[3], ele[0]);
    } else {
      let pnrArr = conditionHashMap.get(ele[3]) || '';
      // if an as allowed pnr is duplicated , have same condition but have different reason and status, club them into single string and remove duplicate as allowed PNR
      const pnrValue = pnrArr.includes(ele[0]) ? '' : `, ${ele[0]}`;
      pnrArr = pnrArr.concat(pnrValue);
      // removing the leading comma in case first as allowed pnr is empty string
      pnrArr = pnrArr.trim().replace(/^, */, '');
      conditionHashMap.set(ele[3], pnrArr);
    }
  });

  return conditionHashMap;
};

const getMatchFlag = (asAlPnr:string): string => {
  // in case of match pnr with or without conditon , one flag is added
  // this flag helps to assign bold font or in sort based on priority
  // flag is separated from as allowed pnr and as allowed condition using _CL_ separator.
  const asAllowedPnr = asAlPnr.split('_CL_');
  return asAllowedPnr[1] || NO_MATCH_FLAG;
};

const isMatchCondition = (condition:string):boolean => {
  return (isEmpty(condition?.split('_CL_')[0]) && (getMatchFlag(condition) === MATCH_FLAG));
};
// Sort the final resultset of reason popup array in case of multiple PNR.
export const sortMultiplePnrs: (reasonDetailArray: any) => any = (reasonDetailArray) => {
  // sort the array based on as_allowed_pnr match.
  reasonDetailArray.sort((a:any, b:any) => {
    // check if as_allowed_pnr contains any match based on match flag which is appended to as allowed PNRs when the match
    // either based on condition (status S2) or without condition (status S1)
    const exactMatchA = a.as_allowed_pnr?.some((arr:any) => arr.some((asAlPnr:string) => getMatchFlag(asAlPnr) === MATCH_FLAG));
    const exactMatchB = b.as_allowed_pnr?.some((arr:any) => arr.some((asAlPnr:string) => getMatchFlag(asAlPnr) === MATCH_FLAG));
    // check if for match condition presents which is a match with condition scenario
    // match without condtion should be given priority thaen match with condition.
    const emptyConditionA = a.as_allowed_cond?.some((arr:any) => arr.some((cond: string) => isMatchCondition(cond)));
    const emptyConditionB = b.as_allowed_cond?.some((arr:any) => arr.some((cond: string) => isMatchCondition(cond)));
    // sorting based on match > everything else also match without condition > match with condition
    return ((exactMatchB - exactMatchA) || (emptyConditionB - emptyConditionA));
  });
  return reasonDetailArray;
};
