/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Buffer } from 'buffer';
import React from 'react';
import { isEqual } from 'lodash';
import {
  Col,
  Container,
  Link, Row, Tooltip, Typography,
} from '@airbus/components-react';
import { Info, OpenInNew } from '@airbus/icons/react';
import './ColumnUtils.scss';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../store/hooksTypes';
import {
  AS_ALLOWED_CONDITIONS,
  NO_CONCURRENT_AVAILABLE, NO_CONCURRENT_LINKED, STATUS_CLASS_NAME_MAP, UNMATCH_FONT,
  TOOLTIP_COLUMNS,
  FIN_STATUS_DISPLAY_TEXT_MAPPER,
  PNR_SOURCE_DISPLAY_TEXT_MAPPER,
  COMMON_FILTER_TEXT,
} from '../constants';
import { CustomPopover } from '../../components/Shared/CustomPopover/CustomPopover';
import { POPUPFILTER_INITIAL_STATE } from '../../models/popupFilterModel/constants';
import { AirNavxCell } from '../../components/Airnavx/AirNavxCell';

interface ColumnProps {
  value: any;
}
type CellValueProps = {
  id: any;
  element: any;
  tableProps: any;
}
export const ColumnToolTip: React.FC<ColumnProps> = (props: ColumnProps) => {
  const { value } = props;
  const tablebody: Element = document.querySelector('[class$=-body]') as Element;

  return (
    <CustomPopover value={value} scrollTrigger={tablebody}>
      <div className="elipseColumn" data-testid="tooltipValue">{value}</div>
    </CustomPopover>
  );
};
export const MenuCell = (props: any) => {
  const [qryParams] = useSearchParams();
  const filterStates = useAppSelector((state) => state.popUpFilter);
  const rowConf = props;
  const dependencyFormula = rowConf.data[rowConf.row.index].dependency_formula;
  const solutionStatus = rowConf.data[rowConf.row.index].solution_status;
  const dependencyFormulaText = rowConf.data[rowConf.row.index].dependency_displayed_formula;
  const tablebody: Element = document.querySelector('[class$=-body]') as Element;
  const sbReference = rowConf.row.values.sb_reference;
  if (!sbReference) {
    return '';
  }
  if (solutionStatus === 'BAD' || solutionStatus === 'BAD_WITH_VSB') {
    return (
      <CustomPopover value={NO_CONCURRENT_AVAILABLE} scrollTrigger={tablebody}>
        <Link href="/" className="no-detail-link">
          <OpenInNew className="no-details" />
        </Link>
      </CustomPopover>
    );
  }
  if ((solutionStatus === 'GOOD' && typeof dependencyFormula !== 'undefined' && dependencyFormula === 'No concurrent SB') || !solutionStatus) {
    return (
      <CustomPopover value={NO_CONCURRENT_LINKED} scrollTrigger={tablebody}>
        <Link href="/" disabled>
          <OpenInNew className="no-concurrent-sb" />
        </Link>
      </CustomPopover>
    );
  }

  const msn = rowConf.row.values.id_aircraft_registration;
  const conf = rowConf.data[rowConf.row.index].configuration_number;
  const status = rowConf.data[rowConf.row.index].sb_status;
  const mod = rowConf.row.values.mod_reference;
  const mp = rowConf.row.values.mp_reference;
  const ata = rowConf.row.values.ata_4d;
  const operatorName = rowConf.data[rowConf.row.index].operator_code_icao;
  let aircraftType;
  if (!isEqual(filterStates, POPUPFILTER_INITIAL_STATE)) {
    aircraftType = filterStates?.sb_filter_selection_configuration?.aircraft_type;
  } else {
    const qryParamsSet = qryParams.getAll('msn_sb');
    const qryParamsSetDecrypted = JSON.parse(Buffer.from(qryParamsSet[0], 'base64').toString('ascii'));
    aircraftType = qryParamsSetDecrypted.aircraftType;
  }
  const qry = Buffer.from(JSON.stringify({
    msn, sbReference, conf, status, mod, mp, ata, dependencyFormula, aircraftType, operatorName, dependencyFormulaText,
  })).toString('base64');
  const queryString = `/concurrentRequirement?msn_sb=${qry} `;
  return (
    <Link href={queryString} target="_blank">
      <OpenInNew className="concurr-msn-sb" />
    </Link>
  );
};

const isTabularCell: (value: Array<Array<string> | string>) => boolean = (
  value,
) => {
  return !!value.find((item: string[] | string) => Array.isArray(item));
};

export const CellValue: React.FunctionComponent<CellValueProps> = (props: CellValueProps) => {
  const { currentPopupDetails } = useAppSelector((state) => state.displayCheckerDetailsTable);
  const { id, element, tableProps } = props;
  const elementVal = typeof element === 'string' ? element?.split('_CL_') : element;
  const nodeValue = Array.isArray(elementVal) ? elementVal[0] : elementVal;
  let fontClass = Array.isArray(elementVal) ? elementVal[1] || UNMATCH_FONT : UNMATCH_FONT;
  if (id === 'as_allowed_cond') fontClass += ` ${AS_ALLOWED_CONDITIONS}`;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  let cellElement: JSX.Element | null = <div className={`cell-values ${fontClass}`}>{nodeValue || <>&nbsp;</>}</div>;

  if (TOOLTIP_COLUMNS.includes(id)) {
    cellElement = (element?.length ? ColumnToolTip({ value: cellElement }) : cellElement) as JSX.Element;
  }
  if (!!tableProps?.tableName && tableProps?.tableName === 'reason-details-table' && (element?.length > 0)) {
    if (id === 'pnr') {
      return AirNavxCell({
        row: {
          values: {
            id_aircraft_registration: currentPopupDetails.msn, pnr: element,
          },
          original: { operator_code_icao: currentPopupDetails.operator_code_icao },
          tableName: tableProps.tableName,
        },
        value: cellElement,
        column: { id: 'pnr' },
      });
    }
    if (id === 'as_allowed_pnr') {
      return AirNavxCell({
        row: {
          values: {
            fin: currentPopupDetails.fin, id_aircraft_registration: currentPopupDetails.msn,
          },
          original: { operator_code_icao: currentPopupDetails.operator_code_icao },
          tableName: tableProps.tableName,
        },
        value: cellElement,
        column: { id: 'as_allowed_pnr' },
      });
    }
    return cellElement;
  }
  return cellElement;
};

const tableStructure: (tableValues: Array<Array<string>>, columnName: string, tableProps: any) => JSX.Element = (tableValues, columnName, tableProps) => {
  const tableRows: Array<JSX.Element> = [];

  tableValues.forEach((item: Array<string>, index: number) => {
    tableRows.push(
      <tr key={`i${index + 1}`}>
        <td>
          <CellValue id={columnName} element={item[0]} tableProps={tableProps} />
        </td>
      </tr>,
    );
  });

  return (
    <table className="innerCheckerTable">
      <tbody>
        {tableRows}
      </tbody>
    </table>
  );
};
export const HeaderWithInfo = (headerTitle: string, headerInfo: string) => {
  return (
    <Container className="tooltip-container">
      <Row>
        <Col xxs={7} className="header-info">
          <Typography variant="small">{headerTitle}</Typography>
        </Col>
        <Col xxs={4} className="tooltip-col">
          <Tooltip
            placement="bottom"
            label={(
              <Typography variant="small" className="chip-text">
                {headerInfo}
              </Typography>
            )}
            className="tooltip-info"
            data-testid="tooltip-info"
          >
            <Info data-testid="info-fin-icon" className="fin-info" />
          </Tooltip>
        </Col>
      </Row>
    </Container>
  );
};
export const asAllowedCondMap = (props: any) => {
  const asAllowedStatus = props.row.values.as_allowed_cond || '';
  const tablebody: Element = document.querySelector('[class$=-body]') as Element;
  const itemClass = asAllowedStatus.split('_SEP_')[1];
  const itemValue = asAllowedStatus.split('_SEP_')[0];
  return (
    <CustomPopover value={itemValue} scrollTrigger={tablebody}>
      <div className={itemClass}>
        <div className="cell-values">{itemValue}</div>
      </div>
    </CustomPopover>
  );
};
export const tableInCellRows = (props: any) => {
  const { value, column } = props;
  if (Array.isArray(value)) {
    if (isTabularCell(value)) {
      return tableStructure(value, column.id, props); // When value is Array<Array<string>>, example [['Test Value 1'], ['Test Value 2']]
    }
    return <CellValue id={column.id} element={value[0]} tableProps={props} />;// When value is Array<string>, example ['Test Value']
  }
  return <CellValue id={column.id} element={value} tableProps={props} />; // When value is string, example 'Test Value'
};

export const statusColorCodeMap = (props: any) => {
  const pnrStatus = props.row && props.row.values.pnr ? props.row.values.pnr.split(',') : props.split(',');
  return (
    <>
      <div data-testid="status-indicator" className={pnrStatus[1]?.trim() ? STATUS_CLASS_NAME_MAP[pnrStatus[1]?.trim().toLowerCase()] : 'tba-border'} />
      <div>{pnrStatus[0]?.trim()}</div>
    </>
  );
};

export const popDetailsCellWrapper = (props: any) => {
  return tableInCellRows({ ...props, tableName: 'reason-details-table' });
};

export const reportFilterLabels = (paramString: string, checkerDetailsState: any) => {
  if (paramString?.length) {
    if (checkerDetailsState?.FilterState?.fin_status?.length === 1) {
      return (
        <Typography
          variant="medium"
          className="report-status-filters-label"
          datatest-id="display-text-fin"
        >
          {FIN_STATUS_DISPLAY_TEXT_MAPPER[checkerDetailsState.FilterState.fin_status[0]]}
        </Typography>
      );
    }
    if (checkerDetailsState?.FilterState?.status_part_check?.length === 1) {
      return (
        <Typography
          variant="medium"
          className="report-status-filters-label"
          datatest-id="display-text-pnr"
        >
          {PNR_SOURCE_DISPLAY_TEXT_MAPPER[checkerDetailsState.FilterState.status_part_check[0]]}
        </Typography>
      );
    }
    if (
      checkerDetailsState?.FilterState?.source_status?.length === 1
      && checkerDetailsState?.FilterState?.source?.length === 1
    ) {
      const source = checkerDetailsState.FilterState.source[0].split('#')[1];
      const status = checkerDetailsState.FilterState.source_status[0].split('#')[1];
      return (
        <Typography
          variant="medium"
          className="report-status-filters-label"
          datatest-id="display-text-pnr"
        >
          {`${COMMON_FILTER_TEXT} ${source} ${status}`}
        </Typography>
      );
    }
  }
  return <>&nbsp;</>;
};
