import { DISCREPENCY_OPTIONS } from '../../utils/constants';

export const CHECKER_FLEET_SLICE = 'checkerFleet';
export const CHECKER_FLEET_DATA_SLICE = 'checkerData';

export const CHECKER_FLEET_INITIAL_STATE: CheckerFleetState = {
  selectedView: 'larger',
  isFilterOperatorLoading: false,
  isDataLoading: false,
  data: {},
  visibleData: [],
  fleet_view_filters: {
    operators_list: [],
    ac_family_list: [],
    ac_type_list: [],
    ac_sub_type_list: [],
    msn_list: [],
    status_list: DISCREPENCY_OPTIONS,
  },
  fleet_view_filter_selected: {
    selected_operator: [],
    selected_aircraft: [],
    selected_sub_aircraft: [],
    selected_msn: [],
    selected_discrepency: DISCREPENCY_OPTIONS,
  },
  checkerFleetFilterInitialLoad: true,
};

export const LOAD_CHECKER_OPERATOR = 'loadCheckerOperators';
export const LOAD_CHECKER_FLEET_DATA = 'loadCheckerFleetData';
