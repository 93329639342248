/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/button-has-type */
import React from 'react';
import {
  Container, Row, Col, Typography, Drawer, Inline, IconButton, Checkbox,
} from '@airbus/components-react';
import { Edit } from '@airbus/icons/react';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import './CheckerFinEditDrawer.scss';
import {
  SELECTED, SELECTED_ALL, MANAGE_FIN_STATUS, MAX_FIN_EDIT_SELECTION, RANGE_EXCEED_WARNING_MESSAGE,
} from '../../utils/constants';
import {
  setFinChangesStateReducer, setFinEditModalState, setFleetDetailSelectedFin, updateSelectAllBottomDrawerStatus,
} from '../../models/checkerDetailsModel/checkerDetailsSlice';

const CheckerFinEditDrawer = () => {
  const {
    isBottomDrawerClose, selectedFin, isSelectAllFin, finEditModalState,
  } = useAppSelector((state) => state.displayCheckerDetailsTable);
  const bottomDrawerDispatcher = useAppDispatch();
  return (
    <Container>
      <Drawer id="fin-edit-drawer" anchor="bottom" open={isBottomDrawerClose} className="custom-drawer-container" data-testid="drawer-open" disableBackdrop>
        <Row className="bottom-drawer-header">
          <Col xxs={4} className="bottom-drawer-left-padding">
            <Inline spacing="1-x">
              <div className="checkboxSelectAll">
                <Checkbox
                  data-testid="select-all-checkbox"
                  label={SELECTED_ALL}
                  defaultChecked={isSelectAllFin}
                  checked={isSelectAllFin}
                  onChange={(event) => {
                    bottomDrawerDispatcher(updateSelectAllBottomDrawerStatus(event.target.checked));
                    bottomDrawerDispatcher(setFleetDetailSelectedFin([]));
                  }}
                  className="select-checkbox"
                />
              </div>
              <div className="square">
                <span className="selectedColor">{selectedFin?.length}</span>
              </div>
              <Typography variant="medium" className="selectedText">{SELECTED}</Typography>
            </Inline>
          </Col>
          <Col xxs={4} className="bottom-drawer-center-padding">
            <Inline spacing="quarter-x" align="center">
              {(selectedFin?.length > MAX_FIN_EDIT_SELECTION) && (
                <Typography variant="medium" className="rangeText">{RANGE_EXCEED_WARNING_MESSAGE}</Typography>
              )}
            </Inline>
          </Col>
          <Col xxs={4} className="bottom-drawer-right-padding">
            <Inline spacing="quarter-x" align="end">
              <IconButton
                variant="ghost"
                aria-label="Delete"
                data-testid="edit-fin"
                onClick={() => {
                  bottomDrawerDispatcher(setFinEditModalState(!finEditModalState));
                  bottomDrawerDispatcher(setFinChangesStateReducer([...selectedFin]));
                }}
              >
                <Edit className="editIconStyle" />
                <Typography variant="medium" data-testid="edit-fin-text" className="editText">{MANAGE_FIN_STATUS}</Typography>
              </IconButton>
            </Inline>
          </Col>
        </Row>
      </Drawer>
    </Container>
  );
};

export default CheckerFinEditDrawer;
