/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Col,
  Collapsible,
  Divider,
  Row,
  Spinner,
  Typography,
} from '@airbus/components-react';
import { ArrowForward } from '@airbus/icons/react';
import {
  COMMENT,
  DATE,
  FIN_STATUS_MAPPER,
  HISTORY_TITLE,
  STATUS_CHANGE,
  USERNAME,
} from '../../utils/constants';
import { getHistory } from './CheckerFinEditModelUtils';
import { chipConstants } from '../Shared/ChipLabel/constants';

const CheckerFinEditHistoryComponent = ({ finStatusHistory, finIndex, checkerDataTableState }: any) => {
  return (
    <div className="checker-fin-edit-history" key={finIndex}>
      {/* First tile will open by deafult */}
      <Collapsible title={HISTORY_TITLE} className={checkerDataTableState.isFinStatusHistoryLoading || (!finStatusHistory) || (finStatusHistory && getHistory(finStatusHistory).length <= 1) ? 'fin-edit-history-title-load' : 'fin-edit-history-title'} defaultExpanded={finIndex === 0}>
        {
          checkerDataTableState.isFinStatusHistoryLoading ? <Spinner className="checker-spinner" size="small" /> : finStatusHistory && getHistory(finStatusHistory).map((item: any) => (
            <div className="history-content" key={item.timeStamp}>
              <Row>
                <Col>
                  <Typography variant="small" className="fin-history-header-label">{USERNAME}</Typography>
                  <Typography variant="small" className="fin-history-header-value">{item.updatedBy}</Typography>
                </Col>
                <Col>
                  <Typography variant="small" className="fin-history-header-label">{STATUS_CHANGE}</Typography>
                  <Typography variant="small" className="fin-history-header-value">
                    <span className="status-change-span">{chipConstants[FIN_STATUS_MAPPER[item.previousStatus]].chipText}</span>
                    <span className="status-change-arraw"><ArrowForward /></span>
                    <span>{chipConstants[FIN_STATUS_MAPPER[item.currentStatus]].chipText}</span>
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="small" className="fin-history-header-label">{DATE}</Typography>
                  <Typography variant="small" className="fin-history-header-value">{item.timeStamp}</Typography>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Typography variant="small" className="fin-history-header-label-comment">{COMMENT}</Typography>
                  <Typography variant="small" className="fin-history-header-comment-value">{item.Comments}</Typography>
                </Col>
              </Row>
              <Divider className="divider-line" />
            </div>
          ))
        }
      </Collapsible>
    </div>
  );
};
export default CheckerFinEditHistoryComponent;
