/* eslint-disable @typescript-eslint/no-explicit-any */
interface KeyValueMap {
  [key: string]: string
}
export const USERNAME = 'Updated by';
export const LOGOUT_BUTTON = 'Logout';
export const SETTINGS = 'Settings';
export const HOME = 'Home';
export const GENERAL_SETTINGS = 'GENERAL SETTINGS';
export const NOTIFICATIONS = 'Notifications';
export const AIRBUS_NOTICE = 'Airbus world privacy notice';
export const USER_GUIDE = 'User guide';
export const HELP_CENTRE = 'Help centre';
export const UPLOAD_NEW_FILE = 'Upload new file';
export const UPLOAD_HISTORY = 'Upload history';
export const APP_FOOTER_VERSION = 'App v 0.1';
export const AIRBUS_NOTICE_LINK = 'https://w3.airbusdoc.com/imageserver/remotegadgets/images/Disclaimer/PrivacyNotice.htm';
export const INTERNAL_LINK = 'people10.airbus.corp/CS_Strategic_dev/eSites/Configuration_Cockpit/customer/enduser/index.html';
export const EXTERNAL_LINK = 'https://w3.airbus.com/crs/A233_Strategic_dev/Configuration_Cockpit/customer/enduser/index.html';
export const PART = 'Part';
export const MOD = 'MOD/SB';
export const DEFAULT_ROW_FETCH_LIMIT = 1000;
export const BROWSER = 'Browser';
export const CHECKER = 'Checker';
export const CHECKER_EXPORT_TYPE = 'CHECKER';
export const ACM = 'ACM';
export const NO_CONCURRENT_AVAILABLE = 'Please check the Service bulletin for concurrent requirement details.';
export const NO_CONCURRENT_LINKED = 'No concurrent requirement linked to this SB reference.';
export const CONCURRENT_REQUIREMENTS = 'Concurrent Requirements';
export const SB_STATUS_VIEW = 'SB Status View';
export const POPUP_TITLE = 'Data Selection';
export const OPERATOR = 'Operator';
export const AC_FAMILY = 'AC Family';
export const AIRCRAFT_TYPE = 'A/C Type';
export const AIRCRAFT_FAMILY = 'A/C Family';
export const AIRCRAFT_SUB_TYPE = 'A/C Model';
export const DATA_SOURCE = 'Data Source';
export const MSN = 'MSN A/C Registration';
export const ACM_MSN = 'MSN/';
export const ACM_REGISTRATION_NUMBER = 'Registration Number';
export const SB = 'SB';
export const MOD_LABEL = 'MOD';
export const ACM_REPORT_DOWNLOAD = 'ACM Report Download';
export const FIN = 'FIN';
export const PNR = 'PNR';
export const ATA = 'ATA';
export const VALIDATE = 'Validate';
export const RESET = 'Reset';
export const RELOAD = 'Reload';
export const POPUP_WARNING_TEXT = 'Please provide atleast one of the inputs below';
export const FILTER = 'Filter';
export const DATA_FILTER_TITLE = 'Filter & Sort';
export const FILTER_BY = 'Filter By';
export const SORT_BY = 'Sort By';
export const NODATA_FILTER = 'No Data found for the selected filter';
export const FILTER_OPTION_FETCH_LIMIT = '100';
export const MAX_LIMIT = '100000000'; // Defined Maximum limit as API require limit as mandatory parameter
export const DEFAULT_OFFSET = '0';
export const ASCENDING = 'ASC';
export const CLOSE_BUTTON = 'Close';
export const COMBOBOX_PLACEHOLDER = 'Select or Type';
export const COMBOBOX_LABEL_SELECT_ALL = 'Select All';
export const COMBOBOX_LABEL_SELECT_FILTERED = 'Select Filtered';
export const COMBOBOX_PLACEHOLDER_ALL = 'All';
export const CLEAR = 'Clear';
export const CANCEL = 'Cancel';
export const DOWNLOADANYWAY = 'Download Anyway';
export const FILEALREADYDOWNLOADED = 'You have downloaded the file earlier.';
export const SUREDOWNLOAD = 'Are you sure you want to export the same data again?';
export const DEFAULT_COL_FILTER_LABEL = 'Null / Empty';
export const DEFAULT_COL_FILTER_VALUE = 'NULL';
export const NON_NULL_FILTER_COLUMNS = ['id_aircraft_registration', 'source', 'fleet_fin', 'fin_status', 'source_status', 'reason'];
export const APPLY = 'Apply';
export const CHANGE_VIEW = 'Change View';
export const NO_RECORDS_FOUND = 'Please retry using different inputs using \'Data Selection\'';
export const NO_TABLE_RECORDS_FOUND = 'No Configuration records found.';
export const NO_CONCURRENT_SERVICE_BULLETIN_FOUND = 'No Concurrent Service Bulletin found applicable to this Aircraft. Please manually verify the applicability.';
export const TOAST_VARIANTS_STATUS_MAP = {
  400: 'error' as Variant,
  500: 'error' as Variant,
  422: 'warning' as Variant,
  200: 'success' as Variant,
  401: 'warning' as Variant,
};
export const TOAST_TIMEOUT = 5000;
export const PERMISSION_FAIL_TIMEOUT = 10000;
export const DATASOURCE_CONSTANT = 'Data Source:';
export const DOWNLOADS = 'Downloads';
export const RETRY_DOWNLOAD = 'Retry Download';
export const DOWNLOADED_TIME = ' ago';
export const NO_DOWNLOADED_RECORDS = 'No Downloads Found';
export const EXPORT_BUTTON_ARRAY = [
  { label: 'xlsx', value: 'xlsx' },
  { label: 'csv', value: 'csv' },
];
export const EXPORT_TEXT = 'Export';
export const EXPORT_BUTTON_CREATE = 'create';
export const EXPORT_BUTTON_UPDATE = 'update';
export const ACR_DATASOURCE = 'ACR';
export const MIS_DATASOURCE = 'MIS';
export const ACM_SB = 'SB ACM';
export const NON_TOAST_PATH = ['sbData', 'parts/acr', 'parts/mis', 'checker/get_fin_detail_history'];
export const LONG_POLLING_DELAY = 5000;
export const ACM_FILE_TYPE = 'xlsx';
export const ACM_EXPORT_TYPE = 'ACM';
export const SB_ACM = 'SB ACM';
export const SB_MOD_ACM = 'SB MOD ACM';
export const ACM_EXPORT_INITIATED = 'File export is initiated successfully';
export const AC_FAMILY_MAPPING: KeyValueMap = {
  K: 'Beluga Family', W: 'A300 Family', L: 'A330/A340 Family', N: 'A320 Family', P: 'A350 Family', R: 'A380 Family',
};
export const SUCCESS = 'Success';
export const DESC_SORT_TYPE = 'DESC';
export const ACM_ORDER_BY = ['sb_function'];
export const FLEET = 'Fleet';
export const DETAILS = 'Details';
export const CHECKER_DETAILS = 'checkerDetails';
export const DISCREPENCY_RESULT = 'Discrepancy Result';
export const MATCH = 'Match';
export const TO_BE_ANALYSED = 'To be Analysed';
export const WRONG = 'Wrong Part';
export const DIVIDER_STRING = ' | ';
export const SEMICOLON = ' : ';
export const PERCENT = '%';
export const INFORMATION_DATE = 'Information Date';
export const ALLOWABLE_CONFIGURATION = 'Allowable Configuration :';
export const MIS_EXTRACTION = 'MIS extraction :';
export const ACR_RECEPTION = 'ACR reception';
export const ACD = 'ACD :';
export const AISD = 'AISD :';
export const OPERATOR_VALUE = 'A2E';
export const AC_TYPE_VALUE = 'A350';
export const MSN_VALUE = 'P-00002 F_WWCF';
export const DATA_SOURCE_COMPARISON = 'Data source comparison';
export const STATUS_PART_CHECK_NA = 'N/A';
export const STATUS_REASON = 'Status Reason';
export const REASON_DETAILS = 'Reason Details';
export const FLEET_LARGER_VIEW_SIZE = 50;
export const FLEET_SMALLER_VIEW_SIZE = 100;
export const MSNS_PER_PAGE = 400;
export const EXPORT_STACK_FIELDS = ['filters', 'export_type', 'operator_code_icao'];
export const CHECKER_TABLE_SORT_COLUMNS = ['fleet_fin', 'ata', 'id_aircraft_registration'];
export const MIS_MATCH = 'Mismatch';
export const DATA_SOURCE_COMPARISON_VALUE = {
  UI_VIEW: 'As allowed <-> MIS & ACR',
  PAYLOAD_VALUE: 'MIS_ACR',
};

// Router Module name map
export const ROUTER_MODULE_MAP: KeyValueMap = {
  '/browser': 'Browser',
  '/checker': 'Checker',
  '/acm': 'ACM',
};

// precedence for modules
export const MODULE_PRECEDENCE = ['/checker', '/browser', '/acm'];
// order in which module name should appear in header
export const HEADER_TAB_ORDER = ['/browser', '/checker', '/acm'];
export const FIN_STATUS_MAPPER: KeyValueMap = {
  Mis_match: 'W',
  match: 'M',
  TBA: 'A',
  W: 'W',
  M: 'M',
  A: 'A',
};
export const FIN_STATUS_MAPPER_FOR_API: KeyValueMap = {
  Match: 'match',
  'Wrong Part': 'Mis_match',
  'To be Analysed': 'TBA',
};
export const DISCREPENCY_OPTIONS = [
  { label: WRONG, column: WRONG, value: 'W' },
  { label: TO_BE_ANALYSED, column: TO_BE_ANALYSED, value: 'A' },
  { label: MATCH, column: MATCH, value: 'M' },
];

export const AC_TYPE_REMOVE_LIST = ['A220'];
export const NO_OPERATOR_TOAST = {
  toastLocation: 'global',
  isToastShown: true,
  toastMessage: 'No Operator Found for this user.',
  toastVariantType: 'warning' as Variant,
  customToastIcon: '',
};
export const ONLY_A220_TOAST = {
  toastLocation: 'global',
  isToastShown: true,
  toastMessage: 'No data available for A220 Family.',
  toastVariantType: 'warning' as Variant,
  customToastIcon: '',
};

export const STATUS_CLASS_NAME_MAP: KeyValueMap = {
  match: 'match-border',
  mismatch: 'missmatch-border',
  tba: 'tba-border',
  'match without condition': 'match-border',
  'match with condition': 'match-border',
  'matched without condition': 'match-border',
  'matched with condition': 'match-border',
  'wrong part without condition': 'missmatch-border',
  'wrong part with condition': 'missmatch-border',
  'manual review required': 'tba-border',
  additional: 'tba-border',
  'missing part': 'tba-border',
  'not checked': 'tba-border',
};
export const STATUS_ACR: KeyValueMap = {
  true: 'elipseColumn as-allowed-true',
  false: 'elipseColumn as-allowed-false',
  tba: 'elipseColumn as-allowed-tba',
  others: 'elipseColumn as-allowed-others',
};

export const STATUS_MIS: KeyValueMap = {
  true: 'elipseColumn as-allowed-true',
  false: 'elipseColumn as-allowed-false',
  tba: 'elipseColumn as-allowed-tba',
  others: 'elipseColumn as-allowed-others',
};

export const REASON_DETAILS_TITLE = 'Reason Details';
export const REASON_DETAIL_INITIAL_STATE = {
  FIN: '',
  MSN: '',
  ATA: '',
  Description: '',
  PopupTableData: [],
  DeviationDetails: [],
};
export const MORE_INFO = 'More info';
export const DEVIATION_DETAILS = 'Deviation Details';
const NO_PERMISSION_MSG = 'You do not have any permission to access Configuration Data. Please contact support to request access.';
export const NO_PERMISSION_TOAST = {
  toastLocation: 'global',
  toastMessage: NO_PERMISSION_MSG,
  toastVariantType: 'error' as Variant,
  customToastIcon: '',
};

export const SB_STATUS_TABLE_COLUMNS = [
  'id_aircraft_registration',
  'sb_reference',
  'ata_4d',
  'sb_status',
  'sb_reporting_date',
  'alert',
  'sb_classification',
  'sb_function',
  'dependency_formula',
  'solution_status',
  'dependency_displayed_formula',
  'configuration_number',
  'sb_title',
  'operator_code_icao',
];
export const columnlevelCheckerKeys = ['popup_details', 'pnr', 'source', 'source_status', 'reason'];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const columnlevelCheckerKeyMapper: any = {
  column_source: 'source',
  column_pnr: 'pnr',
  column_source_status: 'source_status',
  column_reason: 'reason',
};

export const CHECKER_ENVS = ['dev', 'val'];

export const SELECTED = 'Selected';
export const SELECTED_ALL = 'Select All';
export const MANAGE_FIN_STATUS = 'Manage FIN Status';
export const FIN_STATUS = 'Fin Status';
export const PNR_SOURCE_COMPARISON = 'PNR Source Comparison';
export const EDIT = 'Edit';
export const FIN_EDIT_TOOLTIP = 'Edit FIN status';
export const MAX_FIN_EDIT_SELECTION = 100;
export const RANGE_EXCEED_WARNING_MESSAGE = 'You have reached maxmium selection';
export const SELECT_STATUS = 'Select Status';
export const CURRENT_STATUS = 'Current Status';
export const PART_NUMBER = 'Part Number';
export const MSN_AIRCRAFT_REGISTRATION = 'MSN';
export const MANAGE_FIN_STATUS_DESCRIPTION = 'Disclaimer:  By using this FIN management, you commit to never add personal or sensitive data in the comments section.';
export const COMMENTS = 'Comments (Maximum 1000 Characters)';
export const COMMENT = 'Comment';
export const STATUS_CHANGE = 'Status Change';
export const DATE = 'Date';
export const SAVE = 'Save';
export const FIN_HISTORY_PAYLOAD_DEFAULT_COLUMNS = [
  'id_aircraft_registration',
  'fleet_fin',
  'fin_status',
  'revised_timestamp',
  'comment',
  'user_id',
  'comment_id',
];

export const FIN_HISTORY_PAYLOAD_DEFAULT_ORDERBY = [
  'id_aircraft_registration',
  'fleet_fin',
  'revised_timestamp',
];
export const FIN_UPDATE_SUCCESS = 'FIN status managed successfully';
export const FIN_UPDATE_FAILURE = 'Failed to update fin status';
export const HISTORY_TITLE = 'History';
export const SORT_BY_STATUS = ['S1', 'S2', 'S5', 'S6', 'S7', 'S8', 'S3', 'S4'];
export const MATCH_FLAG = 'match-font';
export const UNMATCH_FONT = 'unmatch-font';
export const AS_ALLOWED_CONDITIONS = 'as-allowed-condition';
export const NO_MATCH_FLAG = 'no-match';
export const EXTERNAL_SCOPE = 'EXTERNAL';
export const DISCLAIMER_HEADER_TEXT = 'DISCLAIMER';
export const DISCLAIMER_BODY_TEXT = 'Airbus assumes no responsibility regarding the use of the configuration checker.';
export const DISCLAIMER_WARNING_TEXT = 'Dear customer, we have detected discrepancies in the "allowable" data which is used by the Configuration Checker';
export const DISCLAIMER_WARN_TEXT = '(please refer to OIT 999.0070/21 Rev 05).';
export const DISCLAIMER_TEXT = 'We are sorry for the inconvenience, and are working to solve this issue as soon as possible.';
export const DISCLAIMER_REASON_TEXT = 'For this reason please check any result with the IPC to avoid any risk of error.';
export const STATUS_EXCEPT_MATCH = ['S3', 'S4', 'S5', 'S6', 'S7', 'S8'];
export const STATUS_WITH_MATCH = ['S1', 'S2'];
// Below is the module name - route path mapper which will be used to retrive module name from route path for permission check
export const moduleNameMap:KeyValueMap = {
  '/browser': 'BROWSER',
  '/concurrentRequirement': 'BROWSER',
  '/': 'CHECKER',
  '/checker/details': 'CHECKER',
  '/acm': 'ACM',
};
export const READ_ONLY_ARR = ['READ_ONLY'];
export const READ_ONLY_FLAG = 'READ_ONLY';
export const READ_WRITE_FLAG = 'RW';
export const READ_WRITE = ['RW'];
export const INTERNAL_ABUS = ['INTERNAL_ABUS', 'INTERNAL_SUPPORT'];
export const CORE_INTERNAL = ['INTERNAL_ABUS'];
export const EXTERNAL = ['EXTERNAL'];
export const USER_SCOPE_NSWC = 'EXTERNAL_NSWC';
export const EXTERNAL_ABUS = ['EXTERNAL', 'EXTERNAL_NSWC'];
export const NULL_FILTER_CHECK = ['', 'NULL', null];
export const PART_AIRCRAFT_DETAILS_COLUMNS = ['id_aircraft_registration', 'source', 'operator_code_icao', 'aircraft_type', 'aircraft_model'];
export const NEW_HYBRID_BOX_COLUMN_FILTER = ['FIN', 'ATA', 'Description', 'Part Number', 'PNR', 'S/N', 'Reported Date', 'Last Config Date', 'PNR Origin', 'FIN Origin', 'Installation Date', 'S/N Origin', 'Deviation Description', 'MOD', 'SB Ref.', 'SB Reporting Date', 'MP', 'MOD Title', 'SB Title', 'MP Title'];
export const SB_AIRCRAFT_DETAILS_COLUMNS = ['id_aircraft_registration', 'operator_code_icao', 'aircraft_type', 'aircraft_model'];
export const ACM_AIRCRAFT_DETAILS_COLUMNS = ['id_aircraft_registration', 'operator_code_icao', 'aircraft_program_code', 'aircraft_type', 'aircraft_model'];
export const TOOLTIP_COLUMNS = ['reason', 'fin_description', 'pnr', 'as_allowed_pnr', 'mod_title', 'sb_classification', 'sb_title', 'mp_title', 'part_description', 'fls_classification', 'deviation_description', 'as_allowed_cond', 'pn_origin', 'source_status'];
export const AIR_NAVX_LINKS = ['https://airnavx-internal.eu.airbus.corp/1Y94', 'https://w3.airbus.com/1T40/search'];
export const DOCTYPE_IPC = 'IPC';
export const DOCTYPE_IPD = 'MAINTENANCE IPD - Line Maintenance';
export const REPORT_STATUS_MAPPER: any = {
  'FIN Status': 'fin_status',
  Match: 'match',
  'Wrong part': 'Mis_match',
  'To be analysed': 'TBA',
  ACR: 'acr#ACR',
  MIS: 'mis#MIS',
  'PNR Source Comparison': 'status_part_check',
  'Source unmatched': 'Mismatch',
  'Not applicable': 'NA',
  'Source matched': 'Match',
};
export const WRONG_PART = 'Wrong part';
export const TBA = 'To be analysed';
export const SOURCE_UNMATCHED = 'Source unmatched';
export const NA = 'Not applicable';
export const SOURCE_MATCHED = 'Source matched';
export const REPORT_STATUS_FILTERS = 'reportStatusFilters';
export const FIN_STATUS_DISPLAY_TEXT_MAPPER: KeyValueMap = {
  match: 'Displaying results for all parts with FIN status matching.',
  Mis_match: 'Displaying results for all parts with FIN status wrong.',
  TBA: 'Displaying results for all parts with FIN status to be analysed.',
};
export const PNR_SOURCE_DISPLAY_TEXT_MAPPER: KeyValueMap = {
  Mismatch: 'Displaying results for all parts with PNR source unmatched.',
  NA: 'Displaying results for all parts with PNR source not applicable.',
  Match: 'Displaying results for all parts with PNR source matched.',
};
export const COMMON_FILTER_TEXT = 'Displaying results for all parts with';
export const FLI_URL_DEV_VAL = 'https://fli-val.airbus-v.com/';
export const FLI_URL_PROD = 'https://fli.airbus.com/';
export const HELP_CENTRE_URL: any = {
  INTERNAL_ABUS: `http://${INTERNAL_LINK}`,
  INTERNAL_SUPPORT: `http://${INTERNAL_LINK}`,
  EXTERNAL: EXTERNAL_LINK,
  EXTERNAL_NSWC: EXTERNAL_LINK,
};
