/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Col,
  Container, Inline, Typography,
} from '@airbus/components-react';
import './HelpModal.scss';
import { helpModalConstants } from './constants';
import ChipLabel from '../ChipLabel/ChipLabel';

const componentMap: any = {
  Chip: ({ label }: any) => <ChipLabel sourceOfChip={[label]} />,
};

const HelpComponent: any = ({
  source,
}: any) => {
  const {
    title, mandotory, body, ismandatory, withChipLabel,
  } = helpModalConstants[source];
  return (
    <Container className="helppopup-container">
      <div className="header-label">{title}</div>
      <div className="help-content">
        {ismandatory
          && (
            <Typography variant="medium" className="mandatory-label">
              <div className="assteriskRed">*</div>
              <div className="mandatoryText">{mandotory}</div>
            </Typography>
          )}
        <div className="dataFilterHelplist" data-testid="help-text">
          <ul>
            {body.map((item: any) => {
              if (withChipLabel) {
                const componentArgs = item.args;
                const ChipComponent = componentMap[item.component];
                return (
                  <li key={componentArgs.label}>
                    <Inline spacing="2-x">
                      <Col><ChipComponent key={componentArgs.label} {...componentArgs} /></Col>
                      <Col>-</Col>
                      <Col>{componentArgs.text}</Col>
                    </Inline>
                  </li>
                );
              }
              // eslint-disable-next-line react/no-danger
              return <li key={item} dangerouslySetInnerHTML={{ __html: item }} />;
            })}
          </ul>
        </div>
      </div>
    </Container>
  );
};
export default HelpComponent;
