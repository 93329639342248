/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Row,
} from '@airbus/components-react';
import './ExportButton.scss';
import { Download } from '@airbus/icons/react';
import _ from 'lodash';
import { loadDataExportFile } from '../../../models/downloadStatusModel/downloadStatusThunk';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import {
  setPolling,
} from '../../../models/downloadStatusModel/downloadStatusSlice';
import { withLongPolling } from '../../../utils/apiUtils/longPollingWrapper';
import {
  getFileStatus,
  getPresignedUrl,
} from '../../../models/downloadStatusModel/getStatusThunk';
import {
  checkAcmFiltersSelected,
  checkFetchedStatus,
  generateToastPayload,
} from '../../../utils/commonMethods/generalMethods';
import {
  ACM_EXPORT_TYPE,
  ACM_FILE_TYPE,
  CHECKER_EXPORT_TYPE,
  EXPORT_BUTTON_ARRAY,
  EXPORT_BUTTON_CREATE,
  EXPORT_TEXT,
  LONG_POLLING_DELAY,
  TOAST_TIMEOUT,
} from '../../../utils/constants';
import { getFileListForDownload, prepareFiltersForPayload, restructureColumnFilters } from '../../../utils/commonUtil';
import { addToast, removeToast } from '../../../models/toastModel/toastSlice';
import DownloadModal from '../DownloadModal/DownloadModal';

interface ExportButtonprops {
  selectedExport: any;
  exportType: any;
  columnFilters?: any;
  columns?: any;
  isConcurrentToggle?: boolean;
  isSbStatusToggle?: boolean;
}

const defaultExportButtonProps = {
  columnFilters: {},
  columns: [],
  isConcurrentToggle: undefined,
  isSbStatusToggle: undefined,
};

export default function ExportButton(props: ExportButtonprops) {
  const {
    selectedExport, exportType, columnFilters, columns, isConcurrentToggle, isSbStatusToggle,
  } = props;
  const exportDispatch = useAppDispatch();
  const [isExportDisabled, setExportDisabled] = useState(false);
  const [isExportClicked, setExportClicked] = useState(false);
  const [pollingHashes, setPollingHashes] = useState<Array<HashIdObject>>([]);
  const [fileTypeValue, setFileTypeValue] = useState('');
  const [filesReadyForDownload, setFilesReadyForDownload] = useState<
    Array<DownloadStatus>
  >([]);
  const { downloadStatus, fileSuccess } = useAppSelector((state) => state.downloadStatus);
  const { userScope } = useAppSelector((state) => state.authEntrySlice);
  const [statusForRedownload, setStatusForRedownload] = useState('');
  const options = EXPORT_BUTTON_ARRAY;
  const toastMsg = {
    toastLocation: 'global',
    isToastShown: true,
    toastMessage: 'File export is initiated successfully',
    toastVariantType: 'success' as Variant,
    customToastIcon: 'DOWNLOAD',
  };

  const handleExportClicked = () => {
    setExportClicked(false);
    setExportDisabled(false);
  };
  useEffect(() => {
    const hashIds = downloadStatus
      .filter((allFileStat) => allFileStat.polling_status?.includes('Progress'))
      .map((eachFileStat) => (
        {
          hash_id: eachFileStat.hash_id,
          timestamp: eachFileStat.timestamp,
        }
      ));
    setPollingHashes(hashIds);
    setFilesReadyForDownload(getFileListForDownload(downloadStatus));
  }, [downloadStatus]);

  useEffect(() => {
    // set the polling status to false when starting polling
    setPolling(false);
    if (pollingHashes.length > 0) {
      const pollingOperators = downloadStatus[0]?.operator_code_icao.join(',');
      const fileStatusDispatcher = getFileStatus(pollingHashes, pollingOperators);
      const pollFunction = withLongPolling(
        fileStatusDispatcher,
        LONG_POLLING_DELAY,
        checkFetchedStatus,
      );
      pollFunction('download-status', setPolling, true, exportDispatch);
    }
  }, [pollingHashes.length]);

  useEffect(() => {
    // dispatch the presigned url thunk when polling is completed
    // prepare list of hash ids for which file is prepared and not previously downloaded
    if (filesReadyForDownload.length > 0) {
      filesReadyForDownload.forEach((requiredFileList: any) => {
        setTimeout(() => {
          exportDispatch(
            getPresignedUrl({
              hash_id: requiredFileList?.hash_id,
              operator_code_icao: requiredFileList?.operator_code_icao,
              timestamp: requiredFileList?.timestamp,
              filters: requiredFileList?.filter_payload && JSON.parse(requiredFileList?.filter_payload),
              export_type: requiredFileList?.export_type,
              file_type: requiredFileList?.file_name?.split('.')[1],
            })(),
          ).then(() => {
            const toastPayload = generateToastPayload(
              'global',
              `Configuration data export ${requiredFileList?.file_name} has been downloaded successfully.`,
              200,
            );
            exportDispatch(addToast({ ...toastPayload, isToastShown: true }));
            setTimeout(() => {
              exportDispatch(
                removeToast({ ...toastPayload, isToastShown: false }),
              );
            }, TOAST_TIMEOUT);
          });
        }, 10);
      });
    }
  }, [filesReadyForDownload.length]);

  const exportEvent = (fileType: any) => {
    setFileTypeValue(fileType);
    setExportDisabled(true);
    const mutableColumnFilters = restructureColumnFilters(columnFilters, columns, isConcurrentToggle, isSbStatusToggle, exportType);
    const bodyPayload: BodyPayLoadExportFile = {
      file_type: fileType, // 'csv' or 'xlsx'
      export_type: exportType as string,
      operator_code_icao: [selectedExport.operator_name],
      operation: EXPORT_BUTTON_CREATE,
      filters: prepareFiltersForPayload(exportType, mutableColumnFilters, selectedExport, columns, isConcurrentToggle, isSbStatusToggle),
    };
    let checkFileForRedownload = false;
    let checkStatusForRedownload = '';
    if (exportType !== CHECKER_EXPORT_TYPE) {
      const redownloadArray = downloadStatus.map((item: any) => {
        return {
          file_type: item.file_name.split('.')[1],
          export_type: exportType as string,
          operator_code_icao: [selectedExport.operator_name],
          operation: EXPORT_BUTTON_CREATE,
          filters: item.filter_payload && JSON.parse(item.filter_payload),
        };
      });
      const checkFiltersRedownload = redownloadArray.find((item: any) => _.isEqual(item, bodyPayload));
      checkFileForRedownload = !_.isEmpty(checkFiltersRedownload);
      const matchedIndex = checkFiltersRedownload
        ? redownloadArray.indexOf(checkFiltersRedownload)
        : 0;
      checkStatusForRedownload = downloadStatus[matchedIndex]?.export_status;
      if (checkFileForRedownload && !isExportClicked) {
        setStatusForRedownload(checkStatusForRedownload);
        setExportClicked(true);
      }
    }
    if ((!checkFileForRedownload) || isExportClicked || checkStatusForRedownload === 'Failed') {
      exportDispatch(loadDataExportFile(bodyPayload)())
        .finally(() => {
          setExportDisabled(false);
        });
      exportDispatch(addToast(toastMsg));
      setTimeout(() => {
        exportDispatch(removeToast(toastMsg));
      }, TOAST_TIMEOUT);
    }
  };

  return (
    <div>
      {exportType === ACM_EXPORT_TYPE || exportType === CHECKER_EXPORT_TYPE ? (
        <Row className="acm-row-button-container">
          <Col className={exportType !== CHECKER_EXPORT_TYPE ? 'acm-button-container' : 'checker-export-container'} xxs={4} md={4}>
            <Button
              variant="primary"
              icon={<Download />}
              size={exportType === CHECKER_EXPORT_TYPE ? 'small' : 'medium'}
              className={exportType !== CHECKER_EXPORT_TYPE ? 'download-button' : 'checker-export-button'}
              data-testid="export-download-button"
              disabled={exportType !== CHECKER_EXPORT_TYPE ? checkAcmFiltersSelected(selectedExport, isExportDisabled, userScope) : fileSuccess}
              onClick={() => exportEvent(ACM_FILE_TYPE)}
            >
              Export
            </Button>
          </Col>
        </Row>
      ) : (
        <Container data-testid="export-button" className="export-container">
          <Menu>
            <MenuButton
              disabled={isExportDisabled}
              size="small"
              variant="primary"
              icon={<Download />}
              className="export-button"
              data-testid="export"
              aria-haspopup="dialog"
            >
              {EXPORT_TEXT}
            </MenuButton>
            <MenuList className="export-menulist">
              <>
                {options.map((option, index) => {
                  return (
                    <MenuItem
                      data-testid={option.value}
                      value={option.value}
                      onClick={(event: any) => exportEvent(event.target.textContent)}
                      key={`i${index + 1}`}
                    >
                      {option.value}
                    </MenuItem>
                  );
                })}
              </>
            </MenuList>
          </Menu>
        </Container>
      )}
      {isExportClicked && (
        <DownloadModal
          isExportClicked
          handleExportClicked={handleExportClicked}
          checkStatusForRedownload={statusForRedownload}
          startExport={exportEvent}
          fileTypeValue={fileTypeValue}
          data-testid="download-modal-sb"
        />
      )}
    </div>
  );
}
ExportButton.defaultProps = defaultExportButtonProps;
