/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  LOAD_EXPORT_URL_API_PATH, LOAD_FILE_STATUS_API_PATH, LOAD_GET_API_METHOD, LOAD_POST_API_METHOD,
} from '../../utils/config/apiConfig';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { DOWNLOADSTATUS_SLICE, GET_DOWNLOAD_STATUS, GET_PRESIGNED_URL } from './constants';

export const getFileStatus: any = (hashIds: HashIdObject, operatorCodeIcao:string) => {
  const customAction = {
    sliceName: DOWNLOADSTATUS_SLICE,
    actionType: GET_DOWNLOAD_STATUS,
  };
  const apiRequestObj = {
    path: LOAD_FILE_STATUS_API_PATH,
    method: LOAD_GET_API_METHOD,
    queryStringParameters: {
      hash_ids: JSON.stringify(hashIds),
      operator_code_icao: operatorCodeIcao,
    },
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const getPresignedUrl: any = (payload:PresignedUrlPayload) => {
  const customAction = {
    sliceName: DOWNLOADSTATUS_SLICE,
    actionType: GET_PRESIGNED_URL,
  };
  const apiRequestObject = {
    path: LOAD_EXPORT_URL_API_PATH,
    method: LOAD_POST_API_METHOD,
    body: payload,
  };
  return createCustomAsyncThunk(customAction, apiRequestObject);
};
