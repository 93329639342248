/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty, isEqual } from 'lodash';
import {
  ROUTER_MODULE_MAP, MODULE_PRECEDENCE,
  TOAST_VARIANTS_STATUS_MAP,
  moduleNameMap,
  READ_WRITE,
  READ_WRITE_FLAG,
  READ_ONLY_FLAG,
  WRONG_PART,
  TBA,
  MATCH,
  SOURCE_MATCHED,
  NA,
  SOURCE_UNMATCHED,
  CORE_INTERNAL,
  FIN_STATUS,
  REPORT_STATUS_MAPPER,
  PNR_SOURCE_COMPARISON,
  ACR_DATASOURCE,
  MIS_DATASOURCE,
  USER_SCOPE_NSWC,
} from '../constants';
import { secureLocalStore } from './SecureLSHelper';
import {
  findMatchedCond, findUnMatchWithCond, findUnMatchWithoutCond, sortMultiplePnrs, sortPnrArray,
} from './reasonPopupMethods';

export const setUserName = (givenName: string, familyName: string) => {
  const firstName:string = givenName.charAt(0).toUpperCase() + givenName.slice(1).toLowerCase();
  const lastName:string = familyName.charAt(0).toUpperCase() + familyName.slice(1).toLowerCase();
  secureLocalStore.set('userId', `${firstName} ${lastName}`);
};

export const getUserName = () => {
  return secureLocalStore.get('userId');
};

export const setDisclaimerStatus = () => {
  return secureLocalStore.set('isDisclaimerDisabled', true);
};
export const getDisclaimerStatus = () => {
  return secureLocalStore.get('isDisclaimerDisabled');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateToastPayload = (path:string, message:string, status:any) => {
  return {
    toastLocation: path,
    toastMessage: message,
    toastVariantType: TOAST_VARIANTS_STATUS_MAP[status as keyof typeof TOAST_VARIANTS_STATUS_MAP],
  };
};

export const downloadFile: (preSignedUrl: string) => void = (preSignedUrl) => {
  const anchorTag = document.createElement('a');
  anchorTag.href = preSignedUrl;
  anchorTag.click();
};

export const checkFetchedStatus = (data:any) => {
  const hashes = data.payload.download_status;
  const inProgressStatus = Object.values(hashes).find((eachHash:any) => eachHash.includes('Progress'));
  return isEmpty(inProgressStatus);
};

export const checkAcmFiltersSelected = (selectedExport:any, isExportDisabled: boolean, userScope: any) => {
  if (userScope === USER_SCOPE_NSWC) {
    const acmFiltersSelected = (selectedExport?.operator_name?.length > 0 && selectedExport?.aircraft_type.length > 0 && selectedExport?.selected_msn.length > 0);
    return (!acmFiltersSelected || isExportDisabled);
  }
  const acmFiltersSelected = (selectedExport?.operator_name?.length > 0 && selectedExport?.aircraft_type.length > 0);
  return (!acmFiltersSelected || isExportDisabled);
};

/**
 *
 * @param moduleName string representing Module name for which permission value needed
 * @returns permission value either readonly or read write.
 */
export const getPermissionForModule = (moduleName: string, icao: string, userScope: string, allPermissions: any) => {
  let permissionMap = JSON.parse(JSON.stringify(allPermissions));
  if (!CORE_INTERNAL.includes(userScope)) {
    permissionMap = permissionMap.filter((permission:any) => permission.icao === icao);
  }
  const currentPermission = permissionMap.filter((permission:any) => permission.module === moduleNameMap[moduleName]);
  // in current permissions check if all the permissions are read-write type.
  const foundReadWrite = currentPermission.every((permissionObj:any) => READ_WRITE.includes(permissionObj.permission));
  // if not all permissions of read-write type then return it as readonly.
  return foundReadWrite ? READ_WRITE_FLAG : READ_ONLY_FLAG;
};

// Generate Header map from routers list
export const generateHeaderTabText = (routerList: Array<string>) => {
  return routerList.map((route, index) => {
    return {
      id: index + 1,
      title: ROUTER_MODULE_MAP[route],
      routeTo: MODULE_PRECEDENCE.indexOf(route) === 0 ? '/' : route,
      disabled: false,
    };
  });
};

/**
 * Removes all data stored in local store.
 * \
 * Checks if last consent on disclaimer is expired.
 * In case of consent is expired, clears it or restores the value if not expired.
 * \
 * Use this while logging out.
 */
export const clearLocalStore = () => {
  secureLocalStore.clear();
};
export const handleUnwantedDataRemoval = (toRemoveList: Array<string>, originalObj: Array<object>) => {
  /*  This function removes the unwanted set of values from the original array of objects */
  return originalObj.filter((obj) => {
    return !toRemoveList.some((val) => Object.values(obj).includes(val));
  });
};
export const resolveColumnValue = (acrValue: string | null | undefined, misValue: string | null | undefined, withKey?: boolean) => {
  const resolvedColumn = [];
  // replace _NEXT_ with comma separator to show proper value in details view UI and in filters.
  const acrMultiVal = acrValue?.replace(/_NEXT_/g, ', ');
  const misMultiVal = misValue?.replace(/_NEXT_/g, ', ');
  if (acrValue) {
    resolvedColumn.push(withKey ? { acr: [acrMultiVal] } : [acrMultiVal]);
  }
  if (misValue) {
    resolvedColumn.push(withKey ? { mis: [misMultiVal] } : [misMultiVal]);
  }
  return resolvedColumn;
};

export const keyMapperFunction: any = (data: (string[] | string)[], mapping: KeyValueMap) => {
  return data.map((d) => {
    if (typeof d === 'string') {
      return `${d.split('#')[0]}#${Object.keys(mapping).filter((key) => d.split('#')[1] === mapping[key])}`;
    }
    if (Array.isArray(d)) {
      return d.map((val) => Object.keys(mapping).filter((key) => val === mapping[key]).toString());
    }
    return null;
  });
};

export const processPnrData = (dataValue: any, itemPnr: string, source: string) => {
  const { asAllowedPnrArray, finalPnrArray } = sortPnrArray(dataValue[`${source}_reason_detail`]);
  let allowedPnrs: Array<any> = [];
  let allowedConditions: Array<any> = [];
  const [matchedMap, unMatchedEmptyMap, unMatchWithCond] = [findMatchedCond(asAllowedPnrArray, itemPnr),
    findUnMatchWithoutCond(asAllowedPnrArray, itemPnr), findUnMatchWithCond(asAllowedPnrArray, itemPnr)];
  [matchedMap, unMatchedEmptyMap, unMatchWithCond].forEach((ele: Map<string, string>) => {
    const pnrValues = Array.from(ele.values());
    const pnrCheck = pnrValues.length > 0 ? pnrValues.map((item: string) => item?.replace(/^,+/, '').trim()) : pnrValues;
    let conditionValue = Array.from(ele.keys());
    // convert strings like ',' or ', , ' into ''
    conditionValue = conditionValue.map((cond: unknown) => (cond as string).replace(/,+/g, '').trim() || '');
    if (!isEqual(pnrCheck, [''])) {
      allowedPnrs.push(...pnrCheck);
      allowedConditions.push(...conditionValue);
    }
  });
  allowedPnrs = allowedPnrs.map((ele) => [ele]);
  allowedConditions = allowedConditions.map((ele) => [ele]);
  return {
    asAllowedPnrArray, finalPnrArray, allowedPnrs, allowedConditions,
  };
};

export const reasonPopDetails: (dataValue: any, source: string) => Array<any> = (dataValue, source) => {
  let pnrArray: any = [];
  pnrArray = dataValue[`${source}_pnr`]?.split('_NEXT_') || [];
  const mappedPnrArray = pnrArray.map((item: string) => {
    const reasonPopupResponse = Object.create(null);
    const itemPnr = item.trim();
    if (dataValue[`${source}_reason_detail`]) {
      const { allowedPnrs, allowedConditions } = processPnrData(dataValue, itemPnr, source);
      reasonPopupResponse.as_allowed_pnr = allowedPnrs;
      reasonPopupResponse.as_allowed_cond = allowedConditions;
    }
    reasonPopupResponse.source = dataValue[`${source}_source`];
    reasonPopupResponse.pnr = itemPnr;
    // find the status required for pnr
    return reasonPopupResponse;
  });
  return sortMultiplePnrs(mappedPnrArray);
};
export const misAcrPnrOrigin = (sourceMisAcr:any) => {
  // checking for MIS and ACR pnr origin data has _NEXT_ that will be splitted and seperates to next line in table cell
  const splitPnrOrigin = sourceMisAcr?.split('_NEXT_');
  const partNumber = splitPnrOrigin?.map((x: any[]) => [x]);
  return partNumber;
};

export const deviationPnrAcrDescription = (sourceAcr: any) => {
  // BIMAT logic for ACR to seperate the pnr and description values
  const splitArray = sourceAcr?.split('_NEXTELEMENT_')?.map((value: any) => {
    return value.trim();
  });
  const acrData = splitArray?.map((str: string) => str.split('_SEP_'));
  // x[0] will give ACR multiple pnrs in this format - [[31543], [43537]]
  const acrPnrOrgData = acrData?.map((x: any[]) => [x[0]]);
  const acrPnrOrgValue = acrPnrOrgData[0][0]?.replace(/NODATA/g, ' ');
  // x[1] will give ACR multiple descriptions in this format - [[original part number], [cleaned by airbus]]
  const acrDescriptionData = acrData?.map((x: any[]) => [x[1]]);
  return { acrPnrOrgValue, acrDescriptionData };
};

export const deviationPnrMisDescription = (sourceMis: any) => {
  // BIMAT logic for MIS to seperate the pnr and description values
  const noDataValue = sourceMis?.replace(/NODATA/g, ' ');
  const splitMisArray = noDataValue?.split('_NEXTELEMENT_')?.map((value: any) => {
    return value.trim();
  });
  const misData = splitMisArray?.map((str: string) => str.split('_SEP_'));
  const filteredArray = misData.filter((item:any) => !item.some((y:any) => y === ''));
  // x[0] will give MIS multiple pnrs in this format - [[31643], [43737]]
  const misPnrOrgData = filteredArray?.map((x: any[]) => [x[0]]);
  // x[1] will give MIS multiple descriptions in this format - [[original part number], [cleaned by airbus]]
  const misDescriptionData = filteredArray?.map((x: any[]) => [x[1]]);
  // Duplicate descriptions are removed
  const uniqueValues = Array.from(new Set(misDescriptionData.map(JSON.stringify)));
  const uniqueDescriptionData = uniqueValues.map((json:any) => JSON.parse(json));
  return { misPnrOrgData, uniqueDescriptionData };
};

export const deviationDetailsData = (sourceAcr: any, sourceMis:any) => {
  const deviationData = [];
  // BIMAT logic for ACR deviation table data when will be rendered in UI
  // !! condition will check for both !== null and !==''
  if ((sourceAcr.acr_deviation_type !== 'wrongSN') && (sourceAcr.acr_deviation_type !== null)
  && (!!sourceAcr.acr_deviation_id)
  && (!!sourceAcr.acr_org_pnr) && sourceAcr.acr_org_pnr !== 'XXX') {
    const { acrPnrOrgValue, acrDescriptionData } = deviationPnrAcrDescription(sourceAcr.acr_deviation_description);
    if (acrDescriptionData[0][0] !== 'NODATA') {
      deviationData.push({
        source: sourceAcr.acr_source,
        fin_origin: sourceAcr.acr_org_fin,
        deviation_part_number: misAcrPnrOrigin(sourceAcr?.acr_pnr),
        pn_origin: acrPnrOrgValue,
        deviation_description: acrDescriptionData,
      });
    }
  }
  // BIMAT logic for missing || pnrOrigin === null || XXX
  if ((sourceAcr.acr_org_pnr === '' || sourceAcr.acr_org_pnr === 'XXX') && (sourceAcr.acr_deviation_type === 'missing')) {
    const { acrPnrOrgValue, acrDescriptionData } = deviationPnrAcrDescription(sourceAcr.acr_deviation_description);
    if (acrDescriptionData[0][0] !== 'NODATA') {
      deviationData.push({
        source: sourceAcr.acr_source,
        fin_origin: sourceAcr.acr_org_fin,
        deviation_part_number: misAcrPnrOrigin(sourceAcr?.acr_pnr),
        pn_origin: acrPnrOrgValue,
        deviation_description: acrDescriptionData,
      });
    }
  }
  // BIMAT logic for MIS deviation table data when will be rendered in UI
  // !! condition will check for both !== null and !==''
  if (sourceMis.mis_madam_pnr !== null && sourceMis.mis_madam_pnr) {
    const { misPnrOrgData, uniqueDescriptionData } = deviationPnrMisDescription(sourceMis.mis_deviation_description);
    deviationData.push({
      source: sourceMis.mis_source,
      fin_origin: sourceMis.mis_org_fin,
      deviation_part_number: misAcrPnrOrigin(sourceMis?.mis_pnr),
      pn_origin: misPnrOrgData,
      deviation_description: uniqueDescriptionData,
    });
  }
  return deviationData;
};

/**
 *
 * @param responseArr
 * [
 *   {
 *     "Operator": "BIX",
 *     "id_aircraft_registration": "N02283 SE-RGV",
 *     "source_MIS": {
 *       "source": "MIS",
 *       "as_allowed_pnr": ["2119332-00"],
 *       "as_allowed_cond": "PRE SB-21 P041"
 *     },
 *     "source_ACR": {
 *       "source": "ACR",
 *       "as_allowed_pnr": ["2119332-00"],
 *       "as_allowed_cond": "N/A"
 *     }
 *   },
 *   ...
 * ]
 *
 * @returns
 * [
 *   {
 *     "Operator": "BIX",
 *     "id_aircraft_registration": "N02283 SE-RGV",
 *     "source": [["MIS"], ["ACR"]],
 *     "as_allowed_pnr": ["2119332-00", "2119332-00"],
 *     "as_allowed_cond": [["PRE SB-21 P041"], ["N/A"]]
 *   },
 *   ...
 * ]
 */

export const restructureResponseForCheckerTable: (responseObj: CheckersApiResponseType, reasonStatusMap: any) => CheckerDetailsType = (responseObj, reasonStatusMap) => {
  const finalObj: CheckerDetailsType = {
    operator_code_icao: responseObj.operator_code_icao || '',
    allowable_config_date: responseObj.allowable_config_date || '',
    acd_date: responseObj.acd_date || '',
    aisd_date: responseObj.aisd_date || '',
    extraction_date: responseObj.extraction_date || '',
    total_result: responseObj.total_result || 0,
    tableData: [],
  };
  const tableData: any = [];
  responseObj.ACR_MIS_data.forEach((element: AcrMisSource, index: number) => {
    tableData.push({
      fleet_fin: element.fleet_data.fleet_fin,
      id_aircraft_registration: element.fleet_data.id_aircraft_registration,
      ata: element.fleet_data.ata,
      fin_description: element.fleet_data.fin_description,
      fin_status: element.fleet_data.fin_status,
      status_part_check: element.fleet_data.status_part_check,
      pnr: resolveColumnValue(element.source_ACR.acr_pnr, element.source_MIS.mis_pnr),
      source: resolveColumnValue(element.source_ACR.acr_source, element.source_MIS.mis_source),
      as_allowed_pnr: resolveColumnValue(element.source_ACR.acr_as_allowed_pnr, element.source_MIS.mis_as_allowed_pnr),
      as_allowed_cond: resolveColumnValue(element.source_ACR.acr_as_allowed_cond, element.source_MIS.mis_as_allowed_cond),
      source_status: keyMapperFunction(resolveColumnValue(element.source_ACR.acr_source_status, element.source_MIS.mis_source_status), reasonStatusMap?.statusMapping),
      reason: keyMapperFunction(resolveColumnValue(element.source_ACR.acr_reason, element.source_MIS.mis_reason), reasonStatusMap?.reasonMapping),
      reason_detail: resolveColumnValue(element.source_ACR.acr_reason_detail, element.source_MIS.mis_reason_detail),
      reason_detail_status: resolveColumnValue(element.source_ACR.acr_reason_detail_status, element.source_MIS.mis_reason_detail_status),
      popup_details: [...reasonPopDetails(element.source_ACR, 'acr'), ...reasonPopDetails(element.source_MIS, 'mis')],
      column_pnr: resolveColumnValue(element.source_ACR.acr_pnr, element.source_MIS.mis_pnr, true),
      column_source: resolveColumnValue(element.source_ACR.acr_source, element.source_MIS.mis_source, true),
      column_source_status: resolveColumnValue(element.source_ACR.acr_source_status, element.source_MIS.mis_source_status, true),
      column_reason: resolveColumnValue(element.source_ACR.acr_reason, element.source_MIS.mis_reason, true),
      user_id: element.fleet_data.user_id,
      deviation_details: deviationDetailsData(element.source_ACR, element.source_MIS),
      deviation_id: element.source_ACR.acr_deviation_id,
      deviation_type: element.source_ACR.acr_deviation_type,
      madam_pnr: element.source_MIS.mis_madam_pnr,
    });
  });

  finalObj.tableData = [...tableData];
  return finalObj;
};

export const setValidCheckerOptions = (detailFilters: any, fleetFilters: any, currentOperator: string, detailsOperator: any, ifOperatorChanged?: boolean, ifAircraftTypeChanged?: boolean, ifAircraftSubTypeChanged?: boolean) => {
  if (currentOperator?.toString() !== detailsOperator?.toString() && currentOperator?.length > 0) {
    return detailFilters;
  }
  return (detailFilters && detailFilters.length > 0 && (ifOperatorChanged || ifAircraftTypeChanged || ifAircraftSubTypeChanged)) ? detailFilters : fleetFilters;
};
// eslint-disable-next-line consistent-return
export const setValidCheckerValue = (operatorValue: string, currentFilterVal: any, checkerDetailVal: any, ifOperatorChanged?: boolean, ifAircraftTypeChanged = false, isChanged = false) => {
  if (operatorValue?.length > 0 && ifOperatorChanged) {
    return currentFilterVal;
  }
  if (ifAircraftTypeChanged === true) {
    return currentFilterVal;
  }
  if (ifOperatorChanged === false) {
    let result;
    if (currentFilterVal?.length > 0) {
      result = currentFilterVal;
    } else if (isChanged) {
      result = currentFilterVal;
    } else {
      result = checkerDetailVal;
    }
    return result;
  }
};

export const removeDuplicates = (arr: Array<string | number>) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

export const setTab = (location: any, setSelectedTab: any, authEntry: any, currNav: any) => {
  if (location.pathname.includes('concurrentRequirement')) {
    setSelectedTab(1);
    return;
  }
  if (location.pathname.includes('uploadHistory')) {
    setSelectedTab(undefined);
    return;
  }
  if (location.pathname.includes('settings')) {
    setSelectedTab(undefined);
  } else if (authEntry.moduleList.includes('/checker')) {
    setSelectedTab(currNav ? currNav.id : 2);
  } else {
    setSelectedTab(currNav ? currNav.id : 1);
  }
};

export const setBoxValues = (
  columnLevelFilterState: any,
  getStatusReasonMapper: any,
  reasonStatusMappingState: any,
  setcomboBoxValues: any,
  comboBoxValues: any,
  setSpinner: any,
  intialSpinnerData: any,
) => {
  const itemArrayNew = [];
  for (const x of columnLevelFilterState.FilterData) {
    const item = Object.values(x);
    itemArrayNew.push(item[0]);
  }
  const key = columnLevelFilterState.FilterData.length !== 0
    ? Object.keys(columnLevelFilterState.FilterData[0])[0]
    : '';
  let itemArrayUpdated = [];
  itemArrayUpdated = getStatusReasonMapper(
    key,
    itemArrayNew,
    reasonStatusMappingState?.data?.status_key_mapper,
    reasonStatusMappingState?.data?.reason_key_mapper,
  );
  setcomboBoxValues({ ...comboBoxValues, [key]: itemArrayUpdated });
  if (!columnLevelFilterState.isColumnDataLoading) {
    setSpinner(intialSpinnerData);
  }
};

export const resetComboBox = (
  resetForm: any,
  setSelectedComboValues: any,
  setFilterValues: any,
  intialStateFilter: any,
) => {
  if (resetForm) {
    setSelectedComboValues([]);
    setFilterValues(intialStateFilter);
  }
};
export const noDataCheck = (count: number, transformedData: any) => {
  if (count === 0) {
    return {
      ...transformedData,
      chartData: {
        labels: ['NO DATA'],
        datasets: [
          {
            label: 'NO DATA',
            data: [1e-10],
            backgroundColor: ['#647592'],
            borderColor: ['#647592'],
            borderWidth: 1,
          },
        ],
      },
      all_msn_contribution: false,
    };
  }
  return transformedData;
};

export const transformChartData: (chartResponse: ChartDataType, chartTitle: string) => any = (chartResponse, chartTitle) => {
  let transformedData: any = {
    chartData: {
      labels: [WRONG_PART, TBA, MATCH],
      datasets: [
        {
          label: '',
          data: [
            chartResponse.wrong_part_count,
            chartResponse.tobe_analysed_count,
            chartResponse.match_count,
          ],
          backgroundColor: ['#F23346', '#255FCC', '#18A272'],
          borderColor: ['#F23346', '#255FCC', '#18A272'],
          borderWidth: 1,
        },
      ],
    },
    labelData: [
      {
        color: 's4',
        label: WRONG_PART,
        value: chartResponse.wrong_part_count,
        percentage: chartResponse.wrong_part_percentage,
        newTab: true,
      },
      {
        color: 's8',
        label: TBA,
        value: chartResponse.tobe_analysed_count,
        percentage: chartResponse.tobe_analysed_percentage,
        newTab: true,
      },
      {
        color: 's1',
        label: MATCH,
        value: chartResponse.match_count,
        percentage: chartResponse.match_percentage,
        newTab: true,
      },
    ],
    total_count: chartResponse.total_count,
    all_msn_contribution: !chartResponse?.all_msn_contribution,
    title: chartTitle,
  };
  transformedData = noDataCheck(chartResponse.total_count, transformedData);
  return transformedData;
};

export const transformPnrSourceChartData: (pnrSOurceChartResponse: PnrSourceDataType, chartTitle: string) => any = (pnrSOurceChartResponse, chartTitle) => {
  let transformedData: any = {
    chartData: {
      labels: [SOURCE_UNMATCHED, NA, SOURCE_MATCHED],
      datasets: [
        {
          label: '',
          data: [
            pnrSOurceChartResponse.source_unmatched_count,
            pnrSOurceChartResponse.not_applicable_count,
            pnrSOurceChartResponse.source_matched_count,
          ],
          backgroundColor: ['#F23346', '#255FCC', '#18A272'],
          borderColor: ['#F23346', '#255FCC', '#18A272'],
          borderWidth: 1,
        },
      ],
    },
    labelData: [
      {
        color: 's4',
        label: SOURCE_UNMATCHED,
        value: pnrSOurceChartResponse.source_unmatched_count,
        percentage: pnrSOurceChartResponse.source_unmatched_percentage,
        newTab: true,
      },
      {
        color: 's8',
        label: NA,
        value: pnrSOurceChartResponse.not_applicable_count,
        percentage: pnrSOurceChartResponse.not_applicable_percentage,
        newTab: true,
      },
      {
        color: 's1',
        label: SOURCE_MATCHED,
        value: pnrSOurceChartResponse.source_matched_count,
        percentage: pnrSOurceChartResponse.source_matched_percentage,
        newTab: true,
      },
    ],
    total_count: pnrSOurceChartResponse.total_count,
    title: chartTitle,
  };
  transformedData = noDataCheck(pnrSOurceChartResponse.total_count, transformedData);
  return transformedData;
};
export const misAcrStatusMapping = (statusData: any, statusMapping: KeyValueMap | undefined) => {
  return Object.fromEntries(Object.entries(statusMapping || {}).map(([Key, value]) => [Key, value !== undefined && statusData[value] ? statusData[value] : 0]));
};
export const percentageCalculation = (count: number, totalCount: number) => {
  if (totalCount === 0) {
    return 0;
  }
  const percentage = (count / totalCount) * 100;
  return parseFloat(percentage.toFixed(2));
};

export const transformMisAcrSourceChartData: (misAcrSOurceChartResponse: any, misAcrStatusCount: any, chartTitle: string) => any = (misAcrSOurceChartResponse, misAcrStatusCount, chartTitle) => {
  const misAcrLabels = [
    'Wrong part without condition',
    'Wrong part with condition',
    'Manual review required',
    'Additional',
    'Missing',
    'Not checked',
    'Match without condition',
    'Match with condition',
  ];
  let transformedData: any = {
    chartData: {
      labels: misAcrLabels,
      datasets: [
        {
          label: '',
          data: [
            misAcrSOurceChartResponse['Wrong part without condition'],
            misAcrSOurceChartResponse['Wrong part with condition'],
            misAcrSOurceChartResponse['Manual review required'],
            misAcrSOurceChartResponse.Additional,
            misAcrSOurceChartResponse.Missing,
            misAcrSOurceChartResponse['Not checked'],
            misAcrSOurceChartResponse['Match without condition'],
            misAcrSOurceChartResponse['Match with condition'],
          ],
          backgroundColor: ['#92001C', '#F23346', '#219AE1', '#C8EAFF', '#CFDDF8', '#255FCC', '#18A272', '#A3DA22'],
          borderColor: ['#92001C', '#F23346', '#219AE1', '#C8EAFF', '#CFDDF8', '#255FCC', '#18A272', '#A3DA22'],
          borderWidth: 1,
        },
      ],
    },
    labelData: [
      {
        color: 's3',
        label: misAcrLabels[0],
        value: misAcrSOurceChartResponse['Wrong part without condition'],
        percentage: percentageCalculation(misAcrSOurceChartResponse['Wrong part without condition'], misAcrStatusCount.total_count),
        newTab: true,
      },
      {
        color: 's4',
        label: misAcrLabels[1],
        value: misAcrSOurceChartResponse['Wrong part with condition'],
        percentage: percentageCalculation(misAcrSOurceChartResponse['Wrong part with condition'], misAcrStatusCount.total_count),
        newTab: true,
      },
      {
        color: 's6',
        label: misAcrLabels[2],
        value: misAcrSOurceChartResponse['Manual review required'],
        percentage: percentageCalculation(misAcrSOurceChartResponse['Manual review required'], misAcrStatusCount.total_count),
        newTab: true,
      },
      {
        color: 's5',
        label: misAcrLabels[3],
        value: misAcrSOurceChartResponse.Additional,
        percentage: percentageCalculation(misAcrSOurceChartResponse.Additional, misAcrStatusCount.total_count),
        newTab: true,
      },
      {
        color: 's7',
        label: misAcrLabels[4],
        value: misAcrSOurceChartResponse.Missing,
        percentage: percentageCalculation(misAcrSOurceChartResponse.Missing, misAcrStatusCount.total_count),
        newTab: true,
      },
      {
        color: 's8',
        label: misAcrLabels[5],
        value: misAcrSOurceChartResponse['Not checked'],
        percentage: percentageCalculation(misAcrSOurceChartResponse['Not checked'], misAcrStatusCount.total_count),
        newTab: true,
      },
      {
        color: 's1',
        label: misAcrLabels[6],
        value: misAcrSOurceChartResponse['Match without condition'],
        percentage: percentageCalculation(misAcrSOurceChartResponse['Match without condition'], misAcrStatusCount.total_count),
        newTab: true,
      },
      {
        color: 's2',
        label: misAcrLabels[7],
        value: misAcrSOurceChartResponse['Match with condition'],
        percentage: percentageCalculation(misAcrSOurceChartResponse['Match with condition'], misAcrStatusCount.total_count),
        newTab: true,
      },
    ],
    total_count: misAcrStatusCount.total_count,
    all_msn_contribution: !misAcrStatusCount.all_msn_contribution,
    title: chartTitle,
  };
  transformedData = noDataCheck(misAcrStatusCount.total_count, transformedData);
  return transformedData;
};

export const setLocalStore = (key: string, value: string) => {
  window.localStorage.setItem(key, value);
};

export const getFromLocalStore = (key: string) => {
  return window.localStorage.getItem(key);
};

export const removeFromLocalStore = (key: string) => {
  return window.localStorage.removeItem(key);
};

export const getFiltersForFinStatusAndPnrSource = (
  filterKey: string,
  filterValue: string,
) => {
  let conditions = {};
  if (filterKey === FIN_STATUS) {
    conditions = { fin_status: [REPORT_STATUS_MAPPER[filterValue]] };
  } else if (filterKey === PNR_SOURCE_COMPARISON) {
    conditions = { status_part_check: [REPORT_STATUS_MAPPER[filterValue]] };
  }
  return conditions;
};

export const getConditions = (
  filterKey: string,
  filterValue: string,
  reasonStatusMapping: any,
) => {
  let conditions: any = getFiltersForFinStatusAndPnrSource(
    filterKey,
    filterValue,
  );
  if (
    filterKey === ACR_DATASOURCE
    && reasonStatusMapping.data?.status_key_mapper
  ) {
    conditions = {
      acr_source: ['ACR'],
      acr_source_status: [
        reasonStatusMapping.data?.status_key_mapper[filterValue],
      ],
    };
  } else if (
    filterKey === MIS_DATASOURCE
    && reasonStatusMapping.data?.status_key_mapper
  ) {
    conditions = {
      mis_source: ['MIS'],
      mis_source_status: [
        reasonStatusMapping.data?.status_key_mapper[filterValue],
      ],
    };
  }
  return conditions;
};

export const getFilterState = (
  filterKey: string,
  filterValue: string,
  reasonStatusMapping: any,
) => {
  let filterState: any = getFiltersForFinStatusAndPnrSource(
    filterKey,
    filterValue,
  );
  if (
    (filterKey === 'ACR' || filterKey === 'MIS')
    && reasonStatusMapping.data?.status_key_mapper
  ) {
    filterState = {
      source: [REPORT_STATUS_MAPPER[filterKey]],
      source_status: [`acr#${filterValue}`],
    };
  }
  return filterState;
};
