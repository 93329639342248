/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import {
  ComboBox, Typography, Row, Col, Spinner,
} from '@airbus/components-react';
import '../ModalPopup.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import {
  COMBOBOX_PLACEHOLDER, OPERATOR, AIRCRAFT_TYPE, MAX_LIMIT, DEFAULT_OFFSET, ASCENDING, AIRCRAFT_SUB_TYPE, BROWSER, PART_AIRCRAFT_DETAILS_COLUMNS, SB_AIRCRAFT_DETAILS_COLUMNS,
} from '../../../../utils/constants';
import { loadSpinner } from '../../../../utils/commonMethods/modalPopupMethods';
import {
  loadSb, loadMod, loadPartAircraftDetails, loadSbAircraftDetails,
} from '../../../../models/popupFilterModel/popupFilterThunks';
import { useAppDispatch } from '../../../../store/hooksTypes';
import {
  getAcAndAcSubTypesForBrowser, getDataSourceForPart, getMsnForSbBrowser,
} from '../../../../models/popupFilterModel/popupFilterSlice';
import { getAcSubTypeList, registerPostHogProperties } from '../../../../utils/commonUtil';

const CommanField = function CommanField(props: any) {
  const {
    commonFieldProps,
  } = props;
  const dispatch = useAppDispatch();
  const [acSubTypeOptions, setAcSubTypeOptions] = React.useState<Array<string>>([]);
  const handleColumnsForBrowser = (selectedOperator: any, selectedBrowserTab: number) => {
    if (selectedBrowserTab) {
      if (!_.isEmpty(commonFieldProps.filterProps?.filterState?.sb_data_selection_columns)
        && commonFieldProps.filterProps?.filterState?.sb_data_selection_columns[selectedOperator]) {
        const bodyPayload: any = {
          operator_code_icao: selectedOperator,
          sb_data_selection_columns: commonFieldProps.filterProps.sb_data_selection_columns,
          selectedTab: selectedBrowserTab,
        };
        dispatch(getAcAndAcSubTypesForBrowser(bodyPayload));
        return;
      }
      const payloadParamsForOperatorMsn: allColumnsBodyPayload = {
        conditions: {},
        operator_code_icao: new Array(selectedOperator),
        sortType: ASCENDING,
        distinct: true,
        columns: SB_AIRCRAFT_DETAILS_COLUMNS,
        orderBy: ['id_aircraft_registration'],
      };
      dispatch(loadSbAircraftDetails(payloadParamsForOperatorMsn)({ selectedOperator }));
      commonFieldProps.setAircraftTypeValue('');
    } else {
      if (!_.isEmpty(commonFieldProps.filterProps?.filterState?.part_data_selection_columns)
        && commonFieldProps.filterProps?.filterState?.part_data_selection_columns[selectedOperator]) {
        const bodyPayload: any = {
          operator_code_icao: selectedOperator,
          part_data_selection_columns: commonFieldProps.filterProps.part_data_selection_columns,
          selectedTab: selectedBrowserTab,
        };
        dispatch(getAcAndAcSubTypesForBrowser(bodyPayload));
        return;
      }
      const payloadParamsForOperatorMsn: allColumnsBodyPayload = {
        conditions: {},
        operator_code_icao: new Array(selectedOperator),
        sortType: ASCENDING,
        distinct: true,
        columns: PART_AIRCRAFT_DETAILS_COLUMNS,
        orderBy: ['id_aircraft_registration'],
      };
      dispatch(loadPartAircraftDetails(payloadParamsForOperatorMsn)(selectedOperator));
      commonFieldProps.setAircraftTypeValue('');
    }
  };
  const handleOperatorSelection = (selectedOperator: any) => {
    registerPostHogProperties({
      ICAO_CODE: selectedOperator.toString(),
    });
    // eslint-disable-next-line consistent-return
    commonFieldProps.setCurrentFilterConfig(() => {
      return {
        aircraft_type: [],
        operator_name: selectedOperator,
        aircraft_subtype: [],
        selected_mod: [],
        selected_sb: [],
        selected_msn: [],
        selected_dataSource: '',
        selected_fin: [],
        selected_pnr: [],
      };
    });
    commonFieldProps.setOperatorValue(selectedOperator);
  };

  const handleDataSourceReset = (selectedAc: Array<string>, operatorName: string, selectedAcSubtype?: Array<string>) => {
    const bodyPayload: any = {
      aircraft_types: selectedAc,
      operator_code_icao: operatorName,
      part_data_selection_columns: commonFieldProps.filterProps.part_data_selection_columns,
    };
    if (selectedAcSubtype && selectedAcSubtype.length) {
      bodyPayload.aircraft_family = selectedAcSubtype;
    }
    dispatch(getDataSourceForPart(bodyPayload));
  };

  const handleAcSelection = (selectedAc: Array<string>) => {
    commonFieldProps.setCurrentFilterConfig((prevState: any) => {
      return {
        ...prevState,
        aircraft_type: selectedAc,
        aircraft_subtype: [],
        selected_msn: [],
        selected_dataSource: '',
        selected_mod: [],
        selected_sb: [],
        selected_fin: [],
        selected_pnr: [],
      };
    });
    commonFieldProps.setAircraftTypeValue(selectedAc);
    const arrayOperator = new Array(commonFieldProps.currentFilterConfig?.operator_name);
    const arrayACType = selectedAc;
    if (selectedAc.length) {
      if (commonFieldProps.selectedBrowserTab === 1) {
        const msnPayload: any = {
          aircraft_types: selectedAc,
          aircraft_family: [],
          operator_code_icao: commonFieldProps.currentFilterConfig?.operator_name,
          sb_data_selection_columns: commonFieldProps.filterProps.sb_data_selection_columns,
        };
        dispatch(getMsnForSbBrowser(msnPayload));
        const bodyPayload: msnSbModBodyPayload = {
          operator_code_icao: arrayOperator,
          conditions: {
            aircraft_type: arrayACType,
          },
          limit: Number(MAX_LIMIT),
          offset: Number(DEFAULT_OFFSET),
          sortType: ASCENDING,
          search: false,
        };
        const sbModPayload = JSON.parse(JSON.stringify(bodyPayload));
        delete sbModPayload.conditions.id_aircraft_registration;
        dispatch(loadSb({ ...sbModPayload, orderBy: ['sb_reference'] })());
        dispatch(loadMod({ ...sbModPayload, orderBy: ['mod_reference'] })());
      } else {
        handleDataSourceReset(selectedAc, commonFieldProps.currentFilterConfig?.operator_name);
      }
    }
  };

  const handleAcSubtypeSelection = async (selectedAcSubtype: Array<string>) => {
    const arrayOperator = new Array(commonFieldProps.currentFilterConfig?.operator_name);
    const arrayACType = commonFieldProps.currentFilterConfig?.aircraft_type;
    commonFieldProps.setCurrentFilterConfig((prevState: any) => {
      return {
        ...prevState,
        aircraft_subtype: selectedAcSubtype,
        selected_msn: [],
        selected_dataSource: '',
        selected_mod: [],
        selected_sb: [],
        selected_fin: [],
        selected_pnr: [],
      };
    });
    if (commonFieldProps.selectedBrowserTab === 1) {
      const msnPayload: any = {
        aircraft_types: commonFieldProps.currentFilterConfig?.aircraft_type,
        aircraft_family: selectedAcSubtype,
        operator_code_icao: commonFieldProps.currentFilterConfig?.operator_name,
        sb_data_selection_columns: commonFieldProps.filterProps.sb_data_selection_columns,
        selectedTab: commonFieldProps.selectedBrowserTab,
      };
      dispatch(getMsnForSbBrowser(msnPayload));
      const bodyPayload: msnSbModBodyPayload = {
        operator_code_icao: arrayOperator,
        conditions: {
          aircraft_type: arrayACType,
        },
        limit: Number(MAX_LIMIT),
        offset: Number(DEFAULT_OFFSET),
        sortType: ASCENDING,
        search: false,
      };
      const bodyPayloadSbMod = JSON.parse(JSON.stringify(bodyPayload));
      bodyPayloadSbMod.conditions.id_aircraft_registration = commonFieldProps.filterProps.msn;
      dispatch(loadSb({ ...bodyPayloadSbMod, orderBy: ['sb_reference'] })());
      dispatch(loadMod({ ...bodyPayloadSbMod, orderBy: ['mod_reference'] })());
    } else {
      handleDataSourceReset(commonFieldProps.currentFilterConfig?.aircraft_type, commonFieldProps.currentFilterConfig?.operator_name, selectedAcSubtype);
    }
  };
  useEffect(() => {
    if (!_.isEmpty(commonFieldProps?.filterProps?.selectedFilterState?.operator_name)) {
      const selectedOperator = commonFieldProps?.filterProps?.selectedFilterState?.operator_name;
      if (commonFieldProps.filterProps.sourceTab === BROWSER && commonFieldProps.selectedBrowserTab === 0) {
        handleColumnsForBrowser(selectedOperator, commonFieldProps.selectedBrowserTab);
      }
    }
    if (!_.isEmpty(commonFieldProps.filterProps?.selectedFilterState?.aircraft_type) && commonFieldProps.selectedBrowserTab === 0) {
      handleDataSourceReset(commonFieldProps.filterProps?.selectedFilterState?.aircraft_type, commonFieldProps.filterProps?.selectedFilterState?.operator_name, commonFieldProps.filterProps?.selectedFilterState?.ac_sub_type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!_.isEmpty(commonFieldProps.currentFilterConfig?.operator_name)) handleColumnsForBrowser(commonFieldProps.currentFilterConfig?.operator_name, commonFieldProps.selectedBrowserTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonFieldProps.currentFilterConfig?.operator_name]);
  useEffect(() => {
    const selectedAcType: Array<string> = commonFieldProps.currentFilterConfig?.aircraft_type;
    // Need to filter all the AC sub types based on AC types.
    const filteredACSubTypeOptions: Array<string> = !_.isEmpty(selectedAcType) && !_.isEmpty(commonFieldProps.filterProps?.aircraftSubType)
      ? getAcSubTypeList(selectedAcType, commonFieldProps.filterProps.aircraftSubType) : [];
    if (!_.isEmpty(filteredACSubTypeOptions)) {
      setAcSubTypeOptions(filteredACSubTypeOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonFieldProps.currentFilterConfig?.aircraft_type, commonFieldProps.filterProps.aircraft]);
  return (
    <>
      <Row className="filter-row">
        <Col xxs={3}>
          <Typography variant="small" className="filter-label">
            {OPERATOR}
            <span className="required-fields">*</span>
          </Typography>
        </Col>
        <Col xxs={1}>
          {(!commonFieldProps.filterProps.filterState?.operators_list?.length) && <Spinner />}
        </Col>
        <Col>
          <ComboBox
            aria-label="Select Operator"
            placeholder={COMBOBOX_PLACEHOLDER}
            value={commonFieldProps.currentFilterConfig?.operator_name.toString()}
            data-testid="operator-selector"
            disabled={!commonFieldProps.filterProps.filterState?.operators_list?.length}
            onChange={(value: any) => handleOperatorSelection(value)}
            options={commonFieldProps.filterProps.filterState?.operators_list}
            getOptionLabel={(option) => option}
            getOptionValue={(option) => option}
            hideClear
          />
        </Col>
      </Row>
      <Row className="filter-row">
        <Col xxs={3}>
          <Typography variant="small" className="filter-label">
            {AIRCRAFT_TYPE}
            <span className="required-fields">*</span>
          </Typography>
        </Col>
        <Col xxs={1}>
          {loadSpinner(commonFieldProps.filterProps.filterState?.isFilterLoading, commonFieldProps.operatorValue, commonFieldProps.aircraftTypeValue)}
        </Col>
        <Col>
          <ComboBox
            aria-label="Select ACType"
            placeholder={commonFieldProps.currentFilterConfig?.aircraft_type?.length ? '' : COMBOBOX_PLACEHOLDER}
            value={commonFieldProps.currentFilterConfig?.aircraft_type}
            data-testid="ac-selector"
            onChange={(value: any) => handleAcSelection(value)}
            limitChips={3}
            options={commonFieldProps.filterProps.aircraft}
            getOptionLabel={(option) => option}
            getOptionValue={(option) => option}
            disabled={commonFieldProps.disabledAcTye || commonFieldProps.filterProps.filterState?.isFilterLoading}
            hideClear
            multiple
          />
        </Col>
      </Row>
      <Row className="filter-row">
        <Col xxs={3}>
          <Typography variant="small" className="filter-label">
            {AIRCRAFT_SUB_TYPE}
          </Typography>
        </Col>
        <Col xxs={1}>
          {(!commonFieldProps.disabledAcSubType && _.isEmpty(acSubTypeOptions)) && <Spinner />}
        </Col>
        <Col>
          <ComboBox
            aria-label="Select ACSubType"
            placeholder={commonFieldProps.currentFilterConfig?.aircraft_subtype?.length ? '' : COMBOBOX_PLACEHOLDER}
            value={commonFieldProps.currentFilterConfig?.aircraft_subtype}
            data-testid="acsubType-selector"
            onChange={(value: any) => handleAcSubtypeSelection(value)}
            limitChips={3}
            options={acSubTypeOptions}
            getOptionLabel={(option) => option}
            getOptionValue={(option) => option}
            disabled={commonFieldProps.disabledAcSubType}
            hideClear
            multiple
          />
        </Col>
      </Row>
    </>
  );
};
export default CommanField;
