/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-plusplus */
import { Auth } from 'aws-amplify';
import _, { isEmpty } from 'lodash';
import { posthog } from 'posthog-js';
import { partColumns, tableColumns } from '../components/Shared/Tabs/BrowserTab/BrowserTableStructure';
import {
  setModalToggle, setPartFilters, setPartInitialLoad, setSBFilters, setSBInitialLoad,
} from '../models/popupFilterModel/popupFilterSlice';
import {
  LOAD_ACR_FIN_API_PATH, LOAD_ACR_PNR_API_PATH, LOAD_MIS_FIN_API_PATH, LOAD_MIS_PNR_API_PATH,
  LOAD_PART_BROWSE_MIS_API_PATH, LOAD_PART_BROWSE_ACR_API_PATH,
} from './config/apiConfig';
import {
  ACM_EXPORT_TYPE,
  ACM_SB,
  ACR_DATASOURCE, AC_FAMILY_MAPPING, ASCENDING, CHECKER_EXPORT_TYPE, DATA_SOURCE_COMPARISON_VALUE, DEFAULT_OFFSET, DISCREPENCY_OPTIONS, EXPORT_STACK_FIELDS, FLI_URL_DEV_VAL, FLI_URL_PROD, MIS_DATASOURCE, NO_OPERATOR_TOAST, SB_ACM, SB_MOD_ACM, SUCCESS, TOAST_TIMEOUT,
} from './constants';
import getConfig from '../config';
import {
  clearLocalStore, generateToastPayload, getDisclaimerStatus, getFromLocalStore, keyMapperFunction, setDisclaimerStatus, setUserName,
} from './commonMethods/generalMethods';
import { addToast, removeToast } from '../models/toastModel/toastSlice';
import {
  loadCheckerDetails, loadCheckerFleetFinAndAtaDetails,
} from '../models/checkerDetailsModel/checkerDetailsThunk';
import { columns as checkerDetailscolumns } from '../components/CheckerDetailsTable/CheckerDetailsTableStructure';
import {
  setAcFamilyOptions, setAcSubTypeOptions,
  setAcTypeOptions, setFleetDetailFilter,
  setMsnOptions, updateCheckerFilterSelection,
  updateCheckerFiltersOptions,
} from '../models/checkerDetailsModel/checkerDetailsSlice';
import { getUserPermissions } from '../models/authModel/authEntryThunks';
import { getFleetViewDataFromApi } from '../components/CheckerFleetFilter/Utils/FleetFilterUtils';
import { loadFleetDataMethods } from '../models/checkerFleetModel/checkerFleetExtraReducers';
import {
  getFleetViewFilteredData, setFleetViewFilter, getFleetViewData, updateFleetViewInnerData,
  setOperatorFilters,
} from '../models/checkerFleetModel/checkerFleetSlice';
import { secureLocalStore } from './commonMethods/SecureLSHelper';

interface FilterConfigs {
  acFamily?: string,
  acType?: Array<string>,
  acSubType?: Array<string>,
}
interface CheckerDetailsTableObject {
  operator: any,
  aircraft: Array<string>,
  aircraft_subtype: Array<string>,
  msn: Array<string>,
  fin: Array<string>,
  ata: Array<string>,
  fin_status: Array<string>,
  status_part_check: Array<string>,
  acr_source: Array<string>,
  mis_source: Array<string>,
  acr_source_status: Array<string>,
  mis_source_status: Array<string>,
  dispatch: any
}
/* eslint-disable no-restricted-syntax */
export const getPaginatedChunkData = (calculatedPagenum: number, data: Array<any>, rowperpage: number, previousPage: number, offset?: number, total?: number) => {
  if (offset && total) {
    const minimumValue = 1;
    const modCalculation = total / rowperpage;
    const offsettedPageVal = ((calculatedPagenum - minimumValue + modCalculation) % modCalculation) + minimumValue;
    let start = Math.round((offsettedPageVal * rowperpage) - rowperpage);
    if (((start + rowperpage) > Number(getFromLocalStore('apiPaginationSize'))) || (previousPage && (previousPage > calculatedPagenum)) || (start > data.length)) {
      start %= offset;
    }
    const slicedData = data.slice(start, start + rowperpage);
    return slicedData;
  }
  const minimumValue = 1;
  const modCalculation = Math.ceil(data.length / rowperpage);
  const offsettedPageVal = ((calculatedPagenum - minimumValue + modCalculation) % modCalculation) + minimumValue;
  const slicedData = data.slice(((offsettedPageVal * rowperpage) - rowperpage), (offsettedPageVal * rowperpage));
  return slicedData;
};

/* Map description with dependancy formula - ( Concurrent Requirment ) */
export const getDescriptionWithFormula = (formula: any, ConcurrentData: any[]) => {
  const formattedArray: any = [];
  if (formula !== null && ConcurrentData.length !== 0) {
    let sbRef = formula.map((item: any) => item.sb);
    sbRef = sbRef.flat(1);
    const filteredArray = ConcurrentData.filter((item) => sbRef.includes(item.sb_reference));
    for (const i of filteredArray) {
      const id = i;
      const formatedValues = { [id.sb_reference]: { description: id.sb_status, status: id.sb_title, sbReportingDate: id.sb_reporting_date } };
      formattedArray[0] = { ...formattedArray[0], ...formatedValues };
    }
    return formattedArray;
  }
  return formattedArray;
};

export const getDisplayColumns = (columns: BrowserDataTableColumns[]) => {
  return columns.filter((column) => column.hidden === false);
};

export const handleChangeOutputApply = (changeColumns: any, tableObj: any) => {
  const updatedColumns = tableObj.tableStateColumns.map((item: any) => {
    if (changeColumns[item.Header] !== undefined) { return { ...item, hidden: changeColumns[item.Header] }; }
    return item;
  });
  const keys = Object.keys(changeColumns);
  keys.forEach((key) => {
    const col = updatedColumns.filter((column: any) => column.Header === key);
    if (col.length === 0) {
      const newColumn = tableObj.allColumns.filter((column: any) => column.Header === key);
      updatedColumns.push({ ...newColumn[0], hidden: changeColumns[key] });
    }
  });
  tableObj.dispatcher(tableObj.updateTableReducer({ ...tableObj.tableState, columns: getDisplayColumns(updatedColumns) }));
};

const handlePageChange = (val: number, selectedPage: string, paginationProps: any, payload: any, dataSource?: any) => {
  const dataTableState = paginationProps.tableState;
  // fetch data based only if not available in redux store only for  selectedPage === 'selectedPagechange'.
  if (selectedPage === 'selectedPagechange') {
    // when data is not cached in store
    if (dataTableState.paginationData.start > ((val * dataTableState.paginationData.rowPerPage) - dataTableState.paginationData.rowPerPage) || dataTableState.paginationData.end <= ((val * dataTableState.paginationData.rowPerPage) - dataTableState.paginationData.rowPerPage)) {
      paginationProps.dispatcher(paginationProps.updateTableDataReducer({
        ...payload,
        offset: (val * Number(dataTableState.paginationData.rowPerPage)) - Number(dataTableState.paginationData.rowPerPage),
        limit: Number(getFromLocalStore('apiPaginationSize')),
      }, dataSource)());
    } else {
      paginationProps.dispatcher(paginationProps.updatePaginationReducer({ pageNumber: val, rowPerPage: dataTableState.paginationData.rowPerPage }));
    }
  } else {
    // handling row_per_page_chanage scenario
    paginationProps.dispatcher(paginationProps.updatePaginationReducer({ pageNumber: 1, rowPerPage: val }));
    // only do the fresh call when sbData is not having initial chunk
    if (dataTableState.paginationData.start > 0) {
      paginationProps.dispatcher(paginationProps.updateTableDataReducer({ ...payload, offset: Number(DEFAULT_OFFSET), limit: Number(getFromLocalStore('apiPaginationSize')) }, dataSource)());
    }
  }
};
export const handleSbPageChange = (val: number, selectedPage: string, paginationProps: any) => {
  const selctedPopupFilters = paginationProps.selectedPopupFilters;
  const bodyPayload = {
    aircraft_type: selctedPopupFilters?.aircraft_type,
    id_aircraft_registration: selctedPopupFilters?.selected_msn,
    sb_reference: selctedPopupFilters?.selected_sb,
    mod_reference: selctedPopupFilters?.selected_mod,
  };
  const res = Object.fromEntries(Object.entries(bodyPayload).filter(([k, v]) => v != null && v.length > 0));
  const isConcurrentToggle = paginationProps.tableState.concurrentToggle;
  const isSbStatusToggle = paginationProps.tableState.sbStatusViewToggle;
  let payload: any = {
    conditions: { ...res }, operator_code_icao: new Array(selctedPopupFilters?.operator_name), isConcurrentToggle, isSbStatusToggle,
  };
  if (Object.keys(paginationProps.tableState.FilterPayload).length > 0) { payload = paginationProps.tableState.FilterPayload; }
  handlePageChange(val, selectedPage, paginationProps, payload);
};

export const handlePartPageChange = (val: number, selectedPage: string, paginationProps: any) => {
  const selctedPopupFilters = paginationProps.selectedPopupFilters;
  const dataSource = selctedPopupFilters?.selected_dataSource;
  const bodyPayload = {
    id_aircraft_registration: selctedPopupFilters?.selected_msn,
    fin: selctedPopupFilters?.selected_fin,
    pnr: selctedPopupFilters?.selected_pnr,
  };
  const res = Object.fromEntries(Object.entries(bodyPayload).filter(([k, v]) => v != null && v.length > 0));
  let payload = { conditions: res, operator_code_icao: new Array(selctedPopupFilters?.operator_name) };
  if (Object.keys(paginationProps.tableState.FilterPayload).length > 0) { payload = paginationProps.tableState.FilterPayload; }
  handlePageChange(val, selectedPage, paginationProps, payload, dataSource);
};

export const handleCheckerDetailsPageChange = (val: number, selectedPage: string, paginationProps: any) => {
  const {
    selected_msn, operator_name, aircraft_type, selected_ata, selected_fin, aircraft_subtype,
  } = paginationProps.tableState.checkerDetailFiltersSelected;
  const payloadConditions = {
    id_aircraft_registration: selected_msn,
    aircraft_type,
    ac_subtype: aircraft_subtype,
    data_source: [DATA_SOURCE_COMPARISON_VALUE.PAYLOAD_VALUE],
    ata: selected_ata,
    fleet_fin: selected_fin,
  };
  const res = Object.fromEntries(Object.entries(payloadConditions).filter(([k, v]) => !_.isEmpty(v) && !(v.length === 1 && v[0] === '')));
  let payload = {
    operator_code_icao: [operator_name],
    conditions: res,
    distinct: true,
    columns: checkerDetailscolumns,
  };
  if (Object.keys(paginationProps.tableState.FilterPayload).length > 0) { payload = paginationProps.tableState.FilterPayload; }
  handlePageChange(val, selectedPage, paginationProps, payload);
};

/* Dispatcher - column level filter payload and state updater */
export const columnFilterStateUpdater = (dispatcher: any, columnLevelFilterState: any, dataFilterConfigState: any, updateColumnFilterState: any, updateColumnFilterPayload: any, payLoad: any) => {
  dispatcher(updateColumnFilterState({
    ...columnLevelFilterState,
    records: dataFilterConfigState,
  }));
  dispatcher(updateColumnFilterPayload({
    ...columnLevelFilterState,
    records: payLoad,
  }));
};
export const handleSbDataSelectionValidate = (currentFilterConfig: any, dispatch: any, loadSbBrowseData: any, updateColumnFilterState: any, updateColumnFilterPayload: any, tableState: any) => {
  dispatch(setSBFilters({ sb_filter_selection_configuration: currentFilterConfig as SbFilterConfiguration }));
  const arrayOperator = new Array(currentFilterConfig?.operator_name);
  const arrayACType = currentFilterConfig?.aircraft_type;

  const bodyPayload = {
    conditions: {
      aircraft_type: arrayACType,
      id_aircraft_registration: currentFilterConfig?.selected_msn,
      sb_reference: currentFilterConfig?.selected_sb,
      mod_reference: currentFilterConfig?.selected_mod,
    },
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const res = Object.fromEntries(Object.entries(bodyPayload.conditions).filter(([k, v]) => v != null && v.length > 0));
  dispatch(loadSbBrowseData({
    conditions: { ...res }, operator_code_icao: arrayOperator, offset: 0, isSbStatusToggle: tableState.sbStatusViewToggle, isConcurrentToggle: tableState.concurrentToggle, limit: Number(getFromLocalStore('apiPaginationSize')),
  })());
  dispatch(setModalToggle({ modalToggle: false }));
  dispatch(setSBInitialLoad({ sbBrowseInitialLoad: false }));
  const filterDispatchParam: string[] = [];
  columnFilterStateUpdater(dispatch, filterDispatchParam, filterDispatchParam, updateColumnFilterState, updateColumnFilterPayload, filterDispatchParam);
};
export const handlePartDataSelectionValidate = (currentFilterConfig: any, dispatch: any, loadPartBrowseData: any, updateColumnFilterState: any, updateColumnFilterPayload: any, tableState: any, additionalMethods: any) => {
  dispatch(setPartFilters({ part_filter_selection_configuration: currentFilterConfig as PartFilterConfiguration }));
  const arrayOperator = new Array(currentFilterConfig?.operator_name);
  const dataSource = currentFilterConfig?.selected_dataSource;
  const bodyPayload = {
    conditions: {
      id_aircraft_registration: currentFilterConfig?.selected_msn,
      fin: currentFilterConfig?.selected_fin,
      pnr: currentFilterConfig?.selected_pnr,
    },
  };
  const res = Object.fromEntries(Object.entries(bodyPayload.conditions).filter(([k, v]) => v != null && v.length > 0));
  dispatch(loadPartBrowseData({
    conditions: { ...res }, operator_code_icao: arrayOperator, offset: 0, limit: Number(getFromLocalStore('apiPaginationSize')),
  }, dataSource)());
  dispatch(setModalToggle({ modalToggle: false }));
  dispatch(setPartInitialLoad({ partBrowseInitialLoad: false }));
  const partTableColumns = [...tableColumns, ...partColumns];
  if (dataSource === ACR_DATASOURCE) {
    const updatedColumns = partTableColumns.map((item: any) => {
      if (item.id === 'part_type' || item.id === 'last_configuration_date') { return { ...item, hidden: false }; }
      if (item.id === 'installation_date') { return { ...item, hidden: true }; }
      return item;
    });
    dispatch(additionalMethods.updatePartTableColumns({ ...tableState, columns: getDisplayColumns(updatedColumns) }));
  } else {
    dispatch(additionalMethods.updatePartTableColumns({ ...tableState, columns: getDisplayColumns(partTableColumns) }));
  }
  const filterDispatchParam: string[] = [];
  columnFilterStateUpdater(dispatch, filterDispatchParam, filterDispatchParam, updateColumnFilterState, updateColumnFilterPayload, filterDispatchParam);
};

const additionalCheckerConditions = (objectProperty: any) => {
  const conditions: any = {};
  if (objectProperty.status_part_check?.length > 0 && objectProperty.status_part_check[0] !== '') { conditions.status_part_check = objectProperty.status_part_check; }
  if (objectProperty.acr_source?.length > 0 && objectProperty.acr_source[0] !== '') { conditions.source = objectProperty.acr_source; }
  if (objectProperty.mis_source?.length > 0 && objectProperty.mis_source[0] !== '') { conditions.source = objectProperty.mis_source; }
  if (objectProperty.acr_source_status?.length > 0 && objectProperty.acr_source_status[0] !== '') { conditions.source_status = objectProperty.acr_source_status; }
  if (objectProperty.mis_source_status?.length > 0 && objectProperty.mis_source_status[0] !== '') { conditions.source_status = objectProperty.mis_source_status; }
  return conditions;
};

/**
 * This function is used to build conditions object for fleet details API payload
 * @param objectProperty
 * @returns conditions object
 */
export const buildCheckerDetailsConditions = (objectProperty: any) => {
  const conditions: any = { data_source: [DATA_SOURCE_COMPARISON_VALUE.PAYLOAD_VALUE] };
  if (objectProperty.msn?.length > 0 && objectProperty.msn[0] !== '') { conditions.id_aircraft_registration = objectProperty.msn; }
  if (objectProperty.fin?.length > 0 && objectProperty.fin[0] !== '') { conditions.fleet_fin = objectProperty.fin; }
  if (objectProperty.ata?.length > 0 && objectProperty.ata[0] !== '') { conditions.ata = objectProperty.ata; }
  if (objectProperty.fin_status?.length > 0 && objectProperty.fin_status[0] !== '') { conditions.fin_status = objectProperty.fin_status; }
  const additionalConditions = additionalCheckerConditions(objectProperty);
  return { ...conditions, ...additionalConditions };
};

export const loadCheckerDetailsTable = async (objectProperty: CheckerDetailsTableObject, columns = checkerDetailscolumns) => {
  const conditions = buildCheckerDetailsConditions(objectProperty);
  await objectProperty.dispatch(loadCheckerDetails({
    operator_code_icao: [objectProperty.operator],
    conditions,
    limit: Number(getFromLocalStore('apiPaginationSize')),
    offset: 0,
    columns,
    distinct: true,
  })());
};

export const handleCheckerDetailsDataSelectionValidate = (currentFilterConfig: any, dispatch: any, updateCheckerFilterSelectionFunc: any, updateColumnFilterState: any, updateColumnFilterPayload: any, tableState: any, additionalMethods: any) => {
  dispatch(setModalToggle({ modalToggle: false }));
  dispatch(additionalMethods.setCheckerDetailsInitialLoad({ ...tableState, checkerDetailsInitialLoad: false }));
  dispatch(updateCheckerFilterSelectionFunc({
    operator_name: currentFilterConfig.operator_name,
    selected_msn: currentFilterConfig.selected_msn,
    aircraft_type: currentFilterConfig.aircraft_type,
    aircraft_family: currentFilterConfig.aircraft_family,
    aircraft_subtype: currentFilterConfig.aircraft_subtype,
    selected_ata: currentFilterConfig.selected_ata,
    selected_fin: currentFilterConfig.selected_fin,
  }));
  dispatch(setFleetDetailFilter({
    ...tableState,
    fleet_detail_filter_selected: {
      operator_name: currentFilterConfig.operator_name,
      selected_msn: currentFilterConfig.selected_msn,
      aircraft_type: currentFilterConfig.aircraft_type,
      aircraft_family: currentFilterConfig.aircraft_family,
      aircraft_subtype: currentFilterConfig.aircraft_subtype,
      selected_ata: currentFilterConfig.selected_ata,
      selected_fin: currentFilterConfig.selected_fin,
    },
  }));
  dispatch(updateCheckerFiltersOptions({
    operator_code_icao: tableState.fleetDetailFilterOptions?.operator_code_icao,
    ac_family: tableState.fleetDetailFilterOptions?.ac_family,
    ata: tableState.fleetDetailFilterOptions?.ata,
    fin: tableState.fleetDetailFilterOptions?.fin,
    ac_type: tableState.fleetDetailFilterOptions?.ac_type,
    ac_sub_type: tableState.fleetDetailFilterOptions?.ac_sub_type,
    id_aircraft_registration: tableState.fleetDetailFilterOptions?.id_aircraft_registration,
  }));
  dispatch(additionalMethods.toggleReasonDetail(false));
  loadCheckerDetailsTable({
    operator: currentFilterConfig?.operator_name,
    aircraft: currentFilterConfig.aircraft_type,
    aircraft_subtype: currentFilterConfig.aircraft_subtype,
    msn: currentFilterConfig.selected_msn,
    fin: currentFilterConfig.selected_fin,
    ata: currentFilterConfig.selected_ata,
    fin_status: [],
    status_part_check: [],
    acr_source: [],
    mis_source: [],
    acr_source_status: [],
    mis_source_status: [],
    dispatch,
  });
  const filterDispatchParam: string[] = [];
  columnFilterStateUpdater(dispatch, filterDispatchParam, filterDispatchParam, updateColumnFilterState, updateColumnFilterPayload, filterDispatchParam);
};

export const getFilteredDropdownValues = (data: any, filterConfigs: FilterConfigs, operator: string) => {
  let filteredData = data.filter((item: any) => (item.operator_code_icao === operator));
  if (Object.prototype.hasOwnProperty.call(filterConfigs, 'acFamily') && !isEmpty(filterConfigs.acFamily)) {
    filteredData = filteredData.filter((item: any) => item.ac_family === filterConfigs.acFamily);
  }
  if (Object.prototype.hasOwnProperty.call(filterConfigs, 'acType') && !isEmpty(filterConfigs.acType)) {
    filteredData = filteredData.filter((item: any) => filterConfigs.acType?.includes(item.ac_type));
  }
  if (Object.prototype.hasOwnProperty.call(filterConfigs, 'acSubType') && !isEmpty(filterConfigs.acSubType)) {
    filteredData = filteredData.filter((item: any) => filterConfigs.acSubType?.includes(item.ac_subtype));
  }
  return filteredData;
};
export const loadCheckerAcFamilyOptions = (operator: string, dispatch: any, data: any, metaArg: any = '') => {
  const AcFamilyFiltered = getFilteredDropdownValues(data, {}, operator);
  const AcFamilyUnique = Array.from(new Set(AcFamilyFiltered.map((item: any) => item.ac_family)));
  const acFamilyAction = {
    AcFamily: AcFamilyUnique as Array<string>,
    meta: { arg: metaArg },
  };
  dispatch(setAcFamilyOptions(acFamilyAction));
};

export const loadCheckerAcTypeOptions = (operator: string, acFamily: any, dispatch: any, data:any, metaArg: any = '') => {
  // filter ac type values
  const acTypeFiltered = getFilteredDropdownValues(data, { acFamily }, operator);
  const acTypeData = Array.from(new Set(acTypeFiltered.map((item: any) => item.ac_type)));
  const acTypeAction = {
    AcType: acTypeData as Array<string>,
    meta: { arg: metaArg },
  };
  dispatch(setAcTypeOptions(acTypeAction));
};

export const loadCheckerAcSubTypeOptions = (operator: string, acFamily: string, acType: string[], dispatch: any, data:any, metaArg: any = '') => {
  const acSubTypeFiltered = getFilteredDropdownValues(data, { acFamily, acType }, operator);

  const acSubTypeData = Array.from(new Set(acSubTypeFiltered.map((item: any) => item.ac_subtype)));
  const acSubTypeAction = {
    AcSubType: acSubTypeData as Array<string>,
    meta: { arg: metaArg },
  };
  dispatch(setAcSubTypeOptions(acSubTypeAction));
};

export const loadCheckerMsnOptions = (operator: string, acFamily: string, acType: string[], acSubType: string[], dispatch: any, data: any, metaArg: any = '') => {
  const msnFiltered = getFilteredDropdownValues(data, { acFamily, acType, acSubType }, operator);
  const msnData = Array.from(new Set(msnFiltered.map((item: any) => item.id_aircraft_registration)));
  const msnAction = {
    IdAircraft: msnData as Array<string>,
    meta: { arg: metaArg },
  };
  dispatch(setMsnOptions(msnAction));
};
export const getSelectedFleetCardData = (cardData: any, FleetViewFilterSelected: any) => {
  let filteringData = cardData;
  // eslint-disable-next-line no-debugger
  if (!isEmpty(FleetViewFilterSelected?.selected_aircraft)) {
    filteringData = filteringData.filter((item: any) => FleetViewFilterSelected?.selected_aircraft.includes(item.ac_type));
  }
  if (!isEmpty(FleetViewFilterSelected?.selected_sub_aircraft)) {
    filteringData = filteringData.filter((item: any) => FleetViewFilterSelected?.selected_sub_aircraft.includes(item.ac_subtype));
  }
  if (!isEmpty(FleetViewFilterSelected?.selected_msn)) {
    filteringData = filteringData.filter((item: any) => FleetViewFilterSelected?.selected_msn.includes(item.id_aircraft_registration));
  }
  return filteringData;
};

export const loadCheckerAtaOptions = (operator: string, acType: string[], msn: string[], dispatch: any, apiDispatch: any) => {
  const conditions: any = {
    data_source: [DATA_SOURCE_COMPARISON_VALUE.PAYLOAD_VALUE],
    id_aircraft_registration: msn,
  };
  if (acType[0] !== '' && acType.length > 0) {
    conditions.aircraft_type = acType;
  }
  const ataPayload: any = {
    conditions,
    operator_code_icao: new Array(operator),
    columns: ['ata'],
    sortType: ASCENDING,
    orderBy: ['ata'],
    distinct: true,
  };
  dispatch(apiDispatch(ataPayload)());
};

export const loadCheckerFinOptions = (operator: string, acType: string[], msn: string[], dispatch: any, apiDispatch: any) => {
  const conditions: any = {
    data_source: [DATA_SOURCE_COMPARISON_VALUE.PAYLOAD_VALUE],
    id_aircraft_registration: msn,
  };
  if (acType[0] !== '' && acType.length > 0) {
    conditions.aircraft_type = acType;
  }
  const finPayload: any = {
    conditions,
    operator_code_icao: new Array(operator),
    columns: ['fleet_fin'],
    sortType: ASCENDING,
    orderBy: ['fleet_fin'],
    distinct: true,
  };
  dispatch(apiDispatch(finPayload)());
};

export const loadCheckerFinAndAtaOptions = (operator: string, acType: string[], msn: string[], dispatch: any, apiDispatch: any) => {
  const conditions: any = {
    data_source: [DATA_SOURCE_COMPARISON_VALUE.PAYLOAD_VALUE],
    id_aircraft_registration: msn,
  };
  if (acType[0] !== '' && acType.length > 0) {
    conditions.aircraft_type = acType;
  }
  const finPayload: any = {
    conditions,
    operator_code_icao: new Array(operator),
    columns: ['fleet_fin', 'ata'],
    sortType: ASCENDING,
    orderBy: ['fleet_fin'],
    distinct: true,
  };
  dispatch(apiDispatch(finPayload)());
};
export const getPnrAPIForDataSource = (payloadBody: any) => {
  if (payloadBody !== undefined && payloadBody.data_source === MIS_DATASOURCE) return LOAD_MIS_PNR_API_PATH;
  return LOAD_ACR_PNR_API_PATH;
};

export const getFinAPIForDataSource = (payloadBody: any) => {
  if (payloadBody !== undefined && payloadBody.data_source === MIS_DATASOURCE) return LOAD_MIS_FIN_API_PATH;
  return LOAD_ACR_FIN_API_PATH;
};

export const getPartAPIForDataSource = (dataSource: string) => {
  if (dataSource !== undefined && dataSource === MIS_DATASOURCE) return LOAD_PART_BROWSE_MIS_API_PATH;
  return LOAD_PART_BROWSE_ACR_API_PATH;
};

export const addRequiredBodyToPayload = (body: any, response: any, queryStringParameters: any = undefined) => {
  if (body) {
    if ('offset' in body) {
      response.records_offset = body.offset;
    }
    // Hash_id for presigned url post api
    if ('hash_id' in body) {
      response.hash_id = body.hash_id;
    }
    if (EXPORT_STACK_FIELDS.every((field: string) => field in body)) {
      response.filter_payload = JSON.stringify(body.filters);
      response.export_type = body.export_type;
      response.operator_code_icao = body.operator_code_icao;
    }
  }
  if (queryStringParameters) {
    if ('hash_id' in queryStringParameters) {
      response.hash_id = queryStringParameters.hash_id;
    }
    if ('operator_code_icao' in queryStringParameters) {
      response.operator_code_icao = queryStringParameters.operator_code_icao;
    }
  }
  return response;
};
const applyOrderOnSelection = (selection: Array<string>): Array<string> => {
  const sortAlphaNum = (a: any, b: any) => a.localeCompare(b, 'en', { numeric: true });
  selection.sort(sortAlphaNum);
  return selection;
};

export const exportButtonCreate: (selectedExport: any, columns: string[], isConcurrentToggle: boolean | undefined, isSbStatusToggle: boolean | undefined, exportType: any) => filtersObject = (selectedExport, columns, isConcurrentToggle, isSbStatusToggle, exportType) => {
  const filter: any = {};
  function handleSelectedExport() {
    if (selectedExport?.selected_msn?.length) {
      filter.id_aircraft_registration = applyOrderOnSelection([...selectedExport.selected_msn]);
    }
    if (selectedExport?.selected_sb?.length) {
      filter.sb_reference = applyOrderOnSelection([...selectedExport.selected_sb]);
    }
    if (selectedExport?.selected_mod?.length) {
      filter.mod_reference = applyOrderOnSelection([...selectedExport.selected_mod]);
    }
    if (selectedExport?.selected_fin?.length) {
      filter.fin = applyOrderOnSelection([...selectedExport.selected_fin]);
    }
    if (selectedExport?.selected_pnr?.length) {
      filter.pnr = applyOrderOnSelection([...selectedExport.selected_pnr]);
    }
    if (selectedExport?.selected_dataSource?.length) {
      filter.dataSource = selectedExport.selected_dataSource;
    }
    if (selectedExport?.ac_family) {
      filter.aircraft_program_code = [selectedExport.ac_family.value];
    }
    if (selectedExport?.selected_export_acm_type) {
      filter.acm_type = [selectedExport?.selected_export_acm_type === ACM_SB ? SB_ACM : SB_MOD_ACM];
    }
    if (selectedExport?.sortType) {
      filter.sortType = selectedExport?.sortType;
    }
    if (selectedExport?.orderBy) {
      filter.orderBy = selectedExport?.orderBy;
    }
    if (selectedExport?.aircraft_type) {
      filter.aircraft_type = new Array(selectedExport.aircraft_type).flat();
    }
  }

  function handleColumns() {
    if (columns.length) {
      filter.columns = [...columns];
    }
  }

  function handleToggles() {
    if (isConcurrentToggle !== undefined) {
      filter.isConcurrentToggle = isConcurrentToggle;
    }
    if (isSbStatusToggle !== undefined) {
      filter.isSbStatusToggle = isSbStatusToggle;
    }
  }

  function handleExportType() {
    if (exportType === 'PART') {
      delete filter.aircraft_type;
    }
    if (exportType === CHECKER_EXPORT_TYPE) {
      delete filter.isConcurrentToggle;
      delete filter.isSbStatusToggle;
      if (filter?.fin) delete filter.fin;
      if (filter?.aircraft_type) delete filter.aircraft_type;
      if (selectedExport?.selected_fin?.length) filter.fleet_fin = selectedExport.selected_fin;
      if (selectedExport?.selected_ata?.length) filter.ata = selectedExport.selected_ata;
    }
  }

  handleSelectedExport();
  handleColumns();
  handleToggles();
  handleExportType();

  return filter;
};

export const restructureColumnFilters = (
  columnFilters: any,
  columns: any,
  isConcurrentToggle: any,
  isSbStatusToggle: any,
  exportType: any,
) => {
  let filters: any = {};
  if (Object.keys(columnFilters).length) {
    if ('conditions' in columnFilters && exportType === 'PART') {
      filters = {
        ...columnFilters.conditions, dataSource: columnFilters.dataSource, columns,
      };
      if (columnFilters.sortType) { filters.sortType = columnFilters.sortType; }
      if (columnFilters.orderBy) { filters.orderBy = columnFilters.orderBy; }
    } else {
      filters = {
        ...columnFilters, columns, isConcurrentToggle, isSbStatusToggle,
      };
    }
    delete filters.limit;
    delete filters.offset;
    if (exportType === CHECKER_EXPORT_TYPE) {
      delete filters.columns;
      const condObj = JSON.parse(JSON.stringify(filters.conditions));
      delete condObj.data_source;
      filters.conditions = condObj;
      delete filters.isConcurrentToggle;
      delete filters.isSbStatusToggle;
    }
  }
  return filters;
};

export const prepareFiltersForPayload = (
  exportType: any,
  mutableColumnFilters: any,
  selectedExport: any,
  columns: string[],
  isConcurrentToggle: boolean | undefined,
  isSbStatusToggle: boolean | undefined,
) => {
  return exportType !== ACM_EXPORT_TYPE && Object.keys(mutableColumnFilters).length
    ? mutableColumnFilters
    : exportButtonCreate(selectedExport, columns, isConcurrentToggle, isSbStatusToggle, exportType);
};

export const getFileListForDownload = (
  donwloadStatuses: DownloadStatus[],
): DownloadStatus[] => {
  return donwloadStatuses.filter(
    (fileList: DownloadStatus) => fileList.polling_status === SUCCESS && fileList.is_downloaded === false,
  );
};

export const isRequiredForEnv = (envArray: any) => {
  const stage: any = process.env.REACT_APP_STAGE;
  return envArray.includes(stage.toLowerCase());
};

/**
 *
 * @param responseArr
 * [
 *   {
 *     "Operator": "BIX",
 *     "id_aircraft_registration": "N02283 SE-RGV",
 *     "source_MIS": {
 *       "source": "MIS",
 *       "as_allowed_pnr": ["2119332-00"],
 *       "as_allowed_cond": "PRE SB-21 P041"
 *     },
 *     "source_ACR": {
 *       "source": "ACR",
 *       "as_allowed_pnr": ["2119332-00"],
 *       "as_allowed_cond": "N/A"
 *     }
 *   },
 *   ...
 * ]
 *
 * @returns
 * [
 *   {
 *     "Operator": "BIX",
 *     "id_aircraft_registration": "N02283 SE-RGV",
 *     "source": [["MIS"], ["ACR"]],
 *     "as_allowed_pnr": ["2119332-00", "2119332-00"],
 *     "as_allowed_cond": [["PRE SB-21 P041"], ["N/A"]]
 *   },
 *   ...
 * ]
 */

// store last consent timestamp and consent flag
export const setLastConsentTimestamp = (consentTime:Date) => {
  const lastConsentIso = consentTime.toISOString();
  secureLocalStore.set('lastConsentTimestamp', lastConsentIso);
};

export const setConsentFlag = (flagVal: string) => {
  secureLocalStore.set('isConsentGiven', flagVal);
};

// store the checker fleet filters.
export const storeCheckerFilters = (checkerFilters: any) => {
  const checkerFilterString = JSON.stringify(checkerFilters);
  secureLocalStore.set('checkerFilters', checkerFilterString);
};

export const storeReasonStatusMapping = (reasonMapping: any, statusMapping: any) => {
  const reasonMappingString = JSON.stringify(reasonMapping);
  secureLocalStore.set('ReasonMapping', reasonMappingString);
  const statusMappingString = JSON.stringify(statusMapping);
  secureLocalStore.set('StatusMapping', statusMappingString);
};
// get last consent timestamp and consent flag
/**
 * returns the last consent timestamp adjusting it to expiration timestamp
 */
export const getLastConsentWithExpiration = () => {
  const lastConsentString = secureLocalStore.get('lastConsentTimestamp');
  const lastConsentWithExpiration = new Date(new Date(lastConsentString).getTime() + 60 * 60 * 12 * 1000);
  return lastConsentWithExpiration;
};

export const getConsentFlag = () => {
  return secureLocalStore.get('isConsentGiven');
};

export const getCheckerFilters = () => {
  const checkerFilters = JSON.parse(secureLocalStore.get('checkerFilters') || '{}');
  return checkerFilters;
};

export const getReasonStatusMapping = () => {
  const reasonMapping = JSON.parse(secureLocalStore.get('ReasonMapping') || '{}');
  const statusMapping = JSON.parse(secureLocalStore.get('StatusMapping') || '{}');
  return { reasonMapping, statusMapping };
};

// remove checker filters and last consent timestamp methods for disclaimer cancel
export const removeCheckerFilters = () => {
  secureLocalStore.remove('checkerFilters');
};

export const removeLastConsentTimestamp = () => {
  secureLocalStore.remove('lastConsentTimestamp');
};
/** \
 * Checks if last consent date is not expired and consent flag value is true.
 * returns true if both above conditions are valid , false otherwise.
 */
export const isConsentValid = (lastConsent: Date, consentValue:string) => {
  const currentTimestamp = new Date();
  if (lastConsent >= currentTimestamp && consentValue === 'true') {
    return true;
  }
  return false;
};

export const clearLocalStoreWithCondition = () => {
  const consentTImestamp = new Date(secureLocalStore.get('lastConsentTimestamp'));
  const consentFlag = getConsentFlag();
  const consentExpiration = getLastConsentWithExpiration();
  const checkerFilters = getCheckerFilters();
  const reasonStatusMapper = getReasonStatusMapping();
  clearLocalStore();
  if (isConsentValid(consentExpiration, consentFlag)) {
    setLastConsentTimestamp(consentTImestamp);
    setConsentFlag(consentFlag);
    storeCheckerFilters(checkerFilters);
    storeReasonStatusMapping(reasonStatusMapper.reasonMapping, reasonStatusMapper.statusMapping);
  }
};

export const redirectToLogout = () => {
  const config = getConfig();

  const { logoutUrl } = config.Auth.oauth;
  clearLocalStoreWithCondition();
  Auth.signOut()
    .then(() => {
      window.location.assign(logoutUrl);
    })
    .catch(() => {
      window.location.assign(logoutUrl);
    });
};

export const generateToastNotification = (error: any, path: any, thunkApi: any, toastMsg?: any) => {
  if (error) {
    let toastMessage = error.message;
    if (error.response) {
      toastMessage = error.response.data.message;
    }
    const errorRespPayload = generateToastPayload(path, toastMessage, error.response.status);
    thunkApi.dispatch(addToast({ ...errorRespPayload, isToastShown: true }));
    setTimeout(() => {
      thunkApi.dispatch(removeToast({ ...errorRespPayload, isToastShown: false }));
    }, TOAST_TIMEOUT);
  } else {
    let toastData: ToastType = toastMsg;
    if (!toastData) {
      /* Toast when no Operator is found for the user. */
      toastData = NO_OPERATOR_TOAST;
    }
    thunkApi.dispatch(addToast(toastData));
    setTimeout(() => {
      thunkApi.dispatch(
        removeToast(toastData),
      );
    }, TOAST_TIMEOUT);
  }
};

/* method to calculate the percentage */
export const getpercentageCal = (value: number, total: number) => {
  return ((value / total) * 100).toFixed(1);
};

/* load msn api for checkers */
export const loadMSNApi = (ifOperatorChanged: boolean, ifAircraftTypeChanged:boolean, checkerDetailsState: any, checkerDetailFiltersSelected: any, selectedAcSubTypes: any, dispatch: any, checkerData: any) => {
  if (ifOperatorChanged || ifAircraftTypeChanged) {
    loadCheckerMsnOptions(checkerDetailsState?.operator_name, '', checkerDetailsState.fleet_detail_filter_selected?.aircraft_type, selectedAcSubTypes, dispatch, checkerData, 'CheckerDetailsOperatorChanged');
  } else {
    loadCheckerMsnOptions(checkerDetailsState?.operator_name, '', checkerDetailFiltersSelected?.aircraft_type, selectedAcSubTypes, dispatch, checkerData, 'CheckerDetailsOperatorChanged');
  }
};

interface CheckerDetailsData {
  checkerDetailsState: any,
  checkerDetailsFilterOptions: any,
  checkerDetailFiltersSelected: any,
  dispatch: any,
  selectedMsn: any,
  ifOperatorChanged: any,
  ifAircraftTypeChanged: any,
  ifAircraftSubTypeChanged: any
}
export const setOptionsAndSelectionMsn = ({
  checkerDetailsState, checkerDetailsFilterOptions, checkerDetailFiltersSelected, dispatch, selectedMsn, ifOperatorChanged = false, ifAircraftTypeChanged = false, ifAircraftSubTypeChanged = false,
}: CheckerDetailsData) => {
  if (ifOperatorChanged || (!_.isEmpty(checkerDetailsState?.fleet_detail_filter_selected?.operator_name) && (ifAircraftTypeChanged && ifOperatorChanged) && (
    (checkerDetailsState?.fleet_detail_filter_selected?.operator_name !== checkerDetailFiltersSelected?.operator_name)
    || JSON.stringify(checkerDetailsState?.fleet_detail_filter_selected?.aircraft_type) !== JSON.stringify(checkerDetailFiltersSelected?.aircraft_type)
    || JSON.stringify(checkerDetailsState?.fleet_detail_filter_selected?.aircraft_subtype) !== JSON.stringify(checkerDetailFiltersSelected?.aircraft_subtype)))) {
    dispatch(updateCheckerFilterSelection({
      operator_name: checkerDetailsState.fleet_detail_filter_selected?.operator_name,
      selected_msn: selectedMsn,
      aircraft_type: checkerDetailsState.fleet_detail_filter_selected?.aircraft_type,
      aircraft_subtype: checkerDetailsState.fleet_detail_filter_selected?.aircraft_subtype,
      aircraft_family: '',
      selected_ata: '',
      selected_fin: '',

    }));
    dispatch(updateCheckerFiltersOptions({
      operator_code_icao: checkerDetailsState.fleetDetailFilterOptions?.operator_code_icao,
      ac_family: checkerDetailsFilterOptions?.ac_family,
      ata: checkerDetailsFilterOptions?.ata,
      fin: checkerDetailsFilterOptions?.fin,
      ac_type: checkerDetailsFilterOptions.ac_type,
      ac_sub_type: checkerDetailsFilterOptions?.ac_sub_type,
      id_aircraft_registration: checkerDetailsFilterOptions?.id_aircraft_registration,
    }));
    loadCheckerFinAndAtaOptions(checkerDetailsState.fleet_detail_filter_selected?.operator_name, checkerDetailsState.fleet_detail_filter_selected?.aircraft_type, selectedMsn, dispatch, loadCheckerFleetFinAndAtaDetails);
    loadCheckerDetailsTable({
      operator: checkerDetailsState.fleet_detail_filter_selected?.operator_name,
      aircraft: checkerDetailsState.fleet_detail_filter_selected?.aircraft_type,
      aircraft_subtype:
        checkerDetailsState.fleet_detail_filter_selected?.aircraft_subtype,
      msn: selectedMsn,
      fin: [''],
      ata: [''],
      fin_status: [''],
      status_part_check: [''],
      acr_source: [''],
      mis_source: [''],
      acr_source_status: [''],
      mis_source_status: [''],
      dispatch,
    });
  } else {
    dispatch(updateCheckerFilterSelection({
      operator_name: checkerDetailFiltersSelected?.operator_name,
      selected_msn: selectedMsn,
      aircraft_type: ifAircraftTypeChanged ? checkerDetailsState?.fleet_detail_filter_selected?.aircraft_type : checkerDetailFiltersSelected?.aircraft_type,
      aircraft_subtype: ifAircraftSubTypeChanged ? checkerDetailsState?.fleet_detail_filter_selected?.aircraft_subtype : checkerDetailFiltersSelected?.aircraft_subtype,
      aircraft_family: ifAircraftTypeChanged ? '' : checkerDetailFiltersSelected?.aircraft_family,
      selected_ata: checkerDetailFiltersSelected?.selected_ata,
      selected_fin: checkerDetailFiltersSelected?.selected_fin,
    }));
    const getAircraftType = ifAircraftTypeChanged ? checkerDetailsState?.fleet_detail_filter_selected?.aircraft_type : checkerDetailFiltersSelected?.aircraft_type;
    const getAircraftSubType = ifAircraftTypeChanged ? checkerDetailsState?.fleet_detail_filter_selected?.aircraft_subtype : checkerDetailFiltersSelected?.aircraft_subtype;
    loadCheckerFinAndAtaOptions(checkerDetailFiltersSelected?.operator_name, getAircraftType, selectedMsn, dispatch, loadCheckerFleetFinAndAtaDetails);
    loadCheckerDetailsTable({
      operator: checkerDetailFiltersSelected?.operator_name,
      aircraft: getAircraftType,
      aircraft_subtype: getAircraftSubType,
      msn: selectedMsn,
      fin: checkerDetailFiltersSelected?.selected_fin,
      ata: checkerDetailFiltersSelected?.selected_ata,
      fin_status: [],
      status_part_check: [],
      acr_source: [],
      mis_source: [],
      acr_source_status: [],
      mis_source_status: [],
      dispatch,
    });
  }
};

export const getStatusReasonMapper = (key: string, itemArrayNew: any, statusKeyMapper: any, reasonKeyMapper: any) => {
  if (key === 'source_status') {
    return keyMapperFunction(itemArrayNew, statusKeyMapper);
  }
  if (key === 'reason') {
    return keyMapperFunction(itemArrayNew, reasonKeyMapper);
  }
  return itemArrayNew;
};
export const getAcSubTypeList = (acType: Array<string> | any, acSubList: any) => {
  return acType.map((acVal: any) => {
    return acSubList[acVal];
  }).filter((val: any) => { return val !== (null || undefined); }).flat();
};

export const setUser = async (authEntry: any, user: any, authDispatch: any) => {
  if (isEmpty(authEntry.moduleList)) {
    await authDispatch(getUserPermissions()());
    setUserName(user.attributes.given_name, user.attributes.family_name);
  }
};
/**
 *
 * Input format
 * ({
    N: {
      A320: {
        'A320-214': {
          id_aircraft_registration: [
            'N08522 B-304S',
          ],
        },
        'A320-271N': {
          id_aircraft_registration: [
            'N10655 B-328G',
          ],
        },
      },
    },
  } , ITY)
 */

export const getRestructuredResponseOfACMOperatorMSN = (response: any, selectedOperator: any) => {
  const formattedRes: responceType = {
    aircraft_program_code: [],
    aircraft_types: [],
    aircraft_family: {},
    id_aircraft_registration: [],
  };
  // To Retrive Ac types from response
  const acPgmCodes = Object.keys(response);
  const acFamilyResp = acPgmCodes.map((item:any) => {
    const acFamily = AC_FAMILY_MAPPING[item];
    return { label: acFamily, value: item };
  });
  formattedRes.aircraft_program_code = acFamilyResp;
  // To retrive AC Sub types from response
  if (!_.isEmpty(acPgmCodes)) {
    Object.keys(response).forEach((key, index) => {
      formattedRes.aircraft_types.push(Object.keys(response[key]));
    });
  }
  if (!_.isEmpty(formattedRes.aircraft_types)) {
    const acSubTypes: IObjectKeys = {};
    Object.keys(response).forEach((key, index) => {
      Object.keys(response[key]).forEach((k:any) => {
        acSubTypes[k] = Object.keys(response[key][k]);
      });
    });
    formattedRes.aircraft_family = acSubTypes;
  }
  const finalRes: IObjectKeys = {};
  finalRes[selectedOperator] = response;
  return { finalRes, formattedRes };
};
export const getRestructuredResponseOfOperatorMSN = (response: any, selectedOperator: any) => {
  const formattedRes: responceType = {
    aircraft_types: [],
    aircraft_family: {},
    source: [],
    id_aircraft_registration: [],
  };
  // To Retrive Ac types from response
  const acTypes = Object.keys(response);
  formattedRes.aircraft_types = acTypes;
  // To retrive AC Sub types from response
  if (!_.isEmpty(acTypes)) {
    const acSubTypes: IObjectKeys = {};
    Object.keys(response).forEach((key, index) => {
      acSubTypes[key] = Object.keys(response[key]);
    });
    formattedRes.aircraft_family = acSubTypes;
  }
  const finalRes: IObjectKeys = {};
  finalRes[selectedOperator] = response;
  return { finalRes, formattedRes };
};

export const getSourcevaluesForPartBrowser = (response: any, payload: responceType) => {
  let sourceArray: Array<string> = [];
  const operator = payload.operator_code_icao;
  const acTypes: Array<string> = payload.aircraft_types;
  // To retrive source types from response
  if (!_.isEmpty(acTypes)) {
    acTypes.forEach((data: any) => {
      // If AC Sub type selected, filtering based on that
      const acSubTypes = payload.aircraft_family?.length > 0 ? payload.aircraft_family : Object.keys(response[operator][data]);
      if (!_.isEmpty(acSubTypes)) {
        acSubTypes.forEach((acFam: any) => {
          const sourceValues = response[operator][data][acFam] ? Object.keys(response[payload.operator_code_icao][data][acFam]) : [];
          sourceArray = sourceArray.concat(sourceValues);
        });
      }
    });
  }
  // To get Unique source values
  sourceArray = Array.from(new Set([...sourceArray]));
  return sourceArray;
};

export const getMSNValuesForPartBrowser = (response: any, payload: responceType) => {
  let msnArray: Array<string> = [];
  const operator = payload.operator_code_icao;
  const acTypes: Array<string> = payload.aircraft_types;
  const acSubTypes: Array<string> = payload.aircraft_family;
  const { source } = payload;
  // To get combinations for ac and acsub types
  const msnComb = acTypes.flatMap((ac) => acSubTypes.map((fam) => (fam.includes(ac) ? [ac, fam] : []))).filter((val) => val.length > 0);
  msnComb.forEach((comb: any) => {
    const temp = response[operator][comb[0]][comb[1]] && response[operator][comb[0]][comb[1]][source] ? response[operator][comb[0]][comb[1]][source].id_aircraft_registration : [];
    msnArray = msnArray.concat(temp);
  });
  return msnArray;
};

const getAcSubtypes = (acTypes: any, payload: responceType, response: any, operator: string) => {
  let msnArray: Array<string> = [];
  acTypes.forEach((data: any) => {
    let acSubType = [];
    // If AC Sub type selected, filtering based on that
    if (payload.aircraft_program_code) {
      acSubType = Object.keys(response[operator][payload.aircraft_program_code.value][data]);
      acSubType.forEach((acFam: any) => {
        const msnValues = response[operator][payload.aircraft_program_code.value][data][acFam] ? response[payload.operator_code_icao][payload.aircraft_program_code.value][data][acFam].id_aircraft_registration : [];
        msnArray = msnArray.concat(msnValues);
      });
    } else {
      if (payload.aircraft_family?.length > 0) {
        acSubType = payload.aircraft_family;
      } else if (response[operator] && response[operator][data]) {
        acSubType = Object.keys(response[operator][data]);
      }
      if (!_.isEmpty(acSubType)) {
        acSubType.forEach((acFam: any) => {
          const msnValues = response[operator][data][acFam] ? response[payload.operator_code_icao][data][acFam].id_aircraft_registration : [];
          msnArray = msnArray.concat(msnValues);
        });
      }
    }
  });
  return msnArray;
};

export const getMSNValuesForSbBrowser = (response: any, payload: responceType) => {
  let msnArray: Array<string> = [];
  const operator = payload.operator_code_icao;
  const acTypes: Array<string> = payload.aircraft_types;
  const acSubTypes: Array<string> = payload.aircraft_family;
  // To get combinations for ac and acsub types
  const msnComb = acTypes.flatMap((ac) => acSubTypes.map((fam) => (fam.includes(ac) ? [ac, fam] : []))).filter((val) => val.length > 0);
  if (msnComb.length) {
    msnComb.forEach((comb: any) => {
      let temp = [];
      if (payload.aircraft_program_code) {
        temp = response[operator][payload.aircraft_program_code.value][comb[0]][comb[1]] ? response[operator][payload.aircraft_program_code.value][comb[0]][comb[1]].id_aircraft_registration : [];
      } else {
        temp = response[operator][comb[0]] && response[operator][comb[0]][comb[1]] ? response[operator][comb[0]][comb[1]].id_aircraft_registration : [];
      }
      msnArray = msnArray.concat(temp);
    });
  } else {
    // taking the list of ac types from sub function
    msnArray = getAcSubtypes(acTypes, payload, response, operator);
  }
  return msnArray;
};
export const registerPostHogProperties = (postHogObject: any) => {
  // This will add a key value pair {ICAO_CODE: 'ABC'} to posthog application by which user can filter.
  posthog.register(postHogObject);
};
/**
 * Checks if for an selected operator data already loaded in redux store.
 * populates other quick filter options from redux if data for operator already loaded.
 * fetches from api otherwise.
 * @param checkerState The CheckerFleet State
 * @param dispatch useAppDispatch object to dispatch reducers
 */
export const fetchFleetViewDataForOperator = (checkerState: any, dispatch:any, operator: string[], apiMetaArg: any = '') => {
  // check if data for selected operator exists or not, in case it exists, pick data from redux store
  const [operatorVal] = operator;
  if (Object.prototype.hasOwnProperty.call(checkerState.data, operatorVal)) {
    // set visible data based on operator selection
    const selectedOperatorData = checkerState.data[operatorVal];
    dispatch(getFleetViewFilteredData({ visibleData: selectedOperatorData }));
    // get selected filter
    const {
      idAircraftRegistration, acTypeUnique, aSubTypeUnique, acFamily,
    } = loadFleetDataMethods(selectedOperatorData);
    // set selected filter dropdowns for already selected filter
    const filterValue = {
      selected_operator: operator,
      selected_aircraft: acTypeUnique,
      selected_sub_aircraft: aSubTypeUnique,
      selected_msn: idAircraftRegistration,
      selected_discrepency: DISCREPENCY_OPTIONS,
    };
    const filterOptions = {
      ...checkerState.fleet_view_filters,
      ac_family_list: acFamily,
      ac_type_list: acTypeUnique,
      ac_sub_type_list: aSubTypeUnique,
      msn_list: idAircraftRegistration,
    };
    // update selection only when called from fleet view
    if (apiMetaArg === 'fleetView') {
      dispatch(setFleetViewFilter({ fleet_view_filter_selected: filterValue }));
    }
    // also update the dropdown options
    dispatch(getFleetViewData({ fleet_view_filters: filterOptions }));
  } else {
    getFleetViewDataFromApi(operator, dispatch, apiMetaArg);
  }
};

/**
 *
 * @param cardData Fleet card data
 * @param selectedOperator Selected fleet view operator
 * @param selectedAircraft Selected fleet view aircraft
 * @param selectedSubAircraft Selected fleet view aircraft subtype
 * @param filterOptions
 * @returns filter options to populate into quick filter
 */
export const populateDropdownValues = (cardData: any, selectedOperator: string, selectedAircraft: Array<string>, selectedSubAircraft: Array<string>, filterOptions: any) => {
  // apply the filter if any previously selected filter values present.
  // get ac type options
  const acTypeOptions = getFilteredDropdownValues(cardData, {}, selectedOperator);
  const acTypeUnique = Array.from(new Set(acTypeOptions.map((item: any) => item.ac_type)));
  // get ac subtype options
  const acSubTypeOptions = getFilteredDropdownValues(
    cardData,
    { acType: selectedAircraft },
    selectedOperator,
  );
  const acSubTypeUnique = Array.from(new Set(acSubTypeOptions.map((item: any) => item.ac_subtype)));
  // get msn options
  const msnOptions = getFilteredDropdownValues(
    cardData,
    {
      acType: selectedAircraft,
      acSubType: selectedSubAircraft,
    },
    selectedOperator,
  );
  const msnUnique = Array.from(new Set(msnOptions.map((item: any) => item.id_aircraft_registration)));
  // dispatch to load filter options
  const filterValues = {
    ...filterOptions,
    ac_type_list: acTypeUnique as Array<string>,
    ac_sub_type_list: acSubTypeUnique as Array<string>,
    msn_list: msnUnique as Array<string>,
  };
  return filterValues;
};

export const shouldOpenDisclaimer = (userScope: string) => {
  if (isRequiredForEnv(['pro', 'val'])
      && userScope
      && !getDisclaimerStatus()) {
    return true;
  }
  return false;
};

export const nswcFliUrlLink = () => {
  if (isRequiredForEnv(['pro'])) {
    return window.open(FLI_URL_PROD);
  }
  return window.open(FLI_URL_DEV_VAL);
};

export const populateCheckerOperators = (dispatch:any, operatorReducer:any, checkerState: any) => {
  const checkerCardData = getCheckerFilters();
  if (!isEmpty(checkerCardData.operatorsList) && isEmpty(checkerState.fleet_view_filters.operators_list)) {
    dispatch(setOperatorFilters({ operatorList: checkerCardData.operatorsList }));
  } else {
    const payload:any = {
      conditions: {},
      columns: ['operator_code_icao'],
      distinct: true,
    };
    dispatch(operatorReducer(payload)());
  }
};

export const populateCheckerFleet = (dispatch:any, fleetReducer:any, payload: any, setDisclaimerIsOpen:any, checkerState:any, metaArg?:any) => {
  const checkerCardData = getCheckerFilters();
  // check if data from local store is empty or not, if empty, make api call
  if (!isEmpty(checkerCardData.data) && isEmpty(checkerState.data)) {
    // populate data from local store to redux.
    dispatch(updateFleetViewInnerData({ data: checkerCardData.data }));
    dispatch(getFleetViewData({ fleet_view_filters: checkerCardData.fleet_view_filters }));
    dispatch(setFleetViewFilter({ fleet_view_filter_selected: checkerCardData.fleet_view_filter_selected }));
    setDisclaimerStatus();
    setDisclaimerIsOpen(false);
  } else {
    dispatch(fleetReducer(payload)(metaArg)).then(() => {
      setDisclaimerStatus();
      setDisclaimerIsOpen(false);
    });
  }
};
