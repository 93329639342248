/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  IconButton,
  Inline,
} from '@airbus/components-react';
import { ErrorOutline } from '@airbus/icons/react';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import './DownloadModal.scss';
import {
  CANCEL,
  DOWNLOADANYWAY,
  FILEALREADYDOWNLOADED,
  SUREDOWNLOAD,
  SUCCESS,
} from '../../../utils/constants';

import { updateDrawerState } from '../../../models/downloadStatusModel/downloadStatusSlice';

interface DownloadProps {
  isExportClicked: boolean;
  handleExportClicked: any;
  checkStatusForRedownload: string;
  startExport: any;
  fileTypeValue: string;
}

const DownloadModal: React.FC<DownloadProps> = ({
  isExportClicked,
  handleExportClicked,
  checkStatusForRedownload,
  startExport,
  fileTypeValue,
}) => {
  const dispatch = useAppDispatch();
  const { isDownloadDrawerClose } = useAppSelector(
    (state) => state.downloadStatus,
  );
  const closeDownloadModalHandler = () => {
    handleExportClicked();
  };
  const downloadClickHandler = () => {
    closeDownloadModalHandler();
    startExport(fileTypeValue);
  };
  const handleDownloadDrawer = () => {
    dispatch(updateDrawerState(!isDownloadDrawerClose));
    closeDownloadModalHandler();
  };
  return (
    <Container
      data-testid="popup-download-modal"
      className="popup-download-container"
    >
      <Dialog
        open={isExportClicked}
        onClose={closeDownloadModalHandler}
        id="download-modal-popup"
        data-testid="download-modal-popup"
      >
        <div className="download-container">
          <Inline className="collapse-Icon">
            <IconButton className="errorIcon" data-testid="errorIcon">
              <ErrorOutline />
            </IconButton>
          </Inline>
          {checkStatusForRedownload === SUCCESS ? (
            <>
              <DialogHeader className="header-label" data-testid="success-header-label">
                {FILEALREADYDOWNLOADED}
              </DialogHeader>
              <DialogContent className="download-content" data-testid="success-content-label">
                <p>{SUREDOWNLOAD}</p>
              </DialogContent>
            </>
          ) : (
            <DialogHeader
              className="download-label"
              data-testid="In-Progress-Dialog"
            >
              You have already
              {' '}
              <a href="#" onClick={handleDownloadDrawer} data-testid="InProgress-download">
                initiated
              </a>
              {' '}
              the export for this file earlier.
              {' '}
            </DialogHeader>
          )}
          {checkStatusForRedownload === SUCCESS && (
            <DialogActions>
              <Button variant="secondary" onClick={closeDownloadModalHandler} data-testid="cancel-button">
                {CANCEL}
              </Button>
              <Button
                variant="primary"
                className="close-button"
                onClick={downloadClickHandler}
                data-testid="downloadAnyway-button"
              >
                {DOWNLOADANYWAY}
              </Button>
            </DialogActions>
          )}
        </div>
      </Dialog>
    </Container>
  );
};
export default DownloadModal;
