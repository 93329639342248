import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import { IconButton, Inline } from '@airbus/components-react';
import { Chevronup, Chevrondown } from '@airbus/icons/react';
import { getExpressions, isMissingSB } from './DependencyUtils';
import './CollapsibleComponent.scss';
import { useAppSelector } from '../../../../../store/hooksTypes';
import { SB_NOT_APPLICABLE, EXPRESSION } from './expressionConstants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CollapsibleComponent = (props: any) => {
  const [collapse, setCollapse] = useState(false);
  const setCollapsibleComponent = () => setCollapse(!collapse);
  const { expressionFormulaText, expressionFormula } = props;
  const ConcurrentState = useAppSelector((state) => state.displayConcurrentDataTable);
  const issbNotRelatedToMSN = isMissingSB(expressionFormula.item, expressionFormula.desc);
  const collapsDiv = (
    <div className="expressionDiv">
      <span className="expressionText" data-testid="expressionHeaderTextTag">
        <Inline spacing="5-x">
          <b data-testid="expressionHeaderText">{EXPRESSION}</b>
          <p data-testid="expressionHeaderFormulaText">
            {expressionFormulaText}
            {((!ConcurrentState.ConcurrentData.length || issbNotRelatedToMSN) && !ConcurrentState.isConcurrentDataLoading) && (<span className="missing-sb-text" data-testid="expressionMissingSbText">{SB_NOT_APPLICABLE}</span>)}
          </p>
        </Inline>
      </span>
      <span>
        <Inline className="collapse-Icon">
          <IconButton onClick={setCollapsibleComponent} className="expressionIcon" data-testid="expressionIcon">
            {collapse ? <Chevronup /> : <Chevrondown />}
          </IconButton>
        </Inline>
      </span>

    </div>
  );

  return (
    <Collapsible trigger={collapsDiv} alt="collapsibleComponent">
      <div className="collapsibleSection" data-testid="cardLengthExpression">
        {expressionFormula && expressionFormula.item?.length !== 0 && expressionFormula.desc?.length !== 0 ? getExpressions(expressionFormula) : []}
      </div>
    </Collapsible>
  );
};

export default CollapsibleComponent;
