export const POPUPFILTER_SLICE = 'popUpFilter';

// Initial state for filter keys
export const POPUPFILTER_INITIAL_STATE:FilterState = {
  isFilterLoading: false,
  modalToggle: false,
  operators_list: [],
  acm_operators_list: [],
  operators_ac_mapping: [],
  acm_operators_ac_mapping: [],
  acm_operators_ac_family: [],
  part_operators_ac_mapping: [],
  operators_acsubType_mapping: {},
  part_operators_acsubType_mapping: {},
  acm_operators_acsubType_mapping: {},
  errorMessage: '',
  msns: [],
  part_msns: [],
  acm_msns: [],
  mods: [],
  sb_ref: [],
  pnr: [],
  fin: [],
  datasource: [],
  sb_filter_selection_configuration: {
    operator_name: [],
    aircraft_type: [],
    aircraft_subtype: [],
    selected_msn: [],
    selected_sb: [],
    selected_mod: [],
    selected_pnr: [],
    selected_fin: [],
    selected_dataSource: '',
  },
  part_filter_selection_configuration: {
    operator_name: [],
    aircraft_type: [],
    aircraft_subtype: [],
    selected_msn: [],
    selected_pnr: [],
    selected_fin: [],
    selected_dataSource: '',
    selected_sb: [],
    selected_mod: [],
  },
  checker_filter_selection_configuration: {
    operator_code_icao: [],
    id_aircraft_registration: [],
    ac_type: [],
    data_source: [],
  },
  sbBrowseInitialLoad: true,
  partBrowseInitialLoad: true,
  part_data_selection_columns: {},
  sb_data_selection_columns: {},
  acm_selection_columns: {},
};

export const LOAD_OPERATORS = 'loadOperators';
export const LOAD_MOD = 'loadMod';
export const LOAD_SB = 'loadSb';
export const LOAD_FIN = 'loadFin';
export const LOAD_PNR = 'loadPnr';
export const LOAD_PART_AIRCRAFT_DETAILS = 'loadPartAircraftDetails';
export const LOAD_SB_AIRCRAFT_DETAILS = 'loadSbAircraftDetails';
