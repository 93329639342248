/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import { Buffer } from 'buffer';
import React, { useEffect, useState } from 'react';
import './CheckerReportStatus.scss';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Col,
  Container,
  IconButton,
  Row,
  Spinner,
  Typography,
} from '@airbus/components-react';
import { Reset } from '@airbus/icons/react';
import type { ChartOptions } from 'chart.js';
import { PieChart } from '../Shared/ChartComponent/PieChartComponent/PieChartComponent';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import {
  loadReportStatusChartAcr,
  loadReportStatusChartFinStatus,
  loadReportStatusChartMis,
  loadReportStatusChartPnrSource,
} from '../../models/reportStatusModel/reportStatusThunk';
import {
  setLocalStore,
  transformChartData,
  transformMisAcrSourceChartData,
  transformPnrSourceChartData,
  misAcrStatusMapping,
  getConditions,
  getFilterState,
} from '../../utils/commonMethods/generalMethods';
import {
  RELOAD, REPORT_STATUS_FILTERS, MIS_DATASOURCE, ACR_DATASOURCE,
  FIN_STATUS,
  PNR_SOURCE_COMPARISON,
} from '../../utils/constants';
import CustomChartLegend from './CustomChartLegend';

// Initial chart data
const initialChartData = {
  chartData: {
    labels: ['Wrong part', 'To be analysed', 'Match'],
    datasets: [
      {
        label: '',
        data: [0],
        backgroundColor: ['#008000'],
        borderColor: ['#008000'],
        borderWidth: 1,
      },
    ],
  },
  labelData: [
    {
      label: '',
      value: 0,
      percentage: 0,
    },
  ],
  total_count: 0,
  all_msn_contribution: false,
};

// FIN Status chart option
const finStatusChartOptions: ChartOptions<'pie'> = {
  plugins: {
    legend: {
      display: false,
    },
  },
  maintainAspectRatio: false,
};

// ACR chart option
const AcrChartOptions: ChartOptions<'pie'> = {
  plugins: {
    legend: {
      display: false,
    },
  },
  maintainAspectRatio: false,
};

// MIS chart option
const MisChartOptions: ChartOptions<'pie'> = {
  plugins: {
    legend: {
      display: false,
    },
  },
  maintainAspectRatio: false,
};

// PNR Source comparison chart option
const PnrSourceComparisonChartOptions: ChartOptions<'pie'> = {
  plugins: {
    legend: {
      display: false,
    },
  },
  maintainAspectRatio: false,
};

const CheckerReportStatus = function CheckerReportStatus() {
  const [acrData, setAcrData] = useState<any>(initialChartData);
  const [misData, setMisData] = useState<any>(initialChartData);
  const [finStatusData, setFinStatusData] = useState<any>(initialChartData);
  const [pnrSourceData, setPnrSourceData] = useState<any>(initialChartData);
  const reasonStatusMapping = useAppSelector((state) => state.reasonStatusMapping);
  const dispatch = useAppDispatch();
  const reportStatusState = useAppSelector((state) => state.reportStatus);
  const {
    checkerDetailFiltersSelected,
    fleet_detail_filter_selected,
    fleetDetailFilterOptions,
  } = useAppSelector((state) => state.displayCheckerDetailsTable);
  const { fleet_view_filters } = useAppSelector((state) => state.checkerFleet);
  const { operator_name, selected_msn } = checkerDetailFiltersSelected;

  const apiPayload: any = {
    operator_code_icao: [operator_name],
    conditions: { id_aircraft_registration: selected_msn },
  };
  const reportStatusAcrMapper: any = misAcrStatusMapping(reportStatusState.AcrChartData.source_status_count, reasonStatusMapping.data?.status_key_mapper);
  const reportStatusMisMapper: any = misAcrStatusMapping(reportStatusState.MisChartData.source_status_count, reasonStatusMapping.data?.status_key_mapper);
  useEffect(() => {
    const payload = {
      operator_code_icao: [operator_name],
      conditions: { id_aircraft_registration: selected_msn },
    };
    // calling `charts/acr_details` API
    dispatch(loadReportStatusChartAcr(payload)());
    // calling `charts/mis_details` API
    dispatch(loadReportStatusChartMis(payload)());
    // calling `charts/fin_status` API
    dispatch(loadReportStatusChartFinStatus(payload)());
    // calling `charts/pnr_comparison
    dispatch(loadReportStatusChartPnrSource(payload)());
  }, [selected_msn]);

  useEffect(() => {
    if (reportStatusState.AcrChartData) {
      const transformedData = transformMisAcrSourceChartData(reportStatusAcrMapper, reportStatusState.AcrChartData, ACR_DATASOURCE);
      setAcrData(transformedData);
    }

    if (reportStatusState.MisChartData) {
      const transformedData = transformMisAcrSourceChartData(reportStatusMisMapper, reportStatusState.MisChartData, MIS_DATASOURCE);
      setMisData(transformedData);
    }

    if (reportStatusState.FinStatusChartData) {
      const transformedData = transformChartData(reportStatusState.FinStatusChartData, FIN_STATUS);
      setFinStatusData(transformedData);
    }

    if (reportStatusState.PnrSourceComparisonChartData) {
      const transformedData = transformPnrSourceChartData(reportStatusState.PnrSourceComparisonChartData, PNR_SOURCE_COMPARISON);
      setPnrSourceData(transformedData);
    }
  }, [
    reportStatusState.FinStatusChartData,
    reportStatusState.AcrChartData,
    reportStatusState.MisChartData,
    reportStatusState.PnrSourceComparisonChartData,
  ]);
  const handleReloadApi = (chartApiCall: any, chartApiPayload: any) => {
    dispatch(chartApiCall(chartApiPayload)());
  };

  const checkerFilterUrl = (filterKey: string, filterValue: string) => {
    const newTabFilters = {
      fleet_view_filters,
      checkerDetailFiltersSelected,
      fleet_detail_filter_selected,
      fleetDetailFilterOptions,
    };

    setLocalStore(REPORT_STATUS_FILTERS, JSON.stringify(newTabFilters));
    setLocalStore('showFilteredTable', JSON.stringify(true));

    const checkerDetailsPayload = {
      conditions: getConditions(filterKey, filterValue, reasonStatusMapping),
      filterState: getFilterState(filterKey, filterValue, reasonStatusMapping),
    };

    const encodedQryParams = Buffer.from(
      JSON.stringify(checkerDetailsPayload),
    ).toString('base64');
    window.open(`${window.parent.location.origin}/checker/details?params=${encodedQryParams}`, '_blank');
  };

  // Pie chart component in card component
  const pieChartElement = (
    chartTitle: string,
    statusData: any,
    chartOptions: ChartOptions<'pie'>,
    showMsnLabel: boolean,
    chartApiCall: any,
    chartApiPayload: any,
    reloadChartData: boolean,
    isLoading = false,
  ) => {
    return (
      <div className="bg-coolgrey-10 p-2">
        <Card className="report-status-card">
          <CardHeader
            className="chart-title-checker"
            title={chartTitle}
            action={(
              <div>
                {reloadChartData
                  && (
                    <IconButton variant="ghost" className="icon-button-reload" data-testid="reload-button" onClick={() => { handleReloadApi(chartApiCall, chartApiPayload); }}>
                      <Reset />
                      <Typography variant="small" className="relaod-text" data-testid="checker-reload-card">
                        {RELOAD}
                      </Typography>
                    </IconButton>
                  )}
              </div>
            )}
          />
          <CardContent className="report-card-content">
            <CardTitle className="chart-title-checker">
              {!isLoading}
              {(showMsnLabel && !isLoading) && <Typography variant="xsmall" data-testid="no-data-text" className="msn-contribution-label">(One or more MSN does not have data.)</Typography>}
            </CardTitle>
            {isLoading ? (
              <Spinner size="small" className="checker-report-spinner" />
            ) : (
              <>
                {statusData.chartData.datasets[0].label === 'NO DATA' && <div className="no-data-label">NO DATA</div>}
                <div className={`${statusData.chartData.datasets[0].label === 'NO DATA' ? 'pieChart-data grayout-background' : 'pieChart-data'}`}>
                  <PieChart data={statusData.chartData} options={chartOptions} />
                </div>
                <CustomChartLegend chartStatus={statusData} filterURL={checkerFilterUrl} />
              </>
            )}
          </CardContent>
        </Card>
      </div>
    );
  };
  return (
    <Container className="report-status-container">
      <Row className="report-status-row">
        <Col className="report-status-column" xxs={6}>
          {pieChartElement(
            `FIN Status - ${finStatusData.total_count}`,
            finStatusData,
            finStatusChartOptions,
            false,
            loadReportStatusChartFinStatus,
            apiPayload,
            reportStatusState.reloadFinStatusChartData,
            reportStatusState.isFinStatusChartDataLoading,
          )}
        </Col>
        <Col className="report-status-column" xxs={6}>
          {pieChartElement(
            `PNR Source Comparison - ${pnrSourceData.total_count}`,
            pnrSourceData,
            PnrSourceComparisonChartOptions,
            false,
            loadReportStatusChartPnrSource,
            apiPayload,
            reportStatusState.reloadPnrSourceComparisonChartData,
            reportStatusState.isPnrSourceChartDataLoading,
          )}
        </Col>
      </Row>
      <div className="report-status-div">
        <Row className="report-status-row">
          <Col className="report-status-column" xxs={6}>
            {pieChartElement(
              `ACR - ${acrData.total_count}`,
              acrData,
              AcrChartOptions,
              acrData.all_msn_contribution,
              loadReportStatusChartAcr,
              apiPayload,
              reportStatusState.reloadAcrChartData,
              reportStatusState.isAcrChartDataLoading,
            )}
          </Col>
          <Col className="report-status-column" xxs={6}>
            {pieChartElement(
              `MIS - ${misData.total_count}`,
              misData,
              MisChartOptions,
              misData.all_msn_contribution,
              loadReportStatusChartMis,
              apiPayload,
              reportStatusState.reloadMisChartData,
              reportStatusState.isMisChartDataLoading,
            )}
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default CheckerReportStatus;
