/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Typography, Row, Col, Spinner,
} from '@airbus/components-react';
import '../ModalPopup.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  MSN, FIN, PNR, MAX_LIMIT, DEFAULT_OFFSET, ASCENDING,
} from '../../../../utils/constants';
import { loadFin, loadPnr } from '../../../../models/popupFilterModel/popupFilterThunks';
import { useAppDispatch } from '../../../../store/hooksTypes';
import { HybridComboBox } from '../../../Shared/HybridComboBox/HybridComboBox';

const PartField = function PartField(props: any) {
  const {
    partFieldProps,
  } = props;
  const dispatch = useAppDispatch();

  const handleResetFinAndPnr = (selectedMsn:any, selecteddDtaSource:any, selectedOperator:any) => {
    const dataSource = selecteddDtaSource;
    const arrayOperator = new Array(selectedOperator);
    const bodyPayload:any = {
      conditions: {
        id_aircraft_registration: selectedMsn,
      },
      operator_code_icao: arrayOperator,
      data_source: dataSource,
      limit: Number(MAX_LIMIT),
      offset: Number(DEFAULT_OFFSET),
      sortType: ASCENDING,
      columns: ['fin'],
      distinct: true,
    };
    dispatch(loadFin({ ...bodyPayload, orderBy: ['fin'] })());
    bodyPayload.columns = ['pnr'];
    dispatch(loadPnr({ ...bodyPayload, orderBy: ['pnr'] })());
  };

  const handleFinSelection = (selectFin:any) => {
    partFieldProps.setCurrentFilterConfig((prevState: any) => {
      return {
        ...prevState,
        selected_fin: selectFin,
      };
    });
  };

  const handlePnrSelection = (selectPnr:any) => {
    partFieldProps.setCurrentFilterConfig((prevState: any) => {
      return {
        ...prevState,
        selected_pnr: selectPnr,
      };
    });
  };

  const handleMsnSelection = (selectedMsn:any) => {
    partFieldProps.setCurrentFilterConfig((prevState: any) => {
      return {
        ...prevState,
        selected_msn: selectedMsn,
      };
    });
    if (selectedMsn.length) handleResetFinAndPnr(selectedMsn, partFieldProps.currentFilterConfig?.selected_dataSource, partFieldProps.currentFilterConfig?.operator_name);
  };

  return (
    <>
      <Row className="filter-row">
        <Col xxs={4}>
          <Typography variant="small" className="filter-label">
            {MSN}
          </Typography>
        </Col>
        <Col>
          <Col>
            <HybridComboBox
              state={partFieldProps.filterProps.tableState}
              dispatcher={dispatch}
              disabled={partFieldProps.disablePartLowerFilters}
              selectedOptions={partFieldProps.currentFilterConfig?.selected_msn}
              isSearchDisabled={false}
              hybridComboClassName="hybrid-checker-details-msn-popover"
              placement="top"
              id="browser-part-msn-selector"
              data-testid="msn-selector"
              handleApply={handleMsnSelection}
              isLazyloading
              showApply={false}
              options={partFieldProps.filterProps.msn}
            />
          </Col>
        </Col>
      </Row>
      <Row className="filter-row">
        <Col xxs={3}>
          <Typography variant="small" className="filter-label">
            {FIN}
          </Typography>
        </Col>
        <Col xxs={1}>
          {(partFieldProps.finPnrLoadingState) && <Spinner />}
        </Col>
        <Col>
          <Col>
            <HybridComboBox
              state={partFieldProps.filterProps.tableState}
              dispatcher={dispatch}
              disabled={partFieldProps.disablePartLowerFilters}
              selectedOptions={partFieldProps.currentFilterConfig?.selected_fin}
              isSearchDisabled={false}
              hybridComboClassName="hybrid-checker-details-msn-popover"
              placement="top"
              id="browser-part-fin-selector"
              data-testid="part-fin-selector"
              handleApply={handleFinSelection}
              isLazyloading
              showApply={false}
              options={partFieldProps.filterProps.filterState.fin}
            />
          </Col>
        </Col>
      </Row>
      <Row>
        <Col xxs={3}>
          <Typography variant="small" className="filter-label">
            {PNR}
          </Typography>
        </Col>
        <Col xxs={1}>
          {(partFieldProps.finPnrLoadingState) && <Spinner />}
        </Col>
        <Col>
          <Col>
            <HybridComboBox
              state={partFieldProps.filterProps.tableState}
              dispatcher={dispatch}
              disabled={partFieldProps.disablePartLowerFilters}
              selectedOptions={partFieldProps.currentFilterConfig?.selected_pnr}
              isSearchDisabled={false}
              hybridComboClassName="hybrid-checker-details-msn-popover"
              placement="top"
              id="browser-part-pnr-selector"
              data-testid="pnr-selector"
              handleApply={handlePnrSelection}
              isLazyloading
              showApply={false}
              options={partFieldProps.filterProps.filterState.pnr}
            />
          </Col>
        </Col>
      </Row>
    </>
  );
};
export default PartField;
