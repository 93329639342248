import { createSlice } from '@reduxjs/toolkit';
import { HELPMODAL_INITIAL_STATE, HELP_MODEL_SLICE } from './constants';
import { getHelpModalToggleState, setHelpModalToggleState } from './helpModelReducer';

export const helpModelSlice = createSlice({
  name: HELP_MODEL_SLICE,
  initialState: HELPMODAL_INITIAL_STATE,
  reducers: {
    getHelpModelToggle: getHelpModalToggleState,
    setHelpModelToggle: setHelpModalToggleState,
  },

});

export const {
  setHelpModelToggle, getHelpModelToggle,
} = helpModelSlice.actions;
export default helpModelSlice;
