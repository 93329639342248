import React from 'react';
import { Spinner } from '@airbus/components-react';
import _ from 'lodash';
import { CHECKER } from '../constants';

export const enableValidateButton = (currentFilterConfig: FilterKeys | undefined, sourceTab: string, ifAircraftTypeChanged?: boolean) => {
  if (sourceTab === CHECKER) {
    const isValidateValue = [currentFilterConfig?.operator_name
      && currentFilterConfig?.aircraft_type].every((el) => _.isEmpty(el)
        || _.isEmpty(currentFilterConfig?.selected_msn)) || _.isEmpty(currentFilterConfig?.aircraft_family) || (!_.isEmpty(currentFilterConfig?.aircraft_family) && ifAircraftTypeChanged && _.isEmpty(currentFilterConfig?.selected_msn));
    return isValidateValue;
  }
  return [
    currentFilterConfig?.operator_name,
    currentFilterConfig?.aircraft_type].every((el) => _.isEmpty(el)
      || [currentFilterConfig?.selected_msn,
        currentFilterConfig?.selected_sb,
        currentFilterConfig?.selected_mod,
        currentFilterConfig?.selected_fin,
        currentFilterConfig?.selected_pnr,
      ].every((val) => _.isEmpty(val)));
};
// eslint-disable-next-line consistent-return, @typescript-eslint/no-explicit-any
export const loadSpinner: any = (filterLoading: boolean | undefined, operator: string, aircraft: string, operatorLength: number | undefined) => {
  if (filterLoading && ((operator && aircraft === '') || operatorLength === 0)) {
    return (<Spinner />);
  }
};
export const rowsVisible = (dropDownName: string[] | undefined, size: number) => {
  return Number(dropDownName?.length || 0) > size ? size : (dropDownName?.length);
};
