/* CMI-S UI */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import {
  Col,
  ComboBox, Inline, Spinner, Typography,
} from '@airbus/components-react';
import { Reset } from '@airbus/icons/react';
import {
  fetchFleetViewDataForOperator, getReasonStatusMapping, registerPostHogProperties,
} from '../../utils/commonUtil';
import {
  AIRCRAFT_TYPE, AIRCRAFT_SUB_TYPE,
  COMBOBOX_PLACEHOLDER, DISCREPENCY_RESULT, MSN, OPERATOR, RESET,
  MSNS_PER_PAGE,
} from '../../utils/constants';
import './FleetFilter.scss';
import { useAppSelector, useAppDispatch } from '../../store/hooksTypes';
import {
  setCheckerInitialLoad, setFleetViewFilter,
} from '../../models/checkerFleetModel/checkerFleetSlice';
import { HybridComboBox } from '../Shared/HybridComboBox/HybridComboBox';
import {
  checkerReasonStatusMapping,
  getOperatorFromApi, handleAcApply, handleAcSubApply, handleMsnApply, handleStatusApply,
} from './Utils/FleetFilterUtils';
import { CHECKER_FLEET_INITIAL_STATE } from '../../models/checkerFleetModel/constants';

const FleetFilter: React.FC = function CheckerFilter() {
  const checkerState = useAppSelector((state) => state.checkerFleet);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (checkerState.fleet_view_filter_selected?.selected_operator.length === 0) {
      getOperatorFromApi(dispatch);
    }
  }, [dispatch]);
  useEffect(() => {
    registerPostHogProperties({
      ICAO_CODE: checkerState.fleet_view_filter_selected?.selected_operator?.toString(),
    });
    if (checkerState.checkerFleetFilterInitialLoad && checkerState.fleet_view_filter_selected?.selected_operator.length !== 0) {
      fetchFleetViewDataForOperator(checkerState, dispatch, checkerState.fleet_view_filter_selected?.selected_operator, 'fleetView');
      const reasonStatus = getReasonStatusMapping();
      checkerReasonStatusMapping(dispatch, reasonStatus, checkerState);
    }
  }, [checkerState.fleet_view_filter_selected?.selected_operator]);

  const handleOperatorSelection = (selectedCheckerOperator: any) => {
    if (selectedCheckerOperator) {
      const filterValue = {
        ...checkerState.fleet_view_filter_selected,
        selected_operator: new Array(selectedCheckerOperator),
      };
      dispatch(setFleetViewFilter({ fleet_view_filter_selected: filterValue }));
    }
  };
  const handleReset = () => {
    dispatch(setFleetViewFilter({ fleet_view_filter_selected: { ...CHECKER_FLEET_INITIAL_STATE.fleet_view_filter_selected } }));
    getOperatorFromApi(dispatch);
    dispatch(setCheckerInitialLoad({ checkerFleetFilterInitialLoad: true }));
  };

  return (
    <>
      <Col xxs={2} className="checker-filters-operators">
        <Typography variant="small" className="checker-filter-label">
          {OPERATOR}
        </Typography>
        <Col xxs={1} className="spinner-left">
          {checkerState.isFilterOperatorLoading && <Spinner />}
        </Col>
        <ComboBox
          aria-label="Select Operator"
          placeholder={COMBOBOX_PLACEHOLDER}
          value={checkerState.fleet_view_filter_selected?.selected_operator?.toString()}
          disabled={!checkerState.fleet_view_filters?.operators_list?.length}
          data-testid="checker-operator-selector"
          onChange={(value: any) => handleOperatorSelection(value)}
          options={checkerState.fleet_view_filters?.operators_list}
          getOptionLabel={(option) => option}
          getOptionValue={(option) => option}
          hideClear
        />
      </Col>
      <Col xxs={2} className="checker-filters">
        <Typography variant="small" className="checker-filter-label">
          {AIRCRAFT_TYPE}
        </Typography>
        <HybridComboBox state={checkerState} dispatcher={dispatch} isSearchDisabled={false} id="acType" disabled={!checkerState.fleet_view_filters?.operators_list?.length} options={checkerState.fleet_view_filters?.ac_type_list} selectedOptions={checkerState.fleet_view_filter_selected?.selected_aircraft} handleApply={handleAcApply} />
      </Col>
      <Col xxs={2} className="checker-filters">
        <Typography variant="small" className="checker-filter-label">
          {AIRCRAFT_SUB_TYPE}
        </Typography>
        <HybridComboBox state={checkerState} dispatcher={dispatch} isSearchDisabled={false} id="acSubType" disabled={!checkerState.fleet_view_filters?.operators_list?.length} options={checkerState.fleet_view_filters?.ac_sub_type_list} selectedOptions={checkerState.fleet_view_filter_selected?.selected_sub_aircraft} handleApply={handleAcSubApply} />
      </Col>
      <Col xxs={2} className="checker-filters">
        <Typography variant="small" className="checker-filter-label">
          {MSN}
        </Typography>
        <HybridComboBox perPage={MSNS_PER_PAGE} state={checkerState} dispatcher={dispatch} isSearchDisabled={false} id="msn" disabled={!checkerState.fleet_view_filters?.operators_list?.length} options={checkerState.fleet_view_filters?.msn_list} selectedOptions={checkerState.fleet_view_filter_selected?.selected_msn} handleApply={handleMsnApply} />
      </Col>
      <Col xxs={2} className="checker-filters">
        <Typography variant="small" className="checker-filter-label">
          {DISCREPENCY_RESULT}
        </Typography>
        <HybridComboBox state={checkerState} dispatcher={dispatch} isSearchDisabled={false} id="status" disabled={!checkerState.fleet_view_filters?.operators_list?.length} options={checkerState.fleet_view_filters?.status_list} selectedOptions={checkerState.fleet_view_filter_selected?.selected_discrepency} handleApply={handleStatusApply} />
      </Col>
      <Col className="checker-filters-reset">
        <Inline spacing="1-x">
          <div className="checker-filter-reset-icon">
            <Reset onClick={handleReset} />
          </div>
          <Typography variant="small" data-testid="checker-reset-filter" className="checker-filter-resetlabel" onClick={handleReset}>
            {RESET}
          </Typography>
        </Inline>
      </Col>
    </>
  );
};
export default FleetFilter;
