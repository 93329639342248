import { getDisplayColumns } from '../../utils/commonUtil';
import { tableColumns, actionColumns, sbColumns } from '../../components/Shared/Tabs/BrowserTab/BrowserTableStructure';

export const CONCURRENT_DATATABLE_SLICE = 'displayConcurrentDataTable';
// Action Type
export const CONCURRENT_TABLE_ACTION = 'fetchConcurrentTableData';
export const CONCURRENT_TABLE_FILTER_ACTION = 'fetchConcurrentFilterTableData';
export const CONCURRENT_TABLE_FILTER_THUNK = 'fetchConcurrentFilterTableThunk';

export const CONCURRENT_DATATABLE_INITIAL_STATE: ConcurrentDataTableState = {
  isConcurrentDataLoading: false,
  errorMessage: '',
  ConcurrentData: [],
  records_length: 0,
  records_offset: 0,
  columns: getDisplayColumns([...actionColumns, ...tableColumns, ...sbColumns]),
  FilterData: [],
  FilterState: [],
  FilterPayload: [],
  isColumnDataLoading: false,
  concurrentToggle: false,
};
export const LOAD_CONCURRENT_DATA = 'loadConcurrentData';
export const CONCURRENT_DATA_ERROR = 'Unable to get Concurrent Data';
