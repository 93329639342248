/* eslint-disable @typescript-eslint/no-explicit-any */
// Extra Reducers to handle promises of Async Thunk
import { isEmpty } from 'lodash';
import { getCheckerFilters, redirectToLogout, storeCheckerFilters } from '../../utils/commonUtil';
import {
  DISCREPENCY_OPTIONS,
  TOAST_TIMEOUT,
} from '../../utils/constants';
import { CHECKER_FLEET_INITIAL_STATE } from './constants';

export const loadCheckerOperatorsPending = (state: CheckerFleetState) => ({
  ...state,
  isFilterOperatorLoading: true,
});

export const loadCheckerOperatorsRejected = (state: CheckerFleetState, action: any) => {
  if (String(action.payload).includes('401')) {
    setTimeout(() => {
      redirectToLogout();
    }, TOAST_TIMEOUT);
  }
  return {
    ...state,
    isFilterOperatorLoading: false,
    errorMessage: action.payload?.message,
    operators_list: CHECKER_FLEET_INITIAL_STATE.fleet_view_filters?.operators_list,
    status: action.payload?.ResponseMetadata?.HTTPStatusCode,
  };
};

export const loadCheckerOperatorsFullfilled = (state: CheckerFleetState, action: CheckerFleetAction) => {
  if (action?.payload?.data.length <= 0) {
    setTimeout(() => {
      redirectToLogout();
    }, TOAST_TIMEOUT);
  }
  const OperatorResp = action.payload?.data?.map((item: any) => item.operator_code_icao);
  // store response in localstore only when localstore filter is empty
  const localCheckerFilters = getCheckerFilters();
  if (isEmpty(localCheckerFilters.operatorsList)) {
    storeCheckerFilters({ ...localCheckerFilters, operatorsList: OperatorResp });
  }
  return {
    ...state,
    isFilterOperatorLoading: false,
    fleet_view_filters: {
      ...state.fleet_view_filters,
      operators_list: OperatorResp,
    },
    fleet_view_filter_selected: {
      ...state.fleet_view_filter_selected,
      selected_operator: OperatorResp.length > 0 ? new Array(OperatorResp[0]) : [],
    },
  };
};

export const loadFleetDataPending = (state: CheckerFleetState) => ({
  ...state,
  isDataLoading: true,
});

export const loadFleetDataRejected = (state: CheckerFleetState, action: FilterAPIRejectedAction) => {
  return {
    ...state,
    isDataLoading: false,
    errorMessage: action.payload?.message,
    data: CHECKER_FLEET_INITIAL_STATE.data,
    status: action.payload?.ResponseMetadata?.HTTPStatusCode,
  };
};

/**
 * Extracts unique values for MSN, A/C Type, A/C Subtype, AC Family from fetched fleet data.
 * @param FleetDataResp
 */
export const loadFleetDataMethods = (FleetDataResp: any) => {
  const idAircraftRegistration = FleetDataResp.map((item:any) => item.id_aircraft_registration)
    .filter((item:any, index: number, array:any) => array.indexOf(item) === index);
  idAircraftRegistration.sort();
  const acTypeUnique = FleetDataResp.map((item:any) => item.ac_type)
    .filter((item:any, index: number, array:any) => array.indexOf(item) === index);
  const aSubTypeUnique = FleetDataResp.map((item: any) => item.ac_subtype)
    .filter((item:any, index: number, array:any) => array.indexOf(item) === index);
  const acFamily = FleetDataResp.map((item: any) => item.ac_family)
    .filter((item:any, index: number, array:any) => array.indexOf(item) === index);
  const FleetData = [...FleetDataResp.filter((item: any) => item.discrepency_result === 'W'), ...FleetDataResp.filter((item: any) => item.discrepency_result === 'A'), ...FleetDataResp.filter((item: any) => item.discrepency_result === 'M'), ...FleetDataResp.filter((item: any) => item.discrepency_result !== 'W' && item.discrepency_result !== 'A' && item.discrepency_result !== 'M')];
  return {
    idAircraftRegistration, acTypeUnique, aSubTypeUnique, acFamily, FleetData,
  };
};

export const loadFleetDataFullfilled = (state: CheckerFleetState, action: CheckerFleetAction) => {
  const FleetDataResp = action.payload?.data as Array<any>;
  const {
    idAircraftRegistration, acTypeUnique, aSubTypeUnique, acFamily, FleetData,
  } = loadFleetDataMethods(FleetDataResp);
  const [operatorIcaoData] = FleetData;
  const selectedOperator = operatorIcaoData.operator_code_icao;
  const operatorSpecificData = { [selectedOperator]: FleetData };
  const storeFleetViewFilters = {
    ...state.fleet_view_filters,
    ac_family_list: acFamily,
    ac_type_list: acTypeUnique,
    ac_sub_type_list: aSubTypeUnique,
    msn_list: idAircraftRegistration,
  };
  const storeFleetViewFilterSelected = {
    ...state.fleet_view_filter_selected,
    selected_aircraft: acTypeUnique,
    selected_sub_aircraft: aSubTypeUnique,
    selected_msn: idAircraftRegistration,
    selected_discrepency: DISCREPENCY_OPTIONS,
  };
  // set the local store only when it is empty.
  const localFleetFilters = getCheckerFilters();
  if (isEmpty(localFleetFilters.fleet_view_filters)) {
    storeCheckerFilters({
      ...localFleetFilters,
      data: { ...state.data, ...operatorSpecificData },
      fleet_view_filters: storeFleetViewFilters,
      fleet_view_filter_selected: storeFleetViewFilterSelected,
    });
  }
  if (action.meta.arg === 'fleetView') {
    return {
      ...state,
      isDataLoading: false,
      data: { ...state.data, ...operatorSpecificData },
      visibleData: FleetData,
      fleet_view_filters: storeFleetViewFilters,
      fleet_view_filter_selected: storeFleetViewFilterSelected,
    };
  }
  return {
    ...state,
    isDataLoading: false,
    data: { ...state.data, ...operatorSpecificData },
    visibleData: FleetData,
    fleet_view_filters: storeFleetViewFilters,
  };
};
