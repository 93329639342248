/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Inline, Col, Row, IconButton, Container,
  Typography,
  Link,
} from '@airbus/components-react';
import { LiveHelp, ViewComfyOutline, ViewModuleOutline } from '@airbus/icons/react';
import React from 'react';
import './Fleet.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { setHelpModelToggle } from '../../models/helpModel/helpModelSlice';
import FleetInfiniteComponent from './FleetInfiniteComponent';
import Loader from '../Shared/Loader/Loader';
import { setFleetState } from '../../models/checkerFleetModel/checkerFleetSlice';
import { NO_TABLE_RECORDS_FOUND, USER_SCOPE_NSWC } from '../../utils/constants';
import { nswcFliUrlLink } from '../../utils/commonUtil';

const Fleet: React.FC = function Fleet() {
  const checkerFleetState = useAppSelector((state) => state.checkerFleet);
  const checkerDetailState = useAppSelector((state) => state.displayCheckerDetailsTable);
  const userPermissions = useAppSelector((state) => state.authEntrySlice);
  const featureToggleConfig = useAppSelector((state) => state.featureToggleDetails?.data);
  const dispatch = useAppDispatch();

  const cardDataLoad = () => {
    if (checkerFleetState.visibleData.length === 0 && checkerFleetState.isFilterOperatorLoading === false && userPermissions?.userScope !== USER_SCOPE_NSWC) {
      return (
        <Typography className="no-data-text" data-testid="no-checker-data">
          {NO_TABLE_RECORDS_FOUND}
        </Typography>
      );
    }
    if (featureToggleConfig?.nswc === 'true' && userPermissions?.userScope === USER_SCOPE_NSWC && checkerFleetState.visibleData.length === 0) {
      return (
        <Container className="nswc-container">
          <Typography variant="medium" data-testid="nswc-text" className="nswc-text">No previous records found!</Typography>
          <Link
            variant="underlined"
            title="Upload file now"
            className="nswc-link"
            onClick={nswcFliUrlLink}
            data-testid="nswc-fli-link"
          >
            Upload file now
          </Link>
        </Container>
      );
    }
    return <FleetInfiniteComponent checkerFleetState={checkerFleetState} checkerDetailState={checkerDetailState} />;
  };
  return (
    <>
      <Container>
        <Row>
          <Col className="fleet-container">
            {`Aircrafts (${checkerFleetState.visibleData?.length})`}
            <LiveHelp className="fleet-help-icon" data-testid="filter-help-icon" onClick={() => { dispatch(setHelpModelToggle({ helpModalState: true, sourceOfHelpClick: 'checkerFleetHelp' })); }} />
          </Col>
          <Col className="icon-view">
            <Inline className="view-buttons" align="end">
              <IconButton className="large" variant="ghost" data-testid="large-view-button" onClick={() => dispatch(setFleetState({ selectedView: 'larger' }))} pressed={checkerFleetState.selectedView === 'larger'}>
                <ViewModuleOutline />
              </IconButton>
              <IconButton className="small" variant="ghost" data-testid="small-view-button" size="small" onClick={() => dispatch(setFleetState({ selectedView: 'smaller' }))} pressed={checkerFleetState.selectedView === 'smaller'}>
                <ViewComfyOutline />
              </IconButton>
            </Inline>
          </Col>
        </Row>
      </Container>
      <Container>
        {checkerFleetState.isDataLoading ? (
          <Loader isBlocking={checkerFleetState.isDataLoading} />
        ) : (
          cardDataLoad()
        )}
      </Container>
    </>
  );
};
export default Fleet;
