/*
 * File: locale.ts
 * Airbus, 2024
 * Author: CMIS FE
 * Description: CMIS APP Locale
 * Copyright (C) 2023-2024 CMIS APP
 * This file is part of CMIS APP.
 *
 * CMIS APP is Aviation product:
 * Used in the latest version
 *
 * CMIS APP FE Code
 *
 * This belong to with CMIS APP.
 */
import { createSlice } from '@reduxjs/toolkit';
import {
  DISCLAIMER_DETAILS_SLICE,
  DISCLAIMER_INITIAL_STATE,
} from './constants';
import {
  postDisclaimerFullfilled,
  postDisclaimerPending,
  postDisclaimerRejected,
} from './disclaimerReducer';
import { postDisclaimerStatus } from './disclaimerThunk';

export const disclaimerDetailsSlice = createSlice({
  name: DISCLAIMER_DETAILS_SLICE,
  initialState: DISCLAIMER_INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postDisclaimerStatus().pending, postDisclaimerPending)
      .addCase(postDisclaimerStatus().rejected, postDisclaimerRejected)
      .addCase(postDisclaimerStatus().fulfilled, postDisclaimerFullfilled);
  },
});
export default disclaimerDetailsSlice;
