import { Methods } from '../customHooks/apiTypes';

// API's for Filter popup
export const LOAD_OPERATOR_API_PATH = 'api/browser/filter/operator';
export const LOAD_SB_MSN_API_PATH = 'api/browser/filter/sbAircraftDetails';
export const LOAD_MOD_API_PATH = 'api/browser/filter/mod';
export const LOAD_SB_API_PATH = 'api/browser/filter/sb';
export const LOAD_SB_BROWSE_API_PATH = 'api/browser/sbData';
export const LOAD_MIS_FIN_API_PATH = 'api/browser/parts/finMis';
export const LOAD_ACR_FIN_API_PATH = 'api/browser/parts/finAcr';
export const LOAD_MIS_PNR_API_PATH = 'api/browser/parts/pnrMis';
export const LOAD_ACR_PNR_API_PATH = 'api/browser/parts/pnrAcr';
export const LOAD_PART_MSN_API_PATH = 'api/browser/parts/aircraftDetails';
export const LOAD_PART_BROWSE_MIS_API_PATH = 'api/browser/parts/mis';
export const LOAD_PART_BROWSE_ACR_API_PATH = 'api/browser/parts/acr';
// API's for file export
export const LOAD_EXPORT_URL_API_PATH = 'api/browser/exportUrl';
export const LOAD_FILE_STATUS_API_PATH = 'api/browser/exportStatus';
export const LOAD_EXPORT_FILE_API_PATH = 'api/browser/exportFile';
export const LOAD_GET_API_METHOD = 'get' as Methods;
export const LOAD_POST_API_METHOD = 'post' as Methods;
// API's for checker
export const LOAD_CHECKER_FLEET_API_PATH = 'api/checker/fleet';
export const LOAD_CHECKER_DETAILS_API_PATH = 'api/checker/fleet_detail';
export const LOAD_CHECKER_GET_FIN_HISTORY_API_PATH = 'api/checker/get_fin_detail_history';
export const LOAD_CHECKER_UPDATE_FIN_API_PATH = 'api/checker/update_fin_detail';
export const LOAD_CHECKER_REASON_STATUS_MAPPING_API_PATH = 'api/checker/config';
export const POST_DISCLAIMER_API_PATH = 'api/disclaimer';
export const USER_GUIDE_API_PATH = 'api/assets/get';
export const REPORT_STATUS_CHART_ACR_API_PATH = 'api/checker/charts/acr_details';
export const REPORT_STATUS_CHART_MIS_API_PATH = 'api/checker/charts/mis_details';
export const REPORT_STATUS_CHART_PNR_SOURCE_API_PATH = 'api/checker/charts/pnr_comparison';
export const REPORT_STATUS_CHART_FIN_STATUS_API_PATH = 'api/checker/charts/fin_status';
export const POST_FEATURE_TOGGLE_API_PATH = 'api/featuretoggle';

// API for nswc
export const GET_FILE_STATUS_API_PATH = 'api/nswc/file_status';

// Auth Entry API
export const AUTH_ENTRY_API_PATH = 'api/user';
