import { createSlice } from '@reduxjs/toolkit';
import { AUTH_ENTRY_SLICE, USER_PERMISSIONS_INIT_STATE } from './constants';
import { getUserPermissions } from './authEntryThunks';
import { getUserPermissionsFullfilled, getUserPermissionsPending, getUserPermissionsRejected } from './authEntryExtraReducers';

const authSlice = createSlice({
  name: AUTH_ENTRY_SLICE,
  initialState: USER_PERMISSIONS_INIT_STATE,
  reducers: {
    getUserPermission: getUserPermissionsFullfilled,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPermissions().pending, getUserPermissionsPending)
      .addCase(getUserPermissions().rejected, getUserPermissionsRejected)
      .addCase(getUserPermissions().fulfilled, getUserPermissionsFullfilled);
  },
});

export const { getUserPermission } = authSlice.actions;

export default authSlice;
