/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppDispatch } from '../../store/store';

type StopFunction = (...args:any[]) => boolean
type UniqueFunction = {
  [key: string]: ReturnType<typeof setTimeout>
}

export const withLongPolling = (apiReducer:any, interval:number, stopFunction:StopFunction) => {
  const uniqueFunctions: UniqueFunction = {};
  function longPoll(thunkName:string, nextReducer:any, nextPayload:boolean, apiDispatcher:AppDispatch) {
    apiDispatcher(apiReducer())
      .then((data: any) => {
        if (stopFunction(data)) {
          clearTimeout(uniqueFunctions[thunkName]);
          delete uniqueFunctions[thunkName];
          apiDispatcher(nextReducer(nextPayload));
        } else {
          clearTimeout(uniqueFunctions[thunkName]);
          uniqueFunctions[thunkName] = setTimeout(() => longPoll(thunkName, nextReducer, nextPayload, apiDispatcher), interval);
        }
      }).catch(() => {
        clearTimeout(uniqueFunctions[thunkName]);
        uniqueFunctions[thunkName] = setTimeout(() => longPoll(thunkName, nextReducer, nextPayload, apiDispatcher), interval);
      });
  }

  function getUniqueFunction(thunkName: string, nextReducer:any, nextPayload:boolean, apiDispatcher:AppDispatch) {
    if (!uniqueFunctions[thunkName]) {
      uniqueFunctions[thunkName] = setTimeout(() => longPoll(thunkName, nextReducer, nextPayload, apiDispatcher), interval);
    }
    return uniqueFunctions[thunkName];
  }
  // eslint-disable-next-line func-names
  return function (thunkName:string, nextReducer:any, nextPayload: boolean, apiDispatcher:AppDispatch) {
    getUniqueFunction(thunkName, nextReducer, nextPayload, apiDispatcher);
  };
};
