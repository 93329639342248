/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Divider, List, ListItem, ListItemText,
} from '@airbus/components-react';
import React from 'react';
import './Fleet.scss';
import {
  ACR_DATASOURCE, DIVIDER_STRING, MATCH, MIS_DATASOURCE, PERCENT, SEMICOLON, TO_BE_ANALYSED, WRONG,
} from '../../utils/constants';
import { getpercentageCal } from '../../utils/commonUtil';

interface FleetLargerViewProps {
    fleetDetails : any;
}

const FleetLargerView: React.FC<FleetLargerViewProps> = function FleetLargerView(props: FleetLargerViewProps) {
  const {
    fleetDetails,
  } = props;
  let acrDate = fleetDetails.acd_date;
  if (!acrDate) {
    acrDate = fleetDetails.aisd_date;
  }
  return (
    <>
      <ul className="checker-list">
        <li className="checker-wrong">
          <span className="fleet-card-label">{WRONG}</span>
          <span className="fleet-card-value">
            {SEMICOLON}
            {fleetDetails.wrong_part_count}
            {DIVIDER_STRING}
            {getpercentageCal(fleetDetails.wrong_part_count, fleetDetails.total_count)}
            {PERCENT}
          </span>
        </li>
        <li className="checker-analysed">
          <span className="fleet-card-label">{TO_BE_ANALYSED}</span>
          <span className="fleet-card-value">
            {SEMICOLON}
            {fleetDetails.tobe_analyse_count}
            {DIVIDER_STRING}
            {getpercentageCal(fleetDetails.tobe_analyse_count, fleetDetails.total_count)}
            {PERCENT}
          </span>
        </li>
        <li className="checker-matched">
          <span className="fleet-card-label">{MATCH}</span>
          <span className="fleet-card-value">
            {SEMICOLON}
            {fleetDetails.match_count}
            {DIVIDER_STRING}
            {getpercentageCal(fleetDetails.match_count, fleetDetails.total_count)}
            {PERCENT}
          </span>
        </li>
      </ul>
      <List className="fleet-list">
        <ListItem selected className="fleet-list-padding">
          <ListItemText>
            {MIS_DATASOURCE}
            {fleetDetails.date_mis && <span data-testid="date-mis" className="fleet-card-value">{` - ${fleetDetails.date_mis}`}</span>}
          </ListItemText>
        </ListItem>
        <Divider dense />
        <ListItem selected className="fleet-list-padding">
          <ListItemText>
            {ACR_DATASOURCE}
            {acrDate && <span data-testid="date-acr" className="fleet-card-value">{` - ${acrDate}`}</span>}
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
};
export default FleetLargerView;
