/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Inline, Row } from '@airbus/components-react';
import React from 'react';
import './Fleet.scss';
import { DIVIDER_STRING, PERCENT } from '../../utils/constants';
import { getpercentageCal } from '../../utils/commonUtil';

interface FleetSmallerViewProps {
    fleetDetails : any;
}

const FleetSmallerView: React.FC<FleetSmallerViewProps> = function FleetSmallerView(props: FleetSmallerViewProps) {
  const {
    fleetDetails,
  } = props;
  return (
    <Inline spacing="1-x" className="fleet-inline">
      <Row className="smaller-row">
        <Col>
          <span className="dot-wrong" />
          <span className="fleet-padding">
            {getpercentageCal(fleetDetails.wrong_part_count, fleetDetails.total_count)}
            {PERCENT}
          </span>
          <span className="fleet-small-view-divider">{DIVIDER_STRING}</span>
        </Col>
        <Col>
          <span className="dot-analysed" />
          <span className="fleet-padding">
            {getpercentageCal(fleetDetails.tobe_analyse_count, fleetDetails.total_count)}
            {PERCENT}
          </span>
          <span className="fleet-small-view-divider">{DIVIDER_STRING}</span>
        </Col>
        <Col>
          <span className="dot-match" />
          <span className="fleet-padding">
            {getpercentageCal(fleetDetails.match_count, fleetDetails.total_count)}
            {PERCENT}
          </span>
        </Col>
      </Row>
    </Inline>
  );
};
export default FleetSmallerView;
