/* eslint-disable @typescript-eslint/no-explicit-any */
import { AUTH_ENTRY_SLICE, GET_USER_PERMISSIONS } from './constants';
import { AUTH_ENTRY_API_PATH, LOAD_GET_API_METHOD } from '../../utils/config/apiConfig';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';

export const getUserPermissions: any = () => {
  const customAction = {
    sliceName: AUTH_ENTRY_SLICE,
    actionType: GET_USER_PERMISSIONS,
  };

  const apiRequestObj = {
    path: AUTH_ENTRY_API_PATH,
    method: LOAD_GET_API_METHOD,
  };
  // Add API request obj and create custom thunk
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
