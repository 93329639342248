/* eslint-disable @typescript-eslint/no-explicit-any */
// Extra Reducers to handle promises of Async Thunk
import { getRestructuredResponseOfACMOperatorMSN, getRestructuredResponseOfOperatorMSN, redirectToLogout } from '../../utils/commonUtil';
import { AC_FAMILY_MAPPING, TOAST_TIMEOUT } from '../../utils/constants';
import { POPUPFILTER_INITIAL_STATE } from './constants';

export const loadOperatorsPending = (state: FilterState) => ({
  ...state,
  isFilterLoading: true,
});
export const loadOperatorsRejected = (state: FilterState, action:any) => {
  if (String(action.payload).includes('401')) {
    setTimeout(() => {
      redirectToLogout();
    }, TOAST_TIMEOUT);
  }
  return {
    ...state,
    isFilterLoading: false,
    errorMessage: action.payload?.message,
    operators_list: POPUPFILTER_INITIAL_STATE.operators_list,
    acm_operators_list: POPUPFILTER_INITIAL_STATE.acm_operators_list,
    status: action.payload?.ResponseMetadata?.HTTPStatusCode,
  };
};
export const loadOperatorsFullfilled = (state: FilterState, action:FilterAction) => {
  if (action?.payload?.records.length <= 0) {
    setTimeout(() => {
      redirectToLogout();
    }, TOAST_TIMEOUT);
  }
  const OperatorResp = action?.payload?.records?.map((eachOp: any) => eachOp.operator_code_icao);
  if (action.meta?.arg === 'acm') {
    return {
      ...state,
      isFilterLoading: false,
      acm_operators_list: OperatorResp,
    };
  }
  return {
    ...state,
    isFilterLoading: false,
    operators_list: OperatorResp,
  };
};

export const loadModPending = (state: FilterState) => ({
  ...state,
  isFilterLoading: true,
});

export const loadModRejected = (state: FilterState, action: FilterAPIRejectedAction) => {
  return {
    ...state,
    isFilterLoading: false,
    errorMessage: action.payload?.message,
    mods: POPUPFILTER_INITIAL_STATE.mods,
    status: action.payload?.ResponseMetadata?.HTTPStatusCode,
  };
};

export const loadModFullfilled = (state: FilterState, action : FilterAction) => {
  const modsResp = action.payload?.records[0]?.mod;
  return {
    ...state,
    isFilterLoading: false,
    mods: modsResp,
  };
};

export const loadSbPending = (state: FilterState) => ({
  ...state,
  isFilterLoading: true,
});

export const loadSbRejected = (state: FilterState, action : FilterAPIRejectedAction) => {
  return {
    ...state,
    isFilterLoading: false,
    errorMessage: action.payload?.message,
    sb_ref: POPUPFILTER_INITIAL_STATE.sb_ref,
    status: action.payload?.ResponseMetadata?.HTTPStatusCode,
  };
};

export const loadSbFullfilled = (state: FilterState, action : FilterAction) => {
  const sbsResp = action.payload?.records[0]?.sb;
  return {
    ...state,
    isFilterLoading: false,
    sb_ref: sbsResp,
  };
};
export const loadAcFamilyPending = (state: FilterState) => ({
  ...state,
  isFilterLoading: true,
});

export const loadAcFamilyRejected = (state: FilterState, action : FilterAPIRejectedAction) => {
  return {
    ...state,
    isFilterLoading: false,
    errorMessage: action.payload?.message,
    operatrs_ac_family: POPUPFILTER_INITIAL_STATE.acm_operators_ac_family,
    status: action.payload?.ResponseMetadata.HTTPStatusCode,
  };
};

export const loadAcFamilyFullfilled = (state: FilterState, action : FilterAction) => {
  /* Discards any aircraft_program_code coming from API
    call and is not available in the AC_FAMILY_MAPPING */
  const updatedRecords = action.payload.records.filter((record: any) => {
    return record.aircraft_program_code in AC_FAMILY_MAPPING;
  });
  /* Maps the aircraft_program_code to the corresponding AC Family */
  const acFamilyResp = updatedRecords.map((item:any) => {
    const acFamily = AC_FAMILY_MAPPING[item.aircraft_program_code];
    return { label: acFamily, value: item.aircraft_program_code };
  });
  return {
    ...state,
    isFilterLoading: false,
    acm_operators_ac_family: acFamilyResp,
  };
};

export const loadAcTypeAcFamilyPending = (state: FilterState) => ({
  ...state,
  isFilterLoading: true,
});
export const loadAcTypeFamilyRejected = (state: FilterState, action:FilterAPIRejectedAction) => {
  return {
    ...state,
    isFilterLoading: false,
    errorMessage: action.payload?.message,
    acm_operators_ac_mapping: POPUPFILTER_INITIAL_STATE.acm_operators_ac_mapping,
    status: action.payload?.ResponseMetadata?.HTTPStatusCode,
  };
};
export const loadAcTypeFamilyFullfilled = (state: FilterState, action:FilterAction) => {
  const acTypeResp = action.payload?.records.map((item : any) => item.aircraft_type);
  const acTypeUnique = Array.from(new Set(acTypeResp));
  const acSubType:any = {};
  acTypeUnique.forEach((acType:any) => {
    const acTypeSubTypeFiltered = action.payload?.records?.filter((acTypeObj:any) => (acTypeObj.aircraft_type === acType));
    acSubType[acType] = acTypeSubTypeFiltered?.map((acSubFilObj:any) => acSubFilObj.aircraft_model);
  });
  return {
    ...state,
    isFilterLoading: false,
    acm_operators_ac_mapping: acTypeUnique,
    acm_operators_acsubType_mapping: acSubType,
  };
};
// For Loading A/C Types based on operator
export const loadAcMappingPending = (state: FilterState) => ({
  ...state,
  isFilterLoading: true,
});
export const loadAcMappingRejected = (state: FilterState, action:FilterAPIRejectedAction) => {
  return {
    ...state,
    isFilterLoading: false,
    errorMessage: action.payload?.message,
    operators_ac_mapping: POPUPFILTER_INITIAL_STATE.operators_ac_mapping,
    part_operators_ac_mapping: POPUPFILTER_INITIAL_STATE.part_operators_ac_mapping,
    status: action.payload?.ResponseMetadata?.HTTPStatusCode,
  };
};
export const loadAcMappingFullfilled = (state: FilterState, action:FilterAction) => {
  const acTypeResp = action.payload?.records?.map((opsAc: any) => opsAc?.aircraft_type);
  const acTypeUnique = Array.from(new Set(acTypeResp));
  const acSubType:any = {};
  acTypeUnique.forEach((acType:any) => {
    const acTypeSubTypeFiltered = action.payload?.records?.filter((acTypeObj:any) => (acTypeObj.aircraft_type === acType));
    acSubType[acType] = acTypeSubTypeFiltered?.map((acSubFilObj:any) => acSubFilObj.aircraft_model);
  });
  if (action.meta?.arg === 0) {
    return {
      ...state,
      isFilterLoading: false,
      part_operators_ac_mapping: acTypeUnique,
      part_operators_acsubType_mapping: acSubType,
    };
  }
  return {
    ...state,
    isFilterLoading: false,
    operators_ac_mapping: acTypeUnique,
    operators_acsubType_mapping: acSubType,
  };
};
export const loadFinPending = (state: FilterState) => ({
  ...state,
  isFilterLoading: true,
});

export const loadFinRejected = (state: FilterState, action : FilterAPIRejectedAction) => {
  return {
    ...state,
    isFilterLoading: false,
    errorMessage: action.payload?.message,
    fin: POPUPFILTER_INITIAL_STATE.fin,
  };
};

export const loadFinFullfilled = (state: FilterState, action : FilterAction) => {
  const finResp = action.payload?.data.map((item:any) => item.fin);
  return {
    ...state,
    isFilterLoading: false,
    fin: finResp,
  };
};

export const loadPnrPending = (state: FilterState) => ({
  ...state,
  isFilterLoading: true,
});

export const loadPnrRejected = (state: FilterState, action : FilterAPIRejectedAction) => {
  return {
    ...state,
    isFilterLoading: false,
    errorMessage: action.payload?.message,
    pnr: POPUPFILTER_INITIAL_STATE.pnr,
  };
};

export const loadPnrFullfilled = (state: FilterState, action : FilterAction) => {
  const pnrResp = action.payload?.data.map((item:any) => item.pnr);
  return {
    ...state,
    isFilterLoading: false,
    pnr: pnrResp,
  };
};

export const loadPartAircraftDetailsPending = (state: FilterState) => ({
  ...state,
  isFilterLoading: true,
});

export const loadPartAircraftDetailsRejected = (state: FilterState, action:FilterAPIRejectedAction) => {
  return {
    ...state,
    isFilterLoading: false,
    errorMessage: action.payload?.message,
    part_msns: POPUPFILTER_INITIAL_STATE.part_msns,
    part_operators_ac_mapping: POPUPFILTER_INITIAL_STATE.part_operators_ac_mapping,
    part_operators_acsubType_mapping: POPUPFILTER_INITIAL_STATE.part_operators_acsubType_mapping,
    datasource: POPUPFILTER_INITIAL_STATE.datasource,
  };
};

export const loadPartAircraftDetailsFullfilled = (state: FilterState, action : FilterAction) => {
  const partmsnsResp = action.payload?.data;
  const selectedOperator = action.meta?.arg;
  // Calling Restructure functionality to fetch AC and AC Sub type sperately
  const restructuredResponse = getRestructuredResponseOfOperatorMSN(partmsnsResp, selectedOperator);
  return {
    ...state,
    isFilterLoading: false,
    part_data_selection_columns: { ...state.part_data_selection_columns, ...restructuredResponse.finalRes },
    part_operators_ac_mapping: restructuredResponse.formattedRes.aircraft_types,
    part_operators_acsubType_mapping: restructuredResponse.formattedRes.aircraft_family,
  };
};

export const loadSbAircraftDetailsPending = (state: FilterState) => ({
  ...state,
  isFilterLoading: true,
});

export const loadSbAircraftDetailsRejected = (state: FilterState, action:FilterAPIRejectedAction) => {
  return {
    ...state,
    isFilterLoading: false,
    errorMessage: action.payload?.message,
    msns: POPUPFILTER_INITIAL_STATE.msns,
    operators_ac_mapping: POPUPFILTER_INITIAL_STATE.operators_ac_mapping,
    operators_acsubType_mapping: POPUPFILTER_INITIAL_STATE.operators_acsubType_mapping,
  };
};

export const loadSbAircraftDetailsFullfilled = (state: FilterState, action : FilterAction) => {
  const sbmsnsResp = action.payload?.data;
  const selectedOperator = action.meta?.arg.selectedOperator;
  // Calling Restructure functionality to fetch AC and AC Sub type sperately
  if (action.meta?.arg.tab === 'acm') {
    const restructuredResponse = getRestructuredResponseOfACMOperatorMSN(sbmsnsResp, selectedOperator);
    return {
      ...state,
      isFilterLoading: false,
      acm_selection_columns: { ...state.acm_selection_columns, ...restructuredResponse.finalRes },
      acm_operators_ac_family: restructuredResponse.formattedRes.aircraft_program_code,
      acm_operators_ac_mapping: restructuredResponse.formattedRes.aircraft_types,
      acm_operators_acsubType_mapping: restructuredResponse.formattedRes.aircraft_family,
    };
  }

  const restructuredResponse = getRestructuredResponseOfOperatorMSN(sbmsnsResp, selectedOperator);
  return {
    ...state,
    isFilterLoading: false,
    sb_data_selection_columns: { ...state.sb_data_selection_columns, ...restructuredResponse.finalRes },
    operators_ac_mapping: restructuredResponse.formattedRes.aircraft_types,
    operators_acsubType_mapping: restructuredResponse.formattedRes.aircraft_family,
  };
};
