interface StatusMapping {
  [key: string]: 'In Progress' | 'Failed' | 'Success' | 'InProgress';
}
export const DOWNLOADSTATUS_SLICE = 'downloadStatus';

export const DOWNLOADSTATUS_INITIAL_STATE: DownloadsState = {
  downloadStatus: [],
  notificationCount: 0,
  isDownloadDrawerClose: false,
  errorMessage: '',
  isPollingDone: false,
  startDownload: false,
  fileSuccess: false,
};

export const GET_HASHID = 'getHashId';
export const GET_PRESIGNED_URL = 'getPresignedUrl';
export const LOAD_EXPORT_FILE = 'loadDataExportFile';
export const GET_DOWNLOAD_STATUS = 'getDownloadStatus';
export const DOWNLOAD_STATUS_DB_MAP: StatusMapping = {
  InProgress: 'In Progress',
  Fail: 'Failed',
  Success: 'Success',
};
