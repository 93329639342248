/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-unresolved */
import {
  Button,
  Col,
  ColorModeProvider, Container, Divider, IconButton, Popover, Row, Typography,
} from '@airbus/components-react';
import { useRef, useState } from 'react';
import { Help } from '@airbus/icons/react';
import UseOutsideClick from '../Shared/OutsideClick/UseOutsideClick';
import {
  AIRBUS_NOTICE,
  AIRBUS_NOTICE_LINK,
  APP_FOOTER_VERSION,
  HELP_CENTRE,
  HELP_CENTRE_URL,
} from '../../utils/constants';
import './UserHelp.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { getDisclaimerStatus } from '../../utils/commonMethods/generalMethods';
import { isRequiredForEnv } from '../../utils/commonUtil';
import { USER_GUIDE, USER_GUIDE_FILE_NAME } from '../../models/appConfigModel/constants';
import { userGuide } from '../../models/appConfigModel/appConfigThunk';

const UserHelp: React.FC = function UserHelp() {
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const dispatcher = useAppDispatch();
  const userHelpGuide = useAppSelector((state) => state.app);
  const featureToggleConfig = useAppSelector((state) => state.featureToggleDetails?.data);
  const { userScope } = useAppSelector((state) => state.authEntrySlice);

  const handleSettingsClick = () => {
    window.open(HELP_CENTRE_URL[userScope]);
  };

  const handleHelpClick = async (clickSource: string) => {
    if (clickSource === USER_GUIDE) {
      const actionResult = await dispatcher(userGuide(USER_GUIDE_FILE_NAME)());
      const newGuideLink = actionResult?.payload?.download_file_link || userHelpGuide?.download_file_link;
      window.open(newGuideLink);
    } else {
      window.open(AIRBUS_NOTICE_LINK, 'WindowName', 'noopener');
    }
  };

  const onOpenHandler = () => {
    setOpen(!open);
  };

  UseOutsideClick(buttonRef, () => {
    setOpen(false);
  });
  return (
    <Container className="user-Help-Popover-Container">
      <IconButton
        ref={buttonRef}
        disabled={isRequiredForEnv(['pro', 'val']) ? !getDisclaimerStatus() : false}
        onClick={onOpenHandler}
        aria-haspopup="dialog"
        className="menu-button"
        data-testid="help-click"
      >
        <Help className="help-Style" />
      </IconButton>
      <ColorModeProvider mode="light">
        <Popover open={open} referenceNode={buttonRef.current} className="user-menu-popover" data-testid="usermenu-popover" placement="top">
          <Container>
            <Row>
              {featureToggleConfig?.helpCentre === 'false' && (
              <>
                <Col xxs={7}>
                  <Button className="help-centre" aria-label="user-menuguide" data-testid="userguide" onClick={() => handleHelpClick(USER_GUIDE)}>{USER_GUIDE}</Button>
                </Col>
                <Col xxs={7}>
                  <Button onClick={() => handleHelpClick(AIRBUS_NOTICE)} data-testid="airbusnotice" className="help-centre">{AIRBUS_NOTICE}</Button>
                </Col>
              </>
              )}
              {featureToggleConfig?.helpCentre === 'true' && (
              <Col xxs={6}>
                <Button className="help-centre" aria-label="help-centre" data-testid="help-centre-id" onClick={() => handleSettingsClick()}>{HELP_CENTRE}</Button>
              </Col>
              )}
            </Row>
          </Container>
          <Divider className="user-divider" />
          <Typography className="app-version" data-testid="version">{APP_FOOTER_VERSION}</Typography>
        </Popover>
      </ColorModeProvider>
    </Container>
  );
};
export default UserHelp;
