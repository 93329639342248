import { getPaginatedChunkData } from '../../utils/commonUtil';
import { DATATABLE_INITIAL_STATE, PART_DATA_ERROR } from './constants';

/**
 * The action type is not used here because there is only one action
 */
export const PartBrowserLoadPending = (
  state: PartDataTableState,
) => ({
  ...state,
  isBrowserDataLoading: true,
});

export const PartBrowserLoadRejected = (state: PartDataTableState, action?:FilterAPIRejectedAction) => {
  // if request comes from column level filter and no record found for the combination we are showing the previous state
  if (action?.meta.arg === 'columnLevelFilter') {
    return { ...state, isBrowserDataLoading: false };
  }
  return {
    ...state,
    isBrowserDataLoading: false,
    errorMessage: PART_DATA_ERROR,
    PartBrowserData: DATATABLE_INITIAL_STATE.PartBrowserData,
    paginationData: DATATABLE_INITIAL_STATE.paginationData,
    total_records: DATATABLE_INITIAL_STATE.total_records,
    records_offset: DATATABLE_INITIAL_STATE.records_offset,
    columns: DATATABLE_INITIAL_STATE.columns,
  };
};

export const PartBrowserLoadFullfilled = (state: PartDataTableState, action: PartDataTableAction) => {
  const calculatedPageNum = (action.payload.records_offset + state.paginationData.rowPerPage) / state.paginationData.rowPerPage;
  const prevPage = state.paginationData.page;
  const chunkData = getPaginatedChunkData(
    calculatedPageNum,
    action.payload.data,
    state.paginationData.rowPerPage,
    prevPage,
    action.payload.records_offset,
    action.payload.total_records,
  );
  return {
    ...state,
    isBrowserDataLoading: false,
    // Use only required columns
    PartBrowserData: action.payload.data,
    total_records: action.payload.total_records,
    // update the pagination config as per response count
    paginationData: {
      start: action.payload.records_offset,
      end: action.payload.records_offset + action.payload.data.length,
      rowPerPage: state.paginationData.rowPerPage,
      page: calculatedPageNum, // currently selected
      selectedChunkData: chunkData,
    },
  };
};
