/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Row, Inline, Spinner, Card, CardHeader, Chip, CardContent, CardTitle, Typography, Divider,
} from '@airbus/components-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import FleetLargerView from './FleetLargerView';
import FleetSmallerView from './FleetSmallerView';
import { chipConstants } from '../Shared/ChipLabel/constants';
import { FLEET_LARGER_VIEW_SIZE, FLEET_SMALLER_VIEW_SIZE } from '../../utils/constants';
import { useAppDispatch } from '../../store/hooksTypes';
import { setCheckerState } from '../../models/appConfigModel/appConfigSlice';
import {
  setCheckerDetailOperatorChanged, setCheckerDetailsInitialLoad, setFleetDetailFilter, updateCheckerDetailsColumnFilterPayload, updateCheckerDetailsColumnFilterState, updateCheckerFilterSelection, updateCheckerFiltersOptions,
} from '../../models/checkerDetailsModel/checkerDetailsSlice';
import {
  loadCheckerFleetFinAndAtaDetails,
} from '../../models/checkerDetailsModel/checkerDetailsThunk';
import {
  columnFilterStateUpdater,
  loadCheckerAcFamilyOptions, loadCheckerDetailsTable, loadCheckerFinAndAtaOptions,
} from '../../utils/commonUtil';

interface FleetStateProps {
  checkerFleetState : any;
  checkerDetailState : any;
}

const FleetInfiniteComponent: React.FC<FleetStateProps> = function FleetInfiniteComponent(props: FleetStateProps) {
  const { checkerFleetState, checkerDetailState } = props;
  const fleetCardDispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const [pageNumber, setpageNumber] = useState(1);
  const [key, setKey] = useState(0);
  const pageSize = checkerFleetState.selectedView === 'larger' ? FLEET_LARGER_VIEW_SIZE : FLEET_SMALLER_VIEW_SIZE;
  const loadDataLength = data.length;
  const checkerDataLength = checkerFleetState.visibleData.length;

  const fetchMoreData = () => {
    const recordsToShow = pageNumber * pageSize;
    const selectedOperatorData = checkerFleetState.data[checkerFleetState.fleet_view_filter_selected.selected_operator[0]] || [];
    const endIndex = Math.min(recordsToShow, selectedOperatorData.length);
    const fleetData = checkerFleetState.visibleData.slice(0, endIndex);
    setData(fleetData);
    setpageNumber(pageNumber + 1);
  };
  useEffect(() => {
    fetchMoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, checkerFleetState.visibleData]);

  useEffect(() => {
    setData([]);
    setpageNumber(1);
    setKey((preKey) => preKey + 1);
  }, [checkerFleetState.selectedView]);

  const fleetChip = (value: any) => {
    return chipConstants[value]?.chipVariant;
  };
  const cardClickDispatch = (fleetDetails:any) => {
    fleetCardDispatch(setCheckerState({ selectedCheckerTab: 1 }));
    fleetCardDispatch(setCheckerDetailsInitialLoad({ ...checkerDetailState, checkerDetailsInitialLoad: false }));
    fleetCardDispatch(updateCheckerFiltersOptions({
      operator_code_icao: checkerFleetState.fleet_view_filters?.operators_list,
      ac_type: checkerFleetState.fleet_view_filters?.ac_type_list,
      ac_sub_type: checkerFleetState.fleet_view_filters?.ac_sub_type_list,
      id_aircraft_registration: checkerFleetState.fleet_view_filters?.msn_list,
    }));
    fleetCardDispatch(updateCheckerFilterSelection({
      operator_name: fleetDetails.operator_code_icao,
      selected_msn: [fleetDetails.id_aircraft_registration],
      aircraft_type: [fleetDetails.ac_type],
      aircraft_family: fleetDetails.ac_family,
      aircraft_subtype: [fleetDetails.ac_subtype],
      selected_ata: [],
      selected_fin: [],
    }));
    const filterValue = {
      operator_name: fleetDetails.operator_code_icao,
      selected_msn: [fleetDetails.id_aircraft_registration],
      aircraft_type: [fleetDetails.ac_type],
      aircraft_family: fleetDetails.ac_family,
      aircraft_subtype: [fleetDetails.ac_subtype],
      selected_ata: [],
      selected_fin: [],
    };
    fleetCardDispatch(setCheckerDetailOperatorChanged({ ...checkerDetailState, ifOperatorChanged: false, ifAircraftTypeChanged: false }));
    fleetCardDispatch(setFleetDetailFilter({ ...checkerDetailState, fleet_detail_filter_selected: filterValue }));
    // to reset column level filter
    const filterDispatchParam: string[] = [];
    columnFilterStateUpdater(fleetCardDispatch, filterDispatchParam, filterDispatchParam, updateCheckerDetailsColumnFilterState, updateCheckerDetailsColumnFilterPayload, filterDispatchParam);
    // to get the options in all the dataselection dropdown
    const selectedOperatorData = checkerFleetState.data[checkerFleetState.fleet_view_filter_selected?.selected_operator[0]];
    loadCheckerAcFamilyOptions(fleetDetails.operator_code_icao, fleetCardDispatch, selectedOperatorData);
    loadCheckerFinAndAtaOptions(fleetDetails.operator_code_icao, [fleetDetails.ac_type], [fleetDetails.id_aircraft_registration], fleetCardDispatch, loadCheckerFleetFinAndAtaDetails);
    loadCheckerDetailsTable({
      operator: fleetDetails.operator_code_icao,
      aircraft: [fleetDetails.ac_type],
      aircraft_subtype: [fleetDetails.ac_subtype],
      msn: [fleetDetails.id_aircraft_registration],
      fin: [],
      ata: [],
      fin_status: [],
      status_part_check: [],
      acr_source: [],
      mis_source: [],
      acr_source_status: [],
      mis_source_status: [],
      dispatch: fleetCardDispatch,
    });
  };
  return (
    <InfiniteScroll className="infinite-scroll" dataLength={loadDataLength} next={fetchMoreData} hasMore={loadDataLength < checkerDataLength} loader={<Spinner className="spinner-lazyload" size="medium" label="Loading..." />}>
      <Inline spacing="3-x" wrap>
        {data && data.map((fleetDetails: any, index: number) => (
          <Card
            translate
            key={fleetDetails.id_aircraft_registration}
            className="card-action"
            data-testid={`card-action-${index + 1}`}
            onClick={() => {
              cardClickDispatch(fleetDetails);
            }}
          >
            <CardHeader
              className="fleet-card-header"
              title={fleetDetails.registration_number}
              action={(
                <Chip label={fleetDetails.discrepency_result} size="xsmall" variant={fleetChip(fleetDetails.discrepency_result)} className={`fleet-chip-wrong-color fleet-action-icon ${fleetChip(fleetDetails.discrepency_result)} `} />
              )}
            />
            <CardContent className="fleet-card-content">
              <CardTitle className="fleet-card-title">
                <Typography variant="small">
                  <Row>
                    {` ${fleetDetails.ac_type} ${fleetDetails.id_aircraft}`}
                    <Divider vertical />
                    {fleetDetails.operator_code_icao}
                  </Row>
                </Typography>
              </CardTitle>
              {checkerFleetState.selectedView === 'larger' && <FleetLargerView fleetDetails={fleetDetails} />}
              {checkerFleetState.selectedView === 'smaller' && <FleetSmallerView fleetDetails={fleetDetails} />}
            </CardContent>
          </Card>
        ))}
      </Inline>
    </InfiniteScroll>
  );
};

export default FleetInfiniteComponent;
