import { MATCH, TO_BE_ANALYSED, WRONG } from '../../../utils/constants';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const chipConstants: any = {
  W: {
    chipVariant: 'warning',
    label: 'W',
    chipText: WRONG,
    customChipIcon: '',
    className: 'mismatch',
  },
  A: {
    chipVariant: 'info',
    label: 'A',
    chipText: TO_BE_ANALYSED,
    customChipIcon: '',
    className: 'tba',
  },
  M: {
    chipVariant: 'success',
    label: 'M',
    chipText: MATCH,
    customChipIcon: '',
    className: 'match',
  },
  check: {
    chipText: 'MIS/ACR match',
    customChipIcon: 'CheckCircleOutline',
    chipIcon: 'check',
  },
  cross: {
    chipText: 'MIS/ACR mismatch',
    customChipIcon: 'CancelOutline',
    chipIcon: 'cross',
  },
  Match: {
    chipText: 'MIS/ACR match',
    customChipIcon: 'CheckCircleOutline',
    chipIcon: 'Match',
  },
  Mismatch: {
    chipText: 'MIS/ACR mismatch',
    customChipIcon: 'CancelOutline',
    chipIcon: 'Mismatch',
  },
  NA: {
    chipText: 'only one data source is available',
    text: 'N/A:',
    chipIcon: 'NA',
  },
};
