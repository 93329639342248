import { getPaginatedChunkData } from '../../utils/commonUtil';

export const checkerDetailsDataPaginationReducer = (
  state: CheckerDetailsTableStateType,
  action: CheckerDetailsPaginationAction,
) => {
  const calculatedPageNum = action.payload.pageNumber;
  const prevPage = state.paginationData.page;
  const chunkData = getPaginatedChunkData(
    calculatedPageNum,
    state.checkerDetailsData.tableData,
    action.payload.rowPerPage,
    prevPage,
    state.paginationData.start,
    state.total_records,
  );
  return ({
    ...state,
    paginationData: {
      ...state.paginationData,
      page: action.payload.pageNumber,
      rowPerPage: action.payload.rowPerPage,
      selectedChunkData: chunkData,
    },
  });
};
