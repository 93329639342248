export const getCheckerFleetState = (state: CheckerFleetState) => {
  return {
    ...state,
  };
};

export const setFleetViewState = (state: CheckerFleetState, action: CheckerFleetAction) => {
  return {
    ...state,
    selectedView: action?.payload?.selectedView,
  };
};
export const setCheckerFleetInitialLoad = (state: CheckerFleetState, action: CheckerFleetAction) => {
  return {
    ...state,
    checkerFleetFilterInitialLoad: action.payload?.checkerFleetFilterInitialLoad,
  };
};
export const setFleetViewFilters = (state: CheckerFleetState, action: CheckerFleetAction) => {
  return {
    ...state,
    fleet_view_filter_selected: action?.payload?.fleet_view_filter_selected,
  };
};
export const setFleetViewFilteredData = (state: CheckerFleetState, action: CheckerFleetAction) => {
  return {
    ...state,
    visibleData: action?.payload?.visibleData,
  };
};
export const setFleetViewData = (state: CheckerFleetState, action: CheckerFleetAction) => {
  return {
    ...state,
    fleet_view_filters: action?.payload?.fleet_view_filters,
  };
};
export const setFleetViewInnerData = (state: CheckerFleetState, action: CheckerFleetAction) => {
  return {
    ...state,
    data: action?.payload?.data,
  };
};

export const setOperatorList = (state: CheckerFleetState, action: OperatorListAction) => {
  return {
    ...state,
    fleet_view_filters: {
      ...state.fleet_view_filters,
      operators_list: action.payload.operatorList,
    },
    fleet_view_filter_selected: {
      ...state.fleet_view_filter_selected,
      selected_operator: action.payload.operatorList.length > 0 ? new Array(action.payload.operatorList[0]) : [],
    },
  };
};
