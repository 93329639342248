import React from 'react';
import {
  Container,
  Row,
  Col,
  Typography,
  Inline,
} from '@airbus/components-react';
import './ConcurrentFilter.scss';
import { CONCURRENT_FILTER_TITLE } from './Utils/concurrentFilterConstants';
import { concurrentFilterArray } from './Utils/concurrentFilterUtils';

interface ConcurrentFilterprops {
  filterData: string
}

/* Component - Concurrent Tab Section  */
const ConcurrentFilter: React.FC<ConcurrentFilterprops> = function ConcurrentFilter(props: ConcurrentFilterprops) {
  const { filterData } = props;
  const msnData = JSON.parse(filterData);
  const concurrentFilterValue = concurrentFilterArray(msnData);
  return (
    <Container className="concurrentCard">
      <Row className="rowPadding">
        <Col>
          <Typography variant="medium" className="concurrentParagraph" data-testid="concurrentText">
            {CONCURRENT_FILTER_TITLE}
          </Typography>
        </Col>
        {concurrentFilterValue.map((item) => (
          <Col key={item.label} className="columnMargin">
            <Inline>
              <Typography variant="medium" className="concurrentLabel">
                {item.label}
              </Typography>
              <Typography className="concurrentText" variant="medium">{item.value}</Typography>
            </Inline>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

/* export the component */
export default ConcurrentFilter;
