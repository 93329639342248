import { createSlice } from '@reduxjs/toolkit';
import { EMAILNOTIFICATION_INITIAL_STATE, EMAILNOTIFICATION_SLICE } from './constants';
import { updateEmailNotificationStateReducer } from './emailNotificationReducer';

const emailNotificationSlice = createSlice({
  name: EMAILNOTIFICATION_SLICE,
  initialState: EMAILNOTIFICATION_INITIAL_STATE,
  reducers: {
    updateEmailNotification: updateEmailNotificationStateReducer,
  },
});

export const {
  updateEmailNotification,
} = emailNotificationSlice.actions;

export default emailNotificationSlice;
