/*
 * File: locale.ts
 * Airbus, 2024
 * Author: CMIS FE
 * Description: CMIS APP Locale
 * Copyright (C) 2023-2024 CMIS APP
 * This file is part of CMIS APP.
 *
 * CMIS APP is Aviation product:
 * Used in the latest version
 *
 * CMIS APP FE Code
 *
 * This belong to with CMIS APP.
 */
export const FEATURE_TOGGLE_INITIAL_STATE: FeatureToggleStateType = {
  isFeatureToggleLoading: false,
  errorMessage: '',
  data: { default_key: false },
};

export const FEATURE_TOGGLE_SLICE = 'featureToggleDetails';
export const GET_FEATURE_TOGGLE_STATUS = 'getFeatureToggleConfig';
export const GET_FEATURE_TOGGLE_STATUS_ERROR = 'Failed to load the feature toggle config';
