/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LOAD_GET_API_METHOD, LOAD_CHECKER_REASON_STATUS_MAPPING_API_PATH } from '../../utils/config/apiConfig';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { CustomThunkAction } from '../../utils/thunkUtils/thunkTypes';
import {
  REASON_STATUS_MAPPING_SLICE, LOAD_REASON_STATUS_MAPPING,
} from './constants';

const createCustomThunkCall = (action: CustomThunkAction, operatorName: string) => {
  const apiRequestObj = {
    path: LOAD_CHECKER_REASON_STATUS_MAPPING_API_PATH,
    method: LOAD_GET_API_METHOD,
    queryStringParameters: {
      operator_code_icao: operatorName,
    },
  };
  return createCustomAsyncThunk(action, apiRequestObj);
};

export const loadReasonStatusMapping: any = (operatorName: string) => {
  const customAction = {
    sliceName: REASON_STATUS_MAPPING_SLICE,
    actionType: LOAD_REASON_STATUS_MAPPING,
  };
  return createCustomThunkCall(customAction, operatorName);
};
