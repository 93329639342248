/* eslint-disable @typescript-eslint/no-explicit-any */
import { LOAD_POST_API_METHOD, LOAD_CHECKER_FLEET_API_PATH } from '../../utils/config/apiConfig';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { CustomThunkAction } from '../../utils/thunkUtils/thunkTypes';
import {
  LOAD_CHECKER_FLEET_DATA, CHECKER_FLEET_SLICE, LOAD_CHECKER_OPERATOR,
} from './constants';

const createCustomThunkCall = (action: CustomThunkAction, body: BodyPayLoadCheckerFleet) => {
  const apiRequestObj = {
    path: LOAD_CHECKER_FLEET_API_PATH,
    method: LOAD_POST_API_METHOD,
    body,
  };
  return createCustomAsyncThunk(action, apiRequestObj);
};

export const loadCheckerOperators: any = (payloadBody: BodyPayLoadCheckerFleet) => {
  const customAction = {
    sliceName: CHECKER_FLEET_SLICE,
    actionType: LOAD_CHECKER_OPERATOR,
  };
  return createCustomThunkCall(customAction, payloadBody);
};

export const loadCheckerFleetData: any = (payloadBody: BodyPayLoadCheckerFleet) => {
  const customAction = {
    sliceName: CHECKER_FLEET_SLICE,
    actionType: LOAD_CHECKER_FLEET_DATA,
  };
  return createCustomThunkCall(customAction, payloadBody);
};
