/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';
import LoadingWrapper from './LoadingWrapper/LoadingWrapper';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { clearLocalStoreWithCondition, setUser } from '../../utils/commonUtil';

function PrivateRoute() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(false);
  const [loading, setLoading] = useState<boolean | null>(true);
  // check parmissions in auth entry api
  const authDispatch = useAppDispatch();
  const authEntry = useAppSelector((state) => state.authEntrySlice);
  Hub.listen('auth', (data) => {
    // the user is redirected from onelogin
    const { payload } = data;
    switch (payload.event) {
      case 'signOut':
        clearLocalStoreWithCondition();
        setIsAuthenticated(false);
        break;
      case 'signIn':
        setIsAuthenticated(!!payload.data.username);
        break;
      case 'tokenRefresh':
        clearLocalStoreWithCondition();
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    // the users comes directly to signin
    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        setIsAuthenticated(!!user.username);
        /**
         * The Idea here is, try to get user permitted modules from local store first,
         * if not present, hit the auth entry api to fetch permissions and derive routes.
         * In case of signi in , there wont be any permissions, so auth entry will hit.
         * after getting all permitted modules only set the username and load the modules.
         */
        await setUser(authEntry, user, authDispatch);
      })
      .catch(() => {
        setIsAuthenticated(false);
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <LoadingWrapper loading={loading} isAuthenticated={isAuthenticated} />
  );
}

export default PrivateRoute;
