/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Col, Container, DataTable, Pagination, Row, Toggle,
  Typography,
} from '@airbus/components-react';
/* eslint-disable react/jsx-no-useless-fragment */
// eslint-disable-next-line import/no-unresolved
import { Column } from '@airbus/components-react/dist/components/DataTable';
import './Table.scss';
import ColumnLevelModal from '../ColumnLevelModal/ColumnLevelModal';
import ChangeOutput from '../ChangeOutput/ChangeOutput';
import ExportButton from '../Export/ExportButton';
import TimeInfoPopover from '../../CheckerDetails/TimeInfoPopover/TimeInfoPopover';
import { isRequiredForEnv } from '../../../utils/commonUtil';
import CheckerReportStatus from '../../CheckerReportStatus/CheckerReportStatus';
import { useAppSelector } from '../../../store/hooksTypes';
import { reportFilterLabels } from '../../../utils/TableUtils/ColumnUtils';
import { getFromLocalStore } from '../../../utils/commonMethods/generalMethods';

interface Tableprops {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableData: Array<any>;
  loadingupdate: boolean;
  showPagination: boolean;
  showFilterButton: boolean;
  showTimeInfo?: boolean;
  showChangeViewButton: boolean;
  showExportButton:boolean;
  tableClassName: string;
  filterProps?: any,
  changeOutputProps?: any;
  paginationProps?: any,
  headerColumns: Column;
  selectedExport?: any;
  exportType?: any;
  disableToolbarComponent?: boolean;
  showReportToggleStatus?: boolean;
  paramString?:any;
}
const Table = function Table(props: Tableprops) {
  const {
    tableData, loadingupdate, showPagination, showChangeViewButton, showExportButton, showFilterButton, filterProps, changeOutputProps, headerColumns, paginationProps, selectedExport, exportType, showTimeInfo, tableClassName, disableToolbarComponent, showReportToggleStatus, paramString,
  } = props;
  const [columns, setColumns] = useState([]);
  const [reportStatusChecked, setReportStatusChecked] = useState(false);
  const [showReportStatus, setShowReportStatus] = useState(false);
  const checkerDetailsState = useAppSelector((state) => state.displayCheckerDetailsTable);
  useEffect(() => {
    const columnsArr = filterProps.tableState.columns
      .filter((item: any) => item.hidden === false && item.id !== 'actions')
      .map((item: any) => item.id);
    setColumns(columnsArr);
  }, [filterProps.tableState.columns]);

  return (
    <div className="browserTable">
      <Container className={disableToolbarComponent ? 'grayout-background' : 'visible-background'}>
        <Row>
          <Col>
            {showPagination && (
              <Pagination
                className={`${reportStatusChecked ? 'cmi-pagination grayout-background' : 'cmi-pagination'}`}
                aria-label="cmi-table-pagination"
                page={paginationProps?.tableState.paginationData.page}
                rowsPerPageLabel="Row per page"
                  // eslint-disable-next-line no-unsafe-optional-chaining
                legend={`${((paginationProps?.tableState.paginationData.page - 1) * paginationProps?.tableState.paginationData.rowPerPage) + 1}-${Math.min(paginationProps?.tableState.paginationData.page * paginationProps?.tableState.paginationData.rowPerPage, paginationProps?.tableState.total_records) || 1} of ${paginationProps?.tableState.total_records || 1}`}
                count={paginationProps?.tableState.total_records}
                rowsPerPage={paginationProps?.tableState.paginationData.rowPerPage}
                onPageChange={(e, val) => {
                  return !loadingupdate && paginationProps?.handlePageChange(Number(val), 'selectedPagechange', paginationProps);
                }}
                onRowsPerPageChange={(e, val) => !loadingupdate && paginationProps?.handlePageChange(Number(val), 'selectedRowPerPage', paginationProps)}
              />
            )}
          </Col>
          {showReportToggleStatus && (
            <div className="report-toggle-div">
              <Toggle
                label="A/C Report Status"
                size="small"
                id="Report-Toggle"
                className="report-toggle"
                data-testid="checker-report-status"
                onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  setReportStatusChecked(checked);
                  setShowReportStatus(checked);
                }}
              />
            </div>
          )}
          {showFilterButton && (
            <div className={`${reportStatusChecked ? 'filterSelection grayout-background' : 'filterSelection'}`}>
              {tableData.length > 0 && <ColumnLevelModal tableProps={filterProps} />}
            </div>
          )}
          {showChangeViewButton && (
            <div className={`${reportStatusChecked ? 'changeOutput grayout-background' : 'changeOutput'}`}>
              <ChangeOutput tableProps={changeOutputProps} />
            </div>
          )}
          {showExportButton && (
            <div className={`${reportStatusChecked ? 'gray-out-toolbar' : 'exportButton'}`}>
              <ExportButton
                selectedExport={selectedExport}
                exportType={exportType}
                columnFilters={filterProps.tableState.FilterPayload}
                columns={columns}
                isConcurrentToggle={filterProps.tableState.concurrentToggle}
                isSbStatusToggle={filterProps.tableState.sbStatusViewToggle}
              />
            </div>
          )}
          {isRequiredForEnv(['dev', 'val']) && showTimeInfo && (
            <div className={`${reportStatusChecked ? 'exportButton grayout-background' : ''}`}>
              <TimeInfoPopover />
            </div>
          )}
        </Row>
      </Container>
      {!showReportStatus ? (
        <>
          {reportFilterLabels(paramString, checkerDetailsState)}
          <DataTable
            className={tableClassName}
            columns={headerColumns}
            loading={loadingupdate}
            data={tableData}
            allowResize={false}
            defaultRowsPerPage={Number(getFromLocalStore('apiPaginationSize'))}
          />
        </>
      ) : (
        <div>
          <div className="report-status-label">Filter applied do not affect the displayed results.</div>
          <CheckerReportStatus />
        </div>
      )}
    </div>
  );
};
Table.defaultProps = {
  filterProps: {
    tableState: {
      columns: [],
    },
  },
  disableToolbarComponent: false,
  showReportToggleStatus: false,
};
export default Table;
