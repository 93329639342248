/* eslint-disable @typescript-eslint/no-explicit-any */
import { LOAD_GET_API_METHOD, USER_GUIDE_API_PATH } from '../../utils/config/apiConfig';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { APP_SLICE, USER_GUIDE } from './constants';

// Thunk to update the userguide status
export const userGuide: any = (filenametoget: string) => {
  const customAction = {
    sliceName: APP_SLICE,
    actionType: USER_GUIDE,
  };
  const apiRequestObj = {
    path: USER_GUIDE_API_PATH,
    method: LOAD_GET_API_METHOD,
    queryStringParameters: {
      filename: filenametoget,
    },
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
