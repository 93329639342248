/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
export const updateDrawerStateReducer = (
  state: DownloadsState,
  action: DownloadDrawerAction,
) => ({
  ...state,
  isDownloadDrawerClose: action.payload,
});

export const incrementNotificationCountReducer = (
  state: DownloadsState,
  action: NotificationCountAction,
) => ({
  ...state,
  notificationCount: action.payload,
});

export const clearNotificationCountReducer = (state: DownloadsState) => ({
  ...state,
  notificationCount: 0,
});

export const clearHighlightReducer = (state: DownloadsState) => ({
  ...state,
  downloadStatus: JSON.parse(JSON.stringify(state.downloadStatus)).map(
    (element: DownloadStatus) => {
      element.is_highlighted = false;
      return element;
    },
  ),
});

export const setPollingStatus = (
  state: DownloadsState,
  action: PollStatus,
) => ({
  ...state,
  isPollingDone: action.payload,
});

export const updateDownloadStateReducer = (
  state: DownloadsState,
  action: DownloadDrawerAction,
) => ({
  ...state,
  startDownload: action.payload,
});
