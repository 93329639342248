/**
 * CMI-S.
 * Copyright (C) 2024
*/

/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { Container, Inline, Radio } from '@airbus/components-react';
import { RADIO_OPTIONS } from '../../../models/appConfigModel/constants';
import { setAcmSb } from '../../../models/appConfigModel/appConfigSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';

const AcmTypeSelection: React.FC = () => {
  const dispatch = useAppDispatch();
  const { selectedRadioButton: acmSb } = useAppSelector((state: any) => state.app);
  return (
    <Container>
      <Inline spacing="2-x">
        {RADIO_OPTIONS.map((option) => (
          <Radio
            name={option.value}
            key={option.value}
            value={option.value}
            label={option.label}
            checked={option.value === acmSb}
            data-testid="acm-sb-radio-button"
            onChange={() => {
              dispatch(setAcmSb(option.value));
            }}
          />
        ))}
      </Inline>
    </Container>
  );
};

export default AcmTypeSelection;
