/*
 * File: locale.ts
 * Airbus, 2024
 * Author: CMIS FE
 * Description: CMIS APP Locale
 * Copyright (C) 2023-2024 CMIS APP
 * This file is part of CMIS APP.
 *
 * CMIS APP is Aviation product:
 * Used in the latest version
 *
 * CMIS APP FE Code
 *
 * This belong to with CMIS APP.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  LOAD_GET_API_METHOD, POST_FEATURE_TOGGLE_API_PATH,
} from '../../utils/config/apiConfig';
import {
  FEATURE_TOGGLE_SLICE, GET_FEATURE_TOGGLE_STATUS,
} from './constants';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';

// Thunk to get the feature config
export const postFeatureToggleStatus: any = (operatorCode: string) => {
  const customAction = {
    sliceName: FEATURE_TOGGLE_SLICE,
    actionType: GET_FEATURE_TOGGLE_STATUS,
  };
  const apiRequestObj = {
    path: POST_FEATURE_TOGGLE_API_PATH,
    method: LOAD_GET_API_METHOD,
    queryStringParameters: {
      operator_code_icao: operatorCode,
    },
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
