export const updateReasonStatusMapping = (state: ReasonStatusMappingState, action: ReasonStatusMappingAction) => {
  return {
    ...state,
    loadReasonStatusMapping: false,
    data: {
      reason_key_mapper: action.payload.data?.reason_key_mapper,
      status_key_mapper: action.payload.data?.status_key_mapper,
    },
  };
};
