import {
  SideNav, Typography, SideNavSection, SideNavItem, Flex,
  BreadcrumbItem,
  Link,
  Breadcrumb,
  Container,
  Checkbox,
  Modal,
  Button,
  Inline,
} from '@airbus/components-react';
import React, { useState } from 'react';
import { Close } from '@airbus/icons/react';
import './NotificationSettings.scss';
import { Outlet } from 'react-router-dom';
import { GENERAL_SETTINGS, HOME, NOTIFICATIONS } from '../../utils/constants';
import { LOCALES } from '../../assets/locale';
import AppHeader from '../Shared/AppHeader/AppHeader';

const NotificationSettings: React.FC = function NotificationSettings() {
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(true);
  const [page, setPage] = useState('notificationPage');
  const handleOpen = () => {
    setOpen(true);
    setChecked(true);
  };
  const handleClose = () => {
    setOpen(false);
    setChecked(false);
  };
  const handleClick = () => {
    setOpen(false);
    setChecked(true);
  };
  return (
    <Container className="notification-container">
      <div>
        <AppHeader appName={LOCALES} />
        <Outlet />
      </div>
      <Flex style={{ height: 500, overflow: 'hidden' }}>
        <SideNav permanent theme="light" className="side-nav" aria-label="Main Navigation">
          <SideNavSection style={{ flex: 1 }}>
            <SideNavItem
              disabled
              selected={page === 'page1'}
              data-testid="side-nav-general"
            >
              {GENERAL_SETTINGS}
            </SideNavItem>
            <SideNavItem
              selected={page === 'notificationPage'}
              onSelected={() => setPage('notificationPage')}
              data-testid="side-nav-notifications"
              className="notification-selection"
            >
              <Typography variant="large" className="notification-text-content">{NOTIFICATIONS}</Typography>
            </SideNavItem>
          </SideNavSection>
        </SideNav>
        <div className="breadcrumb-container">
          <Breadcrumb className="breadcrumb-container-item">
            <BreadcrumbItem>
              <Link href="/" className="home-page">
                {HOME}
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem current>Settings</BreadcrumbItem>
          </Breadcrumb>
          <main className="main-container">
            <Typography variant="large" className="text-content">Notificatons</Typography>
            <div className="alert-container">
              <Typography variant="medium" className="text-content">Alert preference</Typography>
              <Typography variant="medium" className="text-notify">Choose how you want to be notified of activities.</Typography>
              <Checkbox label="Email(Daily)" className="email-checkbox" defaultChecked={checked} onChange={handleOpen} data-testid="email-checkbox" />
              <Container>
                <Modal
                  aria-labelledby="modal-title"
                  className="email-popup-container"
                  open={open}
                >
                  <div className="modal-container">
                    <Container className="email-container">
                      <div className="email-header-section">
                        <Inline>
                          <Typography variant="medium">Disable Email notification</Typography>
                          <Close className="email-close" onClick={handleClose} />
                        </Inline>
                      </div>
                      <div className="modal-container-outer">
                        <div className="modal-container-card">
                          <div className="modal-content">
                            Are you sure you want to disable the email notification?
                          </div>
                        </div>
                      </div>
                      <Inline spacing="2-x" align="end" className="email-buttons">
                        <Button size="small" onClick={handleClick} data-testid="email-no-button">No</Button>
                        <Button tabIndex={-3} size="small" variant="error" onClick={handleClose} data-testid="email-disable-button">Disable</Button>
                      </Inline>
                    </Container>
                  </div>
                </Modal>
              </Container>
            </div>
          </main>
        </div>
      </Flex>
    </Container>
  );
};
export default NotificationSettings;
