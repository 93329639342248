import { REPORT_STATUS_INITIAL_STATE } from './constants';

export const reportStatusChartAcrPending = (state: ReportStatusState) => ({
  ...state,
  isAcrChartDataLoading: true,
});

export const reportStatusChartAcrRejected = (state: ReportStatusState, action: FilterAPIRejectedAction) => {
  return {
    ...state,
    isAcrChartDataLoading: false,
    reloadAcrChartData: true,
    errorMessage: action.payload?.message,
    AcrChartData: REPORT_STATUS_INITIAL_STATE.AcrChartData,
    status: action.payload?.ResponseMetadata?.HTTPStatusCode,
  };
};

export const reportStatusChartAcrFullFilled = (state: ReportStatusState, action: ReportStatusMisAcrSourceAction) => {
  return {
    ...state,
    isAcrChartDataLoading: false,
    reloadAcrChartData: false,
    AcrChartData: action.payload.result,
  };
};

export const reportStatusChartMisPending = (state: ReportStatusState) => ({
  ...state,
  isMisChartDataLoading: true,
});

export const reportStatusChartMisRejected = (state: ReportStatusState, action: FilterAPIRejectedAction) => {
  return {
    ...state,
    isMisChartDataLoading: false,
    reloadMisChartData: true,
    errorMessage: action.payload?.message,
    MisChartData: REPORT_STATUS_INITIAL_STATE.MisChartData,
    status: action.payload?.ResponseMetadata?.HTTPStatusCode,
  };
};

export const reportStatusChartMisFullFilled = (state: ReportStatusState, action: ReportStatusMisAcrSourceAction) => {
  return {
    ...state,
    isMisChartDataLoading: false,
    reloadMisChartData: false,
    MisChartData: action.payload.result,
  };
};

export const reportStatusChartFinStatusPending = (state: ReportStatusState) => ({
  ...state,
  isFinStatusChartDataLoading: true,
});

export const reportStatusChartFinStatusRejected = (state: ReportStatusState, action: FilterAPIRejectedAction) => {
  return {
    ...state,
    isFinStatusChartDataLoading: false,
    reloadFinStatusChartData: true,
    errorMessage: action.payload?.message,
    FinStatusChartData: REPORT_STATUS_INITIAL_STATE.FinStatusChartData,
    status: action.payload?.ResponseMetadata?.HTTPStatusCode,
  };
};

export const reportStatusChartFinStatusFullFilled = (state: ReportStatusState, action: ReportStatusAction) => {
  return {
    ...state,
    isFinStatusChartDataLoading: false,
    reloadFinStatusChartData: false,
    FinStatusChartData: action.payload.result[0],
  };
};

export const reportStatusChartPnrSourcePending = (state: ReportStatusState) => ({
  ...state,
  isPnrSourceChartDataLoading: true,
});

export const reportStatusChartPnrSourceRejected = (state: ReportStatusState, action: FilterAPIRejectedAction) => {
  return {
    ...state,
    isPnrSourceChartDataLoading: false,
    reloadPnrSourceComparisonChartData: true,
    errorMessage: action.payload?.message,
    PnrSourceComparisonChartData: REPORT_STATUS_INITIAL_STATE.PnrSourceComparisonChartData,
    status: action.payload?.ResponseMetadata?.HTTPStatusCode,
  };
};

export const reportStatusChartPnrSourceFullFilled = (state: ReportStatusState, action: ReportStatusPnrSourceAction) => {
  return {
    ...state,
    isPnrSourceChartDataLoading: false,
    reloadPnrSourceComparisonChartData: false,
    PnrSourceComparisonChartData: action.payload.result[0],
  };
};
