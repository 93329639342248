/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuCell, tableInCellRows } from '../../../../utils/TableUtils/ColumnUtils';
import { AirNavxCell } from '../../../Airnavx/AirNavxCell';

const falseVal = false;
const trueVal = true;
const width = 93;

export const actionColumns = [{
  Header: 'Actions',
  id: 'actions',
  Cell: MenuCell,
  width: 1,
  disableSortBy: trueVal,
  hidden: falseVal,
  align: 'left',
}];

export const tableColumns = [
  {
    Header: 'MSN-A/C Registration',
    id: 'id_aircraft_registration',
    accessor: 'id_aircraft_registration',
    width: 100,
    hidden: falseVal,
    fontWeight: 100,
    disableSortBy: trueVal,
    align: 'left',
  },
];

export const sbColumns = [
  {
    Header: 'ATA',
    id: 'ata_4d',
    accessor: 'ata_4d',
    hidden: falseVal,
    width: 30,
    disableSortBy: trueVal,
    align: 'left',
    filterMatch: 'STARTS_WITH',
  },
  {
    Header: 'MOD',
    id: 'mod_reference',
    accessor: 'mod_reference',
    width: 60,
    disableSortBy: trueVal,
    hidden: falseVal,
    align: 'left',
  },
  {
    Header: 'SB Ref.',
    accessor: 'sb_reference',
    id: 'sb_reference',
    width: 40,
    disableSortBy: trueVal,
    hidden: falseVal,
    align: 'left',
  },
  {
    Header: 'Status',
    id: 'sb_status',
    accessor: 'sb_status',
    width,
    disableSortBy: trueVal,
    hidden: falseVal,
    align: 'left',
  },
  {
    Header: 'SB Reporting Date',
    id: 'sb_reporting_date',
    accessor: 'sb_reporting_date',
    width: 70,
    disableSortBy: trueVal,
    hidden: falseVal,
    align: 'left',
  },
  {
    Header: 'Compliance',
    id: 'sb_classification',
    accessor: 'sb_classification',
    width,
    disableSortBy: trueVal,
    hidden: falseVal,
    Cell: (row: any) => tableInCellRows(row),
    align: 'left',
  },
  {
    Header: 'Nature',
    id: 'sb_function',
    accessor: 'sb_function',
    width,
    disableSortBy: trueVal,
    hidden: falseVal,
    align: 'left',
  },
  {
    Header: 'CONF',
    accessor: 'configuration_number',
    id: 'configuration_number',
    width: 35,
    disableSortBy: trueVal,
    hidden: falseVal,
    align: 'left',
  },
  {
    Header: 'MP',
    id: 'mp_reference',
    accessor: 'mp_reference',
    width: 63,
    disableSortBy: trueVal,
    hidden: trueVal,
    align: 'left',
  },
  {
    Header: 'MOD Title',
    id: 'mod_title',
    accessor: 'mod_title',
    width,
    disableSortBy: trueVal,
    Cell: (row: any) => tableInCellRows(row),
    hidden: trueVal,
    align: 'left',
  },
  {
    Header: 'SB Title',
    accessor: 'sb_title',
    id: 'sb_title',
    width: 150,
    disableSortBy: trueVal,
    condensed: trueVal,
    Cell: (row: any) => tableInCellRows(row),
    hidden: trueVal,
    align: 'left',
  },
  {
    Header: 'SB Alert',
    id: 'alert',
    accessor: 'alert',
    width: 30,
    disableSortBy: trueVal,
    hidden: trueVal,
    align: 'left',
  },
  {
    Header: 'MP Title',
    id: 'mp_title',
    accessor: 'mp_title',
    width,
    disableSortBy: trueVal,
    Cell: (row: any) => tableInCellRows(row),
    hidden: trueVal,
    align: 'left',
  },
  {
    Header: 'Operator',
    id: 'operator_code_icao',
    accessor: 'operator_code_icao',
    width: 80,
    disableSortBy: trueVal,
    hidden: trueVal,
    align: 'left',
  },
];

export const partColumns = [
  {
    Header: 'ATA',
    id: 'ata',
    accessor: 'ata',
    hidden: falseVal,
    width: 30,
    disableSortBy: trueVal,
    align: 'left',
    filterMatch: 'STARTS_WITH',
  },
  {
    Header: 'PNR Type',
    id: 'part_type',
    accessor: 'part_type',
    width: 65,
    disableSortBy: trueVal,
    hidden: trueVal,
    align: 'left',
  },
  {
    Header: 'FIN',
    accessor: 'fin',
    id: 'fin',
    width: 36,
    disableSortBy: trueVal,
    Cell: (row: any) => AirNavxCell(row),
    hidden: falseVal,
    align: 'left',
  },
  {
    Header: 'Description',
    id: 'part_description',
    accessor: 'part_description',
    width,
    disableSortBy: trueVal,
    Cell: (row: any) => tableInCellRows(row),
    hidden: falseVal,
    align: 'left',
  },
  {
    Header: 'PNR',
    id: 'pnr',
    accessor: 'pnr',
    width: 36,
    disableSortBy: trueVal,
    Cell: (row: any) => AirNavxCell(row),
    hidden: falseVal,
    align: 'left',
  },
  {
    Header: 'S/N',
    id: 'serial_number',
    accessor: 'serial_number',
    width: 80,
    disableSortBy: trueVal,
    hidden: falseVal,
    align: 'left',
  },
  {
    Header: 'Reported Date',
    id: 'reported_date',
    accessor: 'reported_date',
    width,
    disableSortBy: trueVal,
    hidden: falseVal,
    align: 'left',
  },
  {
    Header: 'Last Config Date',
    id: 'last_configuration_date',
    accessor: 'last_configuration_date',
    width,
    disableSortBy: trueVal,
    hidden: trueVal,
    align: 'left',
  },
  {
    Header: 'Installation Date',
    id: 'installation_date',
    accessor: 'installation_date',
    width,
    disableSortBy: trueVal,
    hidden: falseVal,
    align: 'left',
  },
  {
    Header: 'PNR Origin',
    id: 'pnr_origin',
    accessor: 'pnr_origin',
    width: 96,
    disableSortBy: trueVal,
    hidden: trueVal,
    align: 'left',
  },
  {
    Header: 'FIN Origin',
    id: 'fin_origin',
    accessor: 'fin_origin',
    width,
    disableSortBy: trueVal,
    hidden: trueVal,
    align: 'left',
  },
  {
    Header: 'FLS Classification',
    id: 'fls_classification',
    accessor: 'fls_classification',
    width,
    disableSortBy: trueVal,
    Cell: (row: any) => tableInCellRows(row),
    hidden: trueVal,
    align: 'left',
  },
  {
    Header: 'S/N Origin',
    id: 'serial_number_origin',
    accessor: 'serial_number_origin',
    width: 80,
    disableSortBy: trueVal,
    hidden: trueVal,
    align: 'left',
  },
  {
    Header: 'Deviation Description',
    id: 'deviation_description',
    accessor: 'deviation_description',
    width,
    disableSortBy: trueVal,
    Cell: (row: any) => tableInCellRows(row),
    hidden: trueVal,
    align: 'left',
  },
];
