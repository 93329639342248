/* CMI-S UI */
export const OPERATOR_AND = 'and';
export const OPERATOR_OR = 'or';
export const OPERATOR_NOT = 'not';
export const SCHEDULED = 'Scheduled';
export const REJECTED = 'Rejected';
export const FULLY_EMBODIED = 'Fully Embodied';
export const EMBODIED = 'Embodied';
export const DELIVERED = 'Delivered';
export const SB_NOT_APPLICABLE = 'At least one of the SB’s in this expression are not applicable for this MSN';
export const SB_NOT_APPLICABLE_FOOTER = 'This list of SB(s) are not applicable for this MSN';
export const EXPRESSION = 'Expression';
