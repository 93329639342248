/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import {
  Container, Inline, Spinner, Typography,
} from '@airbus/components-react';
import Table from '../../Table/Table';
import './BrowserTab.scss';
import FilterPanel from '../../FilterPanel/FilterPanel';
import { BROWSER, NO_RECORDS_FOUND, NO_TABLE_RECORDS_FOUND } from '../../../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../../../store/hooksTypes';
import {
  setModalToggle,
} from '../../../../models/popupFilterModel/popupFilterSlice';
import {
  actionColumns,
  tableColumns,
  sbColumns,
  partColumns,
} from './BrowserTableStructure';
import {
  handleChangeOutputApply, handleSbPageChange, handlePartDataSelectionValidate, handleSbDataSelectionValidate, handlePartPageChange, isRequiredForEnv,
  registerPostHogProperties,
} from '../../../../utils/commonUtil';
import {
  updateSBDataPagination, updateSbColumnFilterPayload, updateSbColumnFilterState, updateTableColumns,
} from '../../../../models/sbDataTableModel/sbDataTableSlice';
import { loadSbBrowseData, loadSbFilterData } from '../../../../models/sbDataTableModel/sbDataTableThunk';
import { loadPartBrowseData, loadPartFilterData } from '../../../../models/partDataTableModel/partDataTableThunk';
import {
  updatePARTDataPagination, updatePartColumnFilterPayload, updatePartColumnFilterState, updatePartTableColumns,
} from '../../../../models/partDataTableModel/partDataTableSlice';
import {
  getDataFromPartChild, getDataFromSbChild, handlePartFilterColumnPayload, handleSbFilterColumnPayload,
} from '../../ColumnLevelModal/Utils/ColumnLevelhandleUtils';

const BrowserTab: React.FC = function BrowserTab() {
  const isModalOpen = useAppSelector((state) => state.popUpFilter.modalToggle);
  const browserDispatcher = useAppDispatch();
  const selectedBrowserTab = useAppSelector((state) => state.app.selectedTab);
  const popupFilterState = useAppSelector((state) => state.popUpFilter);
  const partdataTableState = useAppSelector((state) => state.displayPartDataTable);
  const partloadingupdate = useAppSelector((state) => state.displayPartDataTable.isBrowserDataLoading);
  const sbdataTableState = useAppSelector((state) => state.displaySbDataTable);
  const sbloadingupdate = useAppSelector((state) => state.displaySbDataTable.isBrowserDataLoading);
  const { isLoading } = useAppSelector((state) => state.authEntrySlice);
  let isInitialPopUp;
  let selectedPopupFilters: any;
  let selectionProps: any;
  let changeOutputprops: any;
  let filterProps: any;
  let paginationProps: any;
  let allColumns;
  let dataTableState: any;
  let loadingupdate: any;
  let exportType: any;
  // Removing these header values from table whenever sbToggle is ON
  const idToRemove = ['mod_reference', 'mod_title', 'mp_reference', 'mp_title'];
  const filtered: any = sbColumns.filter((item) => !idToRemove.includes(item.id));
  if (selectedBrowserTab === 0) {
    dataTableState = partdataTableState;
    loadingupdate = partloadingupdate;
    isInitialPopUp = popupFilterState.partBrowseInitialLoad;
    selectedPopupFilters = popupFilterState.part_filter_selection_configuration;
    exportType = 'PART';
    selectionProps = {
      sourceTab: BROWSER,
      sourceOfHelpClick: 'partFilterHelpIcon',
      filterState: popupFilterState,
      selectedFilterState: selectedPopupFilters,
      msn: popupFilterState.part_msns,
      aircraft: popupFilterState.part_operators_ac_mapping,
      aircraftSubType: popupFilterState.part_operators_acsubType_mapping,
      isInitialLoad: isInitialPopUp,
      apiDispatcher: loadPartBrowseData,
      dispatcher: browserDispatcher,
      tableState: dataTableState,
      additionalMethods: { updatePartTableColumns },
      handleValidate: handlePartDataSelectionValidate,
      updateColumnFilterState: updatePartColumnFilterState,
      updateColumnFilterPayload: updatePartColumnFilterPayload,
      part_data_selection_columns: popupFilterState.part_data_selection_columns,
    };
    allColumns = [...tableColumns, ...partColumns];
    changeOutputprops = {
      tableState: dataTableState,
      selectedFilterState: selectedPopupFilters,
      tableStateColumns: dataTableState.columns,
      allColumns,
      updateTableReducer: updatePartTableColumns,
      dispatcher: browserDispatcher,
      handleChangeOutputApply,
    };
    filterProps = {
      tableState: dataTableState,
      popupFilterState,
      tableStateColumns: dataTableState.columns,
      dispatcher: browserDispatcher,
      browserThunk: loadPartBrowseData,
      filterThunk: loadPartFilterData,
      filterPayloadFn: handlePartFilterColumnPayload,
      getDataPayloadFn: getDataFromPartChild,
      updateColumnFilterState: updatePartColumnFilterState,
      updateColumnFilterPayload: updatePartColumnFilterPayload,
    };
    paginationProps = {
      sourceTab: BROWSER,
      tableState: dataTableState,
      selectedPopupFilters,
      updateTableDataReducer: loadPartBrowseData,
      updatePaginationReducer: updatePARTDataPagination,
      dispatcher: browserDispatcher,
      handlePageChange: handlePartPageChange,
    };
  } else {
    const filteredTableModCol = sbdataTableState.columns.filter((item) => !idToRemove.includes(item.id));
    const sbStatusTableState = { ...sbdataTableState };
    sbStatusTableState.columns = filteredTableModCol;
    if (sbdataTableState.sbStatusViewToggle) {
      dataTableState = sbStatusTableState;
    } else {
      dataTableState = sbdataTableState;
    }
    loadingupdate = sbloadingupdate;
    isInitialPopUp = popupFilterState.sbBrowseInitialLoad;
    selectedPopupFilters = popupFilterState.sb_filter_selection_configuration;
    exportType = 'SB';
    selectionProps = {
      sourceTab: BROWSER,
      sourceOfHelpClick: 'sbFilterHelpIcon',
      filterState: popupFilterState,
      selectedFilterState: selectedPopupFilters,
      msn: popupFilterState.msns,
      aircraft: popupFilterState.operators_ac_mapping,
      aircraftSubType: popupFilterState.operators_acsubType_mapping,
      isInitialLoad: isInitialPopUp,
      dispatcher: browserDispatcher,
      apiDispatcher: loadSbBrowseData,
      updateColumnFilterState: updateSbColumnFilterState,
      updateColumnFilterPayload: updateSbColumnFilterPayload,
      tableState: sbStatusTableState,
      handleValidate: handleSbDataSelectionValidate,
      sb_data_selection_columns: popupFilterState.sb_data_selection_columns,
    };
    if (sbdataTableState.sbStatusViewToggle) {
      allColumns = [...actionColumns, ...tableColumns, ...filtered];
    } else {
      allColumns = [...actionColumns, ...tableColumns, ...sbColumns];
    }
    changeOutputprops = {
      tableState: dataTableState,
      selectedFilterState: selectedPopupFilters,
      tableStateColumns: dataTableState.columns,
      allColumns,
      updateTableReducer: updateTableColumns,
      dispatcher: browserDispatcher,
      handleChangeOutputApply,
    };
    filterProps = {
      tableState: dataTableState,
      popupFilterState,
      tableStateColumns: dataTableState.columns.slice(1),
      dispatcher: browserDispatcher,
      browserThunk: loadSbBrowseData,
      filterThunk: loadSbFilterData,
      filterPayloadFn: handleSbFilterColumnPayload,
      getDataPayloadFn: getDataFromSbChild,
      updateColumnFilterState: updateSbColumnFilterState,
      updateColumnFilterPayload: updateSbColumnFilterPayload,
    };
    paginationProps = {
      sourceTab: BROWSER,
      tableState: dataTableState,
      selectedPopupFilters,
      updateTableDataReducer: loadSbBrowseData,
      updatePaginationReducer: updateSBDataPagination,
      dispatcher: browserDispatcher,
      handlePageChange: handleSbPageChange,
    };
  }
  const { columns } = dataTableState;
  useEffect(() => {
    registerPostHogProperties({
      ICAO_CODE: selectedPopupFilters?.operator_name?.toString(),
    });
    browserDispatcher(setModalToggle({ modalToggle: selectionProps.isInitialLoad }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrowserTab]);
  const tableData = dataTableState.paginationData.selectedChunkData;
  const initialPopup = (isInitialPopUp: any) => {
    if (!isInitialPopUp && loadingupdate === false) {
      return (
        <Container until="sm" className={`${isModalOpen && 'table-disable'}`}>
          <Inline spacing="1-x">
            <Typography className="no-data-text">{NO_TABLE_RECORDS_FOUND}</Typography>
            <Typography variant="medium" className="no-records-text">{NO_RECORDS_FOUND}</Typography>
          </Inline>
        </Container>
      );
    }
    return (!isInitialPopUp && <Spinner size="small" className="spinner-container" />);
  };
  return (
    <>
      {' '}
      {isLoading ? (
        <Spinner size="medium" className="user-api-loading" />
      ) : (
        <Container className="browser-container">
          <Container className="filter-panel-container"><FilterPanel showDataSelectionButton filterProps={selectionProps} /></Container>
          {tableData.length > 0
            ? (
              <Container className="table-container">
                {!isInitialPopUp && (
                <Table
                  tableData={tableData}
                  loadingupdate={loadingupdate}
                  showPagination
                  showChangeViewButton
                  showFilterButton
                  filterProps={filterProps}
                  changeOutputProps={changeOutputprops}
                  headerColumns={columns}
                  paginationProps={paginationProps}
                  showExportButton={isRequiredForEnv(['dev', 'val'])}
                  selectedExport={selectedPopupFilters}
                  exportType={exportType}
                  tableClassName="sb-browser-data-table"
                />
                )}
              </Container>
            )
            : (initialPopup(isInitialPopUp))}
        </Container>
      )}
    </>
  );
};

export default BrowserTab;
