import { Download } from '@airbus/icons/react';

// Slice Name
export const TOAST_SLICE = 'toast';

// To map custom banner icon names
export const customToastIconMap: {[key:string]:React.FC<React.SVGProps<SVGSVGElement>>} = {
  DOWNLOAD: Download,
};

// To map location names
export const toastLocationMap = {
  GLOBAL: 'global',
  POPUP_FILTER: 'filter',
  SB_BROWSER: 'browser',
  CONCURRENT_FILTER: 'concurrent-filter',
  CHECKER: 'checker',
};
