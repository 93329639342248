import React, { useEffect } from 'react';

const UseOutsideClick = (ref: React.RefObject<HTMLElement>, callback:(event: MouseEvent | TouchEvent)=>void) => {
  const handleClick = (e:MouseEvent) => {
    e.stopPropagation();
    if (ref.current && !ref.current.contains(e.target as HTMLInputElement)) {
      callback(e);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default UseOutsideClick;
