export const getHelpModalToggleState = (state: HelpState) => {
  return {
    ...state,
  };
};

export const setHelpModalToggleState = (state: HelpState, action: HelpAction) => {
  return {
    ...state,
    helpModalState: action.payload.helpModalState,
    sourceOfHelpClick: action.payload.sourceOfHelpClick,
  };
};
