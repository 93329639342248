/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../store/hooksTypes';
import { addToast, removeToast } from '../../../models/toastModel/toastSlice';
import { NO_PERMISSION_TOAST, TOAST_TIMEOUT } from '../../../utils/constants';

const NoPermission = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    // show toast message
    dispatch(addToast({ ...NO_PERMISSION_TOAST, isToastShown: true }));
    setTimeout(() => {
      dispatch(removeToast({ ...NO_PERMISSION_TOAST, isToastShown: false }));
    }, TOAST_TIMEOUT);
  }, []);

  return (
    <div />
  );
};
export default NoPermission;
