import React from 'react';
import { Button } from '@airbus/components-react';
import { Settings } from '@airbus/icons/react';
import ModalPopup from '../Modal/ModalPopup';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import {
  setModalToggle,
} from '../../../models/popupFilterModel/popupFilterSlice';
import { clearFleetDetailSelectedFin } from '../../../models/checkerDetailsModel/checkerDetailsSlice';
import { setHelpModelToggle } from '../../../models/helpModel/helpModelSlice';
import { setLocalStore } from '../../../utils/commonMethods/generalMethods';

interface DataSelectionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectionProps: any;
}
export default function DataSelection(props: DataSelectionProps) {
  const { selectionProps } = props;
  const checkerDetailsState = useAppSelector((state) => state.displayCheckerDetailsTable);
  const dataSelectDispatch = useAppDispatch();
  return (
    <div className="dataSelectionPrimary">
      {(!selectionProps.isInitialLoad && (
      <Button
        size="medium"
        variant="primary"
        className="data-selection-button"
        data-testid="data-selection-button"
        disabled={selectionProps.isInitialLoad}
        onClick={() => {
          setLocalStore('showFilteredTable', JSON.stringify(false));
          // To deselect the checkboxes in checker details table
          dataSelectDispatch(clearFleetDetailSelectedFin(!checkerDetailsState.isClearSelection));
          dataSelectDispatch(setModalToggle({ modalToggle: true }));
          dataSelectDispatch(setHelpModelToggle({ helpModalState: false, sourceOfHelpClick: '' }));
        }}
        icon={<Settings />}
      >
        Data Selection
      </Button>
      ))}
      <ModalPopup filterProps={selectionProps} />
    </div>
  );
}
