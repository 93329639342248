import React from 'react';
import {
  Container,
  Modal,
} from '@airbus/components-react';
import { useAppSelector } from '../../../store/hooksTypes';
import DetailsContainer from './DetailsContainer';
import './DetailPopup.scss';

interface DetailProps {
  popupData: ReasonDetail;
}

const DetailsPopup = ({ popupData }: DetailProps) => {
  const togglePopUp = useAppSelector((state) => state.displayCheckerDetailsTable.showReasonDetail);
  return (
    <Container className="container-details-popup">
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={togglePopUp}
        className="reason-detail-modal"
        id="reason-detail-popup"
      >
        <div className="modal-container">
          <DetailsContainer data={popupData} />
        </div>
      </Modal>
    </Container>
  );
};
export default DetailsPopup;
