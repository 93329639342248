/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Table from '../../Shared/Table/Table';
import { deviationTableColumns } from './DeviationTableStructure';

interface DeviationTableProps {
  data: ReasonDetail | any;
}

const DeviationTable = ({ data }: DeviationTableProps) => {
  return (
    <Table
      data-testid="deviation-details-table"
      tableData={data}
      headerColumns={deviationTableColumns}
      showChangeViewButton={false}
      showExportButton={false}
      showFilterButton={false}
      tableClassName="checker-data-table"
      loadingupdate={false}
      showPagination={false}
    />
  );
};
export default DeviationTable;
