import React from 'react';
import SpinnerComponent from '../Spinner/SpinnerComponent';
import './Loader.scss';

interface Dataprops {
  isBlocking: boolean;
}

const Loader = (props: Dataprops) => {
  const { isBlocking } = props;

  return (
    <div data-testid="load-spinner" className="spinner">
      <div className={`spinner-container-${isBlocking ? 'blocking' : 'nonblocking'}`}>
        <div className="spinner-wrapper">
          <SpinnerComponent size="medium" label="loading..." />
        </div>
      </div>
    </div>
  );
};

export default Loader;
