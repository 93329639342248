import { createAsyncThunk } from '@reduxjs/toolkit';
import { Auth, API } from 'aws-amplify';
import { CustomThunkAction, APIRequestObj, APIError } from './thunkTypes';
import * as configs from '../../config';
import { NON_TOAST_PATH } from '../constants';
import { addRequiredBodyToPayload, generateToastNotification } from '../commonUtil';

// Description - createCustomAsyncThunk is a wrapper for createAsyncThunk function with inbuilt ability to make API request

/* createAsyncThunk
  *  It generates promise lifecycle action types based on the action type prefix that you pass
  *  Returns a thunk action creator that will run the promise callback
  *  Dispatch the lifecycle actions based on the returned promise
  *  Documentation - https://redux-toolkit.js.org/api/createAsyncThunk
*/

// Example
/*
    customAction: {
        sliceName: 'users',                     // MANDATORY
        actionType: 'addUsers'                  // MANDATORY
    }
*/
/*
     apiRequestObj: {
        method: 'get | post | del | put | del', // MANDATORY
        path: '/Users',                         // MANDATORY
        queryStringParameters: {                // OPTIONAL
            name: 'param'
        }
        body: {                                 // OPTIONAL
            key: value
        }
    }
*/

/* eslint-disable @typescript-eslint/no-explicit-any */
const handleError = (error: any, path: string, thunkApi: any) => {
  if (!NON_TOAST_PATH.some((str) => path.includes(str))) {
    if (error.response.status === 422 || error.response.status === 401) {
      generateToastNotification(error, path, thunkApi);
    } else if (path.includes('user')) {
      generateToastNotification(error, 'global', thunkApi);
    }
  }
  return thunkApi.rejectWithValue(error.message as APIError);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const createCustomAsyncThunk = (customAction: CustomThunkAction, apiRequestObj: APIRequestObj) => createAsyncThunk<any, void, { rejectValue: APIError }>(
  `${customAction.sliceName}/${customAction.actionType}`,
  async (unavailableInput: void, thunkApi) => {
    const {
      path, method, body, queryStringParameters,
    } = apiRequestObj;
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user?.signInUserSession?.idToken?.jwtToken;
      const request = {
        headers: {
          Authorization: token,
        },
        ...(queryStringParameters && { queryStringParameters }),
        ...(body && { body }),
      };
      const response = await API[method](configs.default().API.endpoints[0].name, path, request);
      if ((path.search(/\boperator\b/) >= 0 && response.records.length <= 0) || (path.search(/\bfleet\b/) >= 0 && body?.columns?.includes('operator_code_icao') && response.data.length <= 0)) {
        generateToastNotification(false, false, thunkApi);
      }
      return addRequiredBodyToPayload(body, response, queryStringParameters);
      /* eslint-disable @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      // Toast Message Should not be shown for SB Browser Table and part browser table when there is no data for provided payload
      return handleError(error, path, thunkApi);
    }
  },
);

export default createCustomAsyncThunk;
