/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Container, Row, Chip, Col, Typography,
} from '@airbus/components-react';
import './DependencyUtils.scss';
import {
  OPERATOR_AND, OPERATOR_NOT, OPERATOR_OR, SB_NOT_APPLICABLE_FOOTER,
} from './expressionConstants';
import { ColumnToolTip } from '../../../../../utils/TableUtils/ColumnUtils';

/* Get Color Code For The Expression Operator  */
export const getColorCode = (operator: string) => {
  if (operator === OPERATOR_AND) {
    return 'error';
  }
  if (operator === OPERATOR_OR) {
    return 'info';
  }
  if (operator === OPERATOR_NOT) {
    return 'warning';
  }
  return 'info';
};

export const getButtonColor = (operator: string) => {
  if (operator === OPERATOR_AND) {
    return 'chip-error';
  }
  if (operator === OPERATOR_OR) {
    return 'chip-info';
  }
  if (operator === OPERATOR_NOT) {
    return 'chip-warning';
  }
  return 'chip-info';
};

/* Text to uppercase function */
export const strToUpper = (operator: string) => operator.toUpperCase();

/* Expression Dependency Container  */
interface ExpressionDescription {
  description: string;
  status: string;
  sbReportingDate: string;
}
export const expressionDependency = (expressionDesc: ExpressionDescription, sbRef: string) => (
  <Container className="expression">
    <Row>
      <Col xxs={1} className="expressionValue">{sbRef}</Col>
      <Col xxs={8}>{ColumnToolTip({ value: expressionDesc.status })}</Col>
      <Col xxs={2} className="expressionDescription">{expressionDesc.description}</Col>
      <Col xxs={1}>{expressionDesc.sbReportingDate}</Col>
    </Row>
  </Container>
);

export const isMissingSB = (sb: any, concurrentSb: any) => {
  const isSbNotRelatedToMSN = false;
  if (typeof sb !== 'undefined') {
    const finalSb = sb.reduce((acc: any, item: any) => {
      return [...acc, ...item.sb];
    }, []);
    const concurrentDataSb = concurrentSb.length ? Object.keys(concurrentSb[0]) : [];
    return finalSb.length !== concurrentDataSb.length;
  }
  return isSbNotRelatedToMSN;
};

/* Function - Showing expression tab dynamically based on expression formula  */
export const getExpressions = (expressionFormula: any) => {
  const { operator, item, desc } = expressionFormula;
  const sbNotRelatedToMSN:any = [];
  return (
    <div className="expCardContainer">
      {
        item.map((exp: any, index: number) => (
          <div key={`exp_${index + 1}`}>
            <Container key={`expcont_${index + 1}`}>
              <div className="card parentCardContainer" key={exp.id}>
                {
                  exp.sb.map((sb: any, sbindex: number) => {
                    if (desc.length > 0 && desc[0][sb]) {
                      const expressionDesc = desc[0][sb];
                      return (
                        <div key={sb}>
                          <div className="card parentContainer">{expressionDependency(expressionDesc, sb)}</div>
                          {sbindex < exp.sb.length - 1 && (
                            <div className="childOperator">
                              <Chip label={strToUpper(exp.operator)} variant={getColorCode(exp.operator)} className={getButtonColor(exp.operator)} />
                            </div>
                          )}
                        </div>
                      );
                    }
                    sbNotRelatedToMSN.push(sb);
                    return '';
                  })
                }
              </div>
            </Container>
            <div className="parentOperator">{index < item.length - 1 ? <Chip label={strToUpper(operator)} variant={getColorCode(operator)} className={getButtonColor(operator)} /> : ''}</div>
          </div>
        ))
      }
      {(sbNotRelatedToMSN.length > 0) && (<Typography className="missing-sb">{`${sbNotRelatedToMSN.join(', ')} ${SB_NOT_APPLICABLE_FOOTER}` }</Typography>)}
    </div>
  );
};
