/* eslint-disable @typescript-eslint/no-explicit-any */
// Write Report Status Thunk when integrating with API response

import {
  LOAD_POST_API_METHOD, REPORT_STATUS_CHART_ACR_API_PATH, REPORT_STATUS_CHART_FIN_STATUS_API_PATH, REPORT_STATUS_CHART_MIS_API_PATH, REPORT_STATUS_CHART_PNR_SOURCE_API_PATH,
} from '../../utils/config/apiConfig';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import {
  LOAD_REPORT_STATUS_CHART_ACR, LOAD_REPORT_STATUS_CHART_FIN_STATUS, LOAD_REPORT_STATUS_CHART_MIS, LOAD_REPORT_STATUS_CHART_PNR_SOURCE,
} from '../checkerDetailsModel/constants';
import { REPORT_STATUS_SLICE } from './constants';

export const loadReportStatusChartAcr: any = (payload: BodyPayloadReportStatus) => {
  const customAction = {
    sliceName: REPORT_STATUS_SLICE,
    actionType: LOAD_REPORT_STATUS_CHART_ACR,
  };
  const apiRequestObj = {
    path: REPORT_STATUS_CHART_ACR_API_PATH,
    method: LOAD_POST_API_METHOD,
    body: payload,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const loadReportStatusChartMis: any = (payload: BodyPayloadReportStatus) => {
  const customAction = {
    sliceName: REPORT_STATUS_SLICE,
    actionType: LOAD_REPORT_STATUS_CHART_MIS,
  };
  const apiRequestObj = {
    path: REPORT_STATUS_CHART_MIS_API_PATH,
    method: LOAD_POST_API_METHOD,
    body: payload,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const loadReportStatusChartFinStatus: any = (payload: BodyPayloadReportStatus) => {
  const customAction = {
    sliceName: REPORT_STATUS_SLICE,
    actionType: LOAD_REPORT_STATUS_CHART_FIN_STATUS,
  };
  const apiRequestObj = {
    path: REPORT_STATUS_CHART_FIN_STATUS_API_PATH,
    method: LOAD_POST_API_METHOD,
    body: payload,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const loadReportStatusChartPnrSource: any = (payload: BodyPayloadReportStatus) => {
  const customAction = {
    sliceName: REPORT_STATUS_SLICE,
    actionType: LOAD_REPORT_STATUS_CHART_PNR_SOURCE,
  };
  const apiRequestObj = {
    path: REPORT_STATUS_CHART_PNR_SOURCE_API_PATH,
    method: LOAD_POST_API_METHOD,
    body: payload,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
