import { createSlice } from '@reduxjs/toolkit';
import { POPUPFILTER_SLICE, POPUPFILTER_INITIAL_STATE } from './constants';
import {
  loadOperatorsPending,
  loadOperatorsRejected,
  loadOperatorsFullfilled,
  loadModPending,
  loadModRejected,
  loadSbPending,
  loadSbRejected,
  loadSbFullfilled,
  loadModFullfilled,
  loadFinPending,
  loadFinRejected,
  loadFinFullfilled,
  loadPnrPending,
  loadPnrRejected,
  loadPnrFullfilled,
  loadPartAircraftDetailsRejected,
  loadPartAircraftDetailsPending,
  loadPartAircraftDetailsFullfilled,
  loadSbAircraftDetailsFullfilled,
  loadSbAircraftDetailsPending,
  loadSbAircraftDetailsRejected,
} from './popupFilterExtraReducers';
import {
  getSelectedFilters, setModalToggleState, setSBSelectedFilters, setPartSelectedFilters, setSbBrowseInitialLoad, setPartBrowseInitialLoad, getDataSourceForPartBrowser, getAcAndAcSubTypes, getMSNForSbBrowser, getMSNForPartBrowser, getAircraftTypesForACM,
} from './popupFilterReducers';
import {
  loadOperators, loadSb, loadMod, loadFin, loadPnr, loadPartAircraftDetails, loadSbAircraftDetails,
} from './popupFilterThunks';

export const popupFilterSlice = createSlice({
  name: POPUPFILTER_SLICE,
  initialState: POPUPFILTER_INITIAL_STATE,
  reducers: {
    getFilters: getSelectedFilters,
    setSBFilters: setSBSelectedFilters,
    setPartFilters: setPartSelectedFilters,
    setModalToggle: setModalToggleState,
    setSBInitialLoad: setSbBrowseInitialLoad,
    setPartInitialLoad: setPartBrowseInitialLoad,
    getDataSourceForPart: getDataSourceForPartBrowser,
    getMsnForPartBrowser: getMSNForPartBrowser,
    getMsnForSbBrowser: getMSNForSbBrowser,
    getAcAndAcSubTypesForBrowser: getAcAndAcSubTypes,
    getAircraftTypeACM: getAircraftTypesForACM,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadOperators().pending, loadOperatorsPending)
      .addCase(loadOperators().rejected, loadOperatorsRejected)
      .addCase(loadOperators().fulfilled, loadOperatorsFullfilled)
      .addCase(loadPartAircraftDetails().pending, loadPartAircraftDetailsPending)
      .addCase(loadPartAircraftDetails().rejected, loadPartAircraftDetailsRejected)
      .addCase(loadPartAircraftDetails().fulfilled, loadPartAircraftDetailsFullfilled)
      .addCase(loadSbAircraftDetails().pending, loadSbAircraftDetailsPending)
      .addCase(loadSbAircraftDetails().rejected, loadSbAircraftDetailsRejected)
      .addCase(loadSbAircraftDetails().fulfilled, loadSbAircraftDetailsFullfilled)
      .addCase(loadMod().pending, loadModPending)
      .addCase(loadMod().rejected, loadModRejected)
      .addCase(loadMod().fulfilled, loadModFullfilled)
      .addCase(loadSb().pending, loadSbPending)
      .addCase(loadSb().rejected, loadSbRejected)
      .addCase(loadSb().fulfilled, loadSbFullfilled)
      .addCase(loadFin().pending, loadFinPending)
      .addCase(loadFin().rejected, loadFinRejected)
      .addCase(loadFin().fulfilled, loadFinFullfilled)
      .addCase(loadPnr().pending, loadPnrPending)
      .addCase(loadPnr().rejected, loadPnrRejected)
      .addCase(loadPnr().fulfilled, loadPnrFullfilled);
  },
});

export const {
  getFilters, setSBFilters, setPartFilters, setModalToggle, setSBInitialLoad, setPartInitialLoad, getDataSourceForPart, getMsnForPartBrowser, getAcAndAcSubTypesForBrowser, getMsnForSbBrowser, getAircraftTypeACM,
} = popupFilterSlice.actions;
export default popupFilterSlice;
