/* CMI-S UI */
import './TimeInfoPopover.scss';
import {
  Button, Col, Container, Popover, Row, Typography,
} from '@airbus/components-react';
import { Time, Close } from '@airbus/icons/react';
import React from 'react';
import UseOutsideClick from '../../Shared/OutsideClick/UseOutsideClick';
import {
  ACD, ACR_RECEPTION, AISD, ALLOWABLE_CONFIGURATION, INFORMATION_DATE, MIS_EXTRACTION,
} from '../../../utils/constants';
import { useAppSelector } from '../../../store/hooksTypes';

const TimeInfoPopover: React.FC = () => {
  const iconRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  UseOutsideClick(iconRef, () => {
    setOpen(false);
  });
  const onOpenHandler = () => {
    setOpen(!open);
  };
  const checkerDataTableState = useAppSelector((state) => state.displayCheckerDetailsTable);
  const allowableMISData = [
    { label: ALLOWABLE_CONFIGURATION, data: checkerDataTableState.checkerDetailsData.allowable_config_date },
    { label: MIS_EXTRACTION, data: checkerDataTableState.checkerDetailsData.extraction_date },
  ];
  const acdAISData = [
    { label: ACD, data: checkerDataTableState.checkerDetailsData.acd_date },
    { label: AISD, data: checkerDataTableState.checkerDetailsData.aisd_date },
  ];
  return (
    <Container className="detailsPopoverContainer">
      <Button
        ref={iconRef}
        onClick={onOpenHandler}
        aria-haspopup="dialog"
        icon={<Time className="iconStyle" />}
        className="buttonStyle"
        data-testid="icon-button"
      />
      <Popover open={open} referenceNode={iconRef.current} className="popoverStyle" data-testid="timeinfo-popover">
        <Container>
          <Row>
            <Col xxs={7}>
              <Typography variant="small" className="headerLabel">
                {INFORMATION_DATE}
              </Typography>
            </Col>
            <Col alignSelf="end" xxs={5}>
              <Close onClick={() => setOpen(false)} className="closeIcon" data-testid="close-icon" />
            </Col>
          </Row>
          {allowableMISData.map((i, index) => (
            <Row key={`i${index + 1}`}>
              <Col className="clock-text" xxs={6}>
                <Typography variant="xsmall" className="label">
                  {i.label}
                </Typography>
              </Col>
              <Col className="clock-text" xxs={6}>
                <Typography variant="xsmall" className="labelData">
                  {i.data}
                </Typography>
              </Col>
            </Row>
          ))}
          <Row>
            <Typography variant="small" className="acrHeader">
              {ACR_RECEPTION}
            </Typography>
          </Row>
          {acdAISData.map((i, index) => (
            <Row key={`i${index + 1}`}>
              <Col className="clock-text" xxs={2}>
                <Typography variant="xsmall" className="label">
                  {i.label}
                </Typography>
              </Col>
              <Col className="clock-text" xxs={7}>
                <Typography variant="xsmall" className="labelData">
                  {i.data}
                </Typography>
              </Col>
            </Row>
          ))}
        </Container>
      </Popover>
    </Container>
  );
};
export default TimeInfoPopover;
