/* eslint-disable @typescript-eslint/no-explicit-any */
import { LOAD_EXPORT_FILE_API_PATH, LOAD_POST_API_METHOD } from '../../utils/config/apiConfig';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { DOWNLOADSTATUS_SLICE, LOAD_EXPORT_FILE } from './constants';

export const loadDataExportFile: any = (payloadBody:BodyPayLoadExportFile) => {
  const customAction = {
    sliceName: DOWNLOADSTATUS_SLICE,
    actionType: LOAD_EXPORT_FILE,
  };
  const apiRequestObj = {
    path: LOAD_EXPORT_FILE_API_PATH,
    method: LOAD_POST_API_METHOD,
    body: payloadBody,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
