/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import {
  Row, Col, Typography, Spinner,
} from '@airbus/components-react';
import {
  MOD_LABEL, SB, MSN, MAX_LIMIT, DEFAULT_OFFSET, ASCENDING,
} from '../../../../utils/constants';
import { useAppDispatch } from '../../../../store/hooksTypes';
import { loadSb, loadMod } from '../../../../models/popupFilterModel/popupFilterThunks';
import { HybridComboBox } from '../../../Shared/HybridComboBox/HybridComboBox';
import { getMsnForSbBrowser } from '../../../../models/popupFilterModel/popupFilterSlice';

const ModSbField: any = (props: any) => {
  const {
    ModSbFieldProps,
  } = props;

  const dispatch = useAppDispatch();

  const handleResetSbandMod = (selectedMsn:any, operatorName:any, aircraftType:Array<any>) => {
    const arrayOperator = new Array(operatorName);
    const arrayACType = aircraftType;
    const bodyPayload : msnSbModBodyPayload = {
      operator_code_icao: arrayOperator,
      conditions: {
        aircraft_type: arrayACType,
        id_aircraft_registration: selectedMsn,
      },
      limit: Number(MAX_LIMIT),
      offset: Number(DEFAULT_OFFSET),
      sortType: ASCENDING,
      search: false,
    };
    dispatch(loadSb({ ...bodyPayload, orderBy: ['sb_reference'] })());
    dispatch(loadMod({ ...bodyPayload, orderBy: ['mod_reference'] })());
  };

  const handleMsnSelection = (selectedMsn:any) => {
    ModSbFieldProps.setCurrentFilterConfig((prevState: any) => {
      return {
        ...prevState,
        selected_msn: selectedMsn,
      };
    });
    if (selectedMsn.length) handleResetSbandMod(selectedMsn, ModSbFieldProps.currentFilterConfig?.operator_name, ModSbFieldProps.currentFilterConfig?.aircraft_type);
  };

  const handleSbSelection = (selectSb:any) => {
    ModSbFieldProps.setCurrentFilterConfig((prevState: any) => {
      return {
        ...prevState,
        selected_sb: selectSb,
      };
    });
  };

  const handleModSelection = (selectMod:any) => {
    ModSbFieldProps.setCurrentFilterConfig((prevState: any) => {
      return {
        ...prevState,
        selected_mod: selectMod,
      };
    });
  };
  useEffect(() => {
    // Resetting values To avoid Mod and Sb mismatch with MSN in not validate case
    const msnPayload: any = {
      aircraft_types: ModSbFieldProps.filterProps?.selectedFilterState?.aircraft_type,
      operator_code_icao: new Array(ModSbFieldProps.filterProps?.selectedFilterState?.operator_name),
      sb_data_selection_columns: ModSbFieldProps.filterProps.sb_data_selection_columns,
      aircraft_family: ModSbFieldProps.filterProps?.selectedFilterState?.aircraft_subtype,
    };
    dispatch(getMsnForSbBrowser(msnPayload));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Row className="filter-row">
        <Col xxs={4}>
          <Typography variant="small" className="filter-label">
            {MSN}
          </Typography>
        </Col>
        <Col>
          <HybridComboBox
            state={ModSbFieldProps.filterProps.tableState}
            dispatcher={dispatch}
            disabled={ModSbFieldProps.disableLowerFilters}
            selectedOptions={ModSbFieldProps.currentFilterConfig?.selected_msn}
            isSearchDisabled={false}
            hybridComboClassName="hybrid-checker-details-msn-popover"
            placement="top"
            id="sb-dataselection-msn-selector"
            data-testid="sb-dataselection-msn-selector"
            handleApply={handleMsnSelection}
            isLazyloading
            showApply={false}
            options={ModSbFieldProps.filterProps.msn}
          />
        </Col>
      </Row>
      <Row className="filter-row">
        <Col xxs={3}>
          <Typography variant="small" className="filter-label">
            {SB}
          </Typography>
        </Col>
        <Col xxs={1}>
          {ModSbFieldProps.modsbLoadingState && <Spinner />}
        </Col>
        <Col>
          <HybridComboBox
            state={ModSbFieldProps.filterProps.tableState}
            dispatcher={dispatch}
            disabled={ModSbFieldProps.disableLowerFilters}
            selectedOptions={ModSbFieldProps.currentFilterConfig?.selected_sb}
            isSearchDisabled={false}
            hybridComboClassName="hybrid-checker-details-msn-popover"
            placement="top"
            id="sb-dataselection-sb-selector"
            data-testid="sb-dataselection-sb-selector"
            handleApply={handleSbSelection}
            isLazyloading
            showApply={false}
            options={ModSbFieldProps.filterProps.filterState?.sb_ref}
          />
        </Col>
      </Row>
      <Row>
        <Col xxs={3}>
          <Typography variant="small" className="filter-label">
            {MOD_LABEL}
          </Typography>
        </Col>
        <Col xxs={1}>
          {ModSbFieldProps.modsbLoadingState && <Spinner />}
        </Col>
        <Col>
          <HybridComboBox
            state={ModSbFieldProps.filterProps.tableState}
            dispatcher={dispatch}
            disabled={ModSbFieldProps.disableLowerFilters}
            selectedOptions={ModSbFieldProps.currentFilterConfig?.selected_mod}
            isSearchDisabled={false}
            hybridComboClassName="hybrid-checker-details-msn-popover"
            placement="top"
            id="sb-dataselection-mod-selector"
            data-testid="sb-dataselection-mod-selector"
            handleApply={handleModSelection}
            isLazyloading
            showApply={false}
            options={ModSbFieldProps.filterProps.filterState?.mods}
          />
        </Col>
      </Row>
    </>
  );
};
export default ModSbField;
