/*
 * File: locale.ts
 * Airbus, 2024
 * Author: CMIS FE
 * Description: CMIS APP Locale
 * Copyright (C) 2023-2024 CMIS APP
 * This file is part of CMIS APP.
 *
 * CMIS APP is Aviation product:
 * Used in the latest version
 *
 * CMIS APP FE Code
 *
 * This belong to with CMIS APP.
 */
import {
  POST_DISCLAIMER_STATUS_ERROR,
} from './constants';

// load and post the disclaimer
export const postDisclaimerPending = (
  state: disclaimerStateType,
) => ({
  ...state,
  isDisclaimerStatusLoading: true,
});

export const postDisclaimerRejected = (state: disclaimerStateType) => {
  return {
    ...state,
    isDisclaimerStatusLoading: false,
    errorMessage: POST_DISCLAIMER_STATUS_ERROR,
  };
};

export const postDisclaimerFullfilled = (state: disclaimerStateType, action: disclaimerActionType) => {
  return {
    ...state,
    isDisclaimerStatusLoading: false,
    disclaimerStatus: action.payload.status,
  };
};
