import { CONCURRENT_DATATABLE_INITIAL_STATE, CONCURRENT_DATA_ERROR } from './constants';

/**
 * The action type is not used here because there is only one action
 */
export const ConcurrentLoadPending = (
  state: ConcurrentDataTableState,
) => ({
  ...state,
  isConcurrentDataLoading: true,
});

export const ConcurrentLoadRejected = (state: ConcurrentDataTableState, action?:FilterAPIRejectedAction) => {
  // if request comes from column level filter and no record found for the combination we are showing the previous state
  if (action?.meta.arg === 'columnLevelFilter') {
    return { ...state, isBrowserDataLoading: false };
  }
  return {
    ...state,
    isConcurrentDataLoading: false,
    errorMessage: CONCURRENT_DATA_ERROR,
    ConcurrentData: CONCURRENT_DATATABLE_INITIAL_STATE.ConcurrentData,
    records_length: CONCURRENT_DATATABLE_INITIAL_STATE.records_length,
    records_offset: CONCURRENT_DATATABLE_INITIAL_STATE.records_offset,
  };
};

export const ConcurrentLoadFullfilled = (state: ConcurrentDataTableState, action: ConcurrentDataTableAction) => {
  return {
    ...state,
    isConcurrentDataLoading: false,
    ConcurrentData: action.payload.records,
    records_length: action.payload.records_length,
  };
};
export const setDataTableColumns = (state: ConcurrentDataTableState, action: ConcurrentDataTableAction) => {
  return ({
    ...state,
    columns: action.payload.columns,
  });
};
export const ConcurrentFilterStateUpdate = (state: ConcurrentDataTableState, action: ConcurrentDataTableAction) => {
  return ({
    ...state,
    isColumnDataLoading: false,
    FilterState: action.payload.records,
  });
};
export const ConcurrentFilterPayloadUpdate = (state: ConcurrentDataTableState, action: ConcurrentDataTableAction) => {
  return ({
    ...state,
    isColumnDataLoading: false,
    FilterPayload: action.payload.records,
  });
};

export const ConcurrentColumnLevelPending = (
  state: ConcurrentDataTableState,
) => ({
  ...state,
  isColumnDataLoading: true,
});

export const ConcurrentColumnLevelRejected = (state: ConcurrentDataTableState) => {
  return {
    ...state,
    isColumnDataLoading: false,
    errorMessage: CONCURRENT_DATA_ERROR,
    FilterData: CONCURRENT_DATATABLE_INITIAL_STATE.FilterData,
  };
};

export const ConcurrentColumnLevelFullfilled = (state: ConcurrentDataTableState, action: ConcurrentDataTableAction) => {
  return {
    ...state,
    isColumnDataLoading: false,
    FilterData: action.payload.records,
  };
};
