/*
 * File: locale.ts
 * Airbus, 2024
 * Author: CMIS FE
 * Description: CMIS APP Locale
 * Copyright (C) 2023-2024 CMIS APP
 * This file is part of CMIS APP.
 *
 * CMIS APP is Aviation product:
 * Used in the latest version
 *
 * CMIS APP FE Code
 *
 * This belong to with CMIS APP.
 */
import {
  GET_FEATURE_TOGGLE_STATUS,
} from './constants';

// load the feature toggle
export const postFeatureTogglePending = (
  state: FeatureToggleStateType,
) => ({
  ...state,
  isFeatureToggleLoading: true,
});

export const postFeatureToggleRejected = (state: FeatureToggleStateType) => {
  return {
    ...state,
    isFeatureToggleLoading: false,
    errorMessage: GET_FEATURE_TOGGLE_STATUS,
  };
};

export const postFeatureToggleFullfilled = (state: FeatureToggleStateType, action: FeatureToggleActionType) => {
  return {
    ...state,
    isFeatureToggleLoading: false,
    data: action.payload.data,
  };
};
