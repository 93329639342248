import React from 'react';
import {
  Button,
  Container, Dialog, DialogActions, DialogContent,
} from '@airbus/components-react';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import { setHelpModelToggle } from '../../../models/helpModel/helpModelSlice';
import './HelpModal.scss';
import { CLOSE_BUTTON } from '../../../utils/constants';
import HelpComponent from './HelpComponent';

const HelpModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const helpStates = useAppSelector((state) => state.helpModal);
  const closeHandler = () => {
    dispatch(setHelpModelToggle({ helpModalState: false, sourceOfHelpClick: '' }));
  };
  return (
    <Container data-testid="popup-help-modal" className="popup-help-container">
      <Dialog
        aria-labelledby="Help-modal-title"
        aria-describedby="help-modal-description"
        className="help-modal-container"
        id="help-modal-popup"
        open={helpStates.helpModalState}
      />
      <div className="help-container">
        <DialogContent>
          <HelpComponent source={helpStates.sourceOfHelpClick} />
        </DialogContent>
        <DialogActions>
          <Button variant="primary" data-testid="helpmodal-close" className="close-button" onClick={closeHandler}>
            {CLOSE_BUTTON}
          </Button>

        </DialogActions>
      </div>
    </Container>
  );
};
export default HelpModal;
