import { USER_GUIDE_ERROR } from './constants';

export const getAppState = (state: AppState) => {
  return {
    ...state,
  };
};

export const setBrowserState = (state: AppState, action: AppAction) => {
  return {
    ...state,
    selectedTab: action?.payload?.selectedTab,
  };
};

export const setAcmSbState = (state: AppState, action: AcmSbAction) => {
  return {
    ...state,
    selectedRadioButton: action?.payload,
  };
};

export const setCheckState = (state: AppState, action: AppAction) => {
  return {
    ...state,
    selectedCheckerTab: action?.payload?.selectedCheckerTab,
  };
};

export const setReasonTabState = (state: AppState, action: AppAction) => {
  return {
    ...state,
    selectedReasonTab: action?.payload?.selectedReasonTab,
  };
};

// load and post the user guide
export const userGuidePending = (
  state: AppState,
) => ({
  ...state,
  isUserGuideStatusLoading: true,
});

export const userGuideRejected = (state: AppState) => {
  return {
    ...state,
    isUserGuideStatusLoading: false,
    errorMessage: USER_GUIDE_ERROR,
  };
};

export const userGuideFullfilled = (state: AppState, action: AppAction) => {
  return {
    ...state,
    isUserGuideStatusLoading: false,
    download_file_link: action.payload.download_file_link,
  };
};
