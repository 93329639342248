import React, { useEffect } from 'react';

const hybridClassList = ['typaahead', 'menu-options', 'select-all', 'checker-multiselect-filter-reset', 'multiselect-checkbox', 'checker-multiselect-filter-resetlabel', 'checker-multiselect-filter-reset-icon', 'selectall'];
const defaultClassList = [/ds-input/, /ds-iconbutton/, /ds-span/];
const UseHybridComboOutsideClick = (ref: React.RefObject<HTMLElement>, callback:(event: MouseEvent | TouchEvent)=>void) => {
  const handleClick = (e:MouseEvent) => {
    e.stopPropagation();
    if ((e.target as HTMLInputElement).parentElement?.classList) {
      const targetParentClassList = Array.from(((e.target as HTMLInputElement).parentElement?.classList) as unknown as Array<string>);
      const isTargetInHybridCombo = targetParentClassList.some((item) => hybridClassList.includes(item));
      const isTargetDefaultHybrid = targetParentClassList.some((item) => defaultClassList.some((clas) => clas.test(item)));
      if (ref.current && !ref.current.contains(e.target as HTMLInputElement) && !isTargetInHybridCombo && !isTargetDefaultHybrid) {
        callback(e);
      }
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default UseHybridComboOutsideClick;
