/* eslint-disable @typescript-eslint/no-explicit-any */
import { LOAD_POST_API_METHOD, LOAD_SB_BROWSE_API_PATH } from '../../utils/config/apiConfig';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { CustomThunkAction } from '../../utils/thunkUtils/thunkTypes';
import {
  LOAD_SB_DATA, DATATABLE_SLICE, LOAD_FILTER_DATA,
} from './constants';

const createCustomThunkCall = (action: CustomThunkAction, body: BodyPayLoadSBData) => {
  const apiRequestObj = {
    path: LOAD_SB_BROWSE_API_PATH,
    method: LOAD_POST_API_METHOD,
    body,
  };
  return createCustomAsyncThunk(action, apiRequestObj);
};

export const loadSbBrowseData: any = (payloadBody: BodyPayLoadSBData) => {
  const customAction = {
    sliceName: DATATABLE_SLICE,
    actionType: LOAD_SB_DATA,
  };
  return createCustomThunkCall(customAction, payloadBody);
};

export const loadSbFilterData: any = (payloadBody: BodyPayLoadSBData) => {
  const Action = {
    sliceName: DATATABLE_SLICE,
    actionType: LOAD_FILTER_DATA,
  };
  return createCustomThunkCall(Action, payloadBody);
};
