export const addToastReducer = (state: ToastList, action: ToastAction) => ({
  ...state,
  toasts: [...state.toasts, action.payload],
});

export const removeToastReducer = (state: ToastList, action: ToastAction) => ({
  ...state,
  toasts: state.toasts.filter(
    (toast) => toast.toastMessage !== action.payload.toastMessage,
  ),
});

export const clearToastLocationReducer = (state: ToastList, action: ToastLocation) => ({
  ...state,
  toasts: state.toasts.filter(
    (toast) => toast.toastLocation !== action.payload,
  ),
});
