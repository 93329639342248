import {
  getMSNValuesForPartBrowser, getMSNValuesForSbBrowser, getRestructuredResponseOfACMOperatorMSN, getRestructuredResponseOfOperatorMSN, getSourcevaluesForPartBrowser,
} from '../../utils/commonUtil';

export const getSelectedFilters = (state:FilterState) => {
  return {
    ...state,
  };
};
export const setPartSelectedFilters = (state: FilterState, action: FilterAction) => {
  return {
    ...state,
    part_filter_selection_configuration: action.payload.part_filter_selection_configuration,
  };
};

export const setSBSelectedFilters = (state: FilterState, action: FilterAction) => {
  return {
    ...state,
    sb_filter_selection_configuration: action.payload.sb_filter_selection_configuration,
  };
};

export const setModalToggleState = (state: FilterState, action: FilterAction) => {
  return {
    ...state,
    modalToggle: action.payload.modalToggle,
  };
};

export const setSbBrowseInitialLoad = (state: FilterState, action: FilterAction) => {
  return {
    ...state,
    sbBrowseInitialLoad: action.payload.sbBrowseInitialLoad,
  };
};
export const setPartBrowseInitialLoad = (state: FilterState, action: FilterAction) => {
  return {
    ...state,
    partBrowseInitialLoad: action.payload.partBrowseInitialLoad,
  };
};

export const getDataSourceForPartBrowser = (state: FilterState, action:dataSelectionActionType) => {
  const getSourceValue = getSourcevaluesForPartBrowser(action.payload.part_data_selection_columns, action.payload);
  return {
    ...state,
    datasource: getSourceValue,
  };
};

export const getMSNForPartBrowser = (state: FilterState, action:dataSelectionActionType) => {
  const getMSNValue = getMSNValuesForPartBrowser(action.payload.part_data_selection_columns, action.payload);
  return {
    ...state,
    part_msns: getMSNValue,
  };
};
export const getMSNForSbBrowser = (state: FilterState, action:dataSelectionActionType) => {
  if (action.payload.selectedTab === 'acm') {
    const getMSNValue = getMSNValuesForSbBrowser(action.payload.acm_selection_columns, action.payload);
    return {
      ...state,
      acm_msns: getMSNValue,
    };
  }
  const getMSNValue = getMSNValuesForSbBrowser(action.payload.sb_data_selection_columns, action.payload);
  return {
    ...state,
    msns: getMSNValue,
  };
};
export const getAircraftTypesForACM = (state: FilterState, action:dataSelectionActionType) => {
  return {
    ...state,
    acm_operators_ac_mapping: Object.keys(action.payload.acm_selection_columns[action.payload.operator_code_icao][action.payload.aircraft_program_code]),
  };
};
export const getAcAndAcSubTypes = (state: FilterState, action:dataSelectionActionType) => {
  if (action.payload.selectedTab === 'acm') {
    const getFormattedValue = getRestructuredResponseOfACMOperatorMSN(action.payload.acm_selection_columns[action.payload.operator_code_icao], action.payload.operator_code_icao);
    return {
      ...state,
      acm_operators_ac_family: getFormattedValue.formattedRes.aircraft_program_code,
      acm_operators_ac_mapping: getFormattedValue.formattedRes.aircraft_types,
      acm_operators_acsubType_mapping: getFormattedValue.formattedRes.aircraft_family,
    };
  }
  if (action.payload.selectedTab) {
    const getFormattedValue = getRestructuredResponseOfOperatorMSN(action.payload.sb_data_selection_columns[action.payload.operator_code_icao], action.payload.operator_code_icao);
    return {
      ...state,
      operators_ac_mapping: getFormattedValue.formattedRes.aircraft_types,
      operators_acsubType_mapping: getFormattedValue.formattedRes.aircraft_family,
    };
  }
  const getFormattedValue = getRestructuredResponseOfOperatorMSN(action.payload.part_data_selection_columns[action.payload.operator_code_icao], action.payload.operator_code_icao);
  return {
    ...state,
    part_operators_ac_mapping: getFormattedValue.formattedRes.aircraft_types,
    part_operators_acsubType_mapping: getFormattedValue.formattedRes.aircraft_family,
  };
};
