/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Col, Inline, Link, Typography,
} from '@airbus/components-react';
import { OpenInNew } from '@airbus/icons/react';
import React from 'react';
import './CheckerReportStatus.scss';

interface CustomLegendProps {
  chartStatus: any;
  filterURL: any;
}

const CustomChartLegend = function CustomChartLegend(props: CustomLegendProps) {
  const { chartStatus, filterURL } = props;

  const arrayOfStatusData = () => {
    return (
      <div className="legend-container">
        {chartStatus.labelData.map((item: any, index: number) => (
          <div className={`${item.value === 0 ? 'legend-div grayout-background' : 'legend-div'}`} key={`i${index + 1}`}>
            <Inline spacing="half-x">
              <Typography className={`${item.color}`} />
              <Typography variant="xsmall" className="legend-text">{`${item.label}`}</Typography>
              <Typography variant="xsmall" className="legend-text">
                {`- ${item.value}`}
              </Typography>
              <Typography variant="xsmall" className="legend-text">
                {`(${item.percentage}%)`}
              </Typography>
              {item.newTab && (
              <Col className="chart-openInNew">
                <Link
                  onClick={() => filterURL(chartStatus.title, item.label)}
                  target="_blank"
                  className="new-tab-filter-link"
                  data-testid={`${chartStatus.title}-newTabLink`}
                >
                  <OpenInNew />
                </Link>
              </Col>
              )}
            </Inline>
          </div>
        ))}
      </div>
    );
  };
  return (
    arrayOfStatusData()
  );
};

export default CustomChartLegend;
