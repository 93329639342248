/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Col, Container, Row, Tooltip, Typography,
} from '@airbus/components-react';
import { Info } from '@airbus/icons/react';
import ChipLabel from '../../Shared/ChipLabel/ChipLabel';
import { chipConstants } from '../../Shared/ChipLabel/constants';
import './TooltipInfo.scss';
import { FIN } from '../../../utils/constants';

const chipInfo = (chipArrayType: any) => {
  const chipDetails: any = [];
  Object.values(chipConstants).forEach((item: any) => {
    if (chipArrayType.includes(item.label) || chipArrayType.includes(item.chipIcon)) {
      chipDetails.push(
        <Row>
          <Col xxs={3} className="chip-col">
            <ChipLabel sourceOfChip={item.chipIcon ? [item.chipIcon] : [item.label]} />
          </Col>
          <Col>
            <Typography variant="small" className="chip-text">{item.chipText}</Typography>
          </Col>
        </Row>,
      );
    }
  });
  return chipDetails;
};

export const TooltipInfo = (showFinStatus: boolean, showSourceStatus: boolean) => {
  const chipIconArray = ['Mismatch', 'Match', 'NA'];
  const chipLabelArray = ['W', 'A', 'M'];
  return (
    <Container className="tooltip-container">
      <Row>
        {showFinStatus && (
        <Col xxs={4}>
          <Typography variant="small">{FIN}</Typography>
        </Col>
        )}
        {showSourceStatus && (
        <Col xxs={4}>
          <Typography variant="small">Source Status</Typography>
        </Col>
        )}
        <Col xxs={6} className="tooltip-col">
          <Tooltip
            placement="bottom"
            label={showFinStatus ? chipInfo(chipLabelArray) : chipInfo(chipIconArray)}
            className={showFinStatus ? 'tooltip-info' : 'tooltip-source-info'}
            data-testid="tooltip-info"
          >
            <Info data-testid="info-fin-icon" className={showFinStatus ? 'fin-info' : 'source-info'} />
          </Tooltip>
        </Col>
      </Row>
    </Container>
  );
};
