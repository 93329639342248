/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../store/hooksTypes';

// This is the HOC component to check whether the component which your passing as argument is to visble for this env or not
const WithFeatureToggle: React.FC<any> = ({ Component, featurekey }) => {
  // By deafult we are setting up as true / bydefault visible
  const [isEnabled, setIsEnabled] = useState(true);
  // we are getting the status of the component from the state and based on that we are updating
  const featureToggleConfig: any = useAppSelector((state) => state.featureToggleDetails?.data);
  const featureConfigValue: boolean = featureToggleConfig && Object.prototype.hasOwnProperty.call(featureToggleConfig, featurekey) ? featureToggleConfig[featurekey] : true;
  useEffect(() => {
    setIsEnabled(featureConfigValue);
  }, [featureConfigValue]);
  // we are rendering the component based on the flag from state
  return isEnabled ? <Component /> : null;
};

export default WithFeatureToggle;
