import { useEffect } from 'react';
import { Auth } from 'aws-amplify';

const SignIn = () => {
  useEffect(() => {
    Auth.federatedSignIn();
  });
  return null;
};

export default SignIn;
