/* eslint-disable @typescript-eslint/no-explicit-any */
import { getFinAPIForDataSource, getPnrAPIForDataSource } from '../../utils/commonUtil';
import {
  LOAD_GET_API_METHOD, LOAD_MOD_API_PATH, LOAD_OPERATOR_API_PATH, LOAD_PART_MSN_API_PATH, LOAD_POST_API_METHOD, LOAD_SB_API_PATH, LOAD_SB_MSN_API_PATH,
} from '../../utils/config/apiConfig';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import {
  LOAD_PART_AIRCRAFT_DETAILS,
  LOAD_FIN,
  LOAD_MOD,
  LOAD_OPERATORS, LOAD_PNR, LOAD_SB, POPUPFILTER_SLICE, LOAD_SB_AIRCRAFT_DETAILS,
} from './constants';

export const loadOperators: any = () => {
  const customAction = {
    sliceName: POPUPFILTER_SLICE,
    actionType: LOAD_OPERATORS,
  };
  const apiRequestObj = {
    path: LOAD_OPERATOR_API_PATH,
    method: LOAD_GET_API_METHOD,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const loadPartAircraftDetails: any = (payloadBody:allColumnsBodyPayload) => {
  const customAction = {
    sliceName: POPUPFILTER_SLICE,
    actionType: LOAD_PART_AIRCRAFT_DETAILS,
  };
  const apiRequestObj = {
    path: LOAD_PART_MSN_API_PATH,
    method: LOAD_POST_API_METHOD,
    body: payloadBody,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
export const loadSbAircraftDetails: any = (payloadBody:allColumnsBodyPayload) => {
  const customAction = {
    sliceName: POPUPFILTER_SLICE,
    actionType: LOAD_SB_AIRCRAFT_DETAILS,
  };
  const apiRequestObj = {
    path: LOAD_SB_MSN_API_PATH,
    method: LOAD_POST_API_METHOD,
    body: payloadBody,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
export const loadMod: any = (payloadBody:msnSbModBodyPayload) => {
  const customAction = {
    sliceName: POPUPFILTER_SLICE,
    actionType: LOAD_MOD,
  };
  const apiRequestObj = {
    path: LOAD_MOD_API_PATH,
    method: LOAD_POST_API_METHOD,
    body: payloadBody,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const loadSb: any = (payloadBody:msnSbModBodyPayload) => {
  const customAction = {
    sliceName: POPUPFILTER_SLICE,
    actionType: LOAD_SB,
  };
  const apiRequestObj = {
    path: LOAD_SB_API_PATH,
    method: LOAD_POST_API_METHOD,
    body: payloadBody,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const loadFin: any = (payloadBody:msnFinPnrBodyPayload) => {
  const customAction = {
    sliceName: POPUPFILTER_SLICE,
    actionType: LOAD_FIN,
  };
  const apiRequestObj = {
    path: getFinAPIForDataSource(payloadBody),
    method: LOAD_POST_API_METHOD,
    body: payloadBody,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const loadPnr: any = (payloadBody:msnFinPnrBodyPayload) => {
  const customAction = {
    sliceName: POPUPFILTER_SLICE,
    actionType: LOAD_PNR,
  };
  const apiRequestObj = {
    path: getPnrAPIForDataSource(payloadBody),
    method: LOAD_POST_API_METHOD,
    body: payloadBody,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
