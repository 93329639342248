/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useEffect, useRef, useState,
} from 'react';
import { Popover } from '@airbus/components-react';
import './CustomPopover.scss';
import { NO_CONCURRENT_AVAILABLE, NO_CONCURRENT_LINKED } from '../../../utils/constants';

 interface Props {
    value: string;
    children?: React.ReactNode | React.ReactNode[]
    scrollTrigger: Element;
}
const defaultPopoverProp = {
  children: null,
};
export const CustomPopover: React.FC<Props> = (props:Props) => {
  const { value, scrollTrigger, children } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const cellRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function handleScroll() {
      setShowTooltip(false);
    }
    scrollTrigger?.addEventListener('scroll', handleScroll);
  }, [scrollTrigger]);
  // Tooltip will be visible only if the value is not fit into cell
  const handleMouseEnter = () => {
    const element = cellRef.current?.querySelector('.cell-values');
    if ((element && element?.scrollWidth > element?.clientWidth) || value === NO_CONCURRENT_AVAILABLE || value === NO_CONCURRENT_LINKED) {
      setShowTooltip(true);
    }
  };
  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  return (
    <div
      ref={cellRef}
      className="popoverContainer"
      data-testid="tooltipTrigger"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      <Popover
        open={showTooltip}
        data-testid="tooltipPopover"
        className="customPopoverTooltip"
        referenceNode={cellRef.current}
        arrowClassName="custom-arrow-class"
      >
        <div className="toolTipStyle" data-testid="tooltipBanner">{value}</div>
      </Popover>
    </div>
  );
};
CustomPopover.defaultProps = defaultPopoverProp;
