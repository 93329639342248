import React from 'react';
import { Spinner } from '@airbus/components-react';

interface Spinnerprops {
  size: 'small' | 'medium' | undefined;
  label: string;
}
const SpinnerComponent = (props: Spinnerprops) => {
  const { size, label } = props;
  return (
    <Spinner size={size} label={label} />
  );
};

export default SpinnerComponent;
