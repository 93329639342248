/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import './ChangeOutput.scss';
import {
  Button, Container, Inline, Checkbox, Modal, Row, Col,
} from '@airbus/components-react';
import { Close, LiveHelp, RemoveRedEye } from '@airbus/icons/react';
import {
  APPLY, CHANGE_VIEW, CANCEL, MIS_DATASOURCE, ACR_DATASOURCE,
} from '../../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import { setHelpModelToggle } from '../../../models/helpModel/helpModelSlice';
import { handleChangeOutputApply } from '../../../utils/commonUtil';

interface ChangeOutputProps {
  tableProps: any
}
const ChangeOutput: React.FC<ChangeOutputProps> = (props: ChangeOutputProps) => {
  const {
    tableProps,
  } = props;
  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState<any>({});
  const [checkColumns, setCheckColumns] = useState<any>({});
  const [applyDisabled, setApplyDisabled] = useState(false);
  const isDataSelectionModalOpen = useAppSelector((state) => state.popUpFilter.modalToggle);
  const browserState = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const misNotRequiredfieldsArray = ['part_type', 'last_configuration_date', 'fin_origin', 'serial_number_origin', 'deviation_description'];
  const acrNotRequiredFieldArray = ['installation_date'];
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setColumns({});
    setApplyDisabled(false);
  };
  useEffect(() => {
    handleClose();
  }, [isDataSelectionModalOpen, browserState]);
  useEffect(() => {
    tableProps.allColumns.forEach((item:any) => {
      if (item.Header !== 'Actions' && !(tableProps.selectedFilterState?.selected_dataSource === MIS_DATASOURCE && misNotRequiredfieldsArray.includes(item.id)) && !(tableProps.selectedFilterState?.selected_dataSource === ACR_DATASOURCE && acrNotRequiredFieldArray.includes(item.id))) {
        const selectedColumn = tableProps.tableStateColumns.filter((column:any) => column.Header === item.Header);
        const checked = selectedColumn.length > 0;
        checkColumns[item.Header] = checked;
      }
    });
  }, [open]);
  useEffect(() => {
    if (!(Object.values(checkColumns).some((value) => value === true))) {
      setApplyDisabled(true);
    } else {
      setApplyDisabled(false);
    }
  }, [checkColumns]);
  const handleApply = () => {
    const tableObj = {
      tableState: tableProps.tableState,
      tableStateColumns: tableProps.tableStateColumns,
      allColumns: tableProps.allColumns,
      updateTableReducer: tableProps.updateTableReducer,
      dispatcher: tableProps.dispatcher,
    };
    handleChangeOutputApply(columns, tableObj);
    handleClose();
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, header: string) => {
    setCheckColumns({ ...checkColumns, [header]: event.target.checked });
    setColumns({ ...columns, [header]: !event.target.checked });
  };
  return (
    <div>
      <Container data-testid="change-output-modal">
        <Button size="small" variant="primary" className="change-output-button" icon={<RemoveRedEye />} onClick={handleOpen} data-testid="changeoutput-button">
          {CHANGE_VIEW}
        </Button>
        <Modal aria-labelledby="modal-title" aria-describedby="modal-description" open={open} id="change-output-modal-popup">
          <div className="change-output-model">
            <Container>
              <div className="change-output-header-label">
                {CHANGE_VIEW}
                <LiveHelp className="help-icon" data-testid="change-help-icon" onClick={() => { dispatch(setHelpModelToggle({ helpModalState: true, sourceOfHelpClick: 'changeViewHelpIcon' })); }} />
                <Close className="change-output-close" onClick={handleClose} data-testid="change-output-modal-close" />
              </div>
              <Container className="change-output-container">
                <Row>
                  {tableProps.allColumns.map((item:any) => {
                    if (item.Header !== 'Actions' && !(tableProps.selectedFilterState?.selected_dataSource === MIS_DATASOURCE && misNotRequiredfieldsArray.includes(item.id)) && !(tableProps.selectedFilterState?.selected_dataSource === ACR_DATASOURCE && acrNotRequiredFieldArray.includes(item.id))) {
                      const selectedColumn = tableProps.tableStateColumns.filter((column:any) => column.Header === item.Header);
                      const checked = selectedColumn.length > 0;
                      let filterDisabled = false;
                      let sortDisabled = false;
                      // disable the columns if the same column is selected in filter or sort by in data level filter
                      if (tableProps.tableState.FilterState && Object.values(tableProps.tableState.FilterState).length > 0) {
                        const getFilterStateForColumn = tableProps.tableState.FilterState[item.id];
                        filterDisabled = getFilterStateForColumn && getFilterStateForColumn.length > 0;
                      }
                      if (tableProps.tableState.FilterPayload && Object.values(tableProps.tableState.FilterPayload).length > 0 && tableProps.tableState.FilterPayload.orderBy) {
                        sortDisabled = tableProps.tableState.FilterPayload.orderBy[0] === item.id;
                      }
                      return (
                        <Col xs={7} md={4} key={item.Header} className="change-output-col">
                          <Checkbox data-testid="change-output-checkbox" defaultChecked={checked} className="dynamic-header-inside" disabled={item.mandatory || filterDisabled || sortDisabled} onChange={(event) => { handleCheckboxChange(event, item.Header); }} />
                          {item.Header}
                        </Col>
                      );
                    }
                    return '';
                  })}
                </Row>
              </Container>
              <Container>
                <Inline spacing="1-x" align="end" className="cancel-apply-buttons">
                  <Button size="small" variant="secondary" onClick={handleClose} className="cancel-button" data-testid="change-output-modal-cancel">
                    {CANCEL}
                  </Button>
                  <Button size="small" variant="primary" onClick={handleApply} disabled={applyDisabled} className="apply-button" data-testid="change-output-modal-apply">
                    {APPLY}
                  </Button>
                </Inline>
              </Container>
            </Container>
          </div>
        </Modal>
      </Container>
    </div>
  );
};
export default ChangeOutput;
