/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Header, IconButton, Inline, Badge,
} from '@airbus/components-react';
import { Download } from '@airbus/icons/react';
import React from 'react';
import ProfileMenu from '../../ProfileMenu/ProfileMenu';
import './AppHeader.scss';
import HeaderTab from '../Tabs/HeaderTab/HeaderTab';
import { updateDrawerState } from '../../../models/downloadStatusModel/downloadStatusSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import UserHelp from '../../UserHelp/UserHelp';
import { getDisclaimerStatus } from '../../../utils/commonMethods/generalMethods';
import { isRequiredForEnv } from '../../../utils/commonUtil';
import UploadFile from '../../UploadFile/UploadFile';
import { USER_SCOPE_NSWC } from '../../../utils/constants';

interface AppHeaderProps {
  appName: { APP_NAME: string, APP_POWERED_BY: string };
}

type headerButton = {
  Icons: JSX.Element;
  ariaLabel: string;
  iconClass: string;
  onClickEvent?(event: any): void;
};

const AppHeader: React.FC<AppHeaderProps> = function AppHeader(
  props: AppHeaderProps,
) {
  const { appName } = props;
  const appHeaderDispatch = useAppDispatch();
  const { notificationCount, isDownloadDrawerClose } = useAppSelector(
    (state) => state.downloadStatus,
  );
  const { userScope } = useAppSelector((state) => state.authEntrySlice);
  const featureToggleConfig = useAppSelector((state) => state.featureToggleDetails?.data);
  const iconArr: headerButton[] = [
    {
      Icons: (
        <Badge count={notificationCount} className={notificationCount ? 'dwn-btn' : 'dwn-btn hide-badge'} variant="error">
          <Download />
        </Badge>
      ),
      ariaLabel: 'Downloads',
      iconClass: isDownloadDrawerClose
        ? 'btn-font-small btn-highlight'
        : 'btn-font-small',
      onClickEvent: (event: any) => {
        event.stopPropagation();
        appHeaderDispatch(updateDrawerState(!isDownloadDrawerClose));
      },
    },
  ];
  const icons = iconArr.map((item: headerButton, index) => (
    <IconButton
      className={item.iconClass}
      aria-label={item.ariaLabel}
      key={`ico_${index + 1}`}
      data-testid={item.ariaLabel}
      onClick={item?.onClickEvent}
      disabled={isRequiredForEnv(['pro', 'val']) ? !getDisclaimerStatus() : false}
    >
      {item.Icons}
    </IconButton>
  ));

  return (
    <div className="cmiAppHeader">
      <Header appName={appName.APP_NAME}>
        <Inline className="powered-by">
          <div className="skywise-span">
            {appName.APP_POWERED_BY}
          </div>
          <div className="skywise-brand" />
        </Inline>
        <HeaderTab />
        <Inline spacing="half-x" className="custom-header">
          {isRequiredForEnv(['dev', 'val']) && icons}
          {featureToggleConfig?.nswc === 'true' && userScope === USER_SCOPE_NSWC && <UploadFile />}
          <UserHelp />
          <ProfileMenu />
        </Inline>
      </Header>
    </div>
  );
};

export default AppHeader;
