import { createSlice } from '@reduxjs/toolkit';
import {
  DATATABLE_INITIAL_STATE, DATATABLE_SLICE,
} from './constants';
import { sbDataPaginationReducer } from './sbDataTablePaginationReducer';
import { SbBrowserLoadFullfilled, SbBrowserLoadPending, SbBrowserLoadRejected } from './sbDataTableExtraReducer';
import { loadSbFilterData, loadSbBrowseData } from './sbDataTableThunk';
import {
  ColumnFilterPayloadUpdate, ColumnFilterStateUpdate, ColumnLevelFullfilled, ColumnLevelPending, ColumnLevelRejected, sbDataTableReducers, setDataTableColumns, setConcurrentToggle, setSbStatusViewToggle,
} from './sbDataTableReducer';

export const sbDataTableSlice = createSlice({
  name: DATATABLE_SLICE,
  initialState: DATATABLE_INITIAL_STATE,
  reducers: {
    updateSBDataPagination: sbDataPaginationReducer,
    updateSBDataTable: sbDataTableReducers,
    updateConcurrentToggle: setConcurrentToggle,
    updateTableColumns: setDataTableColumns,
    updateSbColumnLevelFilter: ColumnLevelFullfilled,
    updateSbColumnFilterState: ColumnFilterStateUpdate,
    updateSbColumnFilterPayload: ColumnFilterPayloadUpdate,
    updateSbStatusViewToggle: setSbStatusViewToggle,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadSbBrowseData().pending, SbBrowserLoadPending)
      .addCase(loadSbBrowseData().rejected, SbBrowserLoadRejected)
      .addCase(loadSbBrowseData().fulfilled, SbBrowserLoadFullfilled)
      .addCase(loadSbFilterData().pending, ColumnLevelPending)
      .addCase(loadSbFilterData().rejected, ColumnLevelRejected)
      .addCase(loadSbFilterData().fulfilled, ColumnLevelFullfilled);
  },
});
export const {
  updateSBDataTable, updateSBDataPagination, updateSbColumnLevelFilter, updateTableColumns, updateSbColumnFilterState, updateSbColumnFilterPayload, updateConcurrentToggle, updateSbStatusViewToggle,
} = sbDataTableSlice.actions;
export default sbDataTableSlice;
