import { setLocalStore } from '../../utils/commonMethods/generalMethods';
import { redirectToLogout } from '../../utils/commonUtil';
import {
  HEADER_TAB_ORDER, PERMISSION_FAIL_TIMEOUT,
  READ_ONLY_FLAG,
  READ_WRITE_FLAG,
} from '../../utils/constants';
import { MODULE_PERMISSION_MAP, USER_PERMISSIONS_INIT_STATE } from './constants';

export const getUserPermissionsFullfilled = (state:UserPermissionState, action:UserPermissionActions) => {
  // map permissions to modules
  if (!action.payload.authorizations.length) {
    redirectToLogout();
  }
  const permissions = action.payload.authorizations.flatMap((eachPremisssion) => (
    eachPremisssion.permissions.map((permissionObj) => {
      return {
        code: permissionObj.code,
        icao: eachPremisssion.company.icao,
      };
    })
  ));
  // in case of empty permissions logout after showing toast message.
  const modules = Array.from(new Set(permissions
    .map((eachPermission: CompanyPermissions) => MODULE_PERMISSION_MAP[eachPermission.code.split('_')[1]])
    .sort((a: string, b: string) => {
      // Set permission order to be shown in header tab.
      return (HEADER_TAB_ORDER.indexOf(a)) - (HEADER_TAB_ORDER.indexOf(b));
    })));
  // create a separate mapping for each modules and their access levels
  const permissionMap = Array.from(new Set(permissions.map((eachPermission: CompanyPermissions) => {
    const permissionCodeArr = eachPermission.code.split('_');
    return {
      icao: eachPermission.icao,
      module: permissionCodeArr[1],
      permission: permissionCodeArr.includes(READ_WRITE_FLAG) ? READ_WRITE_FLAG : READ_ONLY_FLAG,
    };
  })));
  setLocalStore('apiPaginationSize', String(action.payload?.pagination_size || 500));
  return {
    ...state,
    permissionList: permissions,
    moduleList: modules,
    isLoading: false,
    userAllowed: true,
    userScope: action.payload.authorizations[0]?.user_scope || '',
    userIcao: action.payload.authorizations[0]?.company.icao || '',
    nswcConfig: action.payload.authorizations[0]?.nswc_config?.msn_restriction,
    permissionObj: permissionMap,
  };
};

export const getUserPermissionsPending = (state:UserPermissionState) => ({
  ...state,
  isLoading: true,
});

export const getUserPermissionsRejected = (state: UserPermissionState) => {
  setTimeout(() => {
    redirectToLogout();
  }, PERMISSION_FAIL_TIMEOUT);
  return {
    ...state,
    isLoading: false,
    permissionList: USER_PERMISSIONS_INIT_STATE.permissionList,
    moduleList: USER_PERMISSIONS_INIT_STATE.moduleList,
    userAllowed: false,
    errorMessage: 'Unable to get user permissions',
  };
};
