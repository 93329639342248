/* CMI-S UI */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Chip, ColorModeProvider, Inline, Stack, Typography,
} from '@airbus/components-react';
import { CheckCircleOutline, CancelOutline } from '@airbus/icons/react';
import './ChipLabel.scss';
import React from 'react';
import { chipConstants } from './constants';

interface ChipLabelProps {
    sourceOfChip: any;
}

const ChipLabel = function ChipLabel(props: ChipLabelProps) {
  const { sourceOfChip } = props;

  const arrayOfChips = () => {
    return sourceOfChip.map((item: string, index: number) => (
      <ColorModeProvider mode="dark" key={`i${index + 1}`}>
        <Stack className="chip-container">
          <Inline spacing="2-x">
            {chipConstants[item]?.text && <Typography variant="small" className="source-text">{chipConstants[item]?.text}</Typography>}
            {chipConstants[item]?.label && <Chip data-testid="chip-label" label={chipConstants[item]?.label} variant={chipConstants[item]?.chipVariant} className="custom-chips" />}
            {chipConstants[item]?.customChipIcon && <Chip data-testid="chipIcon" icon={chipConstants[item]?.customChipIcon === 'CheckCircleOutline' ? <CheckCircleOutline className="check-icon" /> : <CancelOutline className="cross-icon" />} className="chip-right" />}
          </Inline>
        </Stack>
      </ColorModeProvider>
    ));
  };

  return (
    arrayOfChips()
  );
};

export default ChipLabel;
