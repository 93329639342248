import { createSlice } from '@reduxjs/toolkit';
import { TOAST_SLICE } from './constants';
import { addToastReducer, clearToastLocationReducer, removeToastReducer } from './toastReducer';

export const initialState: ToastList = { toasts: [] };

const toastSlice = createSlice({
  name: TOAST_SLICE,
  initialState,
  reducers: {
    // sync action creators
    addToast: addToastReducer,
    removeToast: removeToastReducer,
    clearToastLocation: clearToastLocationReducer,
  },
});

export const { addToast, removeToast, clearToastLocation } = toastSlice.actions;
export default toastSlice;
