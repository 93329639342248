/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable dot-notation */
import React, { Dispatch, useState } from 'react';
import {
  Container,
  Inline,
  Radio,
  RadioGroup,
  Row,
} from '@airbus/components-react';
import { chipConstants } from '../Shared/ChipLabel/constants';
import {
  FIN_STATUS_MAPPER,
} from '../../utils/constants';
import { handleStatusChange } from './CheckerFinEditModelUtils';

interface FinStatusInterface {
  statusProps: CheckerSelectedFinDataType;
  checkerDataTableState: any
  CheckerFinEditModalPopupDispatcher: Dispatch<any>;
}
const CheckerFinEditStatusComponent = ({ statusProps, checkerDataTableState, CheckerFinEditModalPopupDispatcher }: FinStatusInterface) => {
  const finStatus: string = chipConstants[FIN_STATUS_MAPPER[statusProps.fin_status]].chipText;
  const [selectedStatusValue, setSelectedStatusValue] = useState(finStatus);
  const handlerStatusChangeArgs = {
    setSelectedStatusValue, checkerDataTableState, statusProps, finStatus, CheckerFinEditModalPopupDispatcher,
  };

  return (
    <Container className="fin-status-container">
      <Row className="changes-fin-list">
        <Inline spacing="5-x">
          <RadioGroup
            row
            value={selectedStatusValue}
            onChange={(e, value) => handleStatusChange(value, handlerStatusChangeArgs)}
          >
            <Radio
              label={chipConstants[FIN_STATUS_MAPPER['M']].chipText}
              value={chipConstants[FIN_STATUS_MAPPER['M']].chipText}
              className="fin-status-radio fin-margin"
            />
            <Radio
              label={chipConstants[FIN_STATUS_MAPPER['TBA']].chipText}
              value={chipConstants[FIN_STATUS_MAPPER['TBA']].chipText}
              className="fin-status-radio"
            />
            <Radio
              label={chipConstants[FIN_STATUS_MAPPER['W']].chipText}
              value={chipConstants[FIN_STATUS_MAPPER['W']].chipText}
              className="fin-status-radio"
            />
          </RadioGroup>
        </Inline>
      </Row>
    </Container>
  );
};
export default CheckerFinEditStatusComponent;
