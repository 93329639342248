import { createSlice } from '@reduxjs/toolkit';
import {
  DOWNLOADSTATUS_INITIAL_STATE,
  DOWNLOADSTATUS_SLICE,
} from './constants';
import {
  updateDrawerStateReducer,
  incrementNotificationCountReducer,
  clearNotificationCountReducer,
  clearHighlightReducer,
  setPollingStatus,
  updateDownloadStateReducer,
} from './downloadStatusReducer';
import { getFileStatus, getPresignedUrl } from './getStatusThunk';
import {
  setDownloadFileLinkFulfilledReducer,
  setDownloadFileLinkPendingReducer,
  setDownloadFileLinkRejectedReducer,
  setDownloadStatusFulfilledReducer,
  setDownloadStatusPendingReducer,
  setDownloadStatusRejectedReducer,
  setExportDownloadFulfilledReducer,
  setNewDownloadPendingReducer,
  setNewDownloadRejectedReducer,
} from './downloadStatusExtraReducer';
import { loadDataExportFile } from './downloadStatusThunk';

const downloadStatusSlice = createSlice({
  name: DOWNLOADSTATUS_SLICE,
  initialState: DOWNLOADSTATUS_INITIAL_STATE,
  reducers: {
    updateDrawerState: updateDrawerStateReducer,
    incrementNotificationCount: incrementNotificationCountReducer,
    clearNotificationCount: clearNotificationCountReducer,
    clearHighlight: clearHighlightReducer,
    setPolling: setPollingStatus,
    setNewDownload: setExportDownloadFulfilledReducer,
    updateDownloadState: updateDownloadStateReducer,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFileStatus().pending, setDownloadStatusPendingReducer)
      .addCase(getFileStatus().fulfilled, setDownloadStatusFulfilledReducer)
      .addCase(getFileStatus().rejected, setDownloadStatusRejectedReducer)
      .addCase(loadDataExportFile().pending, setNewDownloadPendingReducer)
      .addCase(loadDataExportFile().rejected, setNewDownloadRejectedReducer)
      .addCase(loadDataExportFile().fulfilled, setExportDownloadFulfilledReducer)
      .addCase(getPresignedUrl().pending, setDownloadFileLinkPendingReducer)
      .addCase(getPresignedUrl().fulfilled, setDownloadFileLinkFulfilledReducer)
      .addCase(getPresignedUrl().rejected, setDownloadFileLinkRejectedReducer);
  },
});

export const {
  updateDrawerState,
  incrementNotificationCount,
  clearNotificationCount,
  clearHighlight,
  setPolling,
  setNewDownload,
  updateDownloadState,
} = downloadStatusSlice.actions;

export default downloadStatusSlice;
