import { getPaginatedChunkData } from '../../utils/commonUtil';
import { DATATABLE_INITIAL_STATE, SB_DATA_ERROR } from './constants';

/**
 * The action type is not used here because there is only one action
 */
export const SbBrowserLoadPending = (
  state: SbDataTableState,
) => ({
  ...state,
  isBrowserDataLoading: true,
});

export const SbBrowserLoadRejected = (state: SbDataTableState, action?:FilterAPIRejectedAction) => {
  // if request comes from column level filter and no record found for the combination we are showing the previous state
  if (action?.meta.arg === 'columnLevelFilter') {
    return { ...state, isBrowserDataLoading: false };
  }
  return {
    ...state,
    isBrowserDataLoading: false,
    errorMessage: SB_DATA_ERROR,
    SbBrowserData: DATATABLE_INITIAL_STATE.SbBrowserData,
    paginationData: DATATABLE_INITIAL_STATE.paginationData,
    total_records: DATATABLE_INITIAL_STATE.total_records,
    records_offset: DATATABLE_INITIAL_STATE.records_offset,
    columns: DATATABLE_INITIAL_STATE.columns,
  };
};

export const SbBrowserLoadFullfilled = (state: SbDataTableState, action: SbDataTableAction) => {
  const calculatedPageNum = (action.payload.records_offset + state.paginationData.rowPerPage) / state.paginationData.rowPerPage;
  const prevPage = state.paginationData.page;
  const chunkData = getPaginatedChunkData(calculatedPageNum, action.payload.records, state.paginationData.rowPerPage, prevPage, action.payload.records_offset, action.payload.total_sb_count);
  return {
    ...state,
    isBrowserDataLoading: false,
    // Use only required columns
    SbBrowserData: action.payload.records,
    total_records: action.payload.total_sb_count,
    // update the pagination config as per response count
    paginationData: {
      start: action.payload.records_offset,
      end: action.payload.records_offset + action.payload.records.length,
      rowPerPage: state.paginationData.rowPerPage,
      page: calculatedPageNum, // currently selected
      selectedChunkData: chunkData,
    },
  };
};
