import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import getConfig from '../../config';
import { clearLocalStoreWithCondition } from '../../utils/commonUtil';

const config = getConfig();

const { logoutUrl } = config.Auth.oauth;

const SignOut = () => {
  useEffect(() => {
    clearLocalStoreWithCondition();
    Auth.signOut()
      .then(() => {
        window.location.assign(logoutUrl);
      })
      .catch(() => window.location.assign(logoutUrl));
  }, []);
  return null;
};

export default SignOut;
