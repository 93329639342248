export const REASON_STATUS_MAPPING_SLICE = 'reasonStatusMapping';
export const REASON_STATUS_MAPPING_INITIAL_STATE: ReasonStatusMappingState = {
  loadReasonStatusMapping: true,
  data: {
    reason_key_mapper: {},
    status_key_mapper: {},
  },
};

export const LOAD_REASON_STATUS_MAPPING = 'loadReasonStatusMapping';
