import React from 'react';
import {
  Container,
  Spinner,
} from '@airbus/components-react';
import CheckerTabs from './CheckerTab/CheckerTabs';
import { useAppSelector } from '../../../../store/hooksTypes';
import Fleet from '../../../Fleet/Fleet';
import './Checker.scss';
import WithFeatureToggle from '../../FeatureToggle/FeatureToggle';

const Checker: React.FC = function Checker() {
  const selectedCheckerTab = useAppSelector((state) => state.app.selectedCheckerTab);
  const { isLoading } = useAppSelector((state) => state.authEntrySlice);
  const spinner = () => {
    return <Spinner size="medium" className="user-api-loading" />;
  };

  const checkerTabs = () => {
    return (
      <Container className="checker-container">
        <Container className="checker-tab-container">
          <CheckerTabs />
        </Container>
        {selectedCheckerTab === 0 && (
          <Container className="checker-fleet">
            <WithFeatureToggle Component={Fleet} featurekey="checker_fleet_view" />
          </Container>
        )}
      </Container>
    );
  };
  return (
    <>
      {' '}
      {isLoading ? spinner() : checkerTabs()}
    </>
  );
};
export default Checker;
