import React from 'react';
import { Container } from '@airbus/components-react';
import CollapsibleComponent from './Utils/CollapsibleComponent';
import './Expressions.scss';

interface ExpressionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data:any;
}
/* Component - Expression  */
const Expressions = function Expressions(props: ExpressionProps) {
  const { data } = props;
  return (
    <Container className="expressionContainerStyle">
      <div className="collapseDiv" id="collapsibleTab" data-testid="expression-tab-default">
        <CollapsibleComponent expressionFormulaText={data.expression_formula_text} expressionFormula={data.expression_formula} />
      </div>
    </Container>
  );
};

/* export the component */
export default Expressions;
