/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable quote-props */
import './DownloadInfoList.scss';
import React from 'react';
import {
  Container,
  Row,
  Col,
  Divider,
  Typography,
  Button,
} from '@airbus/components-react';
import { Refresh } from '@airbus/icons/react';
import moment from 'moment';
import {
  DOWNLOADED_TIME,
  EXPORT_BUTTON_UPDATE,
  NO_DOWNLOADED_RECORDS,
  RETRY_DOWNLOAD,
} from '../../../../utils/constants';
import { useAppDispatch } from '../../../../store/hooksTypes';
import { loadDataExportFile } from '../../../../models/downloadStatusModel/downloadStatusThunk';

interface DownloadInfoListProps {
  downloadStatus: DownloadStatus[];
  currentDate: Date;
}
interface RetryPayload {
  operation: string;
  hash_id: string;
  timestamp: string;
  operator_code_icao?: Array<string>;
}

const DownloadInfoList = function DownloadInfoList(
  props: DownloadInfoListProps,
) {
  const { downloadStatus, currentDate } = props;
  const dispatch = useAppDispatch();

  const formatTimestamp: (timestamp: string) => string = (timestamp) => {
    let time = '';
    time = `${moment(currentDate).diff(new Date(timestamp), 'hours').toString()}h`;
    if (time < '1') {
      time = 'less than an hour';
    }
    return time;
  };

  const statusLabel: (
    status: 'In Progress' | 'Failed' | 'Success' | 'InProgress'
  ) => string = (status) => {
    const classnameObj = {
      'In Progress': 'download-status-inProgress',
      Failed: 'download-status-fail',
      Success: 'download-status-success',
      InProgress: 'download-status-inProgress',
    };
    return classnameObj[status];
  };

  // on retry click function to call file generate api with operation update
  const onRetryClick = (fetchedId: string, fetchedTime: string) => {
    const bodyPayload: RetryPayload = {
      operation: EXPORT_BUTTON_UPDATE,
      hash_id: fetchedId,
      timestamp: fetchedTime,
      operator_code_icao: downloadStatus.find((download) => download.hash_id === fetchedId)?.operator_code_icao,
    };
    dispatch(loadDataExportFile(bodyPayload)());
  };

  return (
    <Container className="drawer-cards-design">
      {downloadStatus.length > 0 ? (
        [...downloadStatus]
          .sort((a, b) => {
            return (
              new Date(b.status_changed_at).getTime() - new Date(a.status_changed_at).getTime()
            );
          })
          .map((item: DownloadStatus, index:number) => (
            <Container
              className={
                item.is_highlighted
                  ? 'download-drawer-card-highlighted'
                  : 'download-drawer-card'
              }
              key={`i${index + 1}`}
            >
              <Row>
                <Col>
                  <Typography
                    variant="medium"
                    className={
                      item.export_status === 'Failed'
                        ? 'download-failed-filename'
                        : 'download-filename'
                    }
                  >
                    {item.file_name}
                  </Typography>
                </Col>
                <Col className="download-align">
                  <Typography className={statusLabel(item.export_status)}>
                    {item.export_status}
                  </Typography>
                </Col>
              </Row>
              {item.export_status === 'Failed' && (
                <Row>
                  <Button
                    size="small"
                    className="retry-button"
                    data-testid="retry-button"
                    onClick={() => onRetryClick(item.hash_id, item.timestamp)}
                    icon={<Refresh />}
                  >
                    {RETRY_DOWNLOAD}
                  </Button>
                </Row>
              )}
              <Row>
                <Typography className="download-time-text">
                  {formatTimestamp(item.download_time)}
                  {DOWNLOADED_TIME}
                </Typography>
              </Row>
              <Divider className="divider-design" />
            </Container>
          ))
      ) : (
        <Typography variant="medium" className="no-data-text">
          {NO_DOWNLOADED_RECORDS}
        </Typography>
      )}
    </Container>
  );
};

export default DownloadInfoList;
