import { createSlice } from '@reduxjs/toolkit';
import { REPORT_STATUS_INITIAL_STATE, REPORT_STATUS_SLICE } from './constants';
import {
  loadReportStatusChartAcr, loadReportStatusChartFinStatus, loadReportStatusChartMis, loadReportStatusChartPnrSource,
} from './reportStatusThunk';
import {
  reportStatusChartAcrFullFilled, reportStatusChartAcrPending, reportStatusChartAcrRejected,
  reportStatusChartFinStatusFullFilled,
  reportStatusChartFinStatusPending,
  reportStatusChartFinStatusRejected,
  reportStatusChartMisFullFilled,
  reportStatusChartMisPending,
  reportStatusChartMisRejected,
  reportStatusChartPnrSourceFullFilled,
  reportStatusChartPnrSourcePending,
  reportStatusChartPnrSourceRejected,
} from './reportStatusExtraReducers';

const reportStatusSlice = createSlice({
  name: REPORT_STATUS_SLICE,
  initialState: REPORT_STATUS_INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadReportStatusChartAcr().pending, reportStatusChartAcrPending)
      .addCase(loadReportStatusChartAcr().rejected, reportStatusChartAcrRejected)
      .addCase(loadReportStatusChartAcr().fulfilled, reportStatusChartAcrFullFilled)
      .addCase(loadReportStatusChartMis().pending, reportStatusChartMisPending)
      .addCase(loadReportStatusChartMis().rejected, reportStatusChartMisRejected)
      .addCase(loadReportStatusChartMis().fulfilled, reportStatusChartMisFullFilled)
      .addCase(loadReportStatusChartFinStatus().pending, reportStatusChartFinStatusPending)
      .addCase(loadReportStatusChartFinStatus().rejected, reportStatusChartFinStatusRejected)
      .addCase(loadReportStatusChartFinStatus().fulfilled, reportStatusChartFinStatusFullFilled)
      .addCase(loadReportStatusChartPnrSource().pending, reportStatusChartPnrSourcePending)
      .addCase(loadReportStatusChartPnrSource().rejected, reportStatusChartPnrSourceRejected)
      .addCase(loadReportStatusChartPnrSource().fulfilled, reportStatusChartPnrSourceFullFilled);
  },
});

export default reportStatusSlice;
