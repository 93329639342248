import { createSlice } from '@reduxjs/toolkit';
import {
  DATATABLE_INITIAL_STATE, DATATABLE_SLICE,
} from './constants';
import { partDataPaginationReducer } from './partDataTablePaginationReducer';
import { PartBrowserLoadFullfilled, PartBrowserLoadPending, PartBrowserLoadRejected } from './partDataTableExtraReducer';
import { loadPartBrowseData, loadPartFilterData } from './partDataTableThunk';
import {
  ColumnFilterPayloadUpdate,
  ColumnFilterStateUpdate,
  ColumnLevelFullfilled,
  ColumnLevelPending,
  ColumnLevelRejected,
  partDataTableReducers, setDataTableColumns,
} from './partDataTableReducer';

export const PartDataTableState = createSlice({
  name: DATATABLE_SLICE,
  initialState: DATATABLE_INITIAL_STATE,
  reducers: {
    updatePARTDataPagination: partDataPaginationReducer,
    updatePARTDataTable: partDataTableReducers,
    updatePartTableColumns: setDataTableColumns,
    updatePartColumnLevelFilter: ColumnLevelFullfilled,
    updatePartColumnFilterState: ColumnFilterStateUpdate,
    updatePartColumnFilterPayload: ColumnFilterPayloadUpdate,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadPartBrowseData().pending, PartBrowserLoadPending)
      .addCase(loadPartBrowseData().rejected, PartBrowserLoadRejected)
      .addCase(loadPartBrowseData().fulfilled, PartBrowserLoadFullfilled)
      .addCase(loadPartFilterData().pending, ColumnLevelPending)
      .addCase(loadPartFilterData().rejected, ColumnLevelRejected)
      .addCase(loadPartFilterData().fulfilled, ColumnLevelFullfilled);
  },
});
export const {
  updatePARTDataTable, updatePARTDataPagination, updatePartTableColumns, updatePartColumnFilterState, updatePartColumnFilterPayload,
} = PartDataTableState.actions;
export default PartDataTableState;
