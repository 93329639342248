/* CMI-S UI */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash';
import { getFleetViewData, getFleetViewFilteredData, setFleetViewFilter } from '../../../models/checkerFleetModel/checkerFleetSlice';
import { loadCheckerFleetData, loadCheckerOperators } from '../../../models/checkerFleetModel/checkerFleetThunk';
import {
  DISCREPENCY_OPTIONS,
} from '../../../utils/constants';
import { updateReasonStatus } from '../../../models/reasonStatusMappingModel/reasonStatusMappingSlice';
import { loadReasonStatusMapping } from '../../../models/reasonStatusMappingModel/reasonStatusMappingThunk';

export const getOperatorFromApi = (dispatch: any) => {
  const operatorPayload: any = {
    conditions: {},
    columns: ['operator_code_icao'],
    distinct: true,
  };
  dispatch(loadCheckerOperators(operatorPayload)());
};

export const getFleetViewDataFromApi = (operator: string[], dispatch: any, metaArg: any = '') => {
  const payload: any = {
    conditions: {},
    operator_code_icao: operator,
    orderBy: ['registration_number'],
    sortType: 'ASC',
  };
  dispatch(loadCheckerFleetData(payload)(metaArg));
};
export const handleAcApplyMethods = (FleetDataResp: any, selectedAcTypes: any) => {
  const acTypeData = FleetDataResp.filter((item: any) => selectedAcTypes.includes(item.ac_type));
  const acSubTypeResp = acTypeData.map((item: any) => item.ac_subtype).filter((item: any) => item);
  const acSubTypeUnique = acSubTypeResp.filter((value: any, index: any, array: any) => array.indexOf(value) === index);
  const msnValues = acTypeData.map((item: any) => item.id_aircraft_registration);
  const msnSelected = msnValues.filter((item: string, index: number, array: any) => array.indexOf(item) === index);
  return { acTypeData, acSubTypeUnique, msnSelected };
};
export const handleAcApply = (selectedAcTypes: any, checkerState: any, dispatch: any) => {
  const FleetDataResp = checkerState.data[checkerState.fleet_view_filter_selected?.selected_operator[0]] || [];
  const { acSubTypeUnique, msnSelected, acTypeData } = handleAcApplyMethods(FleetDataResp, selectedAcTypes);
  const filterValue = {
    ...checkerState.fleet_view_filter_selected,
    selected_aircraft: selectedAcTypes,
    selected_sub_aircraft: acSubTypeUnique,
    selected_msn: msnSelected,
    selected_discrepency: DISCREPENCY_OPTIONS,
  };
  const filterValues = {
    ...checkerState.fleet_view_filters,
    ac_sub_type_list: acSubTypeUnique,
    msn_list: msnSelected,
  };
  dispatch(setFleetViewFilter({ fleet_view_filter_selected: filterValue }));
  dispatch(getFleetViewFilteredData({ visibleData: acTypeData }));
  dispatch(getFleetViewData({ fleet_view_filters: filterValues }));
};
export const handleAcSubApplyMethods = (FleetDataResp: any, selectedAcSubTypes: any) => {
  const acSubTypeData = FleetDataResp.filter((item: any) => selectedAcSubTypes.includes(item.ac_subtype));
  const msnValues = acSubTypeData.map((item: any) => item.id_aircraft_registration);
  const msnSelected = msnValues.filter((item: string, index: number, array: any) => array.indexOf(item) === index);
  return { acSubTypeData, msnSelected };
};
export const handleAcSubApply = (selectedAcSubTypes: any, checkerState: any, dispatch: any) => {
  const FleetDataResp = checkerState.data[checkerState.fleet_view_filter_selected?.selected_operator[0]] || [];
  const { acSubTypeData, msnSelected } = handleAcSubApplyMethods(FleetDataResp, selectedAcSubTypes);
  const filterValue = {
    ...checkerState.fleet_view_filter_selected,
    selected_sub_aircraft: selectedAcSubTypes,
    selected_msn: msnSelected,
    selected_discrepency: DISCREPENCY_OPTIONS,
  };
  const filterValues = {
    ...checkerState.fleet_view_filters,
    msn_list: msnSelected,
  };
  dispatch(getFleetViewFilteredData({ visibleData: acSubTypeData }));
  dispatch(setFleetViewFilter({ fleet_view_filter_selected: filterValue }));
  dispatch(getFleetViewData({ fleet_view_filters: filterValues }));
};
export const handleMsnApplyMethods = (FleetDataResp: any, selectedMsn: any) => {
  const msnData = FleetDataResp.filter((item: any) => selectedMsn.includes(item.id_aircraft_registration));
  return msnData;
};
export const handleMsnApply = (selectedMsn: any, checkerState: any, dispatch: any) => {
  const FleetDataResp = checkerState.data[checkerState.fleet_view_filter_selected?.selected_operator[0]] || [];
  const msnData = handleMsnApplyMethods(FleetDataResp, selectedMsn);
  const filterValue = {
    ...checkerState.fleet_view_filter_selected,
    selected_msn: selectedMsn,
    selected_discrepency: DISCREPENCY_OPTIONS,
  };
  const filterValues = {
    ...checkerState.fleet_view_filters,
  };
  dispatch(getFleetViewFilteredData({ visibleData: msnData }));
  dispatch(setFleetViewFilter({ fleet_view_filter_selected: filterValue }));
  dispatch(getFleetViewData({ fleet_view_filters: filterValues }));
};
export const handleStatusApplyMethods = (FleetDataResp: any, selectedStatus: any, selectedMsns: any) => {
  const filteredMsnDta = FleetDataResp.filter((item: any) => selectedMsns.includes(item.id_aircraft_registration));
  const selectedStatusData = selectedStatus.map((item:any) => item.value);
  const statusData = filteredMsnDta.filter((item: any) => selectedStatusData.includes(item.discrepency_result));
  return statusData;
};
export const handleStatusApply = (selectedStatus: any, checkerState: any, dispatch: any) => {
  const FleetDataResp = checkerState.data[checkerState.fleet_view_filter_selected?.selected_operator[0]] || [];
  const selectedMsns = checkerState.fleet_view_filter_selected.selected_msn;
  const statusData = handleStatusApplyMethods(FleetDataResp, selectedStatus, selectedMsns);
  const filterValue = {
    ...checkerState.fleet_view_filter_selected,
    selected_discrepency: selectedStatus,
  };
  dispatch(getFleetViewFilteredData({ visibleData: statusData }));
  dispatch(setFleetViewFilter({ fleet_view_filter_selected: filterValue }));
};

export const checkerReasonStatusMapping = (dispatch:any, reasonStatus:any, checkerState: any) => {
  if (!isEmpty(reasonStatus?.reasonMapping) && !isEmpty(reasonStatus?.statusMapping)) {
    dispatch(updateReasonStatus({
      data: {
        reason_key_mapper: reasonStatus?.reasonMapping,
        status_key_mapper: reasonStatus?.statusMapping,
      },
    }));
  } else {
    dispatch(loadReasonStatusMapping(checkerState.fleet_view_filter_selected?.selected_operator)());
  }
};
