export const REPORT_STATUS_SLICE = 'reportStatus';

const initialChartData: ChartDataType = {
  total_count: 0,
  match_count: 0,
  wrong_part_count: 0,
  tobe_analysed_count: 0,
  match_percentage: 0,
  wrong_part_percentage: 0,
  tobe_analysed_percentage: 0,
  all_msn_contribution: false,
};

const initialPnrSourceData: PnrSourceDataType = {
  source_unmatched_count: 0,
  source_matched_count: 0,
  not_applicable_count: 0,
  total_count: 0,
  source_matched_percentage: 0,
  source_unmatched_percentage: 0,
  not_applicable_percentage: 0,
};
const initialMisAcrSourceData: MisAcrSourceDataType = {
  source_status_count: {},
  total_count: 0,
  all_msn_contribution: false,
};

export const REPORT_STATUS_INITIAL_STATE: ReportStatusState = {
  AcrChartData: { ...initialMisAcrSourceData },
  MisChartData: { ...initialMisAcrSourceData },
  FinStatusChartData: { ...initialChartData },
  PnrSourceComparisonChartData: { ...initialPnrSourceData },
  isAcrChartDataLoading: false,
  isMisChartDataLoading: false,
  isPnrSourceChartDataLoading: false,
  isFinStatusChartDataLoading: false,
  reloadAcrChartData: false,
  reloadMisChartData: false,
  reloadFinStatusChartData: false,
  reloadPnrSourceComparisonChartData: false,
};
