import { createSlice } from '@reduxjs/toolkit';
import { CONCURRENT_DATATABLE_INITIAL_STATE, CONCURRENT_DATATABLE_SLICE } from './constants';
import {
  ConcurrentLoadFullfilled, ConcurrentLoadPending, ConcurrentLoadRejected, setDataTableColumns, ConcurrentFilterStateUpdate, ConcurrentFilterPayloadUpdate, ConcurrentColumnLevelPending, ConcurrentColumnLevelRejected, ConcurrentColumnLevelFullfilled,
} from './concurrentTableReducer';
import { loadConcurrentData, loadCuncurrentFilterData, loadCuncurrentFilterThunk } from './concurrentTableThunk';

const concurrentDataTableSlice = createSlice({
  name: CONCURRENT_DATATABLE_SLICE,
  initialState: CONCURRENT_DATATABLE_INITIAL_STATE,
  reducers: {
    updateConcurrentDataTable: ConcurrentLoadFullfilled,
    updateConTableColumns: setDataTableColumns,
    updateConcurrentFilterState: ConcurrentFilterStateUpdate,
    updateConcurrentFilterPayload: ConcurrentFilterPayloadUpdate,

  },
  extraReducers: (builder) => {
    builder
      .addCase(loadConcurrentData().pending, ConcurrentLoadPending)
      .addCase(loadConcurrentData().rejected, ConcurrentLoadRejected)
      .addCase(loadConcurrentData().fulfilled, ConcurrentLoadFullfilled)
      .addCase(loadCuncurrentFilterData().pending, ConcurrentColumnLevelPending)
      .addCase(loadCuncurrentFilterData().rejected, ConcurrentColumnLevelRejected)
      .addCase(loadCuncurrentFilterData().fulfilled, ConcurrentColumnLevelFullfilled)
      .addCase(loadCuncurrentFilterThunk().pending, ConcurrentLoadPending)
      .addCase(loadCuncurrentFilterThunk().rejected, ConcurrentLoadRejected)
      .addCase(loadCuncurrentFilterThunk().fulfilled, ConcurrentLoadFullfilled);
  },
});
export const {
  updateConcurrentDataTable, updateConTableColumns, updateConcurrentFilterState, updateConcurrentFilterPayload,
} = concurrentDataTableSlice.actions;
export default concurrentDataTableSlice;
