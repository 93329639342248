/* CMI-S UI */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { Buffer } from 'buffer';
import React, { useState, useEffect } from 'react';
import { Container } from '@airbus/components-react';
import { useSearchParams } from 'react-router-dom';
import FilterPanel from '../Shared/FilterPanel/FilterPanel';
import ConcurrentFilter from './components/ConcurrentFilter/ConcurrentFilter';
import Expressions from './components/Expressions/Expressions';
import Table from '../Shared/Table/Table';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { loadConcurrentData, loadCuncurrentFilterData, loadCuncurrentFilterThunk } from '../../models/concurrentTableModel/concurrentTableThunk';
import { getDescriptionWithFormula, handleChangeOutputApply } from '../../utils/commonUtil';
import { setBrowseState } from '../../models/appConfigModel/appConfigSlice';
import { updateConTableColumns, updateConcurrentFilterPayload, updateConcurrentFilterState } from '../../models/concurrentTableModel/concurrentTableSlice';
import { actionColumns, tableColumns, sbColumns } from '../Shared/Tabs/BrowserTab/BrowserTableStructure';
import { handleConcurrentFilterPayload, getDataFromSbChild } from '../Shared/ColumnLevelModal/Utils/ColumnLevelhandleUtils';
/* Component - Concurrent requirement */
const ConcurrentRequirement = function ConcurrentRequirement() {
  const [qryParams] = useSearchParams();
  const qryParamsSet = qryParams.getAll('msn_sb');
  const qryParamsSetDecrypted = JSON.parse(Buffer.from(qryParamsSet[0], 'base64').toString('ascii'));
  const [dependencyFormula] = useState(JSON.parse(qryParamsSetDecrypted.dependencyFormula));
  const concurrentTableState = useAppSelector((state) => state.displayConcurrentDataTable);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadConcurrentData(qryParamsSetDecrypted)());
    dispatch(setBrowseState({ selectedTab: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const expressionData = { expression_formula: { ...dependencyFormula.dependency_formula, desc: getDescriptionWithFormula(dependencyFormula.dependency_formula && dependencyFormula.dependency_formula.item ? dependencyFormula.dependency_formula.item : null, concurrentTableState.ConcurrentData) }, expression_formula_text: qryParamsSetDecrypted.dependencyFormulaText };
  const ConcurrentState = useAppSelector((state) => state.displayConcurrentDataTable);
  const loadingupdate = useAppSelector((state) => state.displayConcurrentDataTable.isConcurrentDataLoading);
  const changeOutputprops = {
    tableState: ConcurrentState,
    tableStateColumns: ConcurrentState.columns,
    allColumns: [...actionColumns, ...tableColumns, ...sbColumns],
    updateTableReducer: updateConTableColumns,
    dispatcher: dispatch,
    handleChangeOutputApply,
  };
  const concurrentFilterProps = {
    tableState: ConcurrentState,
    tableStateColumns: ConcurrentState.columns.slice(1),
    dispatcher: dispatch,
    browserThunk: loadCuncurrentFilterThunk,
    filterThunk: loadCuncurrentFilterData,
    filterPayloadFn: handleConcurrentFilterPayload,
    getDataPayloadFn: getDataFromSbChild,
    updateColumnFilterState: updateConcurrentFilterState,
    updateColumnFilterPayload: updateConcurrentFilterPayload,
  };
  return (
    <Container className="browser-body-container">
      <Container className="filter-panel-container"><FilterPanel showPartTab /></Container>
      <Container className="table-container">
        <ConcurrentFilter filterData={JSON.stringify(qryParamsSetDecrypted)} />
        <>
          <Expressions data={expressionData} />
          <Table tableData={ConcurrentState.ConcurrentData} showPagination={false} showFilterButton filterProps={concurrentFilterProps} showChangeViewButton changeOutputProps={changeOutputprops} loadingupdate={loadingupdate} headerColumns={ConcurrentState.columns} showExportButton={false} tableClassName="sb-browser-data-table" />
        </>
      </Container>
    </Container>
  );
};

/* export the component */
export default ConcurrentRequirement;
