/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { downloadFile } from '../../utils/commonMethods/generalMethods';
import { DOWNLOAD_STATUS_DB_MAP } from './constants';

/* eslint-disable no-param-reassign */
export const setExportDownloadFulfilledReducer = (state: DownloadsState, { payload }: NewDownloadAction) => {
  // check if the hash id exists and set polling status to In progress after retry clicked for failed
  const foundItem: any = { ...state }.downloadStatus?.find((item: DownloadStatus) => item?.hash_id === payload.hash_id);
  const foundItemIndex = state.downloadStatus.indexOf(foundItem);
  const setNotification = !state.isDownloadDrawerClose ? state.notificationCount + 1 : state.notificationCount;
  if (foundItemIndex >= 0 && !foundItem.polling_status.includes('Progress')) {
    const newFileState = {
      file_name: payload.file_name ? payload.file_name : { ...state }.downloadStatus[foundItemIndex]?.file_name,
      hash_id: { ...state }.downloadStatus[foundItemIndex].hash_id,
      export_status: DOWNLOAD_STATUS_DB_MAP[payload.export_status],
      timestamp: payload.timestamp,
      is_highlighted: false,
      is_downloaded: false,
      filter_payload: { ...state }.downloadStatus[foundItemIndex]?.filter_payload,
      operator_code_icao: { ...state }.downloadStatus[foundItemIndex]?.operator_code_icao,
      export_type: { ...state }.downloadStatus[foundItemIndex]?.export_type || '',
      polling_status: DOWNLOAD_STATUS_DB_MAP[payload.export_status],
    };
    // updating existing file records post retry click and appending other records which have no change
    return {
      ...state,
      fileSuccess: true,
      downloadStatus: { ...state }.downloadStatus.map((eachFile:any) => {
        if (eachFile.hash_id === newFileState.hash_id) {
          return { ...eachFile, ...newFileState };
        }
        return { ...eachFile };
      }),
      notificationCount: setNotification,
    };
  }
  // success flow scenario of download file records
  return {
    ...state,
    fileSuccess: true,
    downloadStatus: [
      ...state.downloadStatus,
      {
        file_name: payload.file_name,
        hash_id: payload.hash_id,
        export_status: DOWNLOAD_STATUS_DB_MAP[payload.export_status],
        timestamp: payload.timestamp,
        download_file_link: payload?.download_file_link,
        is_highlighted: false,
        status_changed_at: payload.status_changed_at || new Date().toISOString(),
        is_downloaded: false,
        filter_payload: payload?.filter_payload,
        operator_code_icao: payload?.operator_code_icao,
        export_type: payload.export_type || '',
        download_time: payload.download_time || new Date().toISOString(),
        polling_status: DOWNLOAD_STATUS_DB_MAP[payload.export_status],
      },
    ],
    notificationCount: setNotification,
  };
};

export const setNewDownloadRejectedReducer = (state: DownloadsState) => ({
  ...state,
  fileSuccess: false,
  errorMessage: 'New Download rejected',
});

export const setNewDownloadPendingReducer = (state: DownloadsState) => ({
  ...state,
  fileSuccess: true,
});

export const setDownloadStatusFulfilledReducer = (state: DownloadsState, action: PollingAction) => ({
  ...state,
  fileSuccess: true,
  downloadStatus: JSON.parse(JSON.stringify(state.downloadStatus)).map((element: DownloadStatus) => {
    Object.keys(action.payload.download_status).forEach((hashId: any) => {
      if (element.hash_id === hashId) {
        element.polling_status = DOWNLOAD_STATUS_DB_MAP[action.payload.download_status[hashId]];
        element.status_changed_at = new Date().toISOString();
        element.is_highlighted = true;
        element.export_status = (element.polling_status === 'Failed' ? element.polling_status : element.export_status);
      }
    });
    return element;
  }),
});

export const setDownloadStatusRejectedReducer = (state: DownloadsState) => ({
  ...state,
  fileSuccess: false,
  errorMessage: 'Download status rejected',
});
export const setDownloadStatusPendingReducer = (state: DownloadsState) => ({
  ...state,
  fileSuccess: true,
});

export const setDownloadFileLinkFulfilledReducer = (state: DownloadsState, action: DownloadFileLinkAction) => ({
  ...state,
  fileSuccess: false,
  downloadStatus: JSON.parse(JSON.stringify(state.downloadStatus)).map((element: DownloadStatus) => {
    if (element.hash_id === action.payload.hash_id) {
      element.download_file_link = action.payload.download_file_link;
      element.is_downloaded = true;
      element.export_status = element.polling_status;
      downloadFile(action.payload.download_file_link);
    }
    return element;
  }),
});

export const setDownloadFileLinkRejectedReducer = (state: DownloadsState) => ({
  ...state,
  fileSuccess: false,
  errorMessage: 'download file link rejected',
});

export const setDownloadFileLinkPendingReducer = (state: DownloadsState) => ({
  ...state,
  fileSuccess: true,
});
