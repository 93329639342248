import { createSlice } from '@reduxjs/toolkit';
import {
  REASON_STATUS_MAPPING_INITIAL_STATE, REASON_STATUS_MAPPING_SLICE,
} from './constants';
import {
  loadReasonStatusMapPending,
  loadReasonStatusMapRejected,
  loadReasonStatusMapFullfilled,
} from './reasonStatusMappingExtraReducers';
import {
  loadReasonStatusMapping,
} from './reasonStatusMappingThunk';
import { updateReasonStatusMapping } from './reasonStatusMappingReducer';

export const reasonStatusMappingSlice = createSlice({
  name: REASON_STATUS_MAPPING_SLICE,
  initialState: REASON_STATUS_MAPPING_INITIAL_STATE,
  reducers: { updateReasonStatus: updateReasonStatusMapping },
  extraReducers: (builder) => {
    builder
      .addCase(loadReasonStatusMapping().pending, loadReasonStatusMapPending)
      .addCase(loadReasonStatusMapping().rejected, loadReasonStatusMapRejected)
      .addCase(loadReasonStatusMapping().fulfilled, loadReasonStatusMapFullfilled);
  },
});
export const {
  updateReasonStatus,
} = reasonStatusMappingSlice.actions;
export default reasonStatusMappingSlice;
