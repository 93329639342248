import React from 'react';
import { Toast } from '@airbus/components-react';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import { RootState } from '../../../store/store';
import './ToastComponent.scss';
import { removeToast } from '../../../models/toastModel/toastSlice';

/**
 * This toast component takes a location to determine which toast instance to render the respective toast messages.
 *
 * To add this toast in a new location, add it to that place and put the location name.
 * Add new toast data in `toastModel\constant.ts` file.
 *
*/
const ToastComponent = (props: { location: string }) => {
  const { location } = props;
  const dispatch = useAppDispatch();
  const { toasts } = useAppSelector((state: RootState) => state.toast);
  const toastList = toasts.filter(({ toastLocation }) => toastLocation.includes(location));
  const toastClass = {
    info: 'toast-cls toast-customisation',
    warning: 'toastwarning',
    error: 'toast-cls toast-customisation',
    success: 'toastsuccess',
  };

  return (
    <>
      {toastList.map((toast, index) => {
        return (
          <Toast
            key={`i${index + 1}`}
            inline
            className={toast.toastVariantType ? toastClass[toast.toastVariantType] : 'toast-cls toast-customisation'}
            data-testid="toast-test"
            variant={toast.toastVariantType}
            onClose={() => dispatch(removeToast(toast))}
            allowClose
          >
            {toast.toastMessage}
          </Toast>
        );
      })}
    </>
  );
};

export default ToastComponent;
